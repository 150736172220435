<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Carga de Contenido
            </h1>
        </div>
    </div>
    <!-- CONTENT -->
    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-lg="row"
        fxLayout.gt-md="column"
    >
        <!-- REACTIVE FORM EXAMPLE -->

        <form
            class="mat-card mat-elevation-z4 p-24 mr-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            name="form"
            [formGroup]="form"
        >
            <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
                fusePerfectScrollbar
            >
                <!--primer row de inputs-->
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Seleccionar Materia</mat-label>
                    <mat-select required formControlName="materia">
                        <mat-option value="Materia 1"> Materia 1 </mat-option>
                        <mat-option value="Materia 2"> Materia 2 </mat-option>
                        <mat-option value="Materia 3"> Materia 3 </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor seleccione una materia.</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Seleccionar Unidad</mat-label>
                    <mat-select required formControlName="unidad">
                        <mat-option value="Unidad 1"> Unidad 1 </mat-option>
                        <mat-option value="Unidad 2"> Unidad 2 </mat-option>
                        <mat-option value="Unidad 3"> Unidad 3 </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor seleccione una unidad.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Seleccionar Sede</mat-label>
                    <mat-select required formControlName="sede">
                        <mat-option value="Sede 1"> Sede 1 </mat-option>
                        <mat-option value="Sede 2"> Sede 2 </mat-option>
                        <mat-option value="Sede 3"> Sede 3 </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor seleccione una sede.</mat-error>
                </mat-form-field>
            </div>
            <!--segundo row de inputs-->
            <div>
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Link del video</mat-label>
                    <input matInput formControlName="link" required />
                    <mat-icon matSuffix class="secondary-text"
                        >account_circle</mat-icon
                    >
                    <mat-error>Por favor ingrese un link.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Seleccionar Respuesta correcta</mat-label>
                    <mat-select required formControlName="respuesta">
                        <mat-option value="Respuesta 1">
                            Respuesta 1
                        </mat-option>
                        <mat-option value="Respuesta 2">
                            Respuesta 2
                        </mat-option>
                        <mat-option
                            *ngIf="form.value.respuesta3 !== ''"
                            value="Respuesta 3"
                        >
                            Respuesta 3
                        </mat-option>
                        <mat-option
                            *ngIf="form.value.respuesta4 !== ''"
                            value="Respuesta 4"
                        >
                            Respuesta 4
                        </mat-option>
                        <mat-option
                            *ngIf="form.value.respuesta5 !== ''"
                            value="Respuesta 5"
                        >
                            Respuesta 5
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error
                        >Por favor seleccione la respuesta correcta.</mat-error
                    >
                </mat-form-field>
            </div>
            <!--tercer row de inputs-->
            <div>
                <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
                    <mat-label>Titulo</mat-label>
                    <input matInput formControlName="titulo" required />
                    <mat-icon matSuffix class="secondary-text"
                        >account_circle</mat-icon
                    >
                    <mat-error>Por favor ingrese un titulo.</mat-error>
                </mat-form-field>
            </div>
            <!--cuarto row de inputs-->

            <!--respuesta 1-->
            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Respuesta 1</mat-label>
                <input matInput formControlName="respuesta1" required />
                <mat-icon matSuffix class="secondary-text"
                    >account_circle</mat-icon
                >
                <mat-error>Por favor ingrese la respuesta 1.</mat-error>
            </mat-form-field>
            <!--respuesta 2-->
            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Respuesta 2</mat-label>
                <input matInput formControlName="respuesta2" required />
                <mat-icon matSuffix class="secondary-text"
                    >account_circle</mat-icon
                >
                <mat-error>Por favor ingrese la respuesta 2.</mat-error>
            </mat-form-field>
            <!--respuesta 3-->
            <mat-form-field
                appearance="outline"
                fxFlex="50"
                class="pl-4"
                *ngIf="form.value.respuesta1 && form.value.respuesta2"
            >
                <mat-label>Respuesta 3 (opcional)</mat-label>
                <input matInput formControlName="respuesta3" />
                <mat-icon matSuffix class="secondary-text"
                    >account_circle</mat-icon
                >
            </mat-form-field>
            <!--respuesta 4-->
            <mat-form-field
                appearance="outline"
                fxFlex="50"
                class="pl-4"
                *ngIf="
                    form.value.respuesta1 &&
                    form.value.respuesta2 &&
                    form.value.respuesta3
                "
            >
                <mat-label>Respuesta 4 (opcional)</mat-label>
                <input matInput formControlName="respuesta4" />
                <mat-icon matSuffix class="secondary-text"
                    >account_circle</mat-icon
                >
            </mat-form-field>
            <!--respuesta 5-->
            <mat-form-field
                appearance="outline"
                fxFlex="50"
                class="pl-4"
                *ngIf="
                    form.value.respuesta1 &&
                    form.value.respuesta2 &&
                    form.value.respuesta3 &&
                    form.value.respuesta4
                "
            >
                <mat-label>Respuesta 5 (opcional)</mat-label>
                <input matInput formControlName="respuesta5" />
                <mat-icon matSuffix class="secondary-text"
                    >account_circle</mat-icon
                >
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <div>
                    <label for="editor" style="text-align: center"
                        ><h2 style="color: #0e2a3b">
                            <strong>Cargar Contenido</strong>
                        </h2></label
                    >

                    <quill-editor
                        required
                        [styles]="editorStyle"
                        [modules]="configuracion"
                        formControlName="contenidoUnidad"
                        placeholder="Insertar texto aqui"
                    ></quill-editor>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center" style="margin-top: 5px">
                <button mat-button color="accent" (click)="enviar()">
                    Listo
                </button>
            </div>
            <!--termina el quill-->

            <!--empiezan los botones-->
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <div fxLayout="row" fxFlex="1 0 auto">
                    <div class="input-group mb-3">
                        <div class="upload-img">
                            <input
                                type="file"
                                class="file"
                                style="display: none"
                                accept="video/*"
                                (change)="updateSource($event)"
                                name="image"
                                #fileVideo
                                accept="video/avi, video/mp4, video/mkv"
                                required
                            /><br />
                            <button
                                mat-button
                                color="accent"
                                #image
                                type="button"
                                class="btn btn-primary"
                                (click)="fileVideo.click()"
                            >
                                Seleccionar Video
                            </button>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <div class="upload-img">
                            <input
                                type="file"
                                class="file"
                                style="display: none"
                                accept="image/*"
                                (change)="updateSource($event)"
                                name="image"
                                #fileUpload
                                accept="image/png, image/jpeg, image/jpg"
                                value="assets/img/box.jpg"
                                required
                            /><br />
                            <button
                                mat-button
                                color="accent"
                                #image
                                type="button"
                                class="btn btn-primary"
                                (click)="fileUpload.click()"
                            >
                                Seleccionar Imagen
                            </button>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <div>
                            <input
                                type="file"
                                accept="application/pdf"
                                style="display: none"
                                (change)="updateSource($event)"
                                #filePDF
                                required
                            /><br />
                            <button
                                mat-button
                                color="accent"
                                type="button"
                                class="btn btn-primary"
                                (click)="filePDF.click()"
                            >
                                Seleccionar PDF
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end">
                <button mat-button color="accent" (click)="agregarContenido()">
                    Agregar Contenido
                </button>
            </div>
            <!--terminan los botones-->
        </form>
        <!--empieza la tabla-->
        <form
            class="mat-card mat-elevation-z4 p-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            name="form"
            [formGroup]="form"
            style="height: 500px"
        >
            <div class="h2 mb-24">Materias Creadas</div>

            <mat-table
                class="products-table"
                #table
                [dataSource]="dataSource"
                matSort
                [@animateStagger]="{ value: '50' }"
                fusePerfectScrollbar
            >
                <!-- Columna Materia -->
                <ng-container matColumnDef="Materia">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Materias
                    </mat-header-cell>
                    <mat-cell *matCellDef="let lista">
                        <p class="text-truncate">
                            {{ lista.MATERIA }}
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Columna Unidades -->
                <ng-container matColumnDef="Unidad">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Unidades
                    </mat-header-cell>
                    <mat-cell *matCellDef="let lista">
                        <p class="text-truncate">
                            {{ lista.UNIDAD }}
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Columna Sede -->
                <ng-container matColumnDef="Sede">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Sede
                    </mat-header-cell>
                    <mat-cell *matCellDef="let sede">
                        <p class="text-truncate">
                            {{ sede.NOMBRE }}
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Columna Acciones -->
                <ng-container matColumnDef="Acciones">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        fxShow.gt-md
                        >Acciones</mat-header-cell
                    >
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-md>
                        <button
                            mat-button
                            color="accent"
                            (click)="openDialog()"
                        >
                            Editar
                        </button>
                        <button
                            mat-button
                            color="warn"
                            (click)="eliminarContenido()"
                        >
                            Eliminar
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Columna Unidad 
                <ng-container matColumnDef="unidad">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Unidad</mat-header-cell
                    >
                    <mat-cell *matCellDef="let unidad">
                        <p class="text-truncate">
                            Unidad ingresada
                        </p>
                    </mat-cell>
                </ng-container>-->
                <!-- Columna Peso 
                <ng-container matColumnDef="peso">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Peso</mat-header-cell
                    >
                    <mat-cell *matCellDef="let unidad">
                        <p class="text-truncate">
                            Peso Ingresado
                        </p>
                    </mat-cell>
                </ng-container>-->

                <!-- Columna Acciones -->

                <mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>

                <mat-row
                    *matRowDef="let product; columns: displayedColumns"
                    class="product"
                    matRipple
                >
                </mat-row>
            </mat-table>
        </form>
    </div>
</div>

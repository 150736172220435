<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="center center">

        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1 [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }">
                Materias
            </h1>
        </div>
    </div>
    <!-- CONTENT -->
    <!-- CONTENT -->
    <div class="content p-12">
        <div fxLayout="column" fxLayoutAlign="center" *ngFor="let semestre of semestres">
            <h1>{{semestre}}</h1>

            <div class="courses" fxLayout="row wrap" fxLayoutAlign="center" [@animateStagger]="{ value: '50' }">

                <!--INICIO DEL CURSO 1-->
                <div class="course" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="25"
                    [@animate]="{ value: '*', params: { y: '100%' } }"
                    *ngFor="let materia of filtrarMaterias(semestre)">
                    <div mat-button color="accent" class="course-content" fxLayout="column" fxFlex="1 1 auto"
                        routerLink="/cargar-unidad" (click)="guardarId(materia.id_disciplina)">
                        <div class="header" fxLayout="row" fxLayoutAlign="center center">
                            <div class="length" fxLayout="row" fxLayoutAlign="center center">
                                <mat-icon class="length-icon s-20" style="color: rgba(255, 255, 255, 0.75)">school
                                </mat-icon>
                                <div style="color: rgba(255, 255, 255, 0.75)">
                                    {{materia.nome}}
                                </div>
                            </div>
                        </div>

                        <div class="content" fxLayout="column" fxLayoutAlign="center center" fxFlex="1 1 auto">
                            <div class="h1"> Seccion: {{materia.nome_semestre}}</div>
                            <div class="h1"> Sede: {{materia.nome_filial}}</div>
                        </div>

                        <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                            <button (click)="verUnidadesComoAlumno(materia.id,
                            materia.nome,
                            materia.id_disciplina,
                            2,
                            materia.nome_professor,
                            materia.sobrenome)" mat-button color="accent">
                                Entrar
                            </button>
                            <button (click)="guardarId(materia.id_disciplina)" mat-button color="accent">
                                Cargar Unidad
                            </button>
                        </div>
                    </div>
                </div>
                <!--FIN DEL CURSO 1-->
            </div>
        </div>
    </div>


</div>
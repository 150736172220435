<div id="academy-courses">
    <div class="form" fxLayout="column">
        <div fxLayout="row" fxFlex="1 0 auto" fxLayoutAlign=" center">
            <div fxLayout="column" fxFlex="40" fxLayoutAlign="start">
                <h1>{{ f.titulo }}</h1>
                <label>Calificacion total</label>
                <label style="font-weight: bolder"
                    >{{ f.calificacion }} pts</label
                >
            </div>

            <div fxFlex="60" fxLayoutAlign="end" fxLayout="row">
                <div fxLayout="column" class="mr-48">
                    <label>Fecha entrega</label>
                    <label style="font-weight: bolder">{{ f.fecha_fin }}</label>
                    <label style="font-weight: bolder">{{ f.hora_fin }}</label>
                </div>

                <div fxLayout="column">
                    <label>Formato entrega</label>
                    <label style="font-weight: bolder">{{
                        f.formatoEntrega
                    }}</label>
                </div>
            </div>
        </div>

        <hr style="margin-bottom: 10px" />

        <h2>Instrucciones</h2>

        <h3 style="margin-bottom: 0">PASO A PASO</h3>

        <hr style="margin-bottom: 10px" />

        <div fxFlex="1 0 auto">
            <p style="max-width: 100%;" fxFlex="1 0 auto">{{ f.instrucciones }}</p>
        </div>

        <h3 style="margin-bottom: 0">ANEXOS</h3>

        <hr style="margin-bottom: 10px" />

        <div fxLayout="column" fxFlex="1 0 auto">
            <label>{{ f.link }}</label>
            <label>{{ f.file.name }}</label>
        </div>

        <hr style="margin-bottom: 10px" />

        <div fxLayout="row" fxLayoutAlign="end" fxFlex="1 0 auto">
            <button
                fxFlex="20"
                class="editar"
                mat-raised-button
                color="accent"
                mat-dialog-close
            >
                Cerrar
            </button>
            <button
                fxFlex="20"
                class="publicar"
                mat-raised-button
                color="accent"
                (click)="publicar()"
            >
                Publicar
            </button>
        </div>
    </div>
</div>

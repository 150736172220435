import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";

import {
    FuseConfirmDialogModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    FuseSidebarModule,
    FuseWidgetModule,
} from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";

import { ToolbarComponent } from "app/layout/components/toolbar/toolbar.component";
import { MatBadgeModule } from "@angular/material/badge";
import { ModalActualizarDatosComponent } from "./modal-actualizar-datos/modal-actualizar-datos.component";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatListModule } from "@angular/material/list";
import { MatTableModule } from "@angular/material/table";
import { NgxMatFileInputModule } from "@angular-material-components/file-input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxPaginationModule } from "ngx-pagination";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { ChatModule } from "app/chat/chat.module";

@NgModule({
    declarations: [ToolbarComponent, ModalActualizarDatosComponent],
    imports: [
        RouterModule,

        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatBadgeModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,

        CommonModule,
        //Imports de Angular Material
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTabsModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatGridListModule,
        FuseSidebarModule,
        MatStepperModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        BrowserAnimationsModule,
        FuseSharedModule,
        ReactiveFormsModule,
        FormsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatSnackBarModule,
        //NoopAnimationsModule,
        NgxPaginationModule,
        MatBadgeModule,
        MatDatepickerModule,
        ChatModule,
        MatExpansionModule,
        MatSortModule,
    ],
    exports: [ToolbarComponent],
})
export class ToolbarModule {}

import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { AlumnosService } from "app/alumnos/alumnos.service";
import { Cotizacion, TablaPagos, DatosComprobantes } from "app/alumnos/alumnos";
import Swal from "sweetalert2";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmarPagoComponent } from "./confirmar-pago/confirmar-pago.component";

@Component({
    selector: "app-modal-finalizar-pago",
    templateUrl: "./modal-finalizar-pago.component.html",
    styleUrls: ["./modal-finalizar-pago.component.scss"],
    animations: fuseAnimations,
})
export class ModalFinalizarPagoComponent implements OnInit {
    form: FormGroup;
    metodoPago: number;
    datosComprobantes: DatosComprobantes = {};
    public cotizacion: Cotizacion = {};
    public Tabla: TablaPagos[] = [];
    displayedColumns: string[] = [
        "nombre",
        "contrato",
        "fechavenc",
        "valor",
        "acciones",
    ];
    eligioMetodoPago: boolean = false;
    TablaFinalizarPago: any;
    enviarDatosComprobante: any;
    descuentos: any = [
        {
            porcentaje: 0,
        },
        {
            porcentaje: 10,
        },
        {
            porcentaje: 12,
        },
        {
            porcentaje: 15,
        },
        {
            porcentaje: 20,
        },
        {
            porcentaje: 30,
        },
        {
            porcentaje: 40,
        },
    ];
    TotalGuaranies: number;
    TotalReales: number;
    dialogRef: any;
    constructor(
        private _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private _Apialumno: AlumnosService
    ) {
        this.TotalGuaranies = 0;
        this.TotalReales = 0;
    }

    ngOnInit(): void {
        this.loading("Cargando Datos..");
        this._Apialumno.getCotizacion().subscribe((data) => {
            this.cotizacion = data.data;
            // console.log("se llamo a cotizacion", data);
            this.Tabla = JSON.parse(sessionStorage.getItem("factura"));
            //  console.log("En el modal, esto lo seleccionado", this.Tabla);
            for (let datos of this.Tabla) {
                this.TotalGuaranies = this.TotalGuaranies + +datos.valor;
            }
            let nome_filial = sessionStorage.getItem("nome_filial");
            if (nome_filial === "Pedro Juan Caballero") {
                this.TotalReales =
                    this.TotalGuaranies / this.cotizacion.cotacao_pjc;
            } else {
                this.TotalReales =
                    this.TotalGuaranies / this.cotizacion.cotacao_cde2;
            }
            Swal.close();
        });
    }

    confirmarPago() { }

    loading(titulo) {
        Swal.fire({
            title: titulo,

            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

    borrarItem(obj) {
        this.Tabla = this.Tabla.filter((item) => item !== obj);
        sessionStorage.setItem("factura", JSON.stringify(this.Tabla));
        this.TotalGuaranies = 0;
        for (let datos of this.Tabla) {
            this.TotalGuaranies = this.TotalGuaranies + +datos.valor;
        }
        let nome_filial = sessionStorage.getItem("nome_filial");
        if (nome_filial === "Pedro Juan Caballero") {
            this.TotalReales =
                this.TotalGuaranies / this.cotizacion.cotacao_pjc;
        } else {
            this.TotalReales =
                this.TotalGuaranies / this.cotizacion.cotacao_cde2;
        }
    }
    calcularDescuento(value) {
        console.log("Este porcentage eligio", value);
        this.TotalGuaranies = 0;
        for (let datos of this.Tabla) {
            this.TotalGuaranies = this.TotalGuaranies + +datos.valor;
        }
        let descuento = (this.TotalGuaranies * value) / 100;
        this.TotalGuaranies = this.TotalGuaranies - descuento;
        let nome_filial = sessionStorage.getItem("nome_filial");
        if (nome_filial === "Pedro Juan Caballero") {
            this.TotalReales =
                this.TotalGuaranies / this.cotizacion.cotacao_pjc;
        } else {
            this.TotalReales =
                this.TotalGuaranies / this.cotizacion.cotacao_cde2;
        }
    }

    metodoDePago(value) {
        this.loading("Recopilando Datos");
        this.metodoPago = +value;
        this.eligioMetodoPago = true;
        console.log("Eligio el metodo de pago", value);
        sessionStorage.setItem("metodoPago", value);
        this._Apialumno.getDatosComprobantes().subscribe((data) => {
            this.datosComprobantes = data;
            Swal.close();
        });
    }

    enviarDatosComprobantes(datos) {
        this.loading("Enviando datos");
        this._Apialumno.postDatosComprobantes(datos).subscribe(
            (response) => {
                console.log("se enviaron los datos");
                Swal.close();
            },
            (err) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ha ocurrido un error con el servidor",
                });
            }
        );
    }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'app-modal-ver-grabado',
    templateUrl: './modal-ver-grabado.component.html',
    styleUrls: ['./modal-ver-grabado.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ModalVerGrabadoComponent implements OnInit {
    dialogTitle: string;
    link_video: any;
    materia: string;
    /**
     * Constructor
     *
     * @param {MatDialogRef<ModalVerGrabadoComponent>} matDialogRef
     * @param _data
     * 
     */
    constructor(public matDialogRef: MatDialogRef<ModalVerGrabadoComponent>,
        private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        this.materia = sessionStorage.getItem("nombre_materia");
        this.link_video = this.sanitizer.bypassSecurityTrustResourceUrl(
            sessionStorage.getItem("link_video_grabado")
        );
    }

}

<div id="academy-courses" class="page-layout simple" *ngIf="metodoPago === 1">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Confirmar Pago
            </h1>
        </div>
    </div>
    <!-- CONTENT -->
    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-md="row"
    >
        <!-- REACTIVE FORM EXAMPLE -->

        <form
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            name="form"
        >
            <!--EMPIEZA PRIMERA LINEA DE INPUTS-->
            <div
                class="inputs"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
            >
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Nome</mat-label>
                    <input
                        matInput
                        required
                        [(ngModel)]="datosComprobantes.nome_sobrenome"
                        [disabled]="true"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </mat-form-field>
            </div>
            <!--TERMINA PRIMERA LINEA DE INPUTS-->

            <!--EMPIEZA SEGUNDA LINEA DE INPUTS-->
            <div
                class="inputs"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
            >
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Cidade</mat-label>
                    <input
                        matInput
                        required
                        [(ngModel)]="datosComprobantes.nome_cidade"
                        [disabled]="true"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Estado</mat-label>
                    <input
                        matInput
                        required
                        [(ngModel)]="datosComprobantes.nome_estado"
                        [disabled]="true"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </mat-form-field>
            </div>
            <!--TERMINA SEGUNDA LINEA DE INPUTS-->

            <!--EMPIEZA TERCERA LINEA DE INPUTS-->
            <div
                class="inputs"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
            >
                <mat-form-field appearance="outline" fxFlex="70">
                    <mat-label>Email</mat-label>
                    <input
                        matInput
                        required
                        [(ngModel)]="datosComprobantes.email"
                        [disabled]="true"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </mat-form-field>

                <mat-form-field appearance="outline" class="pl-4" fxFlex="30">
                    <mat-label>CEP</mat-label>
                    <input
                        matInput
                        required
                        [(ngModel)]="datosComprobantes.cep"
                        [disabled]="true"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </mat-form-field>
            </div>
            <!--TERMINA TERCERA LINEA DE INPUTS-->

            <!--EMPIEZA CUARTA LINEA DE INPUTS-->
            <div
                class="inputs"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
            >
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Endereco</mat-label>
                    <input
                        matInput
                        required
                        [(ngModel)]="datosComprobantes.rua"
                        [disabled]="true"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </mat-form-field>

                <mat-form-field appearance="outline" class="pl-4" fxFlex="50">
                    <mat-label>Telefone</mat-label>
                    <input
                        matInput
                        required
                        [(ngModel)]="datosComprobantes.telefone_celular1"
                        [disabled]="true"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </mat-form-field>
            </div>
            <!--TERMINA CUARTA LINEA DE INPUTS-->

            <!--EMPIEZA QUINTA LINEA DE INPUTS-->
            <div
                class="inputs"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
            >
                <mat-form-field appearance="outline" fxFlex="30">
                    <mat-label>Documento</mat-label>
                    <input matInput required />
                </mat-form-field>

                <mat-form-field appearance="outline" class="pl-4" fxFlex="70">
                    <mat-label>CPF</mat-label>
                    <input
                        matInput
                        required
                        [(ngModel)]="datosComprobantes.cpf"
                        [disabled]="true"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </mat-form-field>
            </div>
            <!--TERMINA QUINTA LINEA DE INPUTS-->
            <div fxLayoutAlign="center">
                <button mat-button color="accent">Confirmar pago</button>
            </div>
        </form>
    </div>
</div>

<div id="academy-courses" class="page-layout simple" *ngIf="metodoPago === 2">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Confirmar Pago
            </h1>
        </div>
    </div>
    <!-- CONTENT -->
    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-md="row"
    >
        <!-- REACTIVE FORM EXAMPLE -->

        <form
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            name="form"
        >
            <!--EMPIEZA PRIMERA LINEA DE INPUTS-->
            <div
                class="inputs"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
            >
                <mat-form-field appearance="outline" fxFlex="70">
                    <mat-label>Nome</mat-label>
                    <input
                        matInput
                        required
                        [(ngModel)]="datosComprobantes.nome_sobrenome"
                        [ngModelOptions]="{ standalone: true }"
                        [disabled]="true"
                    />
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="30" class="pl-4">
                    <mat-label>Valor del comprovante</mat-label>
                    <input matInput required />
                    <mat-error
                        >Por favor ingrese el valor del comprovante.</mat-error
                    >
                </mat-form-field>
            </div>
            <!--TERMINA PRIMERA LINEA DE INPUTS-->

            <!--EMPIEZA SEGUNDA LINEA DE INPUTS-->
            <div
                class="inputs"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
            >
                <mat-form-field appearance="outline" fxFlex="35">
                    <mat-label>Data e hora do deposito</mat-label>
                    <input matInput required />
                    <mat-error
                        >Por favor elija la data e hora do deposito.</mat-error
                    >
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="65" class="pl-4">
                    <mat-label>Descricao</mat-label>
                    <input matInput required />
                    <mat-error>Por favor ingrese la descricao.</mat-error>
                </mat-form-field>
            </div>
            <!--TERMINA SEGUNDA LINEA DE INPUTS-->

            <!--EMPIEZA TERCERA LINEA DE INPUTS-->
            <div
                class="inputs"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
            >
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Seleccione un Descuento</mat-label>
                    <mat-select required>
                        <mat-option
                            *ngFor="let desc of descuentos"
                            [value]="desc.porcentaje"
                        >
                            {{ desc.porcentaje }}%
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor elija un descuento.</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Negociado con</mat-label>
                    <mat-select required>
                        <mat-option> Juan </mat-option>
                        <mat-option> Pedro </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor elija un negociador.</mat-error>
                </mat-form-field>
            </div>
            <!--TERMINA TERCERA LINEA DE INPUTS-->

            <!--EMPIEZA CUARTA LINEA DE INPUTS-->
            <div
                class="inputs"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
            >
                <mat-form-field appearance="outline" fxFlex="33">
                    <mat-label>WhatsApp</mat-label>
                    <input
                        matInput
                        required
                        [(ngModel)]="datosComprobantes.telefone_celular1"
                        [ngModelOptions]="{ standalone: true }"
                        [disabled]="true"
                    />
                </mat-form-field>

                <mat-form-field appearance="outline" class="pl-4" fxFlex="34">
                    <mat-label>Conta Banco</mat-label>
                    <input matInput required />
                    <mat-error>Por favor ingrese la conta banco.</mat-error>
                </mat-form-field>

                <div class="pl-4">
                    <button mat-button color="accent">
                        Arquivo Comprovante
                    </button>
                </div>
            </div>
            <!--TERMINA CUARTA LINEA DE INPUTS-->
        </form>
    </div>
</div>

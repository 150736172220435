import {
    Component,
    OnInit,
    ViewEncapsulation,
    ViewChildren,
    QueryList,
    ChangeDetectorRef,


} from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { SeccionesUnidad, respuestSeccion } from "../alumnos";
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Inject } from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { AlumnosService } from '../alumnos.service';


@Component({
    selector: "academy-courses ",
    templateUrl: "./realizar-unidad.component.html",
    styleUrls: ["./realizar-unidad.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class RealizarUnidadComponent implements OnInit {
    animationDirection: "left" | "right" | "none";
    courseStepContent: any;
    currentStep: number;
    id_unidad: any;
    seccionesUnidad: SeccionesUnidad[] = [];
    terminoSanitizer: boolean = false
    respuestas = [];
    porcentage: number

    pofavo: string = ''
    respuestaSeccion: respuestSeccion = {};

    @ViewChildren(FusePerfectScrollbarDirective)
    fuseScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;
    /**
     * Constructor
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseSidebarService} _fuseSidebarService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseSidebarService: FuseSidebarService,
        private sanitizer: DomSanitizer,
        private route: Router,
        private alumnoService: AlumnosService,
        @Inject(DOCUMENT) private document: Document

    ) {
        // Set the defaults

        this.animationDirection = "none";
        this.currentStep = 0;

    }

    ngOnInit(): void {
        this.porcentage = +sessionStorage.getItem('porcentage')
        this.id_unidad = +sessionStorage.getItem('id_unidad')
        this.getSeccionContenido('Cargando contenido..', true)

    }
    /**
     * After view init
     * 
     */
    ngAfterViewInit(): void {

        this.courseStepContent = this.fuseScrollbarDirectives.find(
            (fuseScrollbarDirective) => {
                return (
                    fuseScrollbarDirective.elementRef.nativeElement.id ===
                    "course-step-content"
                );
            }

        );
    }
    getSeccionContenido(texto, entro) {
        if (this.id_unidad && entro) {
            this.loading(texto)
            this.alumnoService.getSeccionesUnidad(this.id_unidad).subscribe(
                response => {
                    //  console.log('Secciones de la unidad')
                    //  console.log(response.data)
                    this.seccionesUnidad = [...response.data]
                    console.log(response.data)
                    let encontro = false;
                    let i = 0;
                    for (let seccion of this.seccionesUnidad) {
                        seccion.url_video = this.sanitizer.bypassSecurityTrustResourceUrl(seccion.url_video)
                        this.respuestas.push({ respuesta: seccion.resposta })
                        if (seccion.status_liberado === 0 && !encontro) {
                            this.currentStep = i - 1;
                            encontro = true
                        }
                        i = i + 1
                        if (i === this.seccionesUnidad.length && seccion.status_liberado === 1) {
                            this.currentStep = i - 1
                        }
                    }
                    Swal.close()
                    this.terminoSanitizer = true;
                }, err => {
                    Swal.fire(
                        'Opss..',
                        'Ocurrio un error al cagar el contenido',
                        'error'
                    )
                }
            )
        } else if (this.id_unidad && !entro) {
            this.loading(texto)
            this.alumnoService.getSeccionesUnidad(this.id_unidad).subscribe(
                response => {
                    // console.log('Se actualizado la unidad')
                    // console.log(response.data)
                    // this.seccionesUnidad = [...response.data]
                    let encontro = false;
                    let i = 0;
                    for (let data of response.data) {
                        //  seccion.url_video = this.sanitizer.bypassSecurityTrustResourceUrl(seccion.url_video)
                        this.seccionesUnidad[i].status_liberado = data.status_liberado
                        this.seccionesUnidad[i].resposta = data.resposta
                        this.seccionesUnidad[i]
                        // this.respuestas.push({ respuesta: data.resposta })
                        if (data.status_liberado === 0 && !encontro) {
                            this.currentStep = i - 1;
                            encontro = true
                        }
                        i = i + 1
                        if (i === this.seccionesUnidad.length && data.status_liberado === 1) {
                            this.currentStep = i - 1
                        }
                        let id_materia = +sessionStorage.getItem('id_materia')
                        if (id_materia) {
                            this.alumnoService.getUnidadesMateria(id_materia).subscribe(
                                response => {
                                    for (let data of response.data) {
                                        if (data.id === this.id_unidad) {
                                            this.porcentage = data.porcentagem
                                            sessionStorage.setItem('porcentage', data.porcentagem)
                                        }
                                    }
                                }
                            )
                        }

                    }
                    Swal.close()
                }, err => {
                    Swal.fire(
                        'Opss..',
                        'Ocurrio un error al actualizar el contenido',
                        'error'
                    )
                }
            )
        }
    }

    /**
     * Go to step
     *
     * @param step
     */
    gotoStep(step, el: HTMLElement): void {

        if (this.seccionesUnidad[step].status_liberado === 0) {
            return
        }

        // Decide the animation direction
        this.animationDirection = this.currentStep < step ? "left" : "right";

        // Run change detection so the change
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Set the current step
        this.currentStep = step;
        //console.log("Current step", this.currentStep);
        //console.log('Se llamo a go to step')
        el.scrollIntoView();


    }

    /**
     * Go to next step
     */
    gotoNextStep(el: HTMLElement, enviar, correcta): void {

        if (enviar && this.respuestas[this.currentStep].respuesta === correcta) {
            this.loading('Enviando respuesta')
            this.respuestaSeccion.matricula_curso_id = +sessionStorage.getItem('matricula_curso_id')
            this.respuestaSeccion.periodo_letivo_id = +sessionStorage.getItem('periodo_letivo_id')
            this.respuestaSeccion.oferta_unidade_id = this.seccionesUnidad[this.currentStep].plata_oferta_unidade_id
            this.respuestaSeccion.resposta = this.respuestas[this.currentStep].respuesta


            this.respuestaSeccion.aulas_id = this.seccionesUnidad[this.currentStep].id
            // console.log('Lo que iba a enviar', this.respuestaSeccion)
            // console.log('Se esta enviando..')
            this.alumnoService.postRespuestaSeccion(this.respuestaSeccion).subscribe(
                response => {
                    // console.log('Se envio correctamente la respuesta')
                    // console.log(response)
                    if (this.currentStep === this.seccionesUnidad.length - 1) {
                        this.unidadCompletada()
                    } else {
                        this.getSeccionContenido('Actualizando datos..', false)

                    }
                }
            )
            if (this.currentStep === this.seccionesUnidad.length - 1) {
                return
            } else {
                // Set the animation direction
                this.animationDirection = "left";

                // Run change detection so the change
                // in the animation direction registered
                this._changeDetectorRef.detectChanges();

                // Increase the current step
                this.currentStep++;
                //console.log('Se llamo a next step')
                console.log('respuesta', this.respuestas)
                el.scrollIntoView();
            }
        }
        else if (this.respuestas[this.currentStep].respuesta !== correcta) {
            Swal.fire(
                'Opss..',
                'Respuesta Incorrecta',
                'error'
            )
        }
        else if (this.currentStep === this.seccionesUnidad.length - 1) {
            return
        } else {
            // Set the animation direction
            this.animationDirection = "left";

            // Run change detection so the change
            // in the animation direction registered
            this._changeDetectorRef.detectChanges();

            // Increase the current step
            this.currentStep++;
            //console.log('Se llamo a next step')
            // console.log('respuesta', this.respuestas)
            el.scrollIntoView();
        }




    }

    /**
     * Go to previous step
     */
    gotoPreviousStep(el: HTMLElement): void {
        if (this.currentStep === 0) {
            return;
        }

        // Set the animation direction
        this.animationDirection = "right";

        // Run change detection so the change
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Decrease the current step
        this.currentStep--;

        //console.log('Se llamo a previous step', this.currentStep)
        el.scrollIntoView();


    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
    unidadCompletada() {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Unidad Completada Exitosamente',
            showConfirmButton: false,
            timer: 1500
        }).then(() => {
            this.route.navigateByUrl('/unidades-materia')
        }
        )
    }
    loading(titulo) {
        Swal.fire({
            title: titulo,

            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

}

import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { Location } from "@angular/common";

import { ModalIntroduccionUnidadComponent } from "./modal-introduccion-unidad/modal-introduccion-unidad.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AlumnosService } from "../alumnos.service";
import { Router } from "@angular/router";
import {
    UnidadesMateria,
    LivesGrabados,
    LibrosDisciplina,
    TrabajosPracticos,
    TrabajosPracticosFinal,
    DatosTP,
    ComentarioProf,
} from "../alumnos";
import Swal from "sweetalert2";
import { ModalVerGrabadoComponent } from "./modal-ver-grabado/modal-ver-grabado.component";
import { DomSanitizer } from "@angular/platform-browser";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
export var single = [
    {
        name: "Juan",
        value: 8940000,
    },
    {
        name: "USA",
        value: 5000000,
    },
    /* {
         "name": "France",
         "value": 7200000
     },
     {
         "name": "UK",
         "value": 6200000
     }*/
];
export interface Presencia {
    aulas?: number;
    presenca?: number;
    porcentagem?: number;
}

@Component({
    selector: "app-unidades-materia",
    templateUrl: "./unidades-materia.component.html",
    styleUrls: ["./unidades-materia.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class UnidadesMateriaComponent implements OnInit {
    unidadesMateria: UnidadesMateria[] = [];
    livesMateria: LivesGrabados[] = [];
    grabadosMateria: LivesGrabados[] = [];
    librosDisciplina: LibrosDisciplina[] = [];
    form: FormGroup;
    id_materia: string;
    nombre__materia: string;
    link_video: any;
    tipo_materia: string;
    trabajosPracticos: TrabajosPracticos[] = [];
    nombre_profesor: string;
    panelOpenState = false;
    realizar: boolean = false;
    presencia: Presencia = {};
    tpAlumnos: TrabajosPracticosFinal[] = [];
    datosTP: DatosTP = {};
    comentarioProf?: ComentarioProf[] = [];
    enviartrabajo: FormGroup;

    /*
    Para el chart
    
    */
    colorScheme = {
        domain: ["#5AA454", "#A10A28", "#C7B42C", "#AAAAAA"],
    };
    single: any[] = [];
    view: any[] = [700, 400];
    // options
    gradient: boolean = true;
    showLegend: boolean = true;
    showLabels: boolean = true;
    isDoughnut: boolean = false;

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _matDialog: MatDialog,
        private alumnosService: AlumnosService,
        private route: Router,
        private sanitizer: DomSanitizer,
        private _location: Location
    ) {
        single[0].name = "test";
        //Object.assign(this, { single });
    }

    ngOnInit(): void {
        // Reactive Form
        this.enviartrabajo = this._formBuilder.group({
            titulo: ["", Validators.required],
            file: ["", Validators.required],
            oferta_disciplina_id : [""],
            trabalho_id : [""]
        });

        if (sessionStorage.getItem("link_video")) {
            this.link_video = this.sanitizer.bypassSecurityTrustResourceUrl(
                sessionStorage.getItem("link_video")
            );
        } else {
            this.link_video = null;
        }
        this.nombre_profesor = sessionStorage.getItem("nombre_profesor");
        this.id_materia = sessionStorage.getItem("id_materia");
        this.nombre__materia = sessionStorage.getItem("nombre_materia");
        this.tipo_materia = sessionStorage.getItem("tipo_materia");
        // console.log(this.id_materia)
        if (this.id_materia) {
            this.loading("Cargando Unidades de la Materia..");
            this.alumnosService.getUnidadesMateria(this.id_materia).subscribe(
                (response) => {
                    //console.log('Unidades de la Materia')
                    console.log(response);
                    this.unidadesMateria = [...response.data];

                    Swal.close();
                },
                (err) => {
                    Swal.fire(
                        "",
                        "Ocurrio un error al cargar las unidades",
                        "error"
                    );
                }
            );
            this.alumnosService
                .getPresenciaMateria(
                    sessionStorage.getItem("periodo_letivo_id"),
                    this.id_materia
                )
                .subscribe((data) => {
                    this.presencia = data["presencas"];

                    single[0].name = "Presencias";
                    single[0].value = this.presencia.presenca;
                    single[1].name = "Ausencias";
                    single[1].value =
                        this.presencia.aulas - this.presencia.presenca;
                    Object.assign(this, { single });
                    console.log("Presencias ", this.single);
                });
            this.alumnosService
                .getLivesUnidad(this.id_materia)
                .subscribe((data) => {
                    this.livesMateria = [...data.data];
                    this.grabadosMateria = [...data.data];
                    //  console.log(data.data)
                    //  console.log("se llamo a la api")
                });
            let disciplinas_id = +sessionStorage.getItem("disciplinas_id");
            this.alumnosService
                .getLibrosDisciplia(disciplinas_id)
                .subscribe((data) => {
                    console.log(data);
                    this.librosDisciplina = data.data;
                });
        } else {
            //this.route.navigateByUrl("/materias");
        }
        //Para trabajos practicos
        let id = +sessionStorage.getItem("id_materia");
        let id_usuario = +sessionStorage.getItem("id_usuario");
        this.alumnosService
            .getTrabajosPracticos(id, id_usuario)
            .subscribe((data) => {
                this.trabajosPracticos = data;
                console.log("Se trajeron los trabasjos", data);
            });

        this.getTrabajosPracticos();
    }
    percentageFormatting(c) {
        return Math.round(c);
    }
    loading(titulo) {
        Swal.fire({
            title: titulo,

            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }
    //Chart
    onSelect(data): void {
        console.log("Item clicked", JSON.parse(JSON.stringify(data)));
    }

    onActivate(data): void {
        console.log("Activate", JSON.parse(JSON.stringify(data)));
    }

    onDeactivate(data): void {
        console.log("Deactivate", JSON.parse(JSON.stringify(data)));
    }
    //Fin chart
    unidadSeleccionada(id_unidad, porcentage) {
        sessionStorage.setItem("id_unidad", id_unidad);
        sessionStorage.setItem("porcentage", porcentage);
    }
    atras() {
        sessionStorage.removeItem("id_materia");
        sessionStorage.removeItem("nombre_materia");
        sessionStorage.removeItem("link_video");
        this._location.back();
    }
    onNavigate(link_meet, name) {
        if (name === "Live") {
            window.open("https://" + link_meet, "_blank");
        } else {
            window.open(link_meet);
        }
    }
    videoGrabado(vimeo_grabado_link) {
        sessionStorage.setItem("link_video_grabado", vimeo_grabado_link);
        let dialogReff = this._matDialog.open(ModalVerGrabadoComponent);
        dialogReff.afterClosed().subscribe((result) => {
            sessionStorage.removeItem("link_video_grabado");
        });
    }
    irForo() {
        this.route.navigateByUrl("/chat");
    }
    pdfUnidad(id_unidad) {
        this.loading("Cargando Archivo");
        this.alumnosService.getPdfUnidad(id_unidad).subscribe(
            (response) => {
                console.log("Pdf", response);
                Swal.fire({
                    title: "Contenido Archivos de Unidad",
                    text: response[0].nome,
                    showCancelButton: true,
                    confirmButtonText: "Descargar Pdf",
                    cancelButtonText: "Cancelar",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.open(
                            "https://api.ucpvirtual.com.br/storage/medicina/plataforma/" +
                                response[0].caminho
                        );
                    }
                });
            },
            (err) => {
                Swal.fire("", "Hubo un problema con el servidor", "error");
            }
        );
    }
    descargarTrabajoPractico(caminho) {
        window.open(
            "https://api.ucpvirtual.com.br/storage/medicina/trabalhos/" +
                caminho
        );
    }
    // ngOnDestroy() {
    //    sessionStorage.removeItem("id_materia");
    //   sessionStorage.removeItem("nombre_materia");
    //   sessionStorage.removeItem("link_video");
    // }

    /*https://api.ucpvirtual.com.br/storage/medicina/plataforma/" +
                                response[0].caminho */

    realizarTrabajo(id_trabajo) {
        this.realizar = true;
        this.enviartrabajo.patchValue(
            {
                trabalho_id : id_trabajo
            }
        );
        this.alumnosService.getDatosTP(id_trabajo).subscribe((data) => {
            this.datosTP = data;
            console.log("Datos del trabajo", this.datosTP);
        });
        this.alumnosService.getComentarioProf(id_trabajo).subscribe((data) => {
            this.comentarioProf = data;
            console.log("se trajo el comentario", this.comentarioProf);
        });
    }

    realizarTrabajoFalso() {
        this.realizar = false;
    }

    getTrabajosPracticos() {
        this.alumnosService
            .getTrabajoPracticoAlumno(this.id_materia)
            .subscribe((data) => {
                this.tpAlumnos = data;
                console.log("trabajos alumnos", this.tpAlumnos);
            });
    }

    enviarTP() {
        this.loading("Enviando Trabajo Practico");
        //console.log("Esto es lo que quiero subir", this.fileToUpload);
        
        this.alumnosService.postTrabajoPractico(this.enviartrabajo.value).subscribe(
            (response) => {
                console.log(response);
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Trabajo Practico enviado con exito!",
                    showConfirmButton: false,
                    timer: 1500,
                });
            },
            (err) => {
                Swal.fire("", "Hubo un problema con el servidor", "error");
            }
        );
    }
}

<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                style="margin-bottom: 0%; margin-top: 0%"
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Introduccion a {{ nombre_materia }}
            </h1>
        </div>
    </div>

    <form>
        <div class="container" fusePerfectScrollbar>
            <iframe
                class="responsive-iframe"
                [src]="link_video"
                frameborder="0"
                allowfullscreen
                allow="autoplay; encrypted-media"
            ></iframe>
        </div>
        <div
            mat-dialog-actions
            class="m-0 p-16"
            fxLayout="row"
            fxLayoutAlign="end center"
        >
            <button
                (click)="matDialogRef.close()"
                mat-button
                color="primary"
                class="save-button"
                aria-label="SAVE"
            >
                Ingresar
            </button>
        </div>
    </form>
</div>

<mat-toolbar>
    <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutAlign.gt-xs="space-between center"
        fxFlex
    >
        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a
                style="float: right;"
                mat-button
                routerLink="/documentation/getting-started/introduction"
                >Desarrollado por Universidad Central del Paraguay</a
            >
        </div>
    </div>
</mat-toolbar>

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { AdministradoresService } from "../administradores.service";
import { MatSort } from "@angular/material/sort";
import Swal from "sweetalert2";
import { Evaluaciones, ExamenesList } from "../administradores.modal";
import { ExcelService } from "../excel.service";

export interface filtroExamen {
    id?: number;
    texto?: string;
}

@Component({
    selector: "app-evaluaciones",
    templateUrl: "./evaluaciones.component.html",
    styleUrls: ["./evaluaciones.component.scss"],
    animations: fuseAnimations,
})
export class EvaluacionesComponent implements OnInit {
    print = false;
    displayedColumns: string[] = [
        "fecha",
        "tipo",
        "status",
        "alumno",
        "nota",
        "respuestas",
        "tiempo",
        "acciones",
    ];
    listadoExamenes: ExamenesList[] = [];
    filtradoExamenes: ExamenesList[] = [];
    listaSede: any[] = [];
    p: number = 1;
    form: FormGroup;
    itemsPerPage: number = 5;
    term: string;

    filtroTipoEval: filtroExamen[] = [];
    filtroSemestre: filtroExamen[] = [];
    filtroSede: filtroExamen[] = [];

    filtradoSeleccionados = [];

    evaluaciones: Evaluaciones = {};

    id_examen: any;
    usuarios_id: any;
    //usuarios_id: any;

    constructor(
        private administradoresService: AdministradoresService,
        private _formBuilder: FormBuilder,
        private route: Router,
        private excelService: ExcelService
    ) {}

    ngOnInit(): void {
        this.id_examen = +sessionStorage.getItem("id_examen");
        this.usuarios_id = +sessionStorage.getItem("id_usuario");
        // Reactive Form
        this.form = this._formBuilder.group({
            sede: ["", Validators.required],
            semestre: ["", Validators.required],
            materia: ["", Validators.required],
        });
        this.listaSede = [
            {
                ID: 1,
                NOMBRE_SEDE: "CDE LAGO",
            },
            {
                ID: 2,
                NOMBRE_SEDE: "PJC",
            },
            {
                ID: 3,
                NOMBRE_SEDE: "CDE JESUITICA",
            },
        ];
        this.loading("Cargando Examenes");
        this.administradoresService
            .getEvaluaciones(this.id_examen)
            .subscribe((data) => {
                this.evaluaciones = data;
                Swal.close();
            });
    }

    loading(titulo) {
        Swal.fire({
            title: titulo,
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }
    Imprimir() {
        this.print = true;
        setTimeout(() => {
            window.print();
            this.print = false;
        }, 1);
    }
    editarExamen(examen) {
        console.log(examen);
    }
    verPreguntasExamen(id, id_profesor) {
        sessionStorage.setItem("id_disciplina", id);
        sessionStorage.setItem("id_profesor", id_profesor);
        sessionStorage.setItem("ruta", "/lista-examenes-admin");
        this.route.navigateByUrl("/cargar-unidad");
    }
    eliminarExamen(id_examen) {
        console.log("Este es el id_examen", id_examen);
        /*this.profesoresService.deleteExamen(id_examen).subscribe(
            response => {
                console.log(response)
            }
        )*/
        Swal.fire({
            title: "Estas seguro de eliminar el examen?",
            //text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminalo!",
        }).then((result) => {
            if (result.value) {
                this.administradoresService.deleteExamen(id_examen).subscribe(
                    (response) => {
                        console.log(response);
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "El examen se elimino correctamente",
                            showConfirmButton: false,
                            timer: 1500,
                        }).then(() => {
                            //this.loading("Actualizando Lista de Examenes");
                            // this.getMaterias(this.id_semestre)
                        });
                    },
                    (err) => {
                        Swal.fire(
                            "Upps",
                            "Hubo un problema con el servidor",
                            "error"
                        );
                    }
                );
            }
        });
    }

    sortData(sort: MatSort) {
        console.log("Se llama al sort", sort);
        const data = this.evaluaciones.notas.slice();
        if (!sort.active || sort.direction === "")
            this.evaluaciones.notas = data;

        this.evaluaciones.notas = data.sort((a, b) => {
            const isAsc = sort.direction === "asc";
            switch (sort.active) {
                case "fecha":
                    return compare(a.fecha, b.fecha, isAsc);
                case "status":
                    return compare(a.status, b.status, isAsc);
                case "nome":
                    return compare(a.nome, b.nome, isAsc);
                case "nota":
                    return compare(a.nota, b.nota, isAsc);
                case "porcentagem_nota":
                    return compare(
                        a.porcentagem_nota,
                        b.porcentagem_nota,
                        isAsc
                    );
                case "tiempo":
                    return compare(a.tiempo, b.tiempo, isAsc);

                default:
                    return 0;
            }
        });

        function compare(
            a: number | string,
            b: number | string,
            isAsc: boolean
        ) {
            return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
        }
    }

    //filtrado(value, event) {
    //    //Lo que selecciono
    //    console.log(value);
    //    //Si checkeo o saco el check
    //    console.log(event);
    //    if (event) {
    //        //Si checkeo hacemos el push
    //        this.filtradoSeleccionados.push(value);
    //
    //        //Preguntamos si hay mas de un item seleccionado para el filtro
    //        if (this.filtradoSeleccionados.length > 1) {
    //            //Si hay mas de 1 item para el filtro procedemos a hacer push del filtrado
    //            //para ir agrregando al array
    //            let result = this.listadoExamenes.filter(
    //                (valor) => valor.nome_semestre === value
    //            );
    //            //Como el resultado del filter es un Array de objetos, procedemos
    //            //a hacer push uno a uno dentro del for
    //            for (let res of result) {
    //                this.filtradoExamenes.push(res);
    //            }
    //        } else {
    //            //Si hay un solo item seleccionado para el filtro
    //            //directamente igualamos el filter a nuestro filtradoExamenes
    //            this.filtradoExamenes = this.listadoExamenes.filter(
    //                (valor) => valor.nome_semestre === value
    //            );
    //        }
    //
    //        //Si saco el check sacamos
    //    } else {
    //        let index = this.filtradoSeleccionados.indexOf(value);
    //        this.filtradoSeleccionados.splice(index, 1);
    //        //Si hay algun checkbox seleccionado
    //        if (this.filtradoSeleccionados.length > 0) {
    //            //En este caso filtramos todo lo que no sea igual a lo que
    //            //acaba de saca el check dierctamente del array
    //            //de filtrado examenes
    //            this.filtradoExamenes = this.filtradoExamenes.filter(
    //                (valor) => valor.nome_semestre !== value
    //            );
    //        }
    //        //Si saco todos los checkbox directamente volvemos a la lista original y completa
    //        else {
    //            this.filtradoExamenes = [...this.listadoExamenes];
    //        }
    //    }
    //    console.log(
    //        "Este es el vector de filtrados",
    //        this.filtradoSeleccionados
    //    );
    //    console.log("Este es el array filtrado", this.filtradoExamenes);
    //}

    //FILTRO TURBIO

    filtrosSeleccionados = [];
    filtrado2(valor, propiedad) {
        //Logica del vector de filtros

        if (this.filtrosSeleccionados.length == 0) {
            this.filtrosSeleccionados.push({
                value: valor,
                propiedad: propiedad,
            });
        } else {
            if (
                this.filtrosSeleccionados.filter(
                    (x) => x.value == valor && x.propiedad == propiedad
                ).length > 0
            ) {
                let posicion = -1;
                let i = 0;
                for (let eliminar of this.filtrosSeleccionados) {
                    if (
                        eliminar.value == valor &&
                        eliminar.propiedad == propiedad
                    ) {
                        posicion = i;
                    }
                    i++;
                }
                this.filtrosSeleccionados.splice(posicion, 1);
            } else {
                this.filtrosSeleccionados.push({
                    value: valor,
                    propiedad: propiedad,
                });
            }
        }

        //Vector de filtros con datos correctos
        if (this.filtrosSeleccionados.length > 0) {
            //Si tenemos filtros

            //Preparar para los filtros internos
            let cantidadTipo = this.filtrosSeleccionados.filter(
                (x) => x.propiedad == "NOMBRE_TIPO_EXAMEN"
            ).length;
            let cantidadSede = this.filtrosSeleccionados.filter(
                (x) => x.propiedad == "NOMBRE_FILIAL"
            ).length;
            let cantidadSemestre = this.filtrosSeleccionados.filter(
                (x) => x.propiedad == "nome_semestre"
            ).length;

            console.log("Esots son los filtros", this.filtrosSeleccionados);
            console.log(
                "Cantidades ",
                cantidadTipo,
                cantidadSede,
                cantidadSemestre
            );

            //Agregar todos los filtros de una
            this.filtradoExamenes = this.listadoExamenes.filter((x) => {
                let pasa = false;

                let cumpleTipo = false;
                let cumpleSede = false;
                let cumpleSemestre = false;

                if (cantidadTipo == 0) {
                    cumpleTipo = true;
                }
                if (cantidadSede == 0) {
                    cumpleSede = true;
                }
                if (cantidadSemestre == 0) {
                    cumpleSemestre = true;
                }

                for (let filtro of this.filtrosSeleccionados) {
                    if (x[filtro.propiedad] == filtro.value) {
                        if (filtro.propiedad == "nome_semestre") {
                            cumpleSemestre = true;
                        }
                        if (filtro.propiedad == "NOMBRE_FILIAL") {
                            cumpleSede = true;
                        }
                        if (filtro.propiedad == "NOMBRE_TIPO_EXAMEN") {
                            cumpleTipo = true;
                        }
                    }
                }
                return cumpleTipo && cumpleSede && cumpleSemestre;
            });
        } else {
            this.filtradoExamenes = this.listadoExamenes;
        }
    }

    verExamen(id_alumno, nome, sobrenome) {
        sessionStorage.setItem("desde", "admin");
        sessionStorage.setItem("ruta", "/evaluaciones");
        sessionStorage.setItem("id_alumno", id_alumno);
        sessionStorage.setItem("nombre_alumno", nome + " " + sobrenome);
        this.route.navigateByUrl("/ver-examen");
    }

    exportarExcel() {
        const notas: any[] = [];
        for (const nota of this.evaluaciones.notas) {
            notas.push({
                Fecha: nota.fecha,
                Nombre: nota.nome,
                Apellido: nota.sobrenome,
                Nota: nota.nota,
                Porcentaje: nota.porcentagem_nota + "%",
                Tiempo: nota.tiempo,
                Rindio: nota.status === 1 ? "Si" : "No",
            });
        }
        this.excelService.exportAsExcelFile(notas, "Informe de evaluaciones");
    }
}

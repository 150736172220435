<div id="academy-courses" class="page-layout simple inner-scroll">
    <div fusePerfectScrollbar>
        <!-- HEADER -->
        <div
            class="header accent p-16 p-sm-24"
            fxLayout="column"
            fxLayoutAlign="center center"
        >
            <div class="hero-text">
                <mat-icon class="hero-icon">school</mat-icon>
                <h1
                    [@animate]="{
                        value: '*',
                        params: { delay: '100ms', y: '25px' }
                    }"
                >
                    Lista de Calificaciones
                </h1>
            </div>
        </div>
        <!--
    <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-lg="row" fxLayout.gt-md="column"
        id="products">
        <form class="mat-card mat-elevation-z4 p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
            name="form">
            <div class="h2 mb-24">Seleccione una Materia</div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                ACA EMPIEZA SELECCIONAR MATERIA

                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Elegir Materia</mat-label>
                    <mat-select required>
                        <mat-option value="Materia 1">
                            Anatomia
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">outlined_flag</mat-icon>
                    <mat-error>Por favor elija una materia.</mat-error>
                </mat-form-field>
                 ACA TERMINA SELECCIONAR MATERIA


            </div>
        </form>

    </div>-->
        <div
            class="mb-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxLayout.gt-lg="row"
            fxLayout.gt-md="column"
        >
            <!--TABLA DE CALIFICACIONES DE LAS  UNIDADES y TRABAJOS DE LA MATERIA
        <form class="mat-card mat-elevation-z4 p-24 mr-24" fxLayout="column" fxLayoutAlign="start" name="form"
            fxFlex="1 0 auto">
            <div class="h2 mb-24">Unidades y Trabajos Practicos</div>
            ACA EMPIEZA LA TABLA
            <div class="center">
                <mat-table class="products-table" #table [dataSource]="calificacionUnidadesTrabajo" matSort
                    [@animateStagger]="{ value: '50' }" fusePerfectScrollbar>
                     Columna Materia  
                    <ng-container matColumnDef="Materia">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Materia</mat-header-cell>
                        <mat-cell *matCellDef="let calificacion">
                            <p class="text-truncate">{{ calificacion.materia }}</p>
                        </mat-cell>
                    </ng-container>

                   Columna Unidad/Trabajo 
                    <ng-container matColumnDef="Unidad/Trabajo">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Unidad/Trabajo</mat-header-cell>
                        <mat-cell *matCellDef="let calificacion">
                            <p class="text-truncate" *ngIf="calificacion.unidad">
                                {{ calificacion.unidad }}
                            </p>
                            <p class="text-truncate" *ngIf="calificacion.trabajo">
                                {{ calificacion.trabajo }}
                            </p>
                        </mat-cell>

                    </ng-container>

                     Columna Puntaje 
                    <ng-container matColumnDef="Puntaje">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Puntaje</mat-header-cell>
                        <mat-cell *matCellDef="let calificacion">
                            <p class="category text-truncate">
                                {{ calificacion.puntaje }}/{{ calificacion.de }}
                            </p>
                        </mat-cell>
                    </ng-container>


                    <mat-header-row *matHeaderRowDef="displayedColumnsUnidades; sticky: true"></mat-header-row>

                    <mat-row *matRowDef="let product; columns: displayedColumnsUnidades" class="product" matRipple>
                    </mat-row>
                </mat-table>

                <mat-paginator #paginator [length]="calificacionUnidadesTrabajo.length" [pageIndex]="0" [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
            </div>
        </form>
        ACA TERMINA LA TABLA DE UNIDADES Y TRABAJOS DE LA MATERIA-->

            <!--ACA COMIENZA LA TABLA DE CALIFICACIONES DE LOS EXAMENES-->
            <form
                class="mat-card mat-elevation-z4 p-24 mr-24"
                fxLayout="column"
                fxLayoutAlign="start"
                name="form"
                fxFlex="1 0 auto"
            >
                <div class="h2 mb-24">Examenes</div>
                <div class="center">
                    <mat-table
                        class="products-table"
                        #table
                        [dataSource]="
                            calificaciones
                                | paginate: { itemsPerPage: 5, currentPage: p }
                        "
                        [@animateStagger]="{ value: '50' }"
                        fusePerfectScrollbar
                        matSort
                        (matSortChange)="sortData($event)"
                    >
                        <!-- Columna Materia -->
                        <ng-container matColumnDef="Materia">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header="NOME_MATERIA"
                                fxFlex="25"
                                >Materia</mat-header-cell
                            >
                            <mat-cell *matCellDef="let examen" fxFlex="25">
                                <p class="text-truncate">
                                    {{ examen.NOME_MATERIA }}
                                </p>
                            </mat-cell>
                        </ng-container>

                        <!-- Columna Tipo -->
                        <ng-container matColumnDef="Examen">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header="NOME_TIPO_EXAMEN"
                                fxFlex="20"
                                >Tipo</mat-header-cell
                            >
                            <mat-cell *matCellDef="let examen" fxFlex="20">
                                <p class="text-truncate">
                                    {{ examen.NOME_TIPO_EXAMEN }}
                                </p>
                            </mat-cell>
                        </ng-container>

                        <!-- Columna Fecha -->
                        <ng-container matColumnDef="Fecha">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header="FECHA_INICIO"
                                fxFlex="20"
                                >Fecha
                            </mat-header-cell>
                            <mat-cell *matCellDef="let examen" fxFlex="20">
                                <p class="text-truncate">
                                    {{ examen.FECHA_INICIO }}
                                </p>
                            </mat-cell>
                        </ng-container>

                        <!-- Columna Nota -->
                        <ng-container matColumnDef="Nota">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header="NOTA_ALUNO "
                                fxFlex="5"
                                >Nota</mat-header-cell
                            >
                            <mat-cell *matCellDef="let examen" fxFlex="5">
                                <p class="category text-truncate">
                                    {{ examen.NOTA_ALUNO }}
                                </p>
                            </mat-cell>
                        </ng-container>
                        <!-- Columna Duracion -->
                        <ng-container matColumnDef="Duracion">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header="TIEMPO"
                                fxFlex="10"
                                >Duracion
                            </mat-header-cell>
                            <mat-cell *matCellDef="let examen" fxFlex="10">
                                <p class="category text-truncate">
                                    {{ examen.TIEMPO }}min
                                </p>
                            </mat-cell>
                        </ng-container>
                        <!-- Columna Oportundades 
                    <ng-container matColumnDef="Oportunidad">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Oportunidad
                        </mat-header-cell>
                        <mat-cell *matCellDef="let examen">
                            <p class="category text-truncate">
                                {{ examen.oportunidad }}
                            </p>
                        </mat-cell>
                    </ng-container>-->
                        <!-- Columna Acciones -->
                        <ng-container matColumnDef="Acciones">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                                fxFlex="20"
                                >Acciones
                            </mat-header-cell>
                            <mat-cell *matCellDef="let examen" fxFlex="20">
                                <button
                                    (click)="
                                        verExamen(
                                            examen.ID_EXAMEN,
                                            examen.NOME_MATERIA
                                        )
                                    "
                                    mat-button
                                    color="accent"
                                    routerLink="/ver-examen"
                                >
                                    Ver Examen
                                </button>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row
                            *matHeaderRowDef="
                                displayedColumnsExamenes;
                                sticky: true
                            "
                        ></mat-header-row>

                        <mat-row
                            *matRowDef="
                                let product;
                                columns: displayedColumnsExamenes
                            "
                            class="product"
                            matRipple
                        >
                        </mat-row>
                    </mat-table>
                    <pagination-controls
                        (pageChange)="p = $event"
                        autoHide="true"
                        responsive="true"
                        previousLabel="Anterior"
                        nextLabel="Siguiente"
                    ></pagination-controls>
                </div>
            </form>
            <!--ACA TERMINA LA TABLA DE CALIFICACIONES DE  EXAMENES-->
        </div>
    </div>
</div>

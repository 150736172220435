import { Component, OnInit, Inject } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { ModalAgregarPreguntaComponent } from "app/administradores/cargar-unidad-admin/modal-agregar-pregunta/modal-agregar-pregunta.component";
import {
    FormControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import {
    EditarExamen,
    ListaUnidades,
    UnidadesMateria,
} from "app/administradores/administradores.modal";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AdministradoresService } from "app/administradores/administradores.service";
import { isUndefined } from "lodash";
import { DatePipe } from "@angular/common";
import Swal from "sweetalert2";
import { DateAdapter } from "@angular/material/core";
export interface nuevaUnidad {
    id_examen?: number;
    id_unidad?: number;
}
@Component({
    selector: "app-modal-editar-examen-admin",
    templateUrl: "./modal-editar-examen-admin.component.html",
    styleUrls: ["./modal-editar-examen-admin.component.scss"],
    animations: fuseAnimations,
    providers: [DatePipe],
})
export class ModalEditarExamenAdminComponent implements OnInit {
    id_examen: number;
    public habilitarPor: string;
    //Este se utilizaria eventualmente si se desea cambiar
    //las unidades para el examen
    unidadesExamen = new FormControl();
    listaUnidadesReal: UnidadesMateria[] = [];
    //En este objeto guardariamos el original
    examen: EditarExamen = {};
    //Aca se guardaria lo editado
    editarExamen: FormGroup;
    selected: string = "";
    id_disciplina: number;

    listaUnidadesNuevas: any = [];
    nuevasUnidades: nuevaUnidad[] = [];

    constructor(
        public matDialogRef: MatDialogRef<ModalAgregarPreguntaComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private administradoresService: AdministradoresService,
        private datePipe: DatePipe,
        private _adapter: DateAdapter<any>
    ) {}

    ngOnInit(): void {
        this._adapter.setLocale("es");
        this.id_disciplina = this._data.examen.DISCIPLINA_ID;
        /*
         *   Aca faltaria el get para editar el examen
         *
         */

        // Usamos un formGroup para asegurar que se llenen todos los campos
        this.editarExamen = this._formBuilder.group({
            cantidad_preguntas: ["", Validators.required],
            disciplina_id: [""],
            duracion: ["", Validators.required],
            fecha_inicio: [""],
            id_examen: [""],
            id_unidad_terminada: [""],
            hora_inicio: [""],
            oportunidades: ["", Validators.required],
            por_fecha: [null, Validators.required],
            tema: ["", Validators.required],
            total: ["", Validators.required],
            fecha_fin: ["", Validators.required],
            hora_fin: ["", Validators.required],
            id_tipo_examen: [""],
            cantidad_pregunta: [""],
            unidades: [[]],
        });
        this.traerExamen();
        this.traerUnidades();
    }

    agregarUnidad(id_unidad) {
        if (
            this.nuevasUnidades.filter((x) => x.id_unidad == id_unidad)
                .length == 0
        ) {
            this.nuevasUnidades.push({
                id_examen: this.id_examen,
                id_unidad: id_unidad,
            });
        } else {
            this.nuevasUnidades.splice(
                this.nuevasUnidades.indexOf({
                    id_unidad: id_unidad,
                    id_examen: this.id_examen,
                }),
                1
            );
        }
    }

    agarrarUnidades() {
        console.log(
            "Este es el array de unidades ahora",
            this.unidadesExamen.value
        );
        this.editarExamen.patchValue({ unidades: this.unidadesExamen.value });
        console.log(
            "Asi queda el objeto para enviar a la api",
            this.editarExamen.value
        );
    }

    traerUnidades() {
        this.administradoresService
            .getListaUnidadesCrearExamen(this.id_disciplina)
            .subscribe((data) => {
                this.listaUnidadesNuevas = data["data"];
            });
    }

    traerExamen() {
        this.id_examen = this._data.examen.ID_EXAMEN;
        this.administradoresService
            .getDatosExamenEditar(this._data.examen.ID_EXAMEN)
            .subscribe((data) => {
                this.examen = data[0];
                console.log("examen", data[0]);
                this.editarExamen.patchValue(this.examen);
                this.unidadesExamen.setValue(this.examen.unidades);
                console.log(
                    "Este es el form Control",
                    this.unidadesExamen.value
                );

                if (this.editarExamen.value.por_fecha === 1) {
                    this.selected = "fecha";
                    this.habilitarPor = "fecha";
                } else {
                    this.selected = "unidad";
                    this.habilitarPor = "unidad";
                }
            });
    }

    getHabilitadoPor(event) {
        console.log("Habilitado por", event);
        this.habilitarPor = event;
        if (event === "fecha") {
            this.editarExamen.patchValue({ por_fecha: 1 });
        } else if (event === "unidad") {
            this.editarExamen.patchValue({ por_fecha: 0 });
        }
    }
    getUnidades(id_materia) {
        // this.loading("Cargando Unidades");
        console.log("Este id me paso", id_materia);
        this.administradoresService
            .getListaUnidadesCrearExamen(id_materia)
            .subscribe((data) => {
                console.log("Unidades de la Materia", data);
                this.listaUnidadesReal = data["data"];
                //Swal.close();
            });
    }
    guardarEditado() {
        console.log(this.editarExamen.value);
        //Si es valido procedemos
        if (this.editarExamen.valid) {
            this.editarExamen.value.cantidad_pregunta = this.editarExamen.value.cantidad_preguntas;
            //Si esta por habilitar fecha
            if (this.habilitarPor === "fecha") {
                //Aca ponemos la fecha inicio con el formato 'YYYY/MM/DD'
                if (this.editarExamen.value.fecha_inicio._i !== undefined) {
                    this.editarExamen.value.por_fecha = true;
                    this.editarExamen.value.fecha_inicio._i.month =
                        this.editarExamen.value.fecha_inicio._i.month + 1;
                    this.editarExamen.value.fecha_inicio =
                        this.editarExamen.value.fecha_inicio._i.year +
                        "/" +
                        this.editarExamen.value.fecha_inicio._i.month +
                        "/" +
                        this.editarExamen.value.fecha_inicio._i.date;
                }

                if (this.editarExamen.value.fecha_fin._i !== undefined) {
                    //Para fecha fin
                    //Aca ponemos la fecha fin con el formato 'YYYY/MM/DD'
                    this.editarExamen.value.fecha_fin._i.month =
                        this.editarExamen.value.fecha_fin._i.month + 1;
                    this.editarExamen.value.fecha_fin =
                        this.editarExamen.value.fecha_fin._i.year +
                        "/" +
                        this.editarExamen.value.fecha_fin._i.month +
                        "/" +
                        this.editarExamen.value.fecha_fin._i.date;
                }

                this.editarExamen.value.id_unidad_terminada = null;
                console.log(
                    "Habilito por fecha este es lo que se quiere enviar",
                    this.editarExamen.value
                );
                //Aca iria el put de lo editado
                this.administradoresService
                    .putEditarExamenNuevo(
                        this.editarExamen.value,
                        this.id_examen
                    )
                    .subscribe(
                        (data) => {
                            if (this.nuevasUnidades.length > 0) {
                                for (let item of this.examen.unidades) {
                                    this.administradoresService
                                        .deleteUnidadDeExamen(
                                            item.examen_unidad_id
                                        )
                                        .subscribe((data) => {
                                            console.log("eliminado con exito");
                                        });
                                }

                                for (let item of this.nuevasUnidades) {
                                    this.administradoresService
                                        .postUnidadExamenes(item)
                                        .subscribe((data) => {
                                            console.log("Agregado con exito!");
                                        });
                                }
                            }

                            Swal.fire(
                                "Cambios realizados",
                                "Exitosamente",
                                "success"
                            );
                        },
                        (error) => {
                            Swal.fire(
                                "Ocurrio un error",
                                "Vuelva a intentarlo mas tarde",
                                "error"
                            );
                        }
                    );
            }
            //Si esta por habilitar unidad
            else if (this.habilitarPor === "unidad") {
                this.editarExamen.value.por_fecha = false;
                //Como no le podemos enviar null la fecha_inicio
                //Entonces agarramos el dia actual para enviarle como parametro
                this.editarExamen.value.fecha_inicio = this.datePipe.transform(
                    new Date(),
                    "yyyy/MM/dd"
                );
                if (!isUndefined(this.editarExamen.value.fecha_fin._i)) {
                    this.editarExamen.value.fecha_fin._i.month =
                        this.editarExamen.value.fecha_fin._i.month + 1;
                    //Aca ponemos la fecha fin con el formato 'YYYY/MM/DD'
                    this.editarExamen.value.fecha_fin =
                        this.editarExamen.value.fecha_fin._i.year +
                        "/" +
                        this.editarExamen.value.fecha_fin._i.month +
                        "/" +
                        this.editarExamen.value.fecha_fin._i.date;
                }
                console.log(
                    "Habilito por unidad, esto es lo que se quiere enviar",
                    this.editarExamen.value
                );

                //Aca iria el put de lo editado
                this.administradoresService
                    .putEditarExamenNuevo(
                        this.editarExamen.value,
                        this.id_examen
                    )
                    .subscribe(
                        (data) => {
                            if (this.nuevasUnidades.length > 0) {
                                for (let item of this.examen.unidades) {
                                    this.administradoresService
                                        .deleteUnidadDeExamen(
                                            item.examen_unidad_id
                                        )
                                        .subscribe((data) => {
                                            console.log("eliminado con exito");
                                        });
                                }

                                for (let item of this.nuevasUnidades) {
                                    this.administradoresService
                                        .postUnidadExamenes(item)
                                        .subscribe((data) => {
                                            console.log("Agregado con exito!");
                                        });
                                }
                            }
                            Swal.fire(
                                "Cambios realizados",
                                "Exitosamente",
                                "success"
                            );
                        },
                        (error) => {
                            Swal.fire(
                                "Ocurrio un error",
                                "Vuelva a intentarlo mas tarde",
                                "error"
                            );
                        }
                    );
            }
        }
    }
}

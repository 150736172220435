<div>
    <h3>{{ action }}</h3>
</div>

<div mat-dialog-content>
    <form
        fxLayout="column"
        fxLayoutAlign="start"
        fxFlex="1 0 auto"
        name="form"
        [formGroup]="form"
    >
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <!--primer row de inputs-->
            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Seleccionar Materia</mat-label>
                <mat-select
                    required
                    formControlName="materia"
                    [(value)]="form.value.materia"
                >
                    <mat-option value="Materia 1">
                        Materia 1
                    </mat-option>
                    <mat-option value="Materia 2">
                        Materia 2
                    </mat-option>
                    <mat-option value="Materia 3">
                        Materia 3
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="secondary-text"
                    >outlined_flag</mat-icon
                >
                <mat-error>Por favor seleccione una materia.</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Seleccionar Unidad</mat-label>
                <mat-select
                    required
                    formControlName="unidad"
                    [(value)]="form.value.unidad"
                >
                    <mat-option value="Unidad 1">
                        Unidad 1
                    </mat-option>
                    <mat-option value="Unidad 2">
                        Unidad 2
                    </mat-option>
                    <mat-option value="Unidad 3">
                        Unidad 3
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="secondary-text"
                    >outlined_flag</mat-icon
                >
                <mat-error>Por favor seleccione una unidad.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Seleccionar Sede</mat-label>
                <mat-select
                    required
                    formControlName="sede"
                    [(value)]="form.value.sede"
                >
                    <mat-option value="Sede 1">
                        Sede 1
                    </mat-option>
                    <mat-option value="Sede 2">
                        Sede 2
                    </mat-option>
                    <mat-option value="Sede 3">
                        Sede 3
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="secondary-text"
                    >outlined_flag</mat-icon
                >
                <mat-error>Por favor seleccione una sede.</mat-error>
            </mat-form-field>
        </div>
        <!--segundo row de inputs-->
        <div>
            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Link del video</mat-label>
                <input matInput formControlName="link" required />
                <mat-icon matSuffix class="secondary-text"
                    >account_circle</mat-icon
                >
                <mat-error>Por favor ingrese un link.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Seleccionar Respuesta correcta</mat-label>
                <mat-select
                    required
                    formControlName="respuesta"
                    [(value)]="form.value.respuesta"
                >
                    <mat-option value="Respuesta 1">
                        Respuesta 1
                    </mat-option>
                    <mat-option value="Respuesta 2">
                        Respuesta 2
                    </mat-option>
                    <mat-option value="Respuesta 3">
                        Respuesta 3
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="secondary-text"
                    >outlined_flag</mat-icon
                >
                <mat-error
                    >Por favor seleccione la respuesta correcta.</mat-error
                >
            </mat-form-field>
        </div>
        <!--tercer row de inputs-->
        <div>
            <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
                <mat-label>Titulo</mat-label>
                <input matInput formControlName="titulo" required />
                <mat-icon matSuffix class="secondary-text"
                    >account_circle</mat-icon
                >
                <mat-error>Por favor ingrese un titulo.</mat-error>
            </mat-form-field>
        </div>
        <!--cuarto row de inputs-->

        <!--respuesta 1-->
        <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
            <mat-label>Respuesta 1</mat-label>
            <input matInput formControlName="respuesta1" required />
            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
            <mat-error>Por favor ingrese la respuesta 1.</mat-error>
        </mat-form-field>
        <!--respuesta 2-->
        <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
            <mat-label>Respuesta 2</mat-label>
            <input matInput formControlName="respuesta2" required />
            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
            <mat-error>Por favor ingrese la respuesta 2.</mat-error>
        </mat-form-field>
        <!--respuesta 3-->
        <mat-form-field
            appearance="outline"
            fxFlex="50"
            class="pl-4"
            *ngIf="form.value.respuesta1 && form.value.respuesta2"
        >
            <mat-label>Respuesta 3 (opcional)</mat-label>
            <input matInput formControlName="respuesta3" />
            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
        </mat-form-field>
        <!--respuesta 4-->
        <mat-form-field
            appearance="outline"
            fxFlex="50"
            class="pl-4"
            *ngIf="
                form.value.respuesta1 &&
                form.value.respuesta2 &&
                form.value.respuesta3
            "
        >
            <mat-label>Respuesta 4 (opcional)</mat-label>
            <input matInput formControlName="respuesta4" />
            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
        </mat-form-field>
        <!--respuesta 5-->
        <mat-form-field
            appearance="outline"
            fxFlex="50"
            class="pl-4"
            *ngIf="
                form.value.respuesta1 &&
                form.value.respuesta2 &&
                form.value.respuesta3 &&
                form.value.respuesta4
            "
        >
            <mat-label>Respuesta 5 (opcional)</mat-label>
            <input matInput formControlName="respuesta5" />
            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
        </mat-form-field>

        <!--empieza el quill-->

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <div>
                <label for="editor" style="text-align: center;"
                    ><h2 style="color: #0e2a3b;">
                        <strong>Cargar Contenido</strong>
                    </h2></label
                >

                <quill-editor
                    [styles]="editorStyle"
                    [modules]="configuracion"
                    formControlName="contenidoUnidad"
                    placeholder="Insertar texto aqui"
                ></quill-editor>
                <div
                    fxLayout="row"
                    fxLayoutAlign="center"
                    style="margin-top: 5px;"
                >
                    <button mat-button color="accent">
                        Listo
                    </button>
                </div>
            </div>
        </div>
        <!--termina el quill-->
    </form>
</div>

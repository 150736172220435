<div
    id="forms"
    class="page-layout simple fullwidth inner-scroll"
    fxLayout="column"
>
    <!-- HEADER -->
    <div
        class="header accent p-24 h-130"
        fxLayout="row"
        fxLayoutAlign="start center"
    >
        <button mat-icon-button class="mr-16" [routerLink]="'/examenes'">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <div fxLayout="column" fxLayoutAlign="center start">
            <div class="mt-16">
                <h2>{{ nombre_disciplina }}</h2>
                <div class="value" style="font-size: medium">
                    Tiempo restante: {{ countdown.hours }} :
                    {{ countdown.minutes }} :
                    {{ countdown.seconds }}
                </div>
            </div>
        </div>
    </div>
    <!-- / HEADER -->
    <!-- CONTENT -->
    <div class="content p-24" fusePerfectScrollbar>
        <div class="vertical-stepper-wrapper">
            <!--PRUEBA STEPPER-->
            <mat-vertical-stepper [linear]="true" #stepper>
                <mat-step *ngFor="let step of examenRendir; let i = index">
                    <ng-template matStepLabel>
                        <label style="color: blue; font-size: large"
                            >Pregunta</label
                        >
                        <span
                            class="material-icons"
                            [fxHide]="
                                respuestasDelExamen[i]?.id_pregunta === step.ID
                            "
                        >
                            error_outline
                        </span>
                    </ng-template>
                    <div class="h2 mb-24">{{ step.ENUNCIADO }}</div>

                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxFlex="1 0 auto"
                    >
                        <mat-radio-group>
                            <mat-radio-button
                                style="
                                    margin: 5px;
                                    white-space: normal !important;
                                    display: block;
                                "
                                *ngFor="
                                    let option of step.respostas;
                                    let i = index
                                "
                                class="example-radio-button"
                                [fxHide]="!option.TEXTO"
                                [value]="option.ID"
                                (change)="
                                    alSeleccionarRespuesta(step.ID, option.ID)
                                "
                            >
                                <p
                                    style="
                                        margin: 5px;
                                        white-space: normal !important;
                                    "
                                >
                                    {{ option.TEXTO }}
                                </p>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button
                            *ngIf="i > 0"
                            class="mr-8"
                            mat-raised-button
                            matStepperPrevious
                            type="button"
                            color="accent"
                        >
                            Volver
                        </button>
                        <button
                            mat-raised-button
                            matStepperNext
                            type="button"
                            color="accent"
                        >
                            Siguiente
                        </button>
                    </div>
                </mat-step>
                <!--ACA ES PARA ENTREGAR-->

                <mat-step *ngIf="enviado == false">
                    <ng-template matStepLabel>Terminar</ng-template>

                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button
                            class="mr-8"
                            mat-raised-button
                            matStepperPrevious
                            type="button"
                            color="accent"
                        >
                            Volver
                        </button>
                        <button
                            mat-raised-button
                            type="button"
                            color="accent"
                            (click)="terminarExamen(true)"
                        >
                            Entregar
                        </button>
                    </div>
                </mat-step>
                <!--ACA TERMINAR LA PARTE DE ENTREGA-->
            </mat-vertical-stepper>
            <!--ACA TERMINA LA PRUEBA DEL VERTICAL STEPPER-->
        </div>
    </div>
</div>

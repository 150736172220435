import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormArray,
    FormControl,
} from "@angular/forms";
import * as moment from "moment";
import { interval, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AlumnosService } from "./../alumnos.service";
import {
    EXAMENDELALUMNO,
    RespuestasDelExamen,
    rendirExamenAlumno,
} from "../alumnos";
import Swal from "sweetalert2";

import { fuseAnimations } from "@fuse/animations";
import { Router } from "@angular/router";

@Component({
    selector: "app-rendir-examen",
    templateUrl: "./rendir-examen.component.html",
    styleUrls: ["./rendir-examen.component.scss"],
    animations: fuseAnimations,
})
export class RendirExamenComponent implements OnInit, OnDestroy {
    //SEGUNDA PRUEBA VERTICAL STEPPER
    isLinear = false;
    // formGroup: FormGroup;
    //  formA: FormArray;
    @ViewChild("stepper") stepper;
    stepOptions = [
        { label: "Buy Groceries", value: "1" },
        { label: "Cook Dinner", value: "2" },
        { label: "Go To Sleep", value: "3" },
        { label: "Go To Work", value: "4" },
        { label: "Wake Up", value: "5" },
    ];
    newSteps = ([] = []);
    // respondido = ([] = []);
    respuestasDelExamen: RespuestasDelExamen[] = [];
    tiempoExamen = { horas: 1, minutos: 30, segundos: 0 };
    tema: string;
    nombre_disciplina: string;
    //TERMINA SEGUNDA PRUEBA VERTICAL STEPPER
    id_periodo_letivo: number;
    id_examen: number;
    disciplinas_id: number;
    // form: FormGroup;
    // Vertical Stepper
    verticalStepperStep1: FormGroup;
    verticalStepperStep2: FormGroup;
    verticalStepperStep3: FormGroup;
    verticalStepperStep4: FormGroup;
    verticalStepperStep5: FormGroup;
    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _apiAlumnos: AlumnosService,
        private route: Router
    ) {
        //COSAS DEL STEPPER
        // this.newSteps.push({ title: null, value: null });
        //TERMINA COSAS DEL STEPPER
        this.countdown = {
            days: "",
            hours: "",
            minutes: "",
            seconds: "",
        };
    }

    public enviado: boolean = false;
    //public examen: EXAMENDELALUMNO = {};
    //public examenn: EXAMENDELALUMNO[] = [];
    public examenRendir: rendirExamenAlumno[] = [];
    //Countdown
    countdown: any;
    private _unsubscribeAll: Subject<any>;

    private _secondsToRemaining(seconds): any {
        const timeLeft = moment.duration(seconds, "seconds");

        return {
            days: timeLeft.asDays().toFixed(0),
            hours: timeLeft.hours(),
            minutes: timeLeft.minutes(),
            seconds: timeLeft.seconds(),
        };
    }
    puntajeObtenido: number = 0;
    tiempo_inicial: number = 0;
    tiempo_final: number = 0;
    countDown: any;

    ngOnInit(): void {
        this.loading("Cargando Examen");
        this.nombre_disciplina = sessionStorage.getItem("nombre_disciplina");
        this.tema = sessionStorage.getItem("tema");
        this.id_examen = +sessionStorage.getItem("id_examen");
        this.disciplinas_id = +sessionStorage.getItem("disciplinas_id");
        this.id_periodo_letivo = +sessionStorage.getItem("ID_PERIODO_LETIVO");
        this._apiAlumnos.getExamenAlumno(this.id_examen).subscribe(
            (data) => {
                console.log("Se llamo correctamente a la api");
                console.log(data);
                this.examenRendir = data;
                //PARA QUE COMIENCE EL COUNTDOWN
                // Create a subscribable interval
                if (data) {
                    // console.log('entro')
                    if (sessionStorage.getItem("tiempo_restante")) {
                        this.countdown.minutes = JSON.parse(
                            sessionStorage.getItem("tiempo_restante")
                        );
                        this.tiempoExamen.minutos = this.countdown.minutes;
                        //this.tiempoExamen.horas = this.countDown.hours;
                        // this.tiempoExamen.segundos = this.countdown.seconds
                    }
                    console.log("tiempo de examen", this.tiempoExamen.minutos);
                    const currDate = moment();
                    const eventDate = moment(currDate).add(
                        this.tiempoExamen.minutos,
                        "minutes"
                    );
                    console.log("event ", eventDate);
                    let diff = eventDate.diff(currDate, "seconds");
                    this.tiempo_inicial = diff;
                    //console.log("diff", diff);
                    // console.log('Algo turbio', this._secondsToRemaining(diff))

                    // this.countdown = this._secondsToRemaining(diff);

                    this.countDown = interval(1000).pipe(
                        map((value) => {
                            this.tiempo_final = this.tiempo_inicial - diff;
                            if (diff === 180 && !this.enviado) {
                                Swal.fire(
                                    "Advertencia",
                                    "Quedan 3 minutos para entregar el examen",
                                    "warning"
                                );
                            }
                            if (diff == 0) {
                                if (this.enviado === false) {
                                    //console.log("Termino el tiempo");
                                    this.enviado = true;
                                    Swal.fire(
                                        "Termino el tiempo!",
                                        "Su examen ha sido enviado",
                                        "warning"
                                    );
                                    //ACA PONGO TODO LAS RESPUESTAS NULL SI NO CONTESTO NADA
                                    //ESTO PODES COMENTAR CUALQUIER COSA HASTA ACA
                                    if (this.respuestasDelExamen.length < 1) {
                                        for (let pregunta of this
                                            .examenRendir) {
                                            this.respuestasDelExamen.push({
                                                id_pregunta: pregunta.ID,
                                                id_respuesta: null,
                                                id_periodo_letivo: this
                                                    .id_periodo_letivo,
                                                id_examen: this.id_examen,
                                                id_oferta_disciplina: this
                                                    .disciplinas_id,
                                                tiempo: this.tiempo_final,
                                            });
                                        }
                                        this.terminarExamen(false);
                                    }
                                    //ACA SE ENVIA SI ES QUE AL MENOS CONTESTO UNA PREGUNTA
                                    else {
                                        this.terminarExamen(false);
                                    }
                                    //HASTA ACA PODES COMENTAR
                                    //ESTO QUE ESTA ABAJO DESCOMENTA Y TODO FUNCIONARA NORMALMENTE
                                    //this.terminarExamen(false);
                                }
                                return 0;
                            } else {
                                if(window.location.pathname != "/rendir-examen"){
                                    
                                    this.enviado = true;
                                    
                                }
                                return (diff = diff - 1);
                            }
                        }),
                        map((value) => {
                            return this._secondsToRemaining(value);
                        })
                    );
                    // Subscribe to the countdown interval
                    this.countDown.subscribe((value) => {
                        //console.log(this.countdown, 'En el subscribe')
                        this.countdown = value;
                    });
                    //ACA TERMINA EL INICO DEL INTERVALO
                }
                Swal.close();
            },
            (err) => {
                console.log("Este es el error", err);
                Swal.fire("Advertencia", err["error"], "error").then(() => {
                    this.route.navigateByUrl("/examenes");
                });
            }
        );
        // this.obtenerPreguntas();
    }
    //COSAS DEL STEPPER

    //TERMINA COSAS DEL STEPPER

    /**
     * Finish the vertical stepper
     */
    //ESTO PASA CUANDO LE DA TERMINAR
    terminarExamen(presionoEntregar): void {
        let estaCompleto = true;

        for (let pregunta of this.examenRendir) {
            if (
                this.respuestasDelExamen.filter(
                    (x) => x.id_pregunta == pregunta.ID
                ).length == 0 &&
                presionoEntregar
            ) {
                Swal.fire(
                    "No se puede entregar el examen",
                    "Por favor conteste todas las preguntas para poder entregar el examen!",
                    "warning"
                );
                estaCompleto = false;
                console.log("No esta completo", estaCompleto);
            }
        }
        if (estaCompleto) {
            console.log("Esta completo", estaCompleto);
            this.loading("Corrigiendo examen");
            this._apiAlumnos.postExamen(this.respuestasDelExamen).subscribe(
                (data) => {
                    window.clearInterval(this.countDown);
                    this.enviado = true;
                    console.log(
                        "Esto es lo que le envio",
                        this.respuestasDelExamen
                    );
                    console.log(data);
                    this.puntajeObtenido = data.PONTOS;
                    Swal.fire({
                        title: "Su puntaje obtenido es:",
                        text: this.puntajeObtenido + " puntos",
                        icon: "success",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#3085d6",
                        confirmButtonText: "Aceptar",
                        cancelButtonText: "Ver Examen",
                    }).then((result) => {
                        sessionStorage.removeItem("tiempo_restante");
                        if (result.value) {
                            this.route.navigateByUrl("/examenes");
                        } else if (result.dismiss) {
                            sessionStorage.setItem("ruta", "/examenes");
                            this.route.navigateByUrl("ver-examen");
                        }
                    });
                },
                (err) => {
                    Swal.fire(
                        "",
                        "Hubo un problema con el servidor, por favor contacte con atencion al alumno",
                        "error"
                    ).then(() => {
                        this.route.navigateByUrl("/examenes");
                    });
                }
            );
        }
    }

    //Obtener las preguntas del examen

    alSeleccionarRespuesta(id_pregunta, id_respuesta) {
        //Inicializamos la bandera y el index
        console.log("id_respuesta", id_respuesta);
        let i = 0;
        let bandera = false;
        //Preguntamos si ya hay algo dentro del array, si no hay, agregamos directamente
        if (this.respuestasDelExamen.length < 1) {
            this.respuestasDelExamen.push({
                id_pregunta: id_pregunta,
                id_respuesta: id_respuesta,
                id_periodo_letivo: this.id_periodo_letivo,
                id_examen: this.id_examen,
                id_oferta_disciplina: this.disciplinas_id,
                tiempo: this.tiempo_final,
            });
        }
        //Si ya hay algo dentro, entonces recorremos para saber si cambio la respuesta de alguna pregunta que ya respondio
        else if (this.respuestasDelExamen.length > 0) {
            for (let repeticiones of this.respuestasDelExamen) {
                repeticiones.tiempo = this.tiempo_final;
                if (repeticiones.id_pregunta === id_pregunta) {
                    //Si encuentra una pregunta con el mismo id, cambia la respuesta de esa pregunta
                    this.respuestasDelExamen[i].id_respuesta = id_respuesta;
                    bandera = true;
                    break;
                }
                i = i + 1;
            }
            //Si no se cambio ninguna de las preguntas ya respondidas, se agrega normalmente la nueva respuesta
            if (!bandera) {
                this.respuestasDelExamen.push({
                    id_pregunta: id_pregunta,
                    id_respuesta: id_respuesta,
                    id_periodo_letivo: this.id_periodo_letivo,
                    id_examen: this.id_examen,
                    id_oferta_disciplina: this.disciplinas_id,
                    tiempo: this.tiempo_final,
                });
            }
        }

        console.log("Este es con interface", this.respuestasDelExamen);
    }
    loading(title) {
        Swal.fire({
            title: title,
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

    ngOnDestroy() {
        //window.location.reload();
        this.enviado = true;
        
    }
}

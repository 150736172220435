import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';

import { ChatService } from '../chat.service';
import { MensajesForo } from 'app/alumnos/alumnos';
import Swal from 'sweetalert2';

@Component({
    selector: 'chat-view',
    templateUrl: './chat-view.component.html',
    styleUrls: ['./chat-view.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChatViewComponent implements OnInit, OnDestroy, AfterViewInit {
    user: any;
    chat: any;
    dialog: any;
    contact: any;
    replyInput: any;
    selectedChat: any;
    foroMensajes: MensajesForo[] = [];
    id_usuario: number;
    @ViewChild(FusePerfectScrollbarDirective)
    directiveScroll: FusePerfectScrollbarDirective;
    enviando: boolean;
    @ViewChildren('replyInput')
    replyInputField;

    @ViewChild('replyForm')
    replyForm: NgForm;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     */
    constructor(
        private _chatService: ChatService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.enviando = false;
        this.id_usuario = +sessionStorage.getItem('id_usuario')
        this.user = this._chatService.user;
        this._chatService.onChatSelected
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(chatData => {
                if (chatData) {
                    this.loading('Cargando Comentarios..')

                    //  console.log('En chat view', chatData)
                    this.selectedChat = chatData;
                    this._chatService.getForoMensajes(this.selectedChat.id).subscribe(
                        response => {
                            Swal.close();
                            // console.log('Response', response)
                            this.foroMensajes = response
                            this.readyToReply();

                        }, err => {
                            Swal.fire('Opss..', 'Hubo un problema con el servidor', 'error')
                        }
                    )
                }
            });


    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this.replyInput = this.replyInputField.first.nativeElement;
        this.readyToReply();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Decide whether to show or not the contact's avatar in the message row
     *
     * @param message
     * @param i
     * @returns {boolean}
     */


    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isFirstMessageOfGroup(message, i): boolean {
        return (i === 0 || this.foroMensajes[i - 1] && this.foroMensajes[i - 1].id !== message.id);
    }

    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isLastMessageOfGroup(message, i): boolean {
        return (i === this.foroMensajes.length - 1 || this.foroMensajes[i + 1] && this.foroMensajes[i + 1].id !== message.id);
    }

    /**
     * Select contact
     */
    selectContact(): void {
        this._chatService.selectContact(this.contact);
    }

    /**
     * Ready to reply
     */
    readyToReply(): void {
        setTimeout(() => {
            this.focusReplyInput();
            this.scrollToBottom();
        });
    }

    /**
     * Focus to the reply input
     */
    focusReplyInput(): void {
        setTimeout(() => {
            this.replyInput.focus();
        });
    }

    /**
     * Scroll to the bottom
     *
     * @param {number} speed
     */
    scrollToBottom(speed?: number): void {
        speed = speed || 400;
        if (this.directiveScroll) {
            this.directiveScroll.update();

            setTimeout(() => {
                this.directiveScroll.scrollToBottom(0, speed);
            });
        }
    }

    /**
     * Reply
     */
    reply(event): void {
        event.preventDefault();
        if (!this.replyForm.form.value.message || this.enviando) {
            return;
        }
        // Message
        this.enviando = true;
        const message = {
            ead_forum_id: this.selectedChat.id,
            resposta: this.replyForm.form.value.message,
            usuarios_id: +sessionStorage.getItem('id_usuario')
        };

        // Update the server
        this._chatService.postForoMensaje(message).subscribe(response => {
            console.log('La respuesta del post', response)
            this._chatService.getForoMensajes(this.selectedChat.id).subscribe(
                response => {
                    // console.log('Response', response)
                    this.foroMensajes = response
                    // Reset the reply form
                    this.replyForm.reset();
                    this.readyToReply();
                    this.enviando = false;

                }
            )
        });
    }
    loading(title) {
        Swal.fire({
            title: title,
            //timer: 2000,
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }
}

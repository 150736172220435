import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { CalendarEvent } from "angular-calendar";
import { MatColors } from "@fuse/mat-colors";
import { AdministradoresService } from "app/administradores/administradores.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import Swal from "sweetalert2";
import { fuseAnimations } from "@fuse/animations";

@Component({
    selector: "app-modal-editar-pregunta",
    templateUrl: "./modal-editar-pregunta.component.html",
    styleUrls: ["./modal-editar-pregunta.component.scss"],
    animations: fuseAnimations,
})
export class ModalEditarPreguntaComponent implements OnInit {
    action: string;
    event: CalendarEvent;
    eventForm: FormGroup;
    //Creando el form group de Preguntas
    preguntaForm: FormGroup;
    dialogTitle: string;
    presetColors = MatColors.presets;
    checked = true;
    respuestas: any;
    pregunta: any;
    id_respuesta_correcta: number;
    id_unidad: number;
    // preguntaEdit: any;
    /**
     * Constructor
     *
     * @param {MatDialogRef<ModalEditarPreguntaComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<ModalEditarPreguntaComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private administradoresService: AdministradoresService
    ) {}

    ngOnInit() {
        this.pregunta = JSON.parse(JSON.stringify(this._data.pregunta));
        this.id_unidad = this._data.unidad;
        this.administradoresService
            .getEditarPregunta(this.pregunta.ID)
            .subscribe((data) => {
                // console.log("Estas son las respuestas", data);
                this.respuestas = data;
                for (let respuesta of this.respuestas) {
                    if (respuesta.CORRECTA === 1) {
                        this.id_respuesta_correcta = respuesta.ID;
                    }
                }
            });
    }
    save() {
        let pregunta = {
            id_pregunta: this.pregunta.ID,
            enunciado: this.pregunta.ENUNCIADO,
            explicacion: this.pregunta.EXPLICACION,
        };
        this.administradoresService.putEditarPregunta(pregunta).subscribe(
            (response) => {
                // console.log("Editado con exito la pregunta!", pregunta);
                //  console.log(response, 'Respuesta de editar pregunta')
                for (let respuesta of this.respuestas) {
                    if (respuesta.ID !== this.id_respuesta_correcta) {
                        respuesta.CORRECTA = 0;
                        let respuestaEditado = {
                            id_respuesta: respuesta.ID,
                            texto: respuesta.TEXTO,
                            correcta: respuesta.CORRECTA,
                        };
                        this.administradoresService
                            .putEditarRespuesta(respuestaEditado)
                            .subscribe((response) => {
                                //  console.log("Se envio la respuesta", respuestaEditado);
                                // console.log('Respuesta de la respuestas', response)
                            });
                    } else {
                        respuesta.CORRECTA = 1;
                        let respuestaEditado = {
                            id_respuesta: respuesta.ID,
                            texto: respuesta.TEXTO,
                            correcta: respuesta.CORRECTA,
                        };
                        this.administradoresService
                            .putEditarRespuesta(respuestaEditado)
                            .subscribe((response) => {
                                //  console.log(
                                //     "Se envio la respuesta correcta",
                                //      respuestaEditado
                                //  );
                                // console.log(response)
                            });
                    }
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Cambios guardados con exito",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
            },
            (err) => {
                Swal.fire("Upps", "Hubo un problema con el servidor", "error");
            }
        );

        this.matDialogRef.close();
    }
}

<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Lista de Examenes
            </h1>
        </div>
    </div>

    <div class="content p-12" fxLayout="row">
        <div
            class="mb-24"
            fxLayout="row"
            fxLayoutAlign="start"
            id="products"
            fxFlex="1 0 auto"
        >
            <div
                class="mat-card mat-elevation-z4 p-24"
                fxLayout="column"
                fxLayoutAlign="start"
                fxFlex="1 0 auto"
                name="form"
            >
                <!-- <div class="h2 mb-24">Preguntas</div>-->
                <!--ACA EMPIEZA LA TABLA-->
                <!-- ACA EMPIEZASELECCIONAR mostrar-->

                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="25"
                        class="pr-4"
                    >
                        <mat-label>Filtro</mat-label>
                        <mat-select multiple>
                            <div fxLayout="column" class="p-16">
                                <div
                                    fxLayout="column"
                                    fxLayoutAlign="start"
                                    fxFlex="1 0 auto"
                                >
                                    <label class="mb-4"
                                        ><strong>Sede</strong></label
                                    >

                                    <mat-option
                                        (click)="
                                            filtrado2(
                                                sede.texto,
                                                'NOMBRE_FILIAL'
                                            )
                                        "
                                        *ngFor="let sede of filtroSede"
                                        [value]="sede.texto"
                                    >
                                        {{ sede.texto }}
                                    </mat-option>
                                    <label class="mb-4"
                                        ><strong
                                            >Tipo de Evaluacion</strong
                                        ></label
                                    >
                                    <div fxLayout="column" class="pr-8">
                                        <mat-option
                                            [value]="tipo.id"
                                            (click)="
                                                filtrado2(
                                                    tipo.texto,
                                                    'NOMBRE_TIPO_EXAMEN'
                                                )
                                            "
                                            *ngFor="let tipo of filtroTipoEval"
                                        >
                                            {{ tipo.texto }}
                                        </mat-option>
                                    </div>
                                </div>

                                <div
                                    fxLayout="column"
                                    fxLayoutAlign="start"
                                    fxFlex="1 0 auto"
                                >
                                    <label class="mb-4"
                                        ><strong>Semestre</strong></label
                                    >
                                    <div fxLayout="column" class="pr-8">
                                        <mat-option
                                            (click)="
                                                filtrado2(
                                                    semestre.texto,
                                                    'nome_semestre'
                                                )
                                            "
                                            *ngFor="
                                                let semestre of filtroSemestre
                                            "
                                            [value]="semestre.texto"
                                        >
                                            {{ semestre.texto }}
                                        </mat-option>
                                    </div>
                                </div>
                            </div>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field
                        appearance="outline"
                        fxFlex="60"
                        class="pr-4"
                    >
                        <mat-label>Buscar</mat-label>
                        <input
                            [(ngModel)]="term"
                            matInput
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="15">
                        <mat-label>Mostrar</mat-label>
                        <mat-select
                            [(value)]="itemsPerPage"
                            (selectionChange)="p = 1"
                        >
                            <mat-option value="5"> 5 </mat-option>
                            <mat-option value="10"> 10 </mat-option>
                            <mat-option value="15"> 15 </mat-option>
                            <mat-option value="20"> 20 </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!--ACA TERMINA SELECCIONAR MOSTRAR-->

                <mat-table
                    fxFlex="1 0 auto"
                    class="products-table"
                    #table
                    [dataSource]="
                        filtradoExamenes
                            | filter: term
                            | paginate
                                : {
                                      itemsPerPage: itemsPerPage,
                                      currentPage: p
                                  }
                    "
                    [@animateStagger]="{ value: '50' }"
                    fusePerfectScrollbar
                    matSort
                    (matSortChange)="sortData($event)"
                >
                    <!-- Columna SEDE -->
                    <ng-container matColumnDef="index">
                        <mat-header-cell
                            fxFlex="10"
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="NOMBRE_FILIAL"
                            >Sede
                        </mat-header-cell>
                        <mat-cell
                            fxFlex="10"
                            fxLayoutAlign="start"
                            *matCellDef="let sede"
                        >
                            <p class="text-truncate">
                                {{ sede.NOMBRE_FILIAL }}
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna SEMESTRE -->
                    <ng-container matColumnDef="semestre">
                        <mat-header-cell
                            fxFlex="13"
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="nome_semestre"
                            >Semestre
                        </mat-header-cell>
                        <mat-cell
                            fxFlex="13"
                            fxLayoutAlign="start"
                            *matCellDef="let nombre"
                        >
                            <p class="text-truncate">
                                {{ nombre.nome_semestre }}
                                {{ nombre.nome_turma }}
                            </p>
                        </mat-cell>
                    </ng-container>
                    <!-- Columna MATERIA -->
                    <ng-container matColumnDef="materia">
                        <mat-header-cell
                            fxFlex="28"
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="nome"
                        >
                            Materia
                        </mat-header-cell>
                        <mat-cell
                            fxFlex="28"
                            fxLayoutAlign="start"
                            *matCellDef="let nombre"
                        >
                            <p class="text-truncate">
                                {{ nombre.nome }}
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna profesor -->
                    <ng-container matColumnDef="profesor">
                        <mat-header-cell
                            fxFlex="28"
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="NOMBRE"
                        >
                            Profesor
                        </mat-header-cell>
                        <mat-cell
                            fxFlex="28"
                            fxLayoutAlign="start"
                            *matCellDef="let examen"
                        >
                            <p class="text-truncate">
                                {{ examen.NOMBRE }} {{ examen.APELIDO }}
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna PUNTAJE TOTAL -->
                    <ng-container matColumnDef="puntaje">
                        <mat-header-cell
                            fxFlex="7"
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="TOTAL"
                        >
                            Puntaje
                        </mat-header-cell>
                        <mat-cell
                            fxFlex="7"
                            fxLayoutAlign="start"
                            *matCellDef="let examen"
                        >
                            <p class="text-truncate">{{ examen.TOTAL }}pts</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna DURACION -->
                    <ng-container matColumnDef="duracion">
                        <mat-header-cell
                            fxFlex="7"
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="DURACION"
                        >
                            Duracion
                        </mat-header-cell>
                        <mat-cell
                            fxFlex="7"
                            fxLayoutAlign="start"
                            *matCellDef="let examen"
                        >
                            <p class="category text-truncate">
                                {{ examen.DURACION }}min
                            </p>
                        </mat-cell>
                    </ng-container>
                    <!-- Columna FECHA -->
                    <ng-container matColumnDef="fecha">
                        <mat-header-cell
                            fxFlex="7"
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="FECHA_INICIO"
                            >Fecha Inicio
                        </mat-header-cell>
                        <mat-cell
                            fxFlex="7"
                            fxLayoutAlign="start"
                            *matCellDef="let examen"
                        >
                            <p class="category text-truncate">
                                {{ examen.FECHA_INICIO | date: "dd/MM/yyyy" }}
                            </p>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="fecha_fin">
                        <mat-header-cell
                            fxFlex="7"
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="FECHA_FIN"
                        >
                            Fecha Fin
                        </mat-header-cell>
                        <mat-cell
                            fxFlex="7"
                            fxLayoutAlign="start"
                            *matCellDef="let examen"
                        >
                            <p class="category text-truncate">
                                {{ examen.FECHA_FIN | date: "dd/MM/yyyy" }}
                            </p>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <mat-header-cell
                            fxFlex="7"
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="status"
                        >
                            Estado
                        </mat-header-cell>
                        <mat-cell
                            fxFlex="7"
                            fxLayoutAlign="start"
                            *matCellDef="let examen"
                        >
                            <p
                                class="category text-truncate"
                                style="color: rgb(218, 38, 32)"
                                *ngIf="examen.status === 'Cerrado'"
                            >
                                {{ examen.status }}
                            </p>
                            <p
                                class="category text-truncate"
                                style="color: rgb(185, 196, 40)"
                                *ngIf="examen.status === 'Pendiente'"
                            >
                                {{ examen.status }}
                            </p>
                            <p
                                class="category text-truncate"
                                style="color: rgb(27, 155, 27)"
                                *ngIf="examen.status === 'Activo'"
                            >
                                {{ examen.status }}
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna ACCIONES -->
                    <ng-container matColumnDef="acciones">
                        <mat-header-cell
                            fxFlex="10"
                            fxLayoutAlign="start center"
                            *matHeaderCellDef
                            >Acciones
                        </mat-header-cell>

                        <mat-cell
                            fxFlex="10"
                            fxLayoutAlign="start"
                            *matCellDef="let product"
                            fxLayout="column"
                            ><mat-form-field appearance="outline">
                                <mat-label>Acciones</mat-label>
                                <mat-select>
                                    <mat-option style="padding: 0%">
                                        <button
                                            style="padding: 0; width: 100%"
                                            [fxHide]="!product.DISCIPLINA_ID"
                                            mat-button
                                            color="accent"
                                            (click)="
                                                verPreguntasExamen(
                                                    product.DISCIPLINA_ID,
                                                    product.FUNCIONARIO_ID
                                                )
                                            "
                                        >
                                            Ver Preguntas
                                        </button>
                                    </mat-option>
                                    <mat-option style="padding: 0%">
                                        <button
                                            style="
                                                padding: 0;
                                                width: 100%;
                                                height: inherit;
                                            "
                                            mat-button
                                            color="accent"
                                            (click)="
                                                verListaPreguntas(
                                                    product.ID_EXAMEN
                                                )
                                            "
                                        >
                                            Lista Preguntas
                                        </button>
                                    </mat-option>
                                    <!--<mat-option style="padding: 0%">
                                        <button
                                            style="
                                                padding: 0;
                                                width: 100%;
                                                height: inherit;
                                            "
                                            mat-button
                                            color="accent"
                                            (click)="
                                                verInforme(product.ID_EXAMEN)
                                            "
                                        >
                                            Inf. Evaluaciones
                                        </button>
                                    </mat-option>-->
                                    <mat-option style="padding: 0%">
                                        <button
                                            style="
                                                padding: 0;
                                                width: 100%;
                                                height: inherit;
                                            "
                                            mat-button
                                            color="accent"
                                            (click)="editarExamen(product)"
                                        >
                                            Editar Examen
                                        </button>
                                    </mat-option>
                                    <mat-option style="padding: 0%">
                                        <button
                                            style="
                                                padding: 0;
                                                width: 100%;
                                                height: inherit;
                                            "
                                            mat-button
                                            color="warn"
                                            (click)="
                                                eliminarExamen(
                                                    product.ID_EXAMEN
                                                )
                                            "
                                        >
                                            Eliminar
                                        </button></mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row
                        *matHeaderRowDef="displayedColumns; sticky: true"
                    ></mat-header-row>

                    <mat-row
                        *matRowDef="let product; columns: displayedColumns"
                        class="product"
                        matRipple
                    >
                    </mat-row>
                </mat-table>
                <pagination-controls
                    (pageChange)="p = $event"
                    autoHide="true"
                    responsive="true"
                    previousLabel="Anterior"
                    nextLabel="Siguiente"
                ></pagination-controls>

                <!--ACA TERMINA LA TABLA-->

                <div
                    fxLayout="row"
                    fxLayoutAlign="end"
                    fxFlex="1 0 auto"
                    style="margin-top: 20px"
                >
                    <h1 *ngIf="print" style="margin-right: 15%">
                        --------------
                    </h1>

                    <div style="margin-top: 2.5%">
                        <button (click)="Imprimir()" mat-button color="accent">
                            Imprimir
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

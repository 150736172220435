<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Agregar Pregunta
            </h1>
        </div>

        <button
            mat-icon-button
            (click)="matDialogRef.close()"
            aria-label="Close dialog"
        >
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-md="row"
        fusePerfectScrollbar
    >
        <form
            name="eventForm"
            [formGroup]="preguntaForm"
            fxLayout="column"
            fxFlex="1 0 auto"
        >
            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Pregunta</mat-label>
                    <input
                        matInput
                        name="pregunta"
                        formControlName="enunciado"
                        required
                    />
                </mat-form-field>
            </div>

            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Descripcion de la Respuesta Correcta</mat-label>
                    <input
                        matInput
                        name="descripcion"
                        formControlName="descripcion"
                        required
                    />
                </mat-form-field>
            </div>
            <div fxFlex="1 0 auto" fxLayout="row" fxLayout.gt-xs="row">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Metodo Pregunta</mat-label>
                    <mat-select
                        (selectionChange)="SelectMetodoPregunta($event.value)"
                    >
                        <mat-option value="1"> Seleccion multiple </mat-option>
                        <mat-option value="2"> Verdadero o Falso </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >account_circle</mat-icon
                    >
                    <mat-error>Por favor ingrese la unidad.</mat-error>
                </mat-form-field>
                <div fxFlex="50" fxLayout="col" class="pl-4">
                    <input
                        type="file"
                        class="file"
                        (change)="updateSource($event)"
                        style="display: none"
                        accept="image/*"
                        name="image"
                        #fileUpload
                        accept="image/png, image/jpeg, image/jpg"
                        value="assets/img/box.jpg"
                        required
                    /><br />
                    <button
                        mat-raised-button
                        color="accent"
                        #image
                        type="button"
                        class="btn btn-primary"
                        (click)="fileUpload.click()"
                        style="height: 60%; margin-top: 2%"
                    >
                        <mat-icon> insert_photo </mat-icon>
                        {{ source ? "Cambiar Imagen" : "Seleccionar Imagen" }}
                    </button>

                    <img
                        [fxHide]="!source"
                        [src]="source"
                        (click)="verImagen(source)"
                        height="100px"
                        width="100px"
                        style="margin-bottom: 5%"
                    />
                </div>
            </div>
            <!--RESPUESTAS CON RADIO BUTTON-->

            <mat-radio-group
                [fxHide]="!metodoPregunta"
                formControlName="respuestaCorrecta"
                fxFlex="1 0 auto"
                fxLayout="column"
                fxLayoutAlign="start"
            >
                <div fxLayout="row">
                    <mat-radio-button fxLayoutAlign value="respuesta1">
                    </mat-radio-button>
                    <!--INPUT DE LA RESPUESTA 1-->
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Respuesta 1</mat-label>
                        <textarea
                            matInput
                            name="respuesta1"
                            formControlName="respuesta1"
                            required
                        ></textarea>
                    </mat-form-field>
                </div>

                <div fxLayout="row">
                    <mat-radio-button
                        fxLayoutAlign
                        class="example-margin"
                        value="respuesta2"
                    >
                    </mat-radio-button>
                    <!--INPUT DE LA RESPUESTA 2-->
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Respuesta 2</mat-label>
                        <textarea
                            matInput
                            name="respuesta2"
                            formControlName="respuesta2"
                            required
                        ></textarea>
                    </mat-form-field>
                </div>

                <div fxLayout="row" [fxHide]="metodoPregunta === 2">
                    <mat-radio-button
                        fxLayoutAlign
                        class="example-margin"
                        value="respuesta3"
                    >
                    </mat-radio-button>
                    <!--INPUT DE LA RESPUESTA 3-->
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Respuesta 3</mat-label>
                        <textarea
                            matInput
                            name="respuesta3"
                            formControlName="respuesta3"
                        ></textarea>
                    </mat-form-field>
                </div>

                <div fxLayout="row" [fxHide]="metodoPregunta === 2">
                    <mat-radio-button
                        fxLayoutAlign
                        class="example-margin"
                        value="respuesta4"
                    >
                    </mat-radio-button>
                    <!--INPUT DE LA RESPUESTA 4-->
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Respuesta 4</mat-label>
                        <textarea
                            matInput
                            name="respuesta4"
                            formControlName="respuesta4"
                        ></textarea>
                    </mat-form-field>
                </div>
            </mat-radio-group>

            <div class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
                <button
                    mat-button
                    color="primary"
                    class="save-button"
                    (click)="matDialogRef.close(preguntaForm.value)"
                    [disabled]="
                        preguntaForm.invalid ||
                        (metodoPregunta === 1 &&
                            preguntaForm.value.respuesta3 === '') ||
                        (metodoPregunta === 1 &&
                            preguntaForm.value.respuesta4 === '')
                    "
                    aria-label="SAVE"
                >
                    Guardar
                </button>
            </div>
        </form>
    </div>
</div>

<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Agregar Profesor a Materia
            </h1>
        </div>
    </div>
    <!-- CONTENT -->
    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-lg="row"
        fxLayout.gt-md="column"
    >
        <!-- REACTIVE FORM EXAMPLE -->

        <form
            class="mat-card mat-elevation-z4 p-24 mr-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            name="form"
            [formGroup]="formProf"
            style="height: 200px;"
        >
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
                    <mat-label>Seleccionar Profesor</mat-label>
                    <mat-select required formControlName="profe">
                        <mat-option value="profesor 1">
                            profesor 1
                        </mat-option>
                        <mat-option value="profesor 2">
                            profesor 2
                        </mat-option>
                        <mat-option value="profesor 3">
                            profesor 3
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor seleccione un profesor.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Seleccionar Materia</mat-label>
                    <mat-select required formControlName="materia">
                        <mat-option value="materia 1">
                            materia 1
                        </mat-option>
                        <mat-option value="materia 2">
                            materia 2
                        </mat-option>
                        <mat-option value="materia 3">
                            materia 3
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor seleccione una materia.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Seleccionar Sede</mat-label>
                    <mat-select required formControlName="sede">
                        <mat-option value="sede 1">
                            sede 1
                        </mat-option>
                        <mat-option value="sede 2">
                            sede 2
                        </mat-option>
                        <mat-option value="sede 3">
                            sede 3
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor seleccione una sede.</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="end">
                <button mat-button color="accent" (click)="agregarProf()">
                    Agregar Profesor
                </button>
            </div>
        </form>
        <form
            class="mat-card mat-elevation-z4 p-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            name="form"
            [formGroup]="formProf"
            style="height: 500px;"
        >
            <div class="h2 mb-24">Lista de Profesores</div>

            <mat-table
                class="products-table"
                #table
                matSort
                [@animateStagger]="{ value: '50' }"
                fusePerfectScrollbar
            >
                <!-- Columna Pregunta -->
                <ng-container matColumnDef="Profesor">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Profesores
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profesor">
                        <p class="text-truncate">
                            {{ profesor.NOMBRE }}
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Columna Materia -->
                <ng-container matColumnDef="Materia">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Materias
                    </mat-header-cell>
                    <mat-cell *matCellDef="let materia">
                        <p class="text-truncate">
                            {{ materia.MATERIA }}
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Columna Materia -->
                <ng-container matColumnDef="Sede">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Sede
                    </mat-header-cell>
                    <mat-cell *matCellDef="let sede">
                        <p class="text-truncate">
                            {{ sede.NOMBRE }}
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Columna Acciones -->
                <ng-container matColumnDef="Acciones">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        fxShow.gt-md
                        >Acciones</mat-header-cell
                    >
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-md>
                        <button
                            mat-button
                            color="accent"
                            (click)="editarProf()"
                        >
                            Editar
                        </button>
                        <button
                            mat-button
                            color="warn"
                            (click)="eliminarProf()"
                        >
                            Eliminar
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Columna Unidad 
                <ng-container matColumnDef="unidad">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Unidad</mat-header-cell
                    >
                    <mat-cell *matCellDef="let unidad">
                        <p class="text-truncate">
                            Unidad ingresada
                        </p>
                    </mat-cell>
                </ng-container>-->
                <!-- Columna Peso 
                <ng-container matColumnDef="peso">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Peso</mat-header-cell
                    >
                    <mat-cell *matCellDef="let unidad">
                        <p class="text-truncate">
                            Peso Ingresado
                        </p>
                    </mat-cell>
                </ng-container>-->

                <mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>

                <mat-row
                    *matRowDef="let product; columns: displayedColumns"
                    class="product"
                    matRipple
                >
                </mat-row>
            </mat-table>
        </form>
    </div>
</div>

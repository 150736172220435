import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
    CALIFICACIONESALUMNO,
    CalificacionesAlumnoUnidades,
    CalificacionesAlumnoExamenes,
    Calificaciones,
} from "../alumnos";
//import { ListadoExamenes } from 'app/profesores/profesores.modal';
import { fuseAnimations } from "@fuse/animations";
import { DataSource } from "@angular/cdk/table";
import Swal from "sweetalert2";
import { AlumnosService } from "../alumnos.service";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";

@Component({
    selector: "app-calificaciones",
    templateUrl: "./calificaciones.component.html",
    styleUrls: ["./calificaciones.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class CalificacionesComponent implements OnInit {
    calificaciones: Calificaciones[] = [];
    p: number = 1;

    displayedColumnsUnidades: string[] = [
        "Materia",
        "Unidad/Trabajo",
        "Puntaje",
    ];
    displayedColumnsExamenes: string[] = [
        "Materia",
        "Examen",
        "Fecha",
        "Nota",
        "Duracion",
        "Acciones",
    ];

    listaExamenes: CalificacionesAlumnoExamenes[] = [
        {
            id_examen: 1,
            materia: "Anatomia",
            examen: "Unidad 1",
            puntaje: 20,
            de: 30,
            duracion: 30,
            oportunidad: 1,
        },
        {
            id_examen: 2,
            materia: "Histologia",
            examen: "Unidad 1",
            puntaje: 28,
            de: 30,
            duracion: 60,
            oportunidad: 2,
        },
    ];
    calificacionUnidadesTrabajo: CalificacionesAlumnoUnidades[] = [
        {
            materia: "Anatomia",
            unidad: "Unidad 1",
            trabajo: null,
            puntaje: 10,
            de: 10,
        },
        {
            materia: "Anatomia",
            unidad: null,
            trabajo: "Trabajo Practio 1",
            puntaje: 6,
            de: 10,
        },
    ];

    //dataSource: CalificacionesAlumnoExamenes[] = this.listaExamenes;

    constructor(
        private alumnosService: AlumnosService,
        private route: Router
    ) {}

    ngOnInit(): void {
        this.loading("Cargando Calificaciones");
        this.alumnosService.getCalificaciones().subscribe((data) => {
            //  console.log('data', data)
            this.calificaciones = [...data];
            Swal.close();
        });
    }

    loading(title) {
        Swal.fire({
            title: title,

            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }
    verExamen(id_examen, nombre_materia) {
        sessionStorage.setItem("id_examen", id_examen);
        sessionStorage.setItem("nombre_disciplina", nombre_materia);
        sessionStorage.setItem("ruta", "/calificaciones");
        this.route.navigateByUrl("/ver-examen");
    }

    sortData(sort: MatSort) {
        console.log("Se llama al sort", sort);
        const data = this.calificaciones.slice();
        if (!sort.active || sort.direction === "") this.calificaciones = data;

        this.calificaciones = data.sort((a, b) => {
            const isAsc = sort.direction === "asc";
            switch (sort.active) {
                case "NOME_MATERIA":
                    return compare(a.NOME_MATERIA, b.NOME_MATERIA, isAsc);
                case "NOME_TIPO_EXAMEN":
                    return compare(
                        a.NOME_TIPO_EXAMEN,
                        b.NOME_TIPO_EXAMEN,
                        isAsc
                    );
                case "FECHA_INICIO":
                    return compare(a.FECHA_INICIO, b.FECHA_INICIO, isAsc);
                case "NOTA_ALUNO ":
                    return compare(a.NOTA_ALUNO, b.NOTA_ALUNO, isAsc);
                case "TIEMPO":
                    return compare(a.TIEMPO, b.TIEMPO, isAsc);

                default:
                    return 0;
            }
        });

        function compare(
            a: number | string,
            b: number | string,
            isAsc: boolean
        ) {
            return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
        }
    }
}

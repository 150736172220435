import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { AdministradoresService } from "app/administradores/administradores.service";
import { ProfesoresService } from 'app/profesores/profesores.service';
import Swal from "sweetalert2";
import { ModalModalEditarPreguntaComponent } from './modal-modal-editar-pregunta/modal-modal-editar-pregunta.component';

@Component({
    selector: "app-modal-lista-preguntas",
    templateUrl: "./modal-lista-preguntas.component.html",
    styleUrls: ["./modal-lista-preguntas.component.scss"],
    animations: fuseAnimations,
})
export class ModalListaPreguntasComponent implements OnInit {
    term: string;
    listaPreguntas: [] = [];
    displayedColumns: string[] = ["index", "preguntas", "accion"];
    dialogRef: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private adminService: AdministradoresService,
        private _matDialog: MatDialog,
        private dialofCloseRef: MatDialogRef<ModalListaPreguntasComponent>,
        private profesoresService: ProfesoresService
    ) { }

    ngOnInit(): void {
        this.loading("Cargando Preguntas");
        console.log("Este es el data", this.data);
        this.adminService
            .getListaPreguntasDeUnExamen(this.data.id)
            .subscribe((data) => {
                console.log("Datos de la api", data);
                this.listaPreguntas = data;
                Swal.close();
            });
    }
    loading(titulo) {
        Swal.fire({
            title: titulo,
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

    editarPregunta(pregunta) {
        // console.log("La pregunta", pregunta);
        this.dialogRef = this._matDialog.open(ModalModalEditarPreguntaComponent, {
            data: {
                pregunta: pregunta,
            },
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result === 'editado') {
                this.loading("Actualizando lista de  preguntas");
                console.log("Este es el data", this.data);
                this.adminService
                    .getListaPreguntasDeUnExamen(this.data.id)
                    .subscribe((data) => {
                        console.log("Datos de la api", data);
                        this.listaPreguntas = data;
                        Swal.close();
                    });
            }

        })
    }

    eliminarPregunta(pregunta) {
        Swal.fire({
            title: 'Esta seguro de eliminar la pregunta?',
            text: "Esto no se podra revertir!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminalo!',
            cancelButtonText: 'cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                this.loading('Eliminando Pregunta..')
                this.profesoresService
                    .deletePreguntaUnidad(pregunta.ID)
                    .subscribe((data) => {
                        Swal.fire(
                            'Eliminado!',
                            'La pregunta se ha eliminado correctamente.',
                            'success'
                        ).then(() => {
                            this.loading("Actualizando lista de  preguntas");
                            console.log("Este es el data", this.data);
                            this.adminService
                                .getListaPreguntasDeUnExamen(this.data.id)
                                .subscribe((data) => {
                                    console.log("Datos de la api", data);
                                    this.listaPreguntas = data;
                                    Swal.close();
                                });
                        })
                    });

            }
        })
    }
}

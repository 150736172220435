import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { ListaTrabajosPracticos } from "app/profesores/profesores.modal";
import { ProfesoresService } from "app/profesores/profesores.service";
import Swal from "sweetalert2";

@Component({
    selector: "app-modal-vista-previa",
    templateUrl: "./modal-vista-previa.component.html",
    styleUrls: ["./modal-vista-previa.component.scss"],
    animations: fuseAnimations,
})
export class ModalVistaPreviaComponent implements OnInit {
    form: FormGroup;
    dataSource: ListaTrabajosPracticos[] = [];
    f: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder,
        private profesoresService: ProfesoresService,
        public dialogRef: MatDialogRef<ModalVistaPreviaComponent>
    ) {}

    ngOnInit(): void {
        //En el data que se injecta en el constructor llegan los datos
        //Lo unico que hago es poner en una variable y hacerle un console.log para verificar
        this.f = this.data.form;
        this.f.fecha_inicio =
            this.f.fecha_inicio._i.year+    
            "-" +
            (this.f.fecha_inicio._i.month + 1) +
            "-" +
            this.f.fecha_inicio._i.date;    
        this.f.fecha_fin =
            this.f.fecha_fin._i.year
             +
            "-" +
            (this.f.fecha_fin._i.month + 1) +
            "-" +
            this.f.fecha_fin._i.date;
        console.log(this.f);
    }

    publicar() {
        Swal.fire({
            title: "Estas seguro?",
            text:
                "Una vez que subas este trabajo, NO PODRAS eliminar ni editar",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Publicar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                this.agregarTrabajoPractico();
            }
        });
    }

    agregarTrabajoPractico() {
        this.loading("Agregando Trabajo Practico");
        console.log(this.f);
        //console.log("Esto es lo que quiero subir", this.fileToUpload);

        this.profesoresService.postTrabajoPractico(this.f).subscribe(
            (response) => {
                console.log(response);
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Trabajo Practico agregado con exito!",
                    showConfirmButton: false,
                    timer: 1500,
                }).then(() => {
                    this.dialogRef.close();
                });
            },
            (err) => {
                Swal.fire("", "Hubo un problema con el servidor", "error");
            }
        );
    }
    loading(titulo) {
        Swal.fire({
            title: titulo,

            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }
}

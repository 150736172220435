import { Component, OnInit } from "@angular/core";
import { AdministradoresService } from "app/administradores/administradores.service";
import Swal from "sweetalert2";
import { ListadoExamenes } from "app/administradores/administradores.modal";

@Component({
    selector: "app-modal-lista-puntaje-alumno",
    templateUrl: "./modal-lista-puntaje-alumno.component.html",
    styleUrls: ["./modal-lista-puntaje-alumno.component.scss"],
})
export class ModalListaPuntajeAlumnoComponent implements OnInit {
    displayedColumns: string[] = ["Alumno", "Puntaje", "acciones"];
    listadoExamenes: ListadoExamenes[] = [];
    dataSource: ListadoExamenes[] = [];
    constructor(private administradoresService: AdministradoresService) {}

    ngOnInit(): void {}
    loading() {
        Swal.fire({
            title: "Cargando Puntaje de Alumnos",

            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CrearMateriaComponent } from "../crear-materia/crear-materia.component";
import { CrearSedeComponent } from "../crear-sede/crear-sede.component";
import { AgregarProfesoresAMateriaComponent } from "../agregar-profesores-a-materia/agregar-profesores-a-materia.component";
import { CargaDeContenidoComponent } from "../carga-de-contenido/carga-de-contenido.component";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { FuseSharedModule } from "@fuse/shared.module";
import { FuseWidgetModule, FuseConfirmDialogModule } from "@fuse/components";
import { MatTabsModule } from "@angular/material/tabs";
import { MatGridListModule } from "@angular/material/grid-list";
import { FuseSidebarModule } from "@fuse/components";
import { MatInputModule } from "@angular/material/input";
import { MatStepperModule } from "@angular/material/stepper";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AuthGuard } from "app/auth/auth.guard";
import { CrearTrabajoPracticoComponent } from "../crear-trabajo-practico/crear-trabajo-practico.component";
import { QuillModule } from "ngx-quill";
import { MatDialogModule } from "@angular/material/dialog";
import { ModalEditarComponent } from "../carga-de-contenido/modal-editar/modal-editar.component";
import { CargarUnidadAdminComponent } from "../cargar-unidad-admin/cargar-unidad-admin.component";
import { ModalEditarPreguntaComponent } from "../cargar-unidad-admin/modal-editar-pregunta/modal-editar-pregunta.component";
import { ModalAgregarPreguntaComponent } from "../cargar-unidad-admin/modal-agregar-pregunta/modal-agregar-pregunta.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxPaginationModule } from "ngx-pagination";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ListaExamenesAdminComponent } from "../lista-examenes-admin/lista-examenes-admin.component";
import { ModalEditarExamenAdminComponent } from "../lista-examenes-admin/modal-editar-examen-admin/modal-editar-examen-admin.component";
import { ModalListaPuntajeAlumnoComponent } from "../lista-examenes-admin/modal-lista-puntaje-alumno/modal-lista-puntaje-alumno.component";
import { ModalVerExamenComponent } from "../lista-examenes-admin/modal-ver-examen/modal-ver-examen.component";
import { CargarExamenAdminComponent } from "../cargar-examen-admin/cargar-examen-admin.component";
import { MateriasAdminComponent } from "../materias-admin/materias-admin.component";
import { VerUnidadesCargadasComponent } from "../ver-unidades-cargadas/ver-unidades-cargadas.component";
import { EvaluacionesComponent } from "../evaluaciones/evaluaciones.component";
import { InformeDeEvaluacionesComponent } from "../informe-de-evaluaciones/informe-de-evaluaciones.component";

import { MatSortModule } from "@angular/material/sort";
import { ModalListaPreguntasComponent } from "../lista-examenes-admin/modal-lista-preguntas/modal-lista-preguntas.component";
import { ModalModalEditarPreguntaComponent } from '../lista-examenes-admin/modal-lista-preguntas/modal-modal-editar-pregunta/modal-modal-editar-pregunta.component';
// Para el excel
import { ExcelService } from './../excel.service';

const routes = [
    {
        path: "crear-materia",
        component: CrearMateriaComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        },
    },
    {
        path: "crear-sede",
        component: CrearSedeComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        },
    },
    {
        path: "agregar-profesor-a-materia",
        component: AgregarProfesoresAMateriaComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        },
    },
    {
        path: "carga-de-contenido",
        component: CargaDeContenidoComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        },
    },
    {
        path: "crear-trabajo-practico",
        component: CrearTrabajoPracticoComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        },
    },
    {
        path: "admin-materias",
        component: MateriasAdminComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        },
    },
    {
        path: "lista-examenes-admin",
        component: ListaExamenesAdminComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        },
    },
    {
        path: "cargar-examen-admin",
        component: CargarExamenAdminComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        },
    },
    {
        path: "ver-unidades-cargadas",
        component: VerUnidadesCargadasComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        },
    },
    {
        path: "informe-de-evaluaciones",
        component: InformeDeEvaluacionesComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        },
    },
    {
        path: "evaluaciones",
        component: EvaluacionesComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        },
    },
];

@NgModule({
    declarations: [
        CrearMateriaComponent,
        CrearSedeComponent,
        AgregarProfesoresAMateriaComponent,
        CargaDeContenidoComponent,
        CrearTrabajoPracticoComponent,
        ModalEditarComponent,
        CargarUnidadAdminComponent,
        ModalAgregarPreguntaComponent,
        ModalEditarPreguntaComponent,
        ListaExamenesAdminComponent,
        ModalEditarExamenAdminComponent,
        ModalListaPuntajeAlumnoComponent,
        ModalVerExamenComponent,
        CargarExamenAdminComponent,
        MateriasAdminComponent,
        VerUnidadesCargadasComponent,
        InformeDeEvaluacionesComponent,
        EvaluacionesComponent,
        ModalListaPreguntasComponent,
        ModalModalEditarPreguntaComponent
    ],
    entryComponents: [ModalEditarComponent],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTabsModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatGridListModule,
        FuseSidebarModule,
        MatStepperModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        BrowserAnimationsModule,
        FuseSharedModule,
        ReactiveFormsModule,
        FormsModule,
        QuillModule.forRoot(),
        MatDialogModule,

        //

        MatDatepickerModule,

        MatToolbarModule,
        FuseConfirmDialogModule,

        MatListModule,

        MatSlideToggleModule,

        NgxMatSelectSearchModule,
        NgxPaginationModule,
        //Search Filter
        Ng2SearchPipeModule,
        //NoopAnimationsModule
        MatAutocompleteModule,
        MatSortModule,
    ],
    providers: [ExcelService]
})
export class AdministradoresModule { }

export class FileManagerFakeDb {
    public static files = [
        {
            'name': 'Work Documents',
            'type': 'folder',
            'owner': 'me',
            'size': '',
            'modified': 'July 8, 2017',
            'opened': 'July 8, 2017',
            'created': 'July 8, 2017',
            'extention': '',
            'location': 'My Files > Documents',
            'offline': true
        },
        {
            'name': 'Public Documents',
            'type': 'folder',
            'owner': 'public',
            'size': '',
            'modified': 'July 8, 2017',
            'opened': 'July 8, 2017',
            'created': 'July 8, 2017',
            'extention': '',
            'location': 'My Files > Documents',
            'offline': true
        },
        {
            'name': 'Private Documents',
            'type': 'folder',
            'owner': 'me',
            'size': '',
            'modified': 'July 8, 2017',
            'opened': 'July 8, 2017',
            'created': 'July 8, 2017',
            'extention': '',
            'location': 'My Files > Documents',
            'offline': true
        },
        {
            'name': 'Ongoing projects',
            'type': 'document',
            'owner': 'Emily Bennett',
            'size': '1.2 Mb',
            'modified': 'July 8, 2017',
            'opened': 'July 8, 2017',
            'created': 'July 8, 2017',
            'extention': '',
            'location': 'My Files > Documents',
            'offline': true,
            'preview': 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name': 'Shopping list',
            'type': 'document',
            'owner': 'Emily Bennett',
            'size': '980 Kb',
            'modified': 'July 8, 2017',
            'opened': 'July 8, 2017',
            'created': 'July 8, 2017',
            'extention': '',
            'location': 'My Files > Documents',
            'offline': true,
            'preview': 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name': 'Invoices',
            'type': 'spreadsheet',
            'owner': 'Emily Bennett',
            'size': '750 Kb',
            'modified': 'July 8, 2017',
            'opened': 'July 8, 2017',
            'created': 'July 8, 2017',
            'extention': '',
            'location': 'My Files > Documents',
            'offline': true,
            'preview': 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name': 'Crash logs',
            'type': 'document',
            'owner': 'Emily Bennett',
            'size': '980 Mb',
            'modified': 'July 8, 2017',
            'opened': 'July 8, 2017',
            'created': 'July 8, 2017',
            'extention': '',
            'location': 'My Files > Documents',
            'offline': true,
            'preview': 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name': 'System logs',
            'type': 'document',
            'owner': 'Emily Bennett',
            'size': '52 Kb',
            'modified': 'July 8, 2017',
            'opened': 'July 8, 2017',
            'created': 'July 8, 2017',
            'extention': '',
            'location': 'My Files > Documents',
            'offline': true,
            'preview': 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name': 'Prices',
            'type': 'spreadsheet',
            'owner': 'Emily Bennett',
            'size': '27 Mb',
            'modified': 'July 8, 2017',
            'opened': 'July 8, 2017',
            'created': 'July 8, 2017',
            'extention': '',
            'location': 'My Files > Documents',
            'offline': true,
            'preview': 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name': 'Anabelle Manual',
            'type': 'document',
            'owner': 'Emily Bennett',
            'size': '1.1 Kb',
            'modified': 'July 8, 2017',
            'opened': 'July 8, 2017',
            'created': 'July 8, 2017',
            'extention': '',
            'location': 'My Files > Documents',
            'offline': true,
            'preview': 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name': 'Steam summer sale budget',
            'type': 'spreadsheet',
            'owner': 'Emily Bennett',
            'size': '505 Kb',
            'modified': 'July 8, 2017',
            'opened': 'July 8, 2017',
            'created': 'July 8, 2017',
            'extention': '',
            'location': 'My Files > Documents',
            'offline': true,
            'preview': 'assets/images/file-manager/sample-file-preview.jpg'
        }
    ];

}

import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { rendirExamenAlumno, VerExamen } from "app/alumnos/alumnos";
import { AlumnosService } from "app/alumnos/alumnos.service";
import Swal from "sweetalert2";
import { Location } from "@angular/common";
import { AdministradoresService } from "app/administradores/administradores.service";
import { AuthService } from "app/auth/auth.service";
@Component({
    selector: "app-ver-examen",
    templateUrl: "./ver-examen.component.html",
    styleUrls: ["./ver-examen.component.scss"],
})
export class VerExamenComponent implements OnInit {
    form: FormGroup;
    // Vertical Stepper
    verticalStepperStep1: FormGroup;
    verticalStepperStep2: FormGroup;
    verticalStepperStep3: FormGroup;
    verticalStepperStep4: FormGroup;
    verticalStepperStep5: FormGroup;
    nombre_materia: string;
    verExamen: VerExamen[] = [];
    respuestaCorrecta = [];
    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    respuestas: any;
    examen: rendirExamenAlumno[] = [];
    ruta: string;
    nombre_alumno: string;
    desde: string;

    habilitarEdicion: boolean = true;

    constructor(
        private _formBuilder: FormBuilder,
        private route: Router,
        private alumnosService: AlumnosService,
        private _location: Location,
        private adminService: AdministradoresService,
        public authService: AuthService
    ) {}

    ngOnInit(): void {
        this.loading("Cargando Examen..");
        this.nombre_materia = sessionStorage.getItem("nombre_disciplina");
        let id_examen = +sessionStorage.getItem("id_examen");
        this.ruta = sessionStorage.getItem("ruta");
        this.desde = sessionStorage.getItem("desde");
        console.log("Este es el id_examen recibido", id_examen);
        if (id_examen && this.desde !== "admin") {
            this.alumnosService.getVerExamen(id_examen).subscribe(
                (data) => {
                    console.log("Esto me envia en ver examen", data);
                    this.verExamen = data;
                    Swal.close();
                },
                (err) => {}
            );
        } else if (this.desde === "admin") {
            let id_alumno = sessionStorage.getItem("id_alumno");
            this.nombre_alumno = sessionStorage.getItem("nombre_alumno");
            this.adminService.getVerExamen(id_examen, id_alumno).subscribe(
                (data) => {
                    console.log("Se vino desde admin para ver el examen", data);
                    this.verExamen = data;
                    Swal.close();
                },
                (err) => {
                    this._location.back();
                }
            );
        } else {
            this._location.back();
        }
    }
    loading(title) {
        Swal.fire({
            title: title,

            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

    habilitarEdit() {
        this.habilitarEdicion = !this.habilitarEdicion;
    }

    guardar() {
        Swal.fire({
            title: "Estas seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, guardar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    "Listo!",
                    "Los cambios han sido guardados con exito",
                    "success"
                );
            }
        });
        this.habilitarEdicion = !this.habilitarEdicion;
    }
    editarExamenAlumno(id_respuesta, respuestas) {
        console.log("id_respuesta", id_respuesta);
        console.log("respuestas", respuestas);
        for (let respuesta of respuestas) {
            if (respuesta.id_respuesta === id_respuesta) {
                respuesta.marcada = true;
            } else {
                respuesta.marcada = false;
            }
        }
    }
}

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";

import { fuseConfig } from "app/fuse-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { SampleModule } from "app/main/sample/sample.module";

//Imports Module Creados
import { AlumnosModule } from "./alumnos/alumnos.module";
import { ProfesoresModule } from "./profesores/profesores.module";

import { AdministradoresModule } from "./administradores/administradores/administradores.module";

import { ChatModule } from "./chat/chat.module";

//Turbio para el fake-db
import { InMemoryWebApiModule } from "angular-in-memory-web-api";
import { FakeDbService } from "app/fake-db/fake-db.service";

import { NgxPaginationModule } from "ngx-pagination";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { ModalModalEditarPreguntaComponent } from './administradores/lista-examenes-admin/modal-lista-preguntas/modal-modal-editar-pregunta/modal-modal-editar-pregunta.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

const appRoutes: Routes = [
    {
        path: "auth",
        loadChildren: () =>
            import("./auth/auth/auth.module").then((m) => m.AuthModule),
    },
    {
        path: "alumnos",
        loadChildren: () =>
            import("./alumnos/alumnos.module").then((m) => m.AlumnosModule),
    },
    {
        path: "profesores",
        loadChildren: () =>
            import("./profesores/profesores.module").then(
                (m) => m.ProfesoresModule
            ),
    },
    {
        path: "administradores",
        loadChildren: () =>
            import(
                "./administradores/administradores/administradores.module"
            ).then((m) => m.AdministradoresModule),
    },
    {
        path: "chat",
        loadChildren: () =>
            import("./chat/chat.module").then((m) => m.ChatModule),
    },
    {
        path: "**",
        redirectTo: "auth",
    },
];

@NgModule({
    declarations: [AppComponent],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: "es-ES" }, //Puse los date pickers en español
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true,
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
        AlumnosModule,
        ProfesoresModule,
        AdministradoresModule,
        ChatModule,

        //Pagination Ngx
        NgxPaginationModule,
        //Search Filter
        Ng2SearchPipeModule,
        //charts
        NgxChartsModule
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }

import { Component, OnInit } from "@angular/core";
import { AlumnosService } from "../alumnos.service";
import { EXAMENESDELALUMNO, ExamenesAlumno } from "../alumnos";
import { fuseAnimations } from "@fuse/animations";
import Swal from "sweetalert2";
import { Router } from '@angular/router';

@Component({
    selector: "app-examenes",
    templateUrl: "./examenes.component.html",
    styleUrls: ["./examenes.component.scss"],
    animations: fuseAnimations,
})
export class ExamenesComponent implements OnInit {
    public examenes: ExamenesAlumno[] = [];

    constructor(private _apiAlumnos: AlumnosService, private route: Router) { }

    ngOnInit(): void {
        this.loading();
        this.listarExamenes();
    }
    //Listar los examenes del alumno
    listarExamenes() {
        //ACA DEBERIAMOS APLICAR CON LA API CUANDO EL GORDO SE DESPERTE
        this._apiAlumnos.getExamenes().subscribe(
            (data) => {
                this.examenes = data;
                console.log('Estos son los examenes', data);
                Swal.close();
            },
            (err) => {
                // console.log(err);
                Swal.fire(
                    "Opss..",
                    "Hubo un problema al cargar los datos!",
                    "error"
                );
            }
        );
    }



    cargar_examen(id_examen, tiempo, tema, id_periodo_letivo, id_disciplina, nombre_disciplina, nota) {

        sessionStorage.setItem("id_examen", id_examen);
        sessionStorage.setItem("tiempo_restante", tiempo);
        sessionStorage.setItem("tema", tema);
        sessionStorage.setItem('ID_PERIODO_LETIVO', id_periodo_letivo);
        sessionStorage.setItem('disciplinas_id', id_disciplina);
        sessionStorage.setItem('nombre_disciplina', nombre_disciplina)
        sessionStorage.setItem('ruta', '/examenes')
        if (nota === false) {
            //console.log('Entro')
            Swal.fire({
                title: 'LEIA COM ATENÇÃO',
                html: `
                O exame ficará disposnivel apartir da data e hora estabelecida pela universidade<br/>
                • Apartir do momento que o aluno clicar no botão abaixo terá ${tiempo} min para responder!<br/>
                • A Prova tem tentativa UNICA, e o tempo começa a contar depois de clicar no botão abaixo<br/>
                • Ao final das respontas, o aluno terá que clicar no botão Finalizar prova! somente assim será gravado no banco de dados sua resposta<br/>
                • Depois de finalizado o exame, não poderá modificar perguntas! o exame estará encerrado para o aluno!!!<br/>
                `,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Entrar a rendir',
                cancelButtonText: 'Volver'
            }).then((result) => {
                if (result.isConfirmed) {
                    //console.log('Le dio confirm')
                    this.route.navigateByUrl('/rendir-examen')
                } else {
                    return
                }
            })
        } else {
            this.route.navigateByUrl('/ver-examen')
        }
    }
    loading() {
        Swal.fire({
            title: "Cargando examenes",
            //timer: 2000,
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }
}

<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
    <!-- HEADER -->
    <div
        class="header accent p-24 h-130"
        fxLayout="row"
        fxLayoutAlign="start center"
    >
        <button
            mat-icon-button
            class="mr-16"
            [routerLink]="'/lista-puntaje-alumnos'"
        >
            <mat-icon>arrow_back</mat-icon>
        </button>
        <div fxLayout="column" fxLayoutAlign="center start">
            <div class="h1 mt-16">Evaluacion Materia 1</div>
        </div>
    </div>
    <!-- / HEADER -->
    <!-- CONTENT -->
    <div class="content p-24">
        <div class="vertical-stepper-wrapper">
            <!-- VERTICAL STEPPER EXAMPLE -->
            <mat-vertical-stepper class="mat-elevation-z4" [linear]="true">
                <mat-step [stepControl]="verticalStepperStep1">
                    <form fxLayout="column" [formGroup]="verticalStepperStep1">
                        <ng-template matStepLabel>Pregunta</ng-template>
                        <!--ACA VA LA PREGUNTA-->
                        <div class="h2 mb-24">Enunciado 1</div>
                        <mat-form-field class="example-full-width">
                            <mat-label>Explicación (disabled)</mat-label>
                            <input
                                matInput
                                disabled
                                value="Esta es una explicación de por que es esa la respuesta correcta"
                            />
                        </mat-form-field>
                        <!--ACA TERMINAR LA PREGUNTA-->

                        <!--ACA VAN LAS OPCIONES-->
                        <section class="example-section">
                            <!--RESPUESTAS CON RADIO BUTTON-->
                            <mat-radio-group
                                formControlName="respuestaSeleccionada1"
                            >
                                <mat-radio-button
                                    class="example-margin"
                                    value="idrespuesta1"
                                    style="color: green"
                                >
                                    Respuesta 1
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="idrespuesta2"
                                    >Respuesta 2
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="idrespuesta3"
                                    >Respuesta 3
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="idrespuesta4"
                                >
                                    Respuesta 4
                                </mat-radio-button>
                            </mat-radio-group>
                        </section>
                        <!--ACA TERMINAN LAS OPCIONES-->

                        <!--ACA VA EL BOTON DE SIGUIENTE-->

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button
                                mat-raised-button
                                matStepperNext
                                type="button"
                                color="accent"
                            >
                                Siguiente
                            </button>
                        </div>
                        <!--ACA TERMINA EL BOTON SIGUIENTE-->
                    </form>
                </mat-step>

                <mat-step [stepControl]="verticalStepperStep2">
                    <form fxLayout="column" [formGroup]="verticalStepperStep2">
                        <ng-template matStepLabel>Pregunta</ng-template>
                        <!--ACA VA LA PREGUNTA-->
                        <div class="h2 mb-24">Enunciado 2</div>
                        <mat-form-field class="example-full-width">
                            <mat-label>Explicación (disabled)</mat-label>
                            <input
                                matInput
                                disabled
                                value="Esta es una explicación de por que es esa la respuesta correcta"
                            />
                        </mat-form-field>
                        <!--ACA TERMINAR LA PREGUNTA-->
                        <!--ACA VAN LAS OPCIONES-->
                        <section class="example-section">
                            <!--RESPUESTAS CON RADIO BUTTON-->
                            <mat-radio-group
                                formControlName="respuestaSeleccionada2"
                            >
                                <mat-radio-button
                                    class="example-margin"
                                    value="idrespuesta2"
                                    style="color: red"
                                >
                                    Respuesta 1
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="idrespuesta1"
                                    style="color: green"
                                    >Respuesta 2
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="idrespuesta3"
                                    >Respuesta 3
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="idrespuesta4"
                                >
                                    Respuesta 4
                                </mat-radio-button>
                            </mat-radio-group>
                        </section>
                        <!--ACA TERMINAN LAS OPCIONES-->

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button
                                class="mr-8"
                                mat-raised-button
                                matStepperPrevious
                                type="button"
                                color="accent"
                            >
                                Volver
                            </button>
                            <button
                                mat-raised-button
                                matStepperNext
                                type="button"
                                color="accent"
                            >
                                Siguiente
                            </button>
                        </div>
                    </form>
                </mat-step>

                <mat-step [stepControl]="verticalStepperStep3">
                    <form fxLayout="column" [formGroup]="verticalStepperStep3">
                        <ng-template matStepLabel>Pregunta</ng-template>
                        <!--ACA VA LA PREGUNTA-->
                        <div class="h2 mb-24">Enunciado 3</div>
                        <mat-form-field class="example-full-width">
                            <mat-label>Explicación (disabled)</mat-label>
                            <input
                                matInput
                                disabled
                                value="Esta es una explicación de por que es esa la respuesta correcta"
                            />
                        </mat-form-field>
                        <!--ACA TERMINAR LA PREGUNTA-->
                        <!--ACA VAN LAS OPCIONES-->
                        <section class="example-section">
                            <!--RESPUESTAS CON RADIO BUTTON-->
                            <mat-radio-group
                                formControlName="respuestaSeleccionada3"
                            >
                                <mat-radio-button
                                    class="example-margin"
                                    value="respuesta1"
                                >
                                    Respuesta 1
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="respuesta2"
                                    >Respuesta 2
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="respuesta3"
                                    style="color: red"
                                    >Respuesta 3
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="respuesta4"
                                    style="color: green"
                                >
                                    Respuesta 4
                                </mat-radio-button>
                            </mat-radio-group>
                        </section>
                        <!--ACA TERMINAN LAS OPCIONES-->

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button
                                class="mr-8"
                                mat-raised-button
                                matStepperPrevious
                                type="button"
                                color="accent"
                            >
                                Volver
                            </button>
                            <button
                                mat-raised-button
                                matStepperNext
                                type="button"
                                color="accent"
                            >
                                Siguiente
                            </button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="verticalStepperStep4">
                    <form fxLayout="column" [formGroup]="verticalStepperStep4">
                        <ng-template matStepLabel>Pregunta</ng-template>
                        <!--ACA VA LA PREGUNTA-->
                        <div class="h2 mb-24">Enunciado 4</div>
                        <mat-form-field class="example-full-width">
                            <mat-label>Explicación (disabled)</mat-label>
                            <input
                                matInput
                                disabled
                                value="Esta es una explicación de por que es esa la respuesta correcta"
                            />
                        </mat-form-field>
                        <!--ACA TERMINAR LA PREGUNTA-->
                        <!--ACA VAN LAS OPCIONES-->
                        <section class="example-section">
                            <!--RESPUESTAS CON RADIO BUTTON-->
                            <mat-radio-group
                                formControlName="respuestaSeleccionada4"
                            >
                                <mat-radio-button
                                    class="example-margin"
                                    value="respuesta1"
                                >
                                    Respuesta 1
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="respuesta2"
                                    >Respuesta 2
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="respuesta3"
                                    style="color: green"
                                    >Respuesta 3
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="respuesta4"
                                >
                                    Respuesta 4
                                </mat-radio-button>
                            </mat-radio-group>
                        </section>

                        <!--ACA TERMINAN LAS OPCIONES-->

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button
                                class="mr-8"
                                mat-raised-button
                                matStepperPrevious
                                type="button"
                                color="accent"
                            >
                                Volver
                            </button>
                            <button
                                mat-raised-button
                                matStepperNext
                                type="button"
                                color="accent"
                            >
                                Siguiente
                            </button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="verticalStepperStep5">
                    <form fxLayout="column" [formGroup]="verticalStepperStep5">
                        <ng-template matStepLabel>Pregunta</ng-template>
                        <!--ACA VA LA PREGUNTA-->
                        <div class="h2 mb-24">Enunciado 5</div>
                        <mat-form-field class="example-full-width">
                            <mat-label>Explicación (disabled)</mat-label>
                            <input
                                matInput
                                disabled
                                value="Esta es una explicación de por que es esa la respuesta correcta"
                            />
                        </mat-form-field>
                        <!--ACA TERMINAR LA PREGUNTA-->
                        <!--ACA VAN LAS OPCIONES-->
                        <section class="example-section">
                            <!--RESPUESTAS CON RADIO BUTTON-->
                            <mat-radio-group
                                formControlName="respuestaSeleccionada5"
                            >
                                <mat-radio-button
                                    class="example-margin"
                                    value="respuesta1"
                                >
                                    Respuesta 1
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="respuesta2"
                                    >Respuesta 2
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="respuesta3"
                                    >Respuesta 3
                                </mat-radio-button>
                                <br />
                                <mat-radio-button
                                    class="example-margin"
                                    value="respuesta4"
                                    style="color: green"
                                >
                                    Respuesta 4
                                </mat-radio-button>
                            </mat-radio-group>
                        </section>
                        <!--ACA TERMINAN LAS OPCIONES-->

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button
                                class="mr-8"
                                mat-raised-button
                                matStepperPrevious
                                type="button"
                                color="accent"
                            >
                                Volver
                            </button>
                            <button
                                mat-raised-button
                                matStepperNext
                                type="button"
                                color="accent"
                            >
                                Siguiente
                            </button>
                        </div>
                    </form>
                </mat-step>

                <!--ACA ES PARA ENTREGAR-->

                <mat-step>
                    <ng-template matStepLabel>Terminar</ng-template>

                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button
                            class="mr-8"
                            mat-raised-button
                            matStepperPrevious
                            type="button"
                            color="accent"
                        >
                            Volver
                        </button>
                        <button
                            mat-raised-button
                            type="button"
                            color="accent"
                            (click)="finishVerticalStepper()"
                        >
                            Aceptar
                        </button>
                    </div>
                </mat-step>
                <!--ACA TERMINAR LA PARTE DE ENTREGA-->
            </mat-vertical-stepper>
            <!-- / VERTICAL STEPPER EXAMPLE -->
        </div>
    </div>
</div>

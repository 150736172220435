<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Lista de Materias
            </h1>
        </div>
    </div>

    <div class="content p-12">
        <div
            class="mb-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxLayout.gt-md="row"
            id="products"
        >
            <form
                class="mat-card mat-elevation-z4 p-24 mr-24"
                fxLayout="column"
                fxLayoutAlign="start"
                fxFlex="1 0 auto"
                name="form"
                [formGroup]="form"
            >
                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <!-- ACA EMPIEZASELECCIONAR SEDE
                    <mat-form-field appearance="outline" fxFlex="1 0 auto">
                        <mat-label>Sede</mat-label>
                        <mat-select required formControlName="sede">
                            <mat-option [value]="sede.ID" *ngFor="let sede of listaSede"
                                (click)="getSemestreProfesor(sede.ID)">
                                {{ sede.NOMBRE_SEDE }}
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="secondary-text">outlined_flag</mat-icon>
                        <mat-error>Por favor elija una sede.</mat-error>
                    </mat-form-field>
                    ACA TERMINA SELECCIONAR SEDE-->

                    <!--EMPIEZA SELECCIONAR METODO
                    <mat-form-field appearance="outline" fxFlex="1 0 auto" class="pl-4">
                        <mat-label>Metodo de lista por</mat-label>
                        <mat-select required formControlName="metodo">
                            <mat-option> Semestre </mat-option>
                            <mat-option> Profesor </mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="secondary-text">outlined_flag</mat-icon>
                        <mat-error>Por favor elija un metodo.</mat-error>
                    </mat-form-field>
                    ACA TERMINA SELECCIONAR METODO-->

                    <!--ACA EMPIEZA SEMESTRE
                    <mat-form-field appearance="outline" fxFlex="1 0 auto" class="pl-4">
                        <mat-label>Semestre</mat-label>
                        <mat-select required formControlName="semestre">
                            <mat-option [value]="semestre.id" (click)="getMaterias(semestre.id)"
                                *ngFor="let semestre of listaSemestreReal">
                                {{ semestre.nome_semestre }} {{ semestre.nome }}
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="secondary-text">outlined_flag</mat-icon>
                        <mat-error>Por favor elija un semestre.</mat-error>
                    </mat-form-field>
                    ACA TERMINA SEMESTRE-->

                    <!--ACA EMPIEZA PROFESOR-->
                    <mat-form-field
                        appearance="outline"
                        fxFlex="1 0 auto"
                        class="pl-4"
                    >
                        <mat-label>Profesores</mat-label>
                        <mat-select required formControlName="profesores">
                            <mat-option>
                                <ngx-mat-select-search
                                    [(ngModel)]="term2"
                                    [ngModelOptions]="{ standalone: true }"
                                    placeholderLabel="Buscar Materia..."
                                    noEntriesFoundLabel="No existe esta materia"
                                >
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                                [value]="profesor.ID"
                                (click)="getMateriasProfesor(profesor.ID)"
                                *ngFor="
                                    let profesor of listaProfesores
                                        | filter: term2
                                "
                            >
                                {{ profesor.NOMBRE }}
                                {{ profesor.APELLIDO }}</mat-option
                            >
                        </mat-select>
                        <mat-icon matSuffix class="secondary-text"
                            >outlined_flag</mat-icon
                        >
                        <mat-error>Por favor elija un profesor.</mat-error>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>

    <div class="content p-12">
        <div
            class="mb-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxLayout.gt-md="row"
            id="products"
        >
            <form
                class="mat-card mat-elevation-z4 p-24 mr-24"
                fxLayout="column"
                fxLayoutAlign="start"
                fxFlex="1 0 auto"
                name="form"
                style="margin-top: -20px"
            >
                <div class="content p-12">
                    <div fxLayout="column" fxLayoutAlign="center">
                        <div
                            class="courses"
                            fxLayout="row wrap"
                            fxLayoutAlign="center"
                            [@animateStagger]="{ value: '50' }"
                        >
                            <!--INICIO DEL CURSO 1-->
                            <div
                                class="course"
                                fxFlex="100"
                                fxFlex.gt-xs="50"
                                fxFlex.gt-sm="25"
                                [@animate]="{
                                    value: '*',
                                    params: { y: '100%' }
                                }"
                                *ngFor="let materia of materias"
                            >
                                <div
                                    mat-button
                                    color="accent"
                                    class="course-content"
                                    fxLayout="column"
                                    fxFlex="1 1 auto"
                                    routerLink="/cargar-unidad"
                                    (click)="
                                        guardarId(
                                            materia.id_disciplina,
                                            materia.funcionario_id
                                        )
                                    "
                                >
                                    <div
                                        class="header"
                                        fxLayout="row"
                                        fxLayoutAlign="center center"
                                    >
                                        <div
                                            class="length"
                                            fxLayout="row"
                                            fxLayoutAlign="center center"
                                        >
                                            <mat-icon
                                                class="length-icon s-20"
                                                style="
                                                    color: rgba(
                                                        255,
                                                        255,
                                                        255,
                                                        0.75
                                                    );
                                                "
                                                >school
                                            </mat-icon>
                                            <div
                                                style="
                                                    color: rgba(
                                                        255,
                                                        255,
                                                        255,
                                                        0.75
                                                    );
                                                "
                                            >
                                                {{ materia.nome }}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="content"
                                        fxLayout="column"
                                        fxLayoutAlign="center center"
                                        fxFlex="1 1 auto"
                                    >
                                        <div class="h1">
                                            Seccion:
                                            {{ materia.nome_semestre }}
                                            {{ materia.nome_turma }}
                                        </div>
                                        <div class="h1">
                                            Sede: {{ materia.nome_filial }}
                                        </div>
                                    </div>

                                    <div
                                        class="footer"
                                        fxLayout="row"
                                        fxLayoutAlign="center center"
                                    >
                                        <button
                                            (click)="
                                                guardarId(
                                                    materia.id_disciplina,
                                                    materia.funcionario_id
                                                )
                                            "
                                            mat-button
                                            color="accent"
                                        >
                                            Entrar
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!--FIN DEL CURSO 1-->
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

import { Component, OnInit } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { AlumnosService } from "../alumnos.service";
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { MateriasAlumno } from "../alumnos";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

@Component({
    selector: "app-alumno-materias",
    templateUrl: "./alumno-materias.component.html",
    styleUrls: ["./alumno-materias.component.scss"],
    animations: fuseAnimations,
})
export class AlumnoMateriasComponent implements OnInit {
    horizontalPosition: MatSnackBarHorizontalPosition = "start";
    verticalPosition: MatSnackBarVerticalPosition = "bottom";
    cantidadNotificaciones: any;
    materias: MateriasAlumno[] = [];
    role: string;
    mostrarAdvertencia = 0;
    constructor(
        private _apiAlumnos: AlumnosService,
        private _snackBar: MatSnackBar,
        private route: Router
    ) {}

    ngOnInit(): void {
        this.cantidadNotificaciones = localStorage.getItem("Notificaciones");
        this.role = localStorage.getItem("ROLE");
        if (this.role === "ROLE_ALUMNO") {
            this.loading("Cargando Materias");
            this._apiAlumnos.getMaterias().subscribe((data) => {
                if (
                    this.cantidadNotificaciones > 0 &&
                    this.role === "ROLE_SUPERADMIN"
                ) {
                    this.openSnackBar();
                    //console.log('Entro', this.cantidadNotificaciones)
                }
                // console.log('Se llamo bien a la api materias')
                // console.log(data)
                this.materias = [...data];
                this.mostrarAdvertencia = +sessionStorage.getItem(
                    "mostrarAdvertencia"
                );
                if (this.mostrarAdvertencia === 1) {
                    Swal.fire({
                        imageUrl: "assets/images/pdsucp.jpg",
                        imageWidth: 700,
                        width: 700,
                        imageHeight: 700,
                        showCloseButton: true,
                        confirmButtonText: "Cerrar",
                        imageAlt: "Custom image",
                    });
                    sessionStorage.removeItem("mostrarAdvertencia");
                } else {
                    Swal.close();
                }
            });
        }
    }
    openSnackBar() {
        this._snackBar.open("Tiene nuevas notificaciones!", "x", {
            panelClass: "accent",
            verticalPosition: "top",
            horizontalPosition: "right",
            duration: 2000,
        });
    }
    loading(titulo) {
        Swal.fire({
            title: titulo,

            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }
    materiaSeleccionada(
        id_materia,
        link_video,
        materia_curso_id,
        periodo_letivo_id,
        nombre_materia,
        disciplinas_id,
        tipo_materia,
        nome_profesor,
        sobrenome
    ) {
        if (link_video) {
            sessionStorage.setItem("link_video", link_video);
        }
        sessionStorage.setItem("id_materia", id_materia);
        sessionStorage.setItem("matricula_curso_id", materia_curso_id);
        sessionStorage.setItem("periodo_letivo_id", periodo_letivo_id);
        sessionStorage.setItem("nombre_materia", nombre_materia);
        sessionStorage.setItem("disciplinas_id", disciplinas_id);
        sessionStorage.setItem("tipo_materia", tipo_materia);
        sessionStorage.setItem(
            "nombre_profesor",
            nome_profesor + " " + sobrenome
        );
        this.route.navigateByUrl("/unidades-materia");
    }
}

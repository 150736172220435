<!-- SIDENAV HEADER -->
<div class="sidenav-header" *ngIf="contact">

    <!-- CONTACT TOOLBAR -->
    <mat-toolbar class="accent">



        <!-- TOOLBAR BOTTOM -->
        <mat-toolbar-row class="toolbar-bottom" fxLayout="column" fxLayoutAlign="center center">
            <img src="assets/images/avatars/profile.jpg" class="avatar contact-avatar huge" />
            <div class="contact-name my-8">Holi</div>
        </mat-toolbar-row>
        <!-- / TOOLBAR BOTTOM -->

    </mat-toolbar>
    <!-- / CONTACT TOOLBAR -->
</div>

<!-- SIDENAV CONTENT -->
<div class="sidenav-content py-32 px-24" fxFlex fxLayout="column" *ngIf="contact">

    <!-- CONTACT MOOD -->
    <div class="secondary-text mb-8">Mood</div>
    <div>{{contact.mood}}</div>
    <!-- / CONTACT MOOD -->

</div>
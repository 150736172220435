import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { ListadoProfesores } from "../administradores.modal";

@Component({
    selector: "app-agregar-profesores-a-materia",
    templateUrl: "./agregar-profesores-a-materia.component.html",
    styleUrls: ["./agregar-profesores-a-materia.component.scss"],
    animations: fuseAnimations,
})
export class AgregarProfesoresAMateriaComponent implements OnInit {
    displayedColumns: string[] = ["Profesor", "Materia", "Sede", "Acciones"];
    dataSource: ListadoProfesores[] = [
        {
            ID: 1,
            NOMBRE: "Juan Perez",
            MATERIA: "Biologia",
        },
    ];
    formProf: FormGroup;

    constructor(private _formBuilder: FormBuilder) {}

    ngOnInit(): void {
        // Reactive Form
        this.formProf = this._formBuilder.group({
            profe: ["", Validators.required],
            materia: ["", Validators.required],
            sede: ["", Validators.required],
        });
    }
    agregarProf() {}

    editarProf() {}

    eliminarProf() {}
}

<div id="academy-courses" class="page-layout simple inner-scroll">
    <div fusePerfectScrollbar>
        <!-- HEADER -->
        <div
            class="header accent p-16 p-sm-24"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <button
                mat-icon-button
                class="mr-16"
                (click)="atras()"
                style="overflow: visible"
            >
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="hero-text">
                <h1
                    style="margin-bottom: 0%"
                    [@animate]="{
                        value: '*',
                        params: { delay: '100ms', y: '25px' }
                    }"
                >
                    {{ nombre__materia }}
                </h1>
            </div>
            <div style="margin-left: 60%">
                <button routerLink="/chat" mat-raised-button>Ir a Foro</button>
            </div>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT -->
        <div class="content p-24">
            <div fxLayout="column" fxLayoutAlign="center">
                <!--ACA COMIENZA EL TAB-GROUP-->
                <mat-tab-group (selectedTabChange)="realizarTrabajoFalso()">
                    <!--TAB VIDEO DE INTRODUCCION
                <mat-tab label="Introduccion">
                    pantallas mayor a 720
                    <div *ngIf="link_video === null">
                        Actualmente no hay video introduccion para esta
                        disciplina.
                    </div>
                    <div
                        class="card-video2"
                        fxFlex="10%"
                        fxFlex.gt-sm="37%"
                        fxFlex.gt-lg="40%"
                        style="position: inherit"
                        *ngIf="link_video !== null"
                    >
                        <div
                            class="mat-card mat-elevation-z4 p-24 mr-24"
                            fxLayout="column"
                            fxLayoutAlign="center"
                            fxFlex="1 0 auto"
                            style="border-radius: 30px"
                        >
                            <h1>Introduccion a la Materia</h1>

                            <div class="container2">
                                <iframe
                                    class="responsive-iframe2"
                                    [src]="link_video"
                                    frameborder="0"
                                    allowfullscreen
                                    allow="autoplay; encrypted-media"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </mat-tab>-->

                    <!--ACA COMIENZA EL TAB UNIDADES DE LA MATERIA
                <mat-tab label="Unidades de la Materia">
                    <div
                        class="courses"
                        fxLayout="row wrap"
                        fxLayoutAlign="center"
                        [@animateStagger]="{ value: '50' }"
                    >
                        INICIO DEL LA UNIDAD 1
                        <div
                            class="course"
                            fxFlex="100"
                            fxFlex.gt-xs="50"
                            fxFlex.gt-sm="33"
                            [@animate]="{ value: '*', params: { y: '100%' } }"
                            *ngFor="
                                let unidad of unidadesMateria;
                                let i = index
                            "
                            [fxShow]="unidadesMateria.length > 0"
                        >
                            <div
                                class="course-content"
                                fxLayout="column"
                                fxFlex="1 1 auto"
                                (click)="
                                    unidadSeleccionada(
                                        unidad.id,
                                        unidad.porcentagem
                                    )
                                "
                            >
                                <div
                                    class="header"
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                >
                                    <div
                                        class="category"
                                        fxFlex
                                        style="
                                            color: rgba(255, 255, 255, 0.75);
                                            text-align: center;
                                            font-weight: bolder;
                                            font-size: larger;
                                        "
                                    >
                                        Unidad
                                    </div>
                                </div>

                                <div
                                    class="content"
                                    fxLayout="column"
                                    fxLayoutAlign="center center"
                                    fxFlex="1 1 auto"
                                >
                                    <div class="h1">
                                        {{ unidad.nome_unidade }}
                                    </div>

                                    <mat-progress-bar
                                        mode="determinate"
                                        [value]="unidad.porcentagem"
                                        color="warn"
                                        style="margin-top: 5%"
                                    ></mat-progress-bar>
                                    <p style="margin-top: 2%">
                                        {{ unidad.porcentagem }}%
                                    </p>

                                    <div class="updated">Updated {{course.updated}}</div>
                                </div>

                                <div
                                    class="footer"
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                >
                                    <button
                                        *ngIf="tipo_materia === '2'"
                                        (click)="
                                            unidadSeleccionada(
                                                unidad.id,
                                                unidad.porcentagem
                                            )
                                        "
                                        mat-button
                                        color="accent"
                                        routerLink="/realizar-unidad"
                                    >
                                        Iniciar
                                    </button>
                                    <button
                                        mat-button
                                        color="accent"
                                        (click)="pdfUnidad(unidad.unidades_id)"
                                    >
                                        Descargar Manual
                                    </button>
                                </div>
                            </div>
                        </div>
                        FIN DEL LA UNIDAD 1
                    </div>
                </mat-tab>
                ACA TERMINA EL TAB UNIDADES DE LA MATERIA-->

                    <!--ACA COMIENZA EL TAB UNIDADES DE LA MATERIA PLATAFORMA-->
                    <mat-tab
                        label="Unidades de la Materia"
                        *ngIf="tipo_materia === '2'"
                    >
                        <div class="pt-16">
                            <div class="mb-24">
                                <div
                                    class="mat-card mat-elevation-z4 p-24"
                                    fxLayout.lt-md="column"
                                    fxLayout.gt-xs="row"
                                    fxLayoutAlign.gt-xs="start"
                                    fxLayoutAlign.lt-md=" center"
                                    style="border-radius: 5px"
                                >
                                    <div fxLayout="column" fxFlex="1 0 auto">
                                        <h1
                                            style="
                                                margin-top: 0%;
                                                margin-bottom: 0%;
                                            "
                                        >
                                            <strong>
                                                Introduccion al curso</strong
                                            >
                                        </h1>

                                        <div *ngIf="link_video === null">
                                            Actualmente no hay video
                                            introduccion para esta disciplina.
                                        </div>

                                        <div
                                            class="card-video2"
                                            fxFlex="10%"
                                            fxFlex.gt-sm="37%"
                                            fxFlex.gt-lg="40%"
                                            style="position: inherit"
                                            *ngIf="link_video !== null"
                                        >
                                            <div class="container2">
                                                <iframe
                                                    class="responsive-iframe2"
                                                    [src]="link_video"
                                                    frameborder="0"
                                                    allowfullscreen
                                                    allow="autoplay; encrypted-media"
                                                ></iframe>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        fxShow="false"
                                        fxLayout="column"
                                        class="ml-24 mt-48"
                                    >
                                        <div
                                            fxLayout="row"
                                            fxLayoutAlign="start center"
                                        >
                                            <mat-icon>person</mat-icon>
                                            <h3 class="ml-8">
                                                {{ nombre_profesor }}
                                            </h3>
                                        </div>

                                        <!--  <label class="texto">
                                            Lorem ipsum dolor sit amet
                                            consectetur adipisicing elit. Iste
                                            nemo delectus quisquam omnis alias
                                            debitis aliquam rerum adipisci!
                                            Facilis repellat repudiandae quidem
                                            suscipit temporibus nostrum! Velit
                                            impedit officia non neque.
                                        </label>-->
                                    </div>
                                </div>
                            </div>

                            <mat-accordion
                                *ngFor="
                                    let unidad of unidadesMateria;
                                    let i = index
                                "
                                [fxShow]="unidadesMateria.length > 0"
                            >
                                <mat-expansion-panel
                                    class="mb-12"
                                    (opened)="panelOpenState = true"
                                    (closed)="panelOpenState = false"
                                >
                                    <mat-expansion-panel-header
                                        style="background-color: #2295d3"
                                    >
                                        <!--<mat-panel-title> </mat-panel-title>-->

                                        <mat-panel-description
                                            style="color: #fff"
                                        >
                                            {{
                                                unidad.nome_unidade
                                                    | slice: 0:10
                                            }}
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div fxLayout="row" class="pt-24 pb-24">
                                        <div
                                            fxFlex="1 0 auto"
                                            fxLayout="column"
                                            fxLayoutAlign="center center"
                                        >
                                            <p>
                                                {{
                                                    unidad.nome_unidade
                                                        | slice: 12
                                                }}
                                            </p>

                                            <mat-progress-bar
                                                mode="determinate"
                                                [value]="unidad.porcentagem"
                                                color="warn"
                                            >
                                            </mat-progress-bar>
                                            <p style="margin-bottom: 0%">
                                                {{ unidad.porcentagem }}%
                                            </p>

                                            <div fxLayout="column" fxHide.gt-xs>
                                                <button
                                                    class="descargar mb-12"
                                                    mat-raised-button
                                                    color="accent"
                                                    (click)="
                                                        pdfUnidad(
                                                            unidad.unidades_id
                                                        )
                                                    "
                                                >
                                                    Descargar Manual
                                                </button>
                                                <button
                                                    [fxShow]="
                                                        unidad.porcentagem === 0
                                                    "
                                                    class="iniciar mb-8"
                                                    (click)="
                                                        unidadSeleccionada(
                                                            unidad.id,
                                                            unidad.porcentagem
                                                        )
                                                    "
                                                    mat-raised-button
                                                    color="accent"
                                                    routerLink="/realizar-unidad"
                                                >
                                                    Iniciar Unidad
                                                </button>
                                                <button
                                                    [fxShow]="
                                                        unidad.porcentagem > 0
                                                    "
                                                    class="iniciar"
                                                    (click)="
                                                        unidadSeleccionada(
                                                            unidad.id,
                                                            unidad.porcentagem
                                                        )
                                                    "
                                                    mat-raised-button
                                                    color="accent"
                                                    routerLink="/realizar-unidad"
                                                >
                                                    Continuar Unidad
                                                </button>
                                            </div>
                                        </div>

                                        <div
                                            fxLayout="row"
                                            fxLayoutAlign="center center"
                                            fxHide.xs
                                            style="
                                                margin-left: 5px;
                                                margin-right: 5px;
                                            "
                                        >
                                            <button
                                                class="descargar"
                                                mat-raised-button
                                                color="accent"
                                                (click)="
                                                    pdfUnidad(
                                                        unidad.unidades_id
                                                    )
                                                "
                                            >
                                                Descargar Manual
                                            </button>
                                            <button
                                                [fxShow]="
                                                    unidad.porcentagem === 0
                                                "
                                                class="iniciar"
                                                (click)="
                                                    unidadSeleccionada(
                                                        unidad.id,
                                                        unidad.porcentagem
                                                    )
                                                "
                                                mat-raised-button
                                                color="accent"
                                                routerLink="/realizar-unidad"
                                            >
                                                Iniciar Unidad
                                            </button>
                                            <button
                                                [fxShow]="
                                                    unidad.porcentagem > 0
                                                "
                                                class="iniciar"
                                                (click)="
                                                    unidadSeleccionada(
                                                        unidad.id,
                                                        unidad.porcentagem
                                                    )
                                                "
                                                mat-raised-button
                                                color="accent"
                                                routerLink="/realizar-unidad"
                                            >
                                                Continuar Unidad
                                            </button>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </mat-tab>
                    <!--ACA TERMINA EL TAB UNIDADES DE LA MATERIA PLATAFORMA-->

                    <!--ACA COMIENZA EL TAB UNIDADES DE LA MATERIA MEET-->
                    <mat-tab
                        label="Unidades de la Materia"
                        *ngIf="tipo_materia === '1'"
                    >
                        <div class="pt-16">
                            <div class="mb-24">
                                <div
                                    class="mat-card mat-elevation-z4 p-24"
                                    fxLayout.lt-md="column"
                                    fxLayout.gt-xs="row"
                                    fxLayoutAlign.gt-xs="start"
                                    fxLayoutAlign.lt-md=" center"
                                    style="border-radius: 5px"
                                >
                                    <div fxLayout="column" fxFlex="1 0 auto">
                                        <div fxLayout="row" fxFlex="1 0 auto">
                                            <h1
                                                style="
                                                    margin-top: 0%;
                                                    margin-bottom: 0%;
                                                "
                                            >
                                                <strong>
                                                    Introduccion al
                                                    curso</strong
                                                >
                                            </h1>
                                            <div
                                                fxFlex="20"
                                                fxFlex.lt-md="1 0 auto"
                                                fxHide.xs
                                            >
                                                <button
                                                    fxFlex="1 0 auto"
                                                    mat-raised-button
                                                    color="accent"
                                                    ngClass.lt-md="descargar-meet ml-12"
                                                    ngClass.gt-sm="descargar-meet ml-48"
                                                >
                                                    Descargar programa
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            fxLayout="row"
                                            fxLayoutAlign="start center"
                                        >
                                            <mat-icon>person</mat-icon>
                                            <h3 class="ml-8">
                                                {{ nombre_profesor }}
                                            </h3>
                                        </div>

                                        <!--<p
                                            style="max-width: fit-content"
                                            class="texto"
                                        >
                                            Lorem ipsum dolor sit amet
                                            consectetur adipisicing elit. Iste
                                            nemo delectus quisquam omnis alias
                                            debitis aliquam rerum adipisci!
                                            Facilis repellat repudiandae quidem
                                            suscipit temporibus nostrum! Velit
                                            impedit officia non neque.
                                        </p>-->

                                        <button
                                            fxFlex="1 0 auto"
                                            class="descargar-meet mt-12"
                                            mat-raised-button
                                            color="accent"
                                            fxShow.xs
                                            fxHide
                                        >
                                            Descargar programa
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <mat-accordion
                                *ngFor="
                                    let unidad of unidadesMateria;
                                    let i = index
                                "
                                [fxShow]="unidadesMateria.length > 0"
                            >
                                <mat-expansion-panel
                                    class="mb-12"
                                    (opened)="panelOpenState = true"
                                    (closed)="panelOpenState = false"
                                >
                                    <mat-expansion-panel-header
                                        style="background-color: #2c9284"
                                    >
                                        <!--<mat-panel-title> </mat-panel-title>-->

                                        <mat-panel-description
                                            style="color: #fff"
                                        >
                                            {{
                                                unidad.nome_unidade
                                                    | slice: 0:10
                                            }}
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div fxLayout="row" fxFlex="1 0 auto">
                                        <div
                                            fxLayout="column"
                                            fxFlex="50"
                                            fxLayoutAlign="center center"
                                        >
                                            <h1>Sistema Endorcino 1</h1>
                                            <label
                                                >Numero de aulas subidas:
                                                10</label
                                            >
                                        </div>

                                        <div
                                            class="mt-16"
                                            fxLayout="row"
                                            fxFlex="50"
                                            fxLayoutAlign="center"
                                            fxHide.xs
                                        >
                                            <div
                                                fxLayout="column"
                                                class="mr-48"
                                            >
                                                <label>Videos vistos</label>
                                                <label
                                                    ><strong
                                                        >Completados</strong
                                                    ></label
                                                >
                                            </div>

                                            <div fxLayout="column">
                                                <label
                                                    >Ultima clase subida</label
                                                >
                                                <label
                                                    ><strong
                                                        >27 de sep. 2020</strong
                                                    ></label
                                                >
                                                <label
                                                    ><strong
                                                        >14:30 hrs</strong
                                                    ></label
                                                >

                                                <button
                                                    class="descargar-meet mt-24"
                                                    mat-raised-button
                                                    color="accent"
                                                    (click)="
                                                        pdfUnidad(
                                                            unidad.unidades_id
                                                        )
                                                    "
                                                >
                                                    Ver las aulas
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        fxLayout="row"
                                        class="pt-24 pb-24"
                                        fxLayoutAlign="center center"
                                        fxHide.gt-xs
                                    >
                                        <div fxLayout="column" fxFlex="100">
                                            <button
                                                class="descargar mb-12"
                                                mat-raised-button
                                                style="min-width: max-content"
                                                mat-button
                                                color="accent"
                                                (click)="
                                                    pdfUnidad(
                                                        unidad.unidades_id
                                                    )
                                                "
                                            >
                                                Descargar Manual
                                            </button>
                                            <button
                                                [fxShow]="
                                                    unidad.porcentagem === 0
                                                "
                                                class="iniciar"
                                                (click)="
                                                    unidadSeleccionada(
                                                        unidad.id,
                                                        unidad.porcentagem
                                                    )
                                                "
                                                mat-raised-button
                                                color="accent"
                                                routerLink="/realizar-unidad"
                                            >
                                                Iniciar Unidad
                                            </button>
                                            <button
                                                [fxShow]="
                                                    unidad.porcentagem > 0
                                                "
                                                class="iniciar"
                                                (click)="
                                                    unidadSeleccionada(
                                                        unidad.id,
                                                        unidad.porcentagem
                                                    )
                                                "
                                                mat-raised-button
                                                color="accent"
                                                routerLink="/realizar-unidad"
                                            >
                                                Continuar Unidad
                                            </button>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </mat-tab>
                    <!--ACA TERMINA EL TAB UNIDADES DE LA MATERIA MEET-->
                    <!--ACA COMIENZA EL TAB TRABAJOS PRACTICOS DE LA MATERIA-->
                    <!--Oculte el modulo-->
                    <mat-tab label="Trabajos Practicos">
                        <div [fxHide]="realizar === true">
                            <div
                                class="p-12"
                                fxLayout="column"
                                fxLayoutAlign="start"
                                fxLayout.gt-lg="row"
                                fxLayout.gt-md="column"
                            >
                                <form
                                    class="mat-card mat-elevation-z4 p-24 mr-24"
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                    fxFlex="1 0 auto"
                                    name="form"
                                >
                                    <div>
                                        <mat-icon
                                            style="
                                                font-weight: lighter;
                                                font-size: 50px;
                                                width: fit-content;
                                                height: auto;
                                                color: #2295d3;
                                            "
                                            >info</mat-icon
                                        >
                                    </div>

                                    <div>
                                        <h1 style="font-size: 16px">
                                            Recuerda que tienes una evaluacion
                                            el
                                            <strong>
                                                de septiembre del 2020 a las
                                                12:00 hrs</strong
                                            >
                                        </h1>
                                    </div>
                                </form>
                            </div>

                            <div class="p-12 paneles">
                                <mat-accordion>
                                    <mat-expansion-panel
                                        class="mb-12"
                                        (opened)="panelOpenState = true"
                                        (closed)="panelOpenState = false"
                                        *ngFor="let tp of tpAlumnos"
                                    >
                                        <mat-expansion-panel-header
                                            style="background-color: #2295d3"
                                        >
                                            <!--<mat-panel-title> </mat-panel-title>-->

                                            <mat-panel-description
                                                style="color: #fff"
                                            >
                                                {{ tp.titulo }}
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <div
                                            fxLayout="row"
                                            fxFlex="1 0 auto"
                                            fxLayoutAlign=" center"
                                        >
                                            <div
                                                fxLayout="column"
                                                fxLayoutAlign="start"
                                                fxFlex="28"
                                                class="mr-48"
                                            >
                                                <h1 style="font-weight: bold">
                                                    Estado de entrega
                                                </h1>
                                                <label
                                                    style="
                                                        color: rgb(
                                                            167,
                                                            167,
                                                            67
                                                        );
                                                        font-weight: bold;
                                                        font-size: 18px;
                                                    "
                                                    >{{ tp.entrega }}</label
                                                >
                                            </div>

                                            <div
                                                fxFlex="28"
                                                fxLayout="column"
                                                class="mr-48"
                                                fxLayoutAlign="start"
                                            >
                                                <label
                                                    >Calificacion total</label
                                                >
                                                <label
                                                    ><strong>
                                                        ?/9</strong
                                                    ></label
                                                >
                                            </div>

                                            <div
                                                fxFlex="28"
                                                fxLayout="column"
                                                class="mr-48 mt-12"
                                                fxLayoutAlign="start"
                                            >
                                                <label>Fecha de entrega</label>
                                                <label
                                                    ><strong>{{
                                                        tp.data_fin
                                                    }}</strong></label
                                                >
                                                <label
                                                    ><strong>{{
                                                        tp.hora_fin
                                                    }}</strong></label
                                                >
                                            </div>
                                            <div
                                                fxLayoutAlign="start"
                                                fxFlex="16"
                                            >
                                                <button
                                                    class="revisar"
                                                    mat-raised-button
                                                    color="accent"
                                                    (click)="
                                                        realizarTrabajo(tp.id)
                                                    "
                                                >
                                                    Realizar Trabajo
                                                </button>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>

                        <!--realizar el trabajo-->
                        <div [fxShow]="realizar === true">
                            <!-- REACTIVE FORM EXAMPLE -->
                            <div class="p-12">
                                <form
                                    class="mat-card mat-elevation-z4 p-24 mr-24"
                                    fxLayout="column"
                                    fxLayoutAlign="start"
                                    fxFlex="1 0 auto"
                                >
                                    <div fxLayout="column">
                                        <div fxLayout="column">
                                            <div
                                                fxLayout="row"
                                                fxFlex="1 0 auto"
                                                fxLayoutAlign=" center"
                                            >
                                                <div
                                                    fxLayout="column"
                                                    fxFlex="40"
                                                    fxLayoutAlign="start"
                                                >
                                                    <button
                                                        style="width: 30px;"
                                                        mat-raised-button
                                                        color="accent"
                                                        (click)="
                                                            realizarTrabajoFalso()
                                                        "
                                                    >
                                                        Volver
                                                    </button>
                                                    
                                                    <h1>
                                                        {{ datosTP.titulo }}
                                                    </h1>
                                                    <label
                                                        >Calificacion
                                                        total</label
                                                    >
                                                    <label
                                                        style="
                                                            font-weight: bolder;
                                                        "
                                                        >{{
                                                            datosTP.calificacion
                                                        }}/{{
                                                            datosTP.total_pontos
                                                        }}</label
                                                    >
                                                </div>

                                                <div
                                                    fxFlex="60"
                                                    fxLayoutAlign="end"
                                                    fxLayout="row"
                                                >
                                                    <div
                                                        fxLayout="column"
                                                        class="mr-48"
                                                    >
                                                        <label
                                                            >Fecha
                                                            entrega</label
                                                        >
                                                        <label
                                                            style="
                                                                font-weight: bolder;
                                                            "
                                                            >{{
                                                                datosTP.data_fin
                                                            }}</label
                                                        >
                                                        <label
                                                            style="
                                                                font-weight: bolder;
                                                            "
                                                            >{{
                                                                datosTP.hora_fin
                                                            }}</label
                                                        >
                                                    </div>

                                                    <div fxLayout="column">
                                                        <label
                                                            >Formato
                                                            entrega</label
                                                        >
                                                        <label
                                                            style="
                                                                font-weight: bolder;
                                                            "
                                                            >{{
                                                                datosTP.forma_entrega
                                                            }}</label
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                            <hr
                                                style="
                                                    margin-bottom: 10px;
                                                    margin-top: 10px;
                                                "
                                            />
                                        </div>
                                        <div>
                                            <mat-tab-group>
                                                <mat-tab label="Instrucciones">
                                                    <div fxLayoutAlign="start">
                                                        <div fxLayout="column">
                                                            <h3
                                                                
                                                                style="
                                                                    margin-bottom: 0;
                                                                    margin-top: 50px;
                                                                "
                                                            >
                                                                PASO A PASO
                                                            </h3>

                                                            <hr
                                                                style="
                                                                    margin-bottom: 10px;
                                                                "
                                                            />

                                                            <label>{{
                                                                datosTP.instrucao
                                                            }}</label>

                                                            <h3
                                                                style="
                                                                    margin-bottom: 0;
                                                                "
                                                            >
                                                                ANEXOS
                                                            </h3>

                                                            <hr
                                                                style="
                                                                    margin-bottom: 10px;
                                                                "
                                                            />

                                                            <div
                                                                fxLayout="column"
                                                            >
                                                                <label
                                                                    >anexo
                                                                    1</label
                                                                >
                                                                <label
                                                                    >anexo
                                                                    2</label
                                                                >
                                                            </div>

                                                            <button
                                                        
                                                       style="margin-top: 20px;"
                                                        mat-raised-button
                                                        color="primary"
                                                        (click)="
                                                            descargarTrabajoPractico(datosTP.caminho)
                                                        "
                                                    >
                                                        Descargar Trabajo
                                                    </button>
                                                        </div>
                                                    </div>
                                                </mat-tab>

                                                <mat-tab label="Mi entrega">
                                                    <div fxLayoutAlign="start">
                                                        <div
                                                            fxLayout="column"
                                                            fxFlex="1 0 auto"
                                                            fxLayoutAlign="start"
                                                            [formGroup]="
                                                                enviartrabajo
                                                            "
                                                        >
                                                            <mat-form-field
                                                                style="
                                                                    margin-top: 50px;
                                                                "
                                                                appearance="outline"
                                                            >
                                                                <mat-label
                                                                    >Titulo del
                                                                    trabajo</mat-label
                                                                >
                                                                <input
                                                                    matInput
                                                                    formControlName="titulo"
                                                                    required
                                                                />
                                                                <mat-error
                                                                    >Por favor
                                                                    ingrese el
                                                                    titulo del
                                                                    trabajo.</mat-error
                                                                >
                                                            </mat-form-field>

                                                            <div
                                                                fxLayout="column"
                                                                fxLayoutAlign="start"
                                                            >
                                                                <mat-form-field
                                                                    appearance="outline"
                                                                    required
                                                                >
                                                                    <mat-label
                                                                        >Cargar
                                                                        PDF
                                                                    </mat-label>
                                                                    <ngx-mat-file-input
                                                                        formControlName="file"
                                                                        [multiple]="
                                                                            false
                                                                        "
                                                                    >
                                                                    </ngx-mat-file-input>
                                                                </mat-form-field>
                                                                <button
                                                                    [disabled]="
                                                                        this
                                                                            .enviartrabajo
                                                                            .invalid
                                                                    "
                                                                    (click)="
                                                                        enviarTP()
                                                                    "
                                                                    mat-raised-button
                                                                    color="accent"
                                                                >
                                                                    Enviar
                                                                    Trabajo
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </mat-tab>

                                                <mat-tab
                                                    label="Comentarios del profesor"
                                                >
                                                    <div
                                                        *ngFor="
                                                            let comentario of comentarioProf
                                                        "
                                                        fxLayout="column"
                                                        fxFlex="1 0 auto"
                                                        style="margin-top: 50px"
                                                    >
                                                        <label
                                                            style="
                                                                font-weight: bold;
                                                            "
                                                            >{{
                                                                comentario.nombre_funcionario
                                                            }}
                                                            {{
                                                                comentario.apellido_funcionario
                                                            }}</label
                                                        >

                                                        <label>{{
                                                            comentario.publicacion
                                                        }}</label>

                                                        <label
                                                            style="
                                                                margin-top: 10px;
                                                            "
                                                            >{{
                                                                comentario.comentario
                                                            }}</label
                                                        >
                                                    </div>
                                                </mat-tab>
                                            </mat-tab-group>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <!--<div class="courses" fxLayout="row wrap" fxLayoutAlign="center"
                            [@animateStagger]="{ value: '50' }">
                            Cuando no hay trabajos practicos
                            <div *ngIf="trabajosPracticos.length < 1">
                                Actualmente no hay trabajos practicos
                            </div>
                            Termina mensaje cuando no hay trabajos practicos
                            <div class="course" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" [@animate]="{
                                    value: '*',
                                    params: { y: '100%' }
                                }" *ngFor="let tp of trabajosPracticos">
                                <div class="course-content" fxLayout="column" fxFlex="1 1 auto">
                                    <div class="header" fxLayout="row" fxLayoutAlign="center center">
                                        <div class="category" fxFlex style="
                                                color: rgba(
                                                    255,
                                                    255,
                                                    255,
                                                    0.75
                                                );
                                                text-align: center;
                                                font-weight: bolder;
                                                font-size: larger;
                                            ">
                                            {{ tp.titulo }}
                                        </div>
                                    </div>

                                    <div class="content" fxLayout="column" fxLayoutAlign="center center"
                                        fxFlex="1 1 auto">
                                        <div class="h1">
                                            {{ tp.nome_original }}
                                        </div>

                                        <div class="updated">
                                            Updated {{ tp.updated_at }}
                                        </div>
                                    </div>

                                    <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                                        <button (click)="
                                                descargarTrabajoPractico(
                                                    tp.caminho
                                                )
                                            " mat-button color="accent">
                                            Descargar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>-->
                    </mat-tab>
                    <!--ACA TERMINA EL TAB TRABAJOS PRACTICOS  DE LA MATERIA-->
                    <mat-tab label="Lives">
                        <div
                            class="courses"
                            fxLayout="row wrap"
                            fxLayoutAlign="center"
                            [@animateStagger]="{ value: '50' }"
                        >
                            <!--INICIO DEL PRIMER LIVE 1-->
                            <div *ngIf="livesMateria.length < 1">
                                Actualmente no hay lives en esta disciplina.
                            </div>
                            <div
                                class="course"
                                fxFlex="100"
                                fxFlex.gt-xs="50"
                                fxFlex.gt-sm="33"
                                [@animate]="{
                                    value: '*',
                                    params: { y: '100%' }
                                }"
                                *ngFor="let lives of livesMateria"
                                [fxHide]="!lives.link_meet"
                            >
                                <div
                                    class="course-content"
                                    fxLayout="column"
                                    fxFlex="1 1 auto"
                                    (click)="
                                        onNavigate(lives.link_meet, 'Live')
                                    "
                                >
                                    <div
                                        class="header"
                                        fxLayout="row"
                                        fxLayoutAlign="center center"
                                    >
                                        <div
                                            class="length"
                                            fxFlex
                                            fxLayout="row"
                                        >
                                            <mat-icon
                                                class="length-icon s-20"
                                                style="
                                                    color: rgba(
                                                        255,
                                                        255,
                                                        255,
                                                        0.75
                                                    );
                                                "
                                            >
                                                today
                                            </mat-icon>
                                            <div
                                                class="min"
                                                style="
                                                    color: rgba(
                                                        255,
                                                        255,
                                                        255,
                                                        0.75
                                                    );
                                                "
                                            >
                                                {{
                                                    lives.data_inicio
                                                        | slice: 0:10
                                                }}
                                            </div>
                                        </div>

                                        <div
                                            class="length"
                                            fxLayout="row"
                                            fxLayoutAlign="center center"
                                        >
                                            <mat-icon
                                                class="length-icon s-20"
                                                style="
                                                    color: rgba(
                                                        255,
                                                        255,
                                                        255,
                                                        0.75
                                                    );
                                                "
                                            >
                                                access_time
                                            </mat-icon>
                                            <div
                                                class="min"
                                                style="
                                                    color: rgba(
                                                        255,
                                                        255,
                                                        255,
                                                        0.75
                                                    );
                                                "
                                            >
                                                {{
                                                    lives.data_inicio
                                                        | slice: 11:-3
                                                }}hs
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="content"
                                        fxLayout="column"
                                        fxLayoutAlign="center center"
                                        fxFlex="1 1 auto"
                                    >
                                        <div class="h1">
                                            {{ lives.titulo }}
                                        </div>

                                        <!--  <div class="updated">Updated {{course.updated}}</div>-->
                                    </div>

                                    <div
                                        class="footer"
                                        fxLayout="row"
                                        fxLayoutAlign="center center"
                                    >
                                        <button
                                            (click)="
                                                onNavigate(
                                                    lives.link_meet,
                                                    'Live'
                                                )
                                            "
                                            mat-button
                                            color="accent"
                                        >
                                            Ir al Live
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!--FIN DEL PRIMER LIVE 1-->
                        </div>
                    </mat-tab>
                    <mat-tab label="Grabados">
                        <div
                            class="courses"
                            fxLayout="row wrap"
                            fxLayoutAlign="center"
                            [@animateStagger]="{ value: '50' }"
                        >
                            <!--INICIO DEL PRIMER GRABADO 1-->
                            <div *ngIf="grabadosMateria.length === 0">
                                Actualmente no hay grabados en esta disciplina.
                            </div>
                            <div
                                class="course"
                                fxFlex="100"
                                fxFlex.gt-xs="50"
                                fxFlex.gt-sm="33"
                                [@animate]="{
                                    value: '*',
                                    params: { y: '100%' }
                                }"
                                *ngFor="let grabado of grabadosMateria"
                                [fxHide]="!grabado.link_vimeo"
                            >
                                <div
                                    class="course-content"
                                    fxLayout="column"
                                    fxFlex="1 1 auto"
                                    (click)="videoGrabado(grabado.link_vimeo)"
                                >
                                    <div
                                        class="header"
                                        fxLayout="row"
                                        fxLayoutAlign="center center"
                                        style="background: green"
                                    >
                                        <div
                                            class="length"
                                            fxFlex
                                            fxLayout="row"
                                        >
                                            <mat-icon
                                                class="length-icon s-20"
                                                style="
                                                    color: rgba(
                                                        255,
                                                        255,
                                                        255,
                                                        0.75
                                                    );
                                                "
                                            >
                                                today
                                            </mat-icon>
                                            <div
                                                class="min"
                                                style="
                                                    color: rgba(
                                                        255,
                                                        255,
                                                        255,
                                                        0.75
                                                    );
                                                "
                                            >
                                                {{
                                                    grabado.data_inicio
                                                        | slice: 0:10
                                                }}
                                            </div>
                                        </div>

                                        <div
                                            class="length"
                                            fxLayout="row"
                                            fxLayoutAlign="center center"
                                        >
                                            <mat-icon
                                                class="length-icon s-20"
                                                style="
                                                    color: rgba(
                                                        255,
                                                        255,
                                                        255,
                                                        0.75
                                                    );
                                                "
                                            >
                                                access_time
                                            </mat-icon>
                                            <div
                                                class="min"
                                                style="
                                                    color: rgba(
                                                        255,
                                                        255,
                                                        255,
                                                        0.75
                                                    );
                                                "
                                            >
                                                {{
                                                    grabado.data_inicio
                                                        | slice: 11:-3
                                                }}hs
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="content"
                                        fxLayout="column"
                                        fxLayoutAlign="center center"
                                        fxFlex="1 1 auto"
                                    >
                                        <div class="h1">
                                            {{ grabado.titulo }}
                                        </div>

                                        <!--  <div class="updated">Updated {{course.updated}}</div>-->
                                    </div>

                                    <div
                                        class="footer"
                                        fxLayout="row"
                                        fxLayoutAlign="center center"
                                    >
                                        <button
                                            (click)="
                                                videoGrabado(grabado.link_vimeo)
                                            "
                                            mat-button
                                            color="accent"
                                        >
                                            Visualizar
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!--FIN DEL PRIMER GRABADO 1-->
                        </div>
                    </mat-tab>
                    <mat-tab label="Libros de Disciplina">
                        <div
                            class="courses"
                            fxLayout="row wrap"
                            fxLayoutAlign="center"
                            [@animateStagger]="{ value: '50' }"
                        >
                            <!--INICIO DEL PRIMER LIVE 1-->
                            <div [fxHide]="librosDisciplina.length > 0">
                                Actualmente no hay libros para esta disciplina.
                            </div>
                            <div
                                class="course"
                                fxFlex="100"
                                fxFlex.gt-xs="50"
                                fxFlex.gt-sm="33"
                                [@animate]="{
                                    value: '*',
                                    params: { y: '100%' }
                                }"
                                *ngFor="let libros of librosDisciplina"
                                [fxHide]="!libros.link"
                            >
                                <div
                                    class="course-content"
                                    fxLayout="column"
                                    fxFlex="1 1 auto"
                                    (click)="onNavigate(libros.link, 'libros')"
                                >
                                    <div
                                        class="header"
                                        fxLayout="row"
                                        fxLayoutAlign="center center"
                                    ></div>

                                    <div
                                        class="content"
                                        fxLayout="column"
                                        fxLayoutAlign="center center"
                                        fxFlex="1 1 auto"
                                    >
                                        <div class="h1">
                                            {{ libros.titulo }}
                                        </div>

                                        <!--  <div class="updated">Updated {{course.updated}}</div>-->
                                    </div>

                                    <div
                                        class="footer"
                                        fxLayout="row"
                                        fxLayoutAlign="center center"
                                    >
                                        <button
                                            (click)="
                                                onNavigate(
                                                    libros.link,
                                                    'libros'
                                                )
                                            "
                                            mat-button
                                            color="accent"
                                        >
                                            Descargar
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!--FIN DEL PRIMER LIVE 1-->
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="tipo_materia === '1'" label="Presencias">
                        <div class="pt-16">
                            <div class="mb-24">
                                <div
                                    class="mat-card mat-elevation-z4 p-24"
                                    fxLayout.lt-md="column"
                                    fxLayout.gt-xs="row"
                                    fxLayoutAlign.gt-xs="start"
                                    fxLayoutAlign.lt-md=" center"
                                    style="border-radius: 5px"
                                >
                                    <div fxLayout="column" fxFlex="1 0 auto">
                                        <h1
                                            style="
                                                margin-top: 0%;
                                                margin-bottom: 0%;
                                            "
                                        >
                                            <strong>
                                                Presencias del Curso</strong
                                            >
                                        </h1>
                                        <div fxHide.gt-xs>
                                            <p>
                                                Aulas : {{ presencia?.aulas }}
                                            </p>

                                            <p>
                                                Asistencia :
                                                {{ presencia?.presenca }}
                                            </p>
                                            <p>
                                                Porcentaje :
                                                {{ presencia?.porcentagem }}%
                                            </p>
                                        </div>
                                        <ngx-charts-advanced-pie-chart
                                            fxShow.gt-xs
                                            fxHide
                                            [label]="'Aulas'"
                                            [view]="view"
                                            [scheme]="colorScheme"
                                            [results]="single"
                                            [gradient]="gradient"
                                            (select)="onSelect($event)"
                                            (activate)="onActivate($event)"
                                            (deactivate)="onDeactivate($event)"
                                            (animations)="(false)"
                                            [percentageFormatting]="
                                                percentageFormatting
                                            "
                                        >
                                            >
                                        </ngx-charts-advanced-pie-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
                <!--ACA TERMINA EL TAB-GROUP-->
            </div>
        </div>
    </div>
</div>

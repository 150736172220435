<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Crear Materia
            </h1>
        </div>
    </div>
    <!-- CONTENT -->
    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-lg="row"
        fxLayout.gt-md="column"
    >
        <!-- REACTIVE FORM EXAMPLE -->

        <form
            class="mat-card mat-elevation-z4 p-24 mr-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            name="form"
            [formGroup]="form"
            style="height: 300px;"
        >
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Nombre de la Materia</mat-label>
                    <input matInput formControlName="nombre" required />
                    <mat-icon matSuffix class="secondary-text"
                        >account_circle</mat-icon
                    >
                    <mat-error
                        >Por favor ingrese el nombre de la materia.</mat-error
                    >
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Cantidad de Unidades</mat-label>
                    <input matInput formControlName="unidades" required />
                    <mat-icon matSuffix class="secondary-text"
                        >account_circle</mat-icon
                    >
                    <mat-error
                        >Por favor ingrese la cantidad de unidades.</mat-error
                    >
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Cantidad de Clases</mat-label>
                    <input matInput formControlName="clases" required />
                    <mat-icon matSuffix class="secondary-text"
                        >account_circle</mat-icon
                    >
                    <mat-error
                        >Por favor ingrese la cantidad de clases.</mat-error
                    >
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Correlatividad</mat-label>
                    <mat-select required formControlName="correlatividad">
                        <mat-option value="Correlatividad 1">
                            Correlatividad 1
                        </mat-option>
                        <mat-option value="Correlatividad 2">
                            Correlatividad 2
                        </mat-option>
                        <mat-option value="Correlatividad 3">
                            Correlatividad 3
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error
                        >Por favor seleccione la correlatividad.</mat-error
                    >
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Seleccionar Sede</mat-label>
                    <mat-select required formControlName="sede">
                        <mat-option value="Sede 1">
                            Sede 1
                        </mat-option>
                        <mat-option value="Sede 2">
                            Sede 2
                        </mat-option>
                        <mat-option value="Sede 3">
                            Sede 3
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor seleccione una Sede.</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="end">
                <button mat-button color="accent" (click)="agregarMateria()">
                    Crear Materia
                </button>
            </div>
        </form>

        <form
            class="mat-card mat-elevation-z4 p-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            name="form"
            [formGroup]="form"
            style="height: 500px;"
        >
            <div class="h2 mb-24">Materias Creadas</div>

            <mat-table
                class="products-table"
                #table
                [dataSource]="dataSource"
                matSort
                [@animateStagger]="{ value: '50' }"
                fusePerfectScrollbar
            >
                <!-- Columna Materia -->
                <ng-container matColumnDef="Materia">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Materias
                    </mat-header-cell>
                    <mat-cell *matCellDef="let listado">
                        <p class="text-truncate">
                            {{ listado.NOMBRE }}
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Columna Unidades -->
                <ng-container matColumnDef="Unidades">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Unidades </mat-header-cell
                    >listado
                    <mat-cell *matCellDef="let listado">
                        <p class="text-truncate">
                            {{ listado.UNIDADES }}
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Columna Clases -->
                <ng-container matColumnDef="Clases">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Clases
                    </mat-header-cell>
                    <mat-cell *matCellDef="let listado">
                        <p class="text-truncate">
                            {{ listado.CLASES }}
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Columna Acciones -->
                <ng-container matColumnDef="Acciones">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        fxShow.gt-md
                        >Acciones</mat-header-cell
                    >
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-md>
                        <button
                            mat-button
                            color="accent"
                            (click)="editarMateria()"
                        >
                            Editar
                        </button>
                        <button
                            mat-button
                            color="warn"
                            (click)="eliminarMateria()"
                        >
                            Eliminar
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Columna Unidad 
                <ng-container matColumnDef="unidad">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Unidad</mat-header-cell
                    >
                    <mat-cell *matCellDef="let unidad">
                        <p class="text-truncate">
                            Unidad ingresada
                        </p>
                    </mat-cell>
                </ng-container>-->
                <!-- Columna Peso 
                <ng-container matColumnDef="peso">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Peso</mat-header-cell
                    >
                    <mat-cell *matCellDef="let unidad">
                        <p class="text-truncate">
                            Peso Ingresado
                        </p>
                    </mat-cell>
                </ng-container>-->

                <!-- Columna Acciones -->

                <mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>

                <mat-row
                    *matRowDef="let product; columns: displayedColumns"
                    class="product"
                    matRipple
                >
                </mat-row>
            </mat-table>
        </form>
    </div>
</div>

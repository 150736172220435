<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Pagos
            </h1>
        </div>
    </div>

    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-lg="row"
        fxLayout.gt-md="column"
        id="products"
    >
        <form
            class="mat-card mat-elevation-z4 p-24 mr-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            name="form"
            [formGroup]="form"
        >
            <div class="h2 mb-24">Seleccione Matricula y Semestre</div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <!-- ACA EMPIEZA SELECCIONAR SEDE-->
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Seleccionar Matricula</mat-label>
                    <mat-select required formControlName="matricula">
                        <mat-option
                            *ngFor="let matricula of listaMatricula"
                            [value]="matricula.id"
                            (click)="
                                ListaSemestre(
                                    matricula.id,
                                    matricula.nome_filial
                                )
                            "
                        >
                            {{ matricula.nome_curso }}
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor elija una matricula.</mat-error>
                </mat-form-field>
                <!-- ACA TERMINA SELECCIONAR SEDE-->

                <!-- ACA EMPIEZA SELECCIONAR SEDE-->
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Seleccionar Semestre</mat-label>
                    <mat-select required formControlName="semestre">
                        <mat-option
                            *ngFor="let semestre of listaSemestres"
                            [value]="semestre.id"
                            (click)="ListaFacturas(semestre.id)"
                        >
                            {{ semestre.nome_semestre }}
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor elija un semestre.</mat-error>
                </mat-form-field>
                <!-- ACA TERMINA SELECCIONAR SEDE-->
            </div>
        </form>
    </div>
    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-lg="row"
        fxLayout.gt-md="column"
    >
        <!--TABLA DE CALIFICACIONES DE LAS  UNIDADES y TRABAJOS DE LA MATERIA-->
        <form
            class="mat-card mat-elevation-z4 p-24 mr-24"
            fxLayout="column"
            fxLayoutAlign="start"
            name="form"
            fxFlex="1 0 auto"
        >
            <div
                fxLayout="row"
                fxLayoutAlign="end center"
                style="
                    font-size: 20px;
                    margin-top: 10px;
                    margin-bottom: 20px;
                    background: linear-gradient(
                        to right,
                        #0e2a3b 0%,
                        #34306b 100%
                    );
                    padding-top: 15px;
                    padding-bottom: 15px;
                    color: white;
                "
            >
                <strong
                    ><label
                        >Cotizaciones: &nbsp;&nbsp; PDJ:
                        {{
                            cotizacion.cotacao_pjc | currency: "PYG":"₲ "
                        }}&nbsp; - &nbsp;CDE:
                        {{ cotizacion.cotacao_cde2 | currency: "PYG":"₲ " }}
                    </label></strong
                >

                <button
                    mat-button
                    (click)="finalizarPago()"
                    style="margin-left: 17%; margin-right: 3%"
                >
                    <mat-icon [matBadge]="factura.length" matBadgeColor="warn"
                        >shopping_cart</mat-icon
                    >
                </button>
            </div>

            <mat-tab-group>
                <mat-tab label="Pagos">
                    <!--ACA EMPIEZA LA TABLA 1-->
                    <div class="center">
                        <mat-table
                            class="products-table"
                            #table
                            [dataSource]="
                                tablaPago
                                    | paginate
                                        : { itemsPerPage: 5, currentPage: p }
                            "
                            matSort
                            [@animateStagger]="{ value: '50' }"
                            fusePerfectScrollbar
                        >
                            <!-- Columna Nombre  -->
                            <ng-container matColumnDef="nombre">
                                <mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    >Nombre</mat-header-cell
                                >
                                <mat-cell *matCellDef="let nombre">
                                    <p>{{ nombre.nome_centro_custo }}</p>
                                </mat-cell>
                            </ng-container>

                            <!-- Columna Contrato -->
                            <ng-container matColumnDef="contrato">
                                <mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    >Contrato</mat-header-cell
                                >
                                <mat-cell *matCellDef="let contrato">
                                    <p>
                                        {{ contrato.nome_departamento }}
                                    </p>
                                </mat-cell>
                            </ng-container>

                            <!-- Columna Fecha de vencimiento -->
                            <ng-container matColumnDef="fechavenc">
                                <mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    >Fecha Vencimiento</mat-header-cell
                                >
                                <mat-cell *matCellDef="let fechavenc">
                                    <p>{{ fechavenc.data_vencimento }}</p>
                                </mat-cell>
                            </ng-container>

                            <!-- Columna Fecha de pago -->
                            <ng-container matColumnDef="fechapago">
                                <mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    >Fecha Pagamento</mat-header-cell
                                >
                                <mat-cell *matCellDef="let fechapago">
                                    <p>{{ fechapago.data_pagamento }}</p>
                                </mat-cell>
                            </ng-container>

                            <!-- Columna Fecha de vencimiento -->
                            <ng-container matColumnDef="valor">
                                <mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    >Valor</mat-header-cell
                                >
                                <mat-cell *matCellDef="let valor">
                                    <p>
                                        {{ valor.valor | currency: "PYG":"₲ " }}
                                    </p>
                                </mat-cell>
                            </ng-container>

                            <!-- Columna seleccionar -->
                            <ng-container matColumnDef="valorpago">
                                <mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    >Valor Pago</mat-header-cell
                                >
                                <mat-cell *matCellDef="let valorpago">
                                    <p>
                                        {{
                                            valorpago.valor_pago
                                                | currency: "PYG":"₲ "
                                        }}
                                    </p>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="estado">
                                <mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    >Estado</mat-header-cell
                                >
                                <mat-cell
                                    *matCellDef="let agrega; let i = index"
                                >
                                    <button
                                        mat-button
                                        [color]="
                                            agregado[i]?.a ? 'accent' : 'warn'
                                        "
                                        (click)="agregar(agrega, i)"
                                    >
                                        {{
                                            agregado[i]?.a
                                                ? "Agregar"
                                                : "Agregado"
                                        }}
                                    </button>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row
                                *matHeaderRowDef="
                                    displayedColumns;
                                    sticky: true
                                "
                            ></mat-header-row>

                            <mat-row
                                *matRowDef="
                                    let product;
                                    columns: displayedColumns
                                "
                                class="product"
                                matRipple
                            >
                            </mat-row>
                        </mat-table>
                        <pagination-controls
                            (pageChange)="p = $event"
                            autoHide="true"
                            responsive="true"
                            previousLabel="Anterior"
                            nextLabel="Siguiente"
                        ></pagination-controls>

                        <!--ACA TERMINA LA TABLA 1-->
                    </div>
                </mat-tab>

                <mat-tab label="Pedidos">
                    <!--EMPIEZA LA TABLA 2-->
                    <mat-table
                        class="products-table"
                        [dataSource]="
                            tablaPedidos
                                | paginate
                                    : {
                                          id: '1',
                                          itemsPerPage: 5,
                                          currentPage: pe
                                      }
                        "
                        matSort
                        [@animateStagger]="{ value: '50' }"
                        fusePerfectScrollbar
                    >
                        <!-- Columna data do pedido  -->
                        <ng-container matColumnDef="datadopedido">
                            <mat-header-cell *matHeaderCellDef mat-sort-header
                                >Data do Pedido</mat-header-cell
                            >
                            <mat-cell *matCellDef="let datadopedido">
                                <p>{{ datadopedido.created }}</p>
                            </mat-cell>
                        </ng-container>

                        <!-- Columna valor guarani -->
                        <ng-container matColumnDef="Valorguarani">
                            <mat-header-cell *matHeaderCellDef mat-sort-header
                                >Valor Guarani</mat-header-cell
                            >
                            <mat-cell *matCellDef="let valorguarani">
                                <p>
                                    {{ valorguarani.valor_guarani }}
                                </p>
                            </mat-cell>
                        </ng-container>

                        <!-- Columna comprovante/boleto -->
                        <ng-container matColumnDef="comprovante/boleto">
                            <mat-header-cell *matHeaderCellDef mat-sort-header
                                >Comprovante/Boleto</mat-header-cell
                            >
                            <mat-cell *matCellDef="let comprovanteboleto">
                                <p>{{ comprovanteboleto.valor_real }}</p>
                            </mat-cell>
                        </ng-container>

                        <!-- Columna cambio -->
                        <ng-container matColumnDef="cambio">
                            <mat-header-cell *matHeaderCellDef mat-sort-header
                                >Cambio</mat-header-cell
                            >
                            <mat-cell *matCellDef="let cambio">
                                <p>{{ cambio.cambio }}</p>
                            </mat-cell>
                        </ng-container>

                        <!-- Columna tipo pagamento -->
                        <ng-container matColumnDef="tipopagamento">
                            <mat-header-cell *matHeaderCellDef mat-sort-header
                                >Tipo Pagamento</mat-header-cell
                            >
                            <mat-cell *matCellDef="let tipopagamento">
                                <p *ngIf="tipopagamento.tipo_pagamento === 'B'">
                                    Boleto
                                </p>
                                <p
                                    *ngIf="
                                        tipopagamento.tipo_pagamento === 'CO'
                                    "
                                >
                                    Comprovante
                                </p>
                            </mat-cell>
                        </ng-container>

                        <!-- Columna status -->
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef mat-sort-header
                                >Status</mat-header-cell
                            >
                            <mat-cell *matCellDef="let status">
                                <p>
                                    {{ status.status }}
                                </p>
                            </mat-cell>
                        </ng-container>

                        <!-- Columna botoes -->
                        <ng-container matColumnDef="botoes">
                            <mat-header-cell *matHeaderCellDef mat-sort-header
                                >Botoes</mat-header-cell
                            >
                            <mat-cell *matCellDef="let status">
                                <div>
                                    <button mat-button color="accent">
                                        Faturas
                                    </button>
                                    <button mat-button color="warn">
                                        Boletos
                                    </button>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row
                            *matHeaderRowDef="
                                displayedColumnsPedidos;
                                sticky: true
                            "
                        ></mat-header-row>

                        <mat-row
                            *matRowDef="
                                let product;
                                columns: displayedColumnsPedidos
                            "
                            class="product"
                            matRipple
                        >
                        </mat-row>
                    </mat-table>
                    <pagination-controls
                        (pageChange)="pe = $event"
                        id="1"
                        autoHide="true"
                        responsive="true"
                        previousLabel="Anterior"
                        nextLabel="Siguiente"
                    ></pagination-controls>
                </mat-tab>
            </mat-tab-group>

            <div>
                <div fxLayoutAlign="center">
                    <button mat-button color="accent" (click)="finalizarPago()">
                        Ver Carrito
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

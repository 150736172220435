import { Component, OnInit, ViewEncapsulation, Inject } from "@angular/core";
import { CalendarEvent } from "angular-calendar";
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl,
} from "@angular/forms";
import { MatColors } from "@fuse/mat-colors";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AlumnosService } from "app/alumnos/alumnos.service";
import { DomSanitizer } from "@angular/platform-browser";
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: "app-modal-introduccion-unidad",
    templateUrl: "./modal-introduccion-unidad.component.html",
    styleUrls: ["./modal-introduccion-unidad.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ModalIntroduccionUnidadComponent implements OnInit {
    action: string;
    event: CalendarEvent;
    eventForm: FormGroup;
    //Creando el form group de Preguntas
    preguntaForm: FormGroup;
    dialogTitle: string;
    presetColors = MatColors.presets;
    link_video: any;
    nombre_materia: string;
    /**
     * Constructor
     *
     * @param {MatDialogRef<ModalIntroduccionUnidadComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<ModalIntroduccionUnidadComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private sanitizer: DomSanitizer
    ) {
        this.eventForm = this.createEventForm();
        this.preguntaForm = this._formBuilder.group({
            enunciado: ["", Validators.required],
            respuesta1: ["", Validators.required],
            respuesta2: ["", Validators.required],
            respuesta3: ["", Validators.required],
            respuesta4: ["", Validators.required],
            respuestaCorrecta: ["", Validators.required],
            puntos: ["", Validators.required],
            descripcion: ["", Validators.required],
        });
    }
    ngOnInit() {
        this.nombre_materia = sessionStorage.getItem("nombre_materia");
        this.link_video = this.sanitizer.bypassSecurityTrustResourceUrl(
            sessionStorage.getItem("link_video")
        );

        //console.log('Se abrio el modal, aca se buscario el video')
    }

    /**
     * Create the event form
     *
     * @returns {FormBuilder}
     */
    createEventForm(): FormGroup {
        return new FormGroup({
            title: new FormControl("this.form.value.pregunta"),
            start: new FormControl("this.event.start"),
            end: new FormControl("this.event.end"),
            allDay: new FormControl("this.event.allDay"),
        });
    }
}

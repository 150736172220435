import { Component, OnInit } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import {
    FormBuilder,
    FormGroup,
    FormControl,
    Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {
    ListaSedes,
    ListaSemestres,
    ListaMaterias,
    ListaUnidades,
    TablaUnidades,
    getMateriasTemporal,
    getUnidadesTemporal,
    Respuesta,
    PreguntaUnidad,
} from "../administradores.modal";
import Swal from "sweetalert2";
import { ModalEditarPreguntaComponent } from "./modal-editar-pregunta/modal-editar-pregunta.component";
import { AdministradoresService } from "../administradores.service";
import { ModalAgregarPreguntaComponent } from "./modal-agregar-pregunta/modal-agregar-pregunta.component";

@Component({
    selector: "app-cargar-unidad-admin",
    templateUrl: "./cargar-unidad-admin.component.html",
    styleUrls: ["./cargar-unidad-admin.component.scss"],
    animations: fuseAnimations,
})
export class CargarUnidadAdminComponent implements OnInit {
    displayedColumns: string[] = ["index", "enunciado", "acciones"];
    pregunta: PreguntaUnidad = {};
    respuesta1: Respuesta = {};
    respuesta2: Respuesta = {};
    respuesta3: Respuesta = {};
    respuesta4: Respuesta = {};
    dialogRef: any;
    form: FormGroup;
    id_examen: any;
    id_unidad_creado: any = null;
    dataSource: any;
    listaSede: ListaSedes[] = [];
    listaSemestres: ListaSemestres[] = [];
    listaMaterias: ListaMaterias[] = [];
    listadoUnidades: ListaUnidades[] = [];
    tablaUnidades: TablaUnidades[] = [];
    listaMateriasTemporal: getMateriasTemporal[] = [];
    listaUnidadesTemporal: getUnidadesTemporal[] = [];
    ControlMaterias = new FormControl("", []);
    p: number = 1;
    itemsPerPage: number = 5;
    term: string;
    term2: string;
    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _matDialog: MatDialog,
        private administradoresService: AdministradoresService
    ) {}

    ngOnInit(): void {
        // Reactive Form
        this.form = this._formBuilder.group({
            id_materia: ["", Validators.required],
            unidad: ["", Validators.required],
            sede: [""],
            semestre: [""],
        });

        this.administradoresService.getMateriasTemporal().subscribe((data) => {
            // console.log('Este es el data', data)
            this.listaMateriasTemporal = data.data;
        });
        /*this.profesoresService.getListaSede().subscribe(
            (data) => {
                this.listaSede = [...data];
                console.log("Se llamo correctamente a la api");
                console.log("Estos son los datos", data);
            },
            (error) => {
                console.log("Hubo un error");
                console.log("Este fue el error", error);
            }
        );*/

        this.listaSede = [
            {
                ID: 1,
                NOMBRE_SEDE: "CDE LAGO",
            },
            {
                ID: 2,
                NOMBRE_SEDE: "PJC",
            },
            {
                ID: 3,
                NOMBRE_SEDE: "CDE JESUITICA",
            },
        ];
    }

    getSemetres(id_sede) {
        this.loading("Cargando Semestres");
        this.administradoresService.getListadoSemestres(id_sede).subscribe(
            (response) => {
                this.listaSemestres = response;
                //  console.log("Se llamo correctamente a get Semestres");
                // console.log("Estos son los datos", response);
                Swal.close();
            },
            (error) => {
                console.log("Hubo un problema al llamar la api");
            }
        );
    }

    getMaterias(id_semestre) {
        this.loading("Cargando Materias");
        this.administradoresService.getListaMaterias(id_semestre).subscribe(
            (data) => {
                this.listaMaterias = data;
                //  console.log("Se llamo correctamente a get materias");
                // console.log("Estos son los datos", data);
                Swal.close();
            },
            (error) => {
                console.log("Hubo un problema al llamar a la api");
            }
        );
    }

    getUnidades(id_materia) {
        this.loading("Cargando Unidades");
        this.administradoresService
            .getUnidadesTemporal(id_materia)
            .subscribe((data) => {
                // console.log('Unidades de la Materia', data)
                this.listaUnidadesTemporal = data.data;
                Swal.close();
            });
        /* this.profesoresService.getListaUnidades(id_materia).subscribe(
             (data) => {
                 this.listadoUnidades = data.data;
                 console.log("Se llamo correctamente a la get unidades");
                 console.log("Estos son los datos", data);
                 Swal.close();
             },
             (error) => {
                 console.log("Hubo un problema al llamar a la api");
             }
         );*/
    }

    preguntasUnidad(id_unidad) {
        //HACEMOS EL GET DE LA LISTA DE PREGUNTAS
        this.id_unidad_creado = id_unidad;
        this.loading("Cargando Tabla");
        this.administradoresService
            .getTablaUnidades(id_unidad)
            .subscribe((data) => {
                this.tablaUnidades = data;
                // console.log(data);
                Swal.close();
            });
    }

    agregarPregunta() {
        if (this.form.valid) {
            let dialogReff = this._matDialog.open(
                ModalAgregarPreguntaComponent
            );
            dialogReff.afterClosed().subscribe((result) => {
                // console.log("Esto es lo que devuelve", result);
                if (!result) {
                    return;
                }
                // console.log("Esto es result", result);
                //AGARRAMOS EL ID_EXAMEN DEL LOCAL STORAGE Y LOS DATOS DEL MODAL
                this.pregunta.id_unidad = this.form.value.unidad;
                this.pregunta.enunciado = result.enunciado;
                this.pregunta.puntos = 1;
                this.pregunta.explicacion = result.descripcion;
                //PRIMERO POST DE LA PREGUNTA
                this.loading("Enviando pregunta y respuestas");
                this.administradoresService
                    .postPregunta(this.pregunta)
                    .subscribe(
                        (response) => {
                            //EN RESPUESTA AGARRAMOS EL ID_PREGUNTA
                            this.respuesta1.id_pregunta = response;
                            //NOS PASAMOS LOS DATOS DE LA RESPUESTA Y VEMOS SI ES LA CORRECTA
                            this.respuesta1.texto = result.respuesta1;
                            if (result.respuestaCorrecta === "respuesta1") {
                                this.respuesta1.correcta = true;
                            } else {
                                this.respuesta1.correcta = false;
                            }
                            //HACEMOS EL POST PARA LA RESPUESTA 1
                            this.administradoresService
                                .postRespuesta(this.respuesta1)
                                .subscribe((response) => {
                                    //  console.log(
                                    //     "Se envio la respuesta 1",
                                    //    this.respuesta1
                                    // );
                                });
                            //EN RESPUESTA AGARRAMOS EL ID_PREGUNTA
                            this.respuesta2.id_pregunta = response;
                            //NOS PASAMOS LOS DATOS DE LA RESPUESTA  Y VEMOS SI ES LA CORRECTA
                            this.respuesta2.texto = result.respuesta2;
                            if (result.respuestaCorrecta === "respuesta2") {
                                this.respuesta2.correcta = true;
                            } else {
                                this.respuesta2.correcta = false;
                            }
                            //HACEMOS EL POST PARA LA RESPUESTA 2
                            this.administradoresService
                                .postRespuesta(this.respuesta2)
                                .subscribe((response) => {
                                    // console.log(
                                    //    "Se envio la respuesta 2",
                                    //    this.respuesta2
                                    // );
                                });
                            //EN RESPUESTA AGARRAMOS EL ID_PREGUNTA
                            this.respuesta3.id_pregunta = response;
                            //NOS PASAMOS LOS DATOS DE LA RESPUESTA Y VEMOS SI ES LA CORRECTA
                            this.respuesta3.texto = result.respuesta3;
                            if (result.respuestaCorrecta === "respuesta3") {
                                this.respuesta3.correcta = true;
                            } else {
                                this.respuesta3.correcta = false;
                            }
                            //HACEMOS EL POST PARA LA RESPUESTA 3 SI ES QUE NO ESTA VACIO
                            if (this.respuesta3.texto !== "") {
                                this.administradoresService
                                    .postRespuesta(this.respuesta3)
                                    .subscribe((response) => {
                                        // console.log(
                                        //    "Se envio la respuesta 3",
                                        //   this.respuesta3
                                        // );
                                    });
                            }

                            //EN RESPUESTA AGARRAMOS EL ID_PREGUNTA
                            this.respuesta4.id_pregunta = response;
                            //NOS PASAMOS LOS DATOS DE LA RESPUESTA Y VEMOS SI ES LA CORRECTA
                            this.respuesta4.texto = result.respuesta4;
                            if (result.respuestaCorrecta === "respuesta4") {
                                this.respuesta4.correcta = true;
                            } else {
                                this.respuesta4.correcta = false;
                            }
                            //HACEMOS EL POST PARA LA RESPUESTA 4 SI NO ESTA VACIO
                            if (this.respuesta4.texto !== "") {
                                this.administradoresService
                                    .postRespuesta(this.respuesta4)
                                    .subscribe((response) => {
                                        // console.log(
                                        //   "Se envio la respuesta 4",
                                        //   this.respuesta4
                                        //  );
                                    });
                            }
                            //HACEMOS EL GET DE LA LISTA DE PREGUNTAS
                            this.administradoresService
                                .getTablaUnidades(this.form.value.unidad)
                                .subscribe((data) => {
                                    this.tablaUnidades = data;
                                });
                            Swal.close();
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Preguntas agregadas con exito",
                                showConfirmButton: false,
                                timer: 1500,
                            });
                        },
                        (err) => {
                            console.log(
                                "Algo salio mal al hacer post de preguntas.."
                            );
                            Swal.fire(
                                "Upps",
                                "Hubo un problema con el servidor",
                                "error"
                            );
                        }
                    );
            });
        }
    }

    editarPregunta(pregunta) {
        // console.log("Este es  la pregunta", pregunta);
        this.dialogRef = this._matDialog.open(ModalEditarPreguntaComponent, {
            data: {
                pregunta: pregunta,
                id_unidad: this.form.value.unidad,
            },
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            //console.log(result);
            this.preguntasUnidad(this.id_unidad_creado);
        });
    }

    loading(titulo) {
        Swal.fire({
            title: titulo,

            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

    eliminarPregunta(ID) {
        Swal.fire({
            title: "¿Estas seguro?",
            text: "Esto no se podra revertir",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {
                console.log("Entra la funcion delete", ID);
                this.administradoresService.deletePreguntaUnidad(ID).subscribe(
                    (response) => {
                        console.log(response);
                        Swal.fire(
                            "Eliminado!",
                            "Tu pregunta se ha sido eliminada con exito.",
                            "success"
                        );
                        this.administradoresService
                            .getTablaUnidades(this.id_unidad_creado)
                            .subscribe((data) => {
                                this.tablaUnidades = data;
                            });
                    },
                    (err) => {
                        Swal.fire(
                            "Upps",
                            "Hubo un problema con el servidor",
                            "error"
                        );
                    }
                );
            }
        });
    }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
//import { CargarExamenComponent } from "./cargar-examen/cargar-examen.component";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
//import { ModalAgregarPreguntasComponent } from "./cargar-examen/modal-agregar-preguntas/modal-agregar-preguntas.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseConfirmDialogModule } from "@fuse/components";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatListModule } from "@angular/material/list";
//import { ModalEditarPreguntaComponent } from "./cargar-examen/modal-editar-pregunta/modal-editar-pregunta.component";
//import { ModalVerPreguntaComponent } from "./cargar-examen/modal-ver-pregunta/modal-ver-pregunta.component";
//import { ListarExamenesComponent } from "./listar-examenes/listar-examenes.component";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTabsModule } from "@angular/material/tabs";
//import { ListaPuntajeAlumnosComponent } from "./listar-examenes/lista-puntaje-alumnos/lista-puntaje-alumnos.component";
//import { VerExamenAlumnoComponent } from "./listar-examenes/ver-examen-alumno/ver-examen-alumno.component";
import { MatStepperModule } from "@angular/material/stepper";
import { CargarUnidadComponent } from "./cargar-unidad/cargar-unidad.component";
//import { HabilitacionesComponent } from "./habilitaciones/habilitaciones.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { AuthGuard } from "app/auth/auth.guard";
//import { CargarPuntajeComponent } from "./cargar-puntaje/cargar-puntaje.component";
//import { ModalAgregarPreguntaUnidadComponent } from "./cargar-unidad/modal-agregar-pregunta-unidad/modal-agregar-pregunta-unidad.component";
//import { ModalEditarPreguntaUnidadComponent } from "./cargar-unidad/modal-editar-pregunta-unidad/modal-editar-pregunta-unidad.component";
//import { ModalEditarExamenComponent } from "./listar-examenes/modal-editar-examen/modal-editar-examen.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxPaginationModule } from "ngx-pagination";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MateriasComponent } from "./materias/materias.component";
import { CargarTrabajoPracticoComponent } from "./cargar-trabajo-practico/cargar-trabajo-practico.component";
import { NgxMatFileInputModule } from "@angular-material-components/file-input";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSortModule } from "@angular/material/sort";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ModalVistaPreviaComponent } from './cargar-trabajo-practico/modal-vista-previa/modal-vista-previa.component';

const routes = [
    {
        path: "profesor-materias",
        component: MateriasComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_PROFESOR"],
        },
    },
    // {
    //    path: "cargar-examen",
    //     component: CargarExamenComponent,
    //     canActivate: [AuthGuard],
    //     data: {
    //         role: ["ROLE_SUPERADMIN", "ROLE_PROFESOR"],
    //    },
    //},
    // {
    //    path: "listar-examenes",
    //  component: ListarExamenesComponent,
    //  canActivate: [AuthGuard],
    //  data: {
    //      role: ["ROLE_SUPERADMIN", "ROLE_PROFESOR"],
    //  },
    //},
    // {
    //    path: "lista-puntaje-alumnos",
    //   component: ListaPuntajeAlumnosComponent,
    //   canActivate: [AuthGuard],
    //   data: {
    //      role: ["ROLE_SUPERADMIN", "ROLE_PROFESOR"],
    //  },
    //},
    //{
    //    path: "ver-examen-alumno",
    //    component: VerExamenAlumnoComponent,
    //   canActivate: [AuthGuard],
    //   data: {
    //       role: ["ROLE_SUPERADMIN", "ROLE_PROFESOR"],
    //   },
    // },
    {
        path: "cargar-unidad",
        component: CargarUnidadComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_PROFESOR", "ROLE_ADMIN"],
        },
    },
    //{
    //   path: "habilitaciones",
    //   component: HabilitacionesComponent,
    //   canActivate: [AuthGuard],
    //   data: {
    //       role: ["ROLE_SUPERADMIN", "ROLE_PROFESOR"],
    //   },
    // },
    {
        path: "cargar-trabajo-practico",
        component: CargarTrabajoPracticoComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_PROFESOR"],
        },
    },
];

@NgModule({
    declarations: [
        // CargarExamenComponent,
        //ModalAgregarPreguntasComponent,
        //ModalEditarPreguntaComponent,
        //ModalVerPreguntaComponent,
        // ListarExamenesComponent,
        // ListaPuntajeAlumnosComponent,
        //VerExamenAlumnoComponent,
        CargarUnidadComponent,
        //  HabilitacionesComponent,
        // CargarPuntajeComponent,
        // ModalAgregarPreguntaUnidadComponent,
        // ModalEditarPreguntaUnidadComponent,
        // ModalEditarExamenComponent,
        MateriasComponent,
        CargarTrabajoPracticoComponent,
        ModalVistaPreviaComponent,
    ],
    entryComponents: [
        // ModalAgregarPreguntaUnidadComponent,
        // ModalEditarPreguntaUnidadComponent,
        // ModalVerPreguntaComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        FuseSharedModule,
        MatDatepickerModule,
        MatDialogModule,
        MatToolbarModule,
        FuseConfirmDialogModule,
        MatCheckboxModule,
        MatRadioModule,
        MatListModule,
        MatTableModule,
        MatPaginatorModule,
        MatTabsModule,
        MatStepperModule,
        MatSlideToggleModule,
        BrowserAnimationsModule,
        NgxMatSelectSearchModule,
        NgxPaginationModule,
        //Search Filter
        Ng2SearchPipeModule,
        //NoopAnimationsModule
        MatAutocompleteModule,
        NgxMatFileInputModule,
        MatExpansionModule,
        MatSortModule,
        MatProgressBarModule,
    ],
})
export class ProfesoresModule {}

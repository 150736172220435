import { Component, OnInit } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import Swal from "sweetalert2";
import { preguntasNuevas, getUnidadesTemporal } from "../profesores.modal";
import { ProfesoresService } from "../profesores.service";
import { isUndefined } from "lodash";

@Component({
    selector: "app-cargar-unidad",
    templateUrl: "./cargar-unidad.component.html",
    styleUrls: ["./cargar-unidad.component.scss"],
    animations: fuseAnimations,
})
export class CargarUnidadComponent implements OnInit {
    listaUnidadesTemporal: getUnidadesTemporal[] = [];
    id_unidad: number = 0;
    preguntasEnviar: preguntasNuevas[] = [];
    preguntasNuevas: preguntasNuevas[] = [
        /*  {
              id : 1,
              id_unidad : this.id_unidad,
              enunciado : "Esta es una pregunta",
              explicacion: "Porque es una prueba",
              puntos : 1,
              nueva: false,
              imagen : null,
              respuestas: [
                  {
                      texto: "Respuesta nueva",
                      correcta: false
                  },
                  {
                      texto: "Respuesta 2",
                      correcta: true
                  }
              ]
          },
          {
              id : 2,
              id_materia : this.id_unidad,
              enunciado : "Esta es otra pregunta",
              explicacion: "Porque es una prueba",
              nueva: false,
              puntos : 1,
              imagen : null,
              respuestas: [
                  {
                      texto: "Respuesta nueva",
                      correcta: false
                  },
                  {
                      texto: "Respuesta 2",
                      correcta: true
                  }
              ]
          }*/
    ];
    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(private profesoresService: ProfesoresService) {}

    id_profesor = parseInt(sessionStorage.getItem("id_profesor"));

    ngOnInit(): void {
        this.getUnidades(sessionStorage.getItem("id_disciplina"));
    }

    listarPreguntas(id) {
        this.loading("Cargando preguntas");

        console.log(this.id_profesor);
        if (this.id_profesor == -1) {
            this.profesoresService.getListaPreguntas(id).subscribe((data) => {
                //Convertir los traidos
                this.preguntasNuevas = [];
                for (let pregunta of data) {
                    this.preguntasNuevas.push({
                        id: pregunta.ID,
                        enunciado: pregunta.ENUNCIADO,
                        explicacion: pregunta.EXPLICACION,
                        puntos: pregunta.PUNTOS,
                        nueva: false,
                        editar: false,
                        respuestas: pregunta.respuestas,
                    });
                }
                //this.preguntasNuevas = data;
                Swal.close();
            });
        } else {
            this.profesoresService
                .getListaPreguntasAdmin(id, this.id_profesor)
                .subscribe((data) => {
                    //Convertir los traidos
                    this.preguntasNuevas = [];
                    for (let pregunta of data) {
                        this.preguntasNuevas.push({
                            id: pregunta.ID,
                            enunciado: pregunta.ENUNCIADO,
                            explicacion: pregunta.EXPLICACION,
                            puntos: pregunta.PUNTOS,
                            nueva: false,
                            editar: false,
                            respuestas: pregunta.respuestas,
                        });
                    }
                    //this.preguntasNuevas = data;
                    Swal.close();
                });
        }
    }

    cambiarRespuesta(id, i) {
        console.log("id es ", id);
        console.log("i es ", i);
        if (!isUndefined(id)) {
            for (let respuesta of this.preguntasNuevas[i].respuestas) {
                console.log("id respuesta :", respuesta.id);
                console.log("id que entra: ", id);
                if (respuesta.id == id) {
                    respuesta.correcta = true;
                } else {
                    respuesta.correcta = false;
                }
            }
        }

        console.log("Asi queda el objeto: ", this.preguntasNuevas[i]);
    }

    editarPregunta(i) {
        let pregunta = {
            id_pregunta: this.preguntasNuevas[i].id,
            enunciado: this.preguntasNuevas[i].enunciado,
            explicacion: this.preguntasNuevas[i].explicacion,
        };
        this.profesoresService.putEditarPregunta(pregunta).subscribe(
            (response) => {
                // console.log("Editado con exito la pregunta!", pregunta);
                //  console.log(response, 'Respuesta de editar pregunta')

                for (let respuesta of this.preguntasNuevas[i].respuestas) {
                    let respuestaEditado = {
                        id_respuesta: respuesta.id,
                        texto: respuesta.texto,
                        correcta: respuesta.correcta,
                    };
                    this.profesoresService
                        .putEditarRespuesta(respuestaEditado)
                        .subscribe(
                            (response) => {
                                //  console.log("Se envio la respuesta", respuestaEditado);
                                // console.log('Respuesta de la respuestas', response)
                            },
                            (error) => {
                                Swal.fire(
                                    "Ocurrio un error",
                                    "Verifique los datos ingresados o contacte con su administrador de sistemas",
                                    "error"
                                );
                            }
                        );
                }
                return new Promise(() => {});
            },
            (err) => {
                Swal.fire(
                    "Hubo un problema con el servidor, o no completo correctamente los campos",
                    "Contacte con su administrador de sistemas",
                    "error"
                );
            }
        );
    }

    goback() {
        window.history.back();
    }

    guardarPreguntas() {
        this.loading("Guardando preguntas");
        //Setear la unidad

        let ban_guardar = true;
        let contador_editadas = 0;
        let soloEdita =
            this.preguntasNuevas.filter((x) => x.nueva == false).length ==
            this.preguntasNuevas.length;
        var terminado_editar = false;
        //Verificar
        for (let pregunta of this.preguntasNuevas) {
            pregunta.id_unidad = this.id_unidad;
            if (
                pregunta.respuestas.filter((x) => x.correcta == false).length ==
                pregunta.respuestas.length
            ) {
                ban_guardar = false;
            }
            //SPLICE I 1
            /*
            for(let respuesta of pregunta.respuestas){
                if(respuesta.texto == " " || respuesta.texto.length == 0){
                    console.log("eliminar");
                    pregunta.respuestas.splice(pregunta.respuestas.indexOf(respuesta), 1)
                }
            }
            */
        }
        //Si pasa la verificacion guardar
        if (ban_guardar == true) {
            if (
                this.preguntasNuevas.filter((x) => x.nueva == true).length > 0
            ) {
                if (this.id_profesor == -1) {
                    this.profesoresService
                        .crearPreguntaRespuesta(
                            this.preguntasNuevas.filter((x) => x.nueva == true)
                        )
                        .subscribe(
                            (data) => {
                                if (
                                    this.preguntasNuevas.filter(
                                        (x) => x.nueva == true
                                    ).length == this.preguntasNuevas.length
                                ) {
                                    console.log("Entre a guardar");
                                    this.preguntasNuevas = [];
                                    Swal.fire({
                                        position: "top-end",
                                        icon: "success",
                                        title: "Cambios guardados con exito",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                    this.listarPreguntas(this.id_unidad);
                                } else {
                                    let contador_editadas2 = 0;
                                    for (let pregunta of this.preguntasNuevas.filter(
                                        (x) => x.nueva == false
                                    )) {
                                        this.editarPregunta(
                                            this.preguntasNuevas.indexOf(
                                                pregunta
                                            )
                                        );

                                        contador_editadas2++;

                                        if (
                                            contador_editadas2 ==
                                            this.preguntasNuevas.filter(
                                                (x) => x.nueva == false
                                            ).length
                                        ) {
                                            terminado_editar = true;

                                            Swal.fire({
                                                position: "top-end",
                                                icon: "success",
                                                title:
                                                    "Cambios guardados con exito",
                                                showConfirmButton: false,
                                            });
                                            setTimeout(() => {
                                                this.listarPreguntas(
                                                    this.id_unidad
                                                );
                                            }, 3000);
                                        }
                                    }
                                }
                            },
                            (error) => {
                                Swal.fire(
                                    "Ocurrio un error al guardar",
                                    "Por favor revise si hay campos incompletos y rellenalos, contenedores vacios (en este caso elimine el contenedor vacio) e intentalo nuevamente o contacte a su administrador de sistema",
                                    "error"
                                );
                            }
                        );
                } else {
                    this.profesoresService
                        .crearPreguntaRespuestaAdmin(
                            this.preguntasNuevas.filter((x) => x.nueva == true),
                            this.id_profesor
                        )
                        .subscribe(
                            (data) => {
                                if (
                                    this.preguntasNuevas.filter(
                                        (x) => x.nueva == true
                                    ).length == this.preguntasNuevas.length
                                ) {
                                    console.log("Entre a guardar");
                                    this.preguntasNuevas = [];
                                    Swal.fire({
                                        position: "top-end",
                                        icon: "success",
                                        title: "Cambios guardados con exito",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                    this.listarPreguntas(this.id_unidad);
                                } else {
                                    let contador_editadas2 = 0;
                                    for (let pregunta of this.preguntasNuevas.filter(
                                        (x) => x.nueva == false
                                    )) {
                                        this.editarPregunta(
                                            this.preguntasNuevas.indexOf(
                                                pregunta
                                            )
                                        );

                                        contador_editadas2++;

                                        if (
                                            contador_editadas2 ==
                                            this.preguntasNuevas.filter(
                                                (x) => x.nueva == false
                                            ).length
                                        ) {
                                            terminado_editar = true;

                                            Swal.fire({
                                                position: "top-end",
                                                icon: "success",
                                                title:
                                                    "Cambios guardados con exito",
                                                showConfirmButton: false,
                                            });
                                            setTimeout(() => {
                                                this.listarPreguntas(
                                                    this.id_unidad
                                                );
                                            }, 3000);
                                        }
                                    }
                                }
                            },
                            (error) => {
                                Swal.fire(
                                    "Ocurrio un error al guardar",
                                    "Revise los campos o contacte a su administrador de sistema",
                                    "error"
                                );
                            }
                        );
                }
            }

            //Editar las preguntas viejas

            if (soloEdita == true) {
                //Convertir temporal
                /*for(let tempPregunta of this.preguntasNuevas.filter(x=> x.nueva == false)){
                    tempPregunta.id_pregunta = tempPregunta.id
                    for(let tempResp of tempPregunta.respuestas){
                        tempResp.id_respuesta = tempResp.id;
                    }
                }
                this.profesoresService.putEditarPreguntaNuevo(this.preguntasNuevas.filter(x=> x.nueva == false)).subscribe(
                    data=>{
                        
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Cambios guardados con exito",
                            showConfirmButton: false
                            
                        });

                        
                            this.listarPreguntas(this.id_unidad);      
                        
                    }
                )*/
                for (let pregunta of this.preguntasNuevas.filter(
                    (x) => x.nueva == false
                )) {
                    this.editarPregunta(this.preguntasNuevas.indexOf(pregunta));

                    contador_editadas++;

                    if (
                        contador_editadas ==
                        this.preguntasNuevas.filter((x) => x.nueva == false)
                            .length
                    ) {
                        terminado_editar = true;

                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Cambios guardados con exito",
                            showConfirmButton: false,
                        });

                        setTimeout(() => {
                            this.listarPreguntas(this.id_unidad);
                        }, 3000);
                    }
                }
            }
        } else {
            Swal.fire(
                "Respuestas incompletas",
                "Favor verifique sus respuestas, recuerde marcar la correcta y completar todos los campos",
                "warning"
            );
        }
    }

    cambiarMetodoPreguntas(i, metodo) {
        /**
         * 1 Seleccion multiple
         * 2 Verdadero Falso
         */
        if (metodo == 1) {
            this.preguntasNuevas[i].respuestas = [];
            for (let j = 0; j < 4; j++) {
                this.preguntasNuevas[i].respuestas.push({
                    id: j,
                    correcta: false,
                    texto: " ",
                });
            }
        } else {
            this.preguntasNuevas[i].respuestas = [];
            this.preguntasNuevas[i].respuestas.push({
                id: 1,
                texto: "Verdadero",
                correcta: false,
            });
            this.preguntasNuevas[i].respuestas.push({
                id: 2,
                texto: "Falso",
                correcta: false,
            });
        }
    }

    cambiarDimension(cantidad) {
        for (let i = 0; i < cantidad; i++) {
            this.preguntasNuevas.push({});
        }
    }

    upload(files: any) {
        console.log("si");
    }

    agregarPregunta() {
        if (this.id_unidad > 0) {
            this.preguntasNuevas.push({
                nueva: true,
                puntos: 1,
            });
        } else {
            Swal.fire(
                "Falta seleccionar unidad",
                "Por favor seleccione la unidad antes de continuar",
                "warning"
            );
        }
    }

    quitarPregunta(i) {
        if (this.preguntasNuevas[i].nueva == false) {
            this.profesoresService
                .deletePreguntaUnidad(this.preguntasNuevas[i].id)
                .subscribe((data) => {
                    Swal.fire("Eliminado exitosamente!");
                });
        }
        if (i == 0) {
            this.preguntasNuevas[0] = {};
        }
        this.preguntasNuevas.splice(i, 1);
    }

    loading(titulo) {
        Swal.fire({
            title: titulo,

            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

    getUnidades(id_materia) {
        this.loading("Cargando Unidades");
        //console.log("Esta es el id de la materia seleccionada", id_materia);
        this.profesoresService
            .getUnidadesTemporal(id_materia)
            .subscribe((data) => {
                // console.log('Unidades de la Materia', data)
                this.listaUnidadesTemporal = data.data;
                Swal.close();
            });
        /*this.profesoresService
            .getListaUnidades(id_materia)
            .subscribe((data) => {
                console.log("Esto es lo que me tira el data unidades", data);
                this.unidades = data.data;
                Swal.close();
            });*/
    }
}

import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable()
export class ExcelService {
    constructor() {}

  
    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const header = Object.keys(json[0]); // columns name

        let wscols = [];
        for (var i = 0; i < header.length; i++) {
            // columns length added
            wscols.push({ wch: header[i].length + 12 });
        }
        // Chequeo propio
        


        const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        myworksheet["!cols"] = wscols;
       
        const myworkbook: XLSX.WorkBook = {
            Sheets: { data: myworksheet },
            SheetNames: ["data"],
        };
        const excelBuffer: any = XLSX.write(myworkbook, {
            bookType: "xlsx",
            type: "array",
        });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(data, fileName + "_exported" + EXCEL_EXTENSION);
    }
}

<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Trabajos Prácticos
            </h1>
        </div>
    </div>
    <!-- CONTENT -->
    <mat-tab-group (selectedTabChange)="revisarEsFalse()" (selectedTabChange)="listaTrabajosPracticos()">
        <mat-tab label="Crear trabajo práctico">
            <div
                class="p-12"
                fxLayout="column"
                fxLayoutAlign="start"
                fxLayout.gt-lg="row"
                fxLayout.gt-md="column"
            >
                <!-- REACTIVE FORM EXAMPLE -->

                <form
                    class="mat-card mat-elevation-z4 p-24 mr-24"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    fxFlex="1 0 auto"
                    name="form"
                    [formGroup]="form"
                >
                    <div>
                        <mat-icon
                            style="
                                font-weight: lighter;
                                font-size: 100px;
                                width: fit-content;
                                height: auto;
                                color: #2295d3;
                            "
                            >info</mat-icon
                        >
                    </div>

                    <div>
                        <h1 style="font-size: 30px">
                            Acá puedes crear tus trabajos prácticos
                        </h1>
                    </div>
                </form>
            </div>

            <div
                class="mb-24 p-12"
                fxLayout="column"
                fxLayoutAlign="start"
                fxLayout.gt-lg="row"
                fxLayout.gt-md="column"
            >
                <!-- REACTIVE FORM EXAMPLE -->

                <form
                    class="mat-card mat-elevation-z4 p-24 mr-24"
                    fxLayout="column"
                    fxLayoutAlign="start"
                    fxFlex="1 0 auto"
                    name="form"
                    [formGroup]="form"
                >
                    <div class="h2 mb-24" style="font-weight: bolder">
                        Nuevo trabajo práctico
                    </div>

                    <div fxFlex="1 0 auto" fxLayout="row">
                        <mat-form-field
                            appearance="outline"
                            fxFlex="60"
                            class="pr-4"
                        >
                            <mat-label>Seleccionar Materia</mat-label>
                            <mat-select
                                required
                                formControlName="oferta_disciplina_id"
                            >
                                <mat-option
                                    [value]="materia.id"
                                    *ngFor="let materia of materias"
                                >
                                    {{ materia.nome_filial }} -
                                    {{ materia.nome }}
                                    {{ materia.nome_turma }}
                                </mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="secondary-text"
                                >outlined_flag</mat-icon
                            >
                            <mat-error
                                >Por favor seleccione una materia.</mat-error
                            >
                        </mat-form-field>

                        <mat-form-field
                            appearance="outline"
                            fxFlex="20"
                            class="pr-4"
                        >
                            <mat-label>Fecha Inicio</mat-label>
                            <input
                                readonly
                                matInput
                                [matDatepicker]="startDatePicker"
                                formControlName="fecha_inicio"
                                name="start"
                                (click)="verFecha()"
                            />

                            <mat-datepicker-toggle
                                matSuffix
                                [for]="startDatePicker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                            <mat-error>Ingrese la fecha de inicio.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="20">
                            <mat-label>Fecha Fin</mat-label>
                            <input
                                readonly
                                matInput
                                [matDatepicker]="endDatePicker"
                                formControlName="fecha_fin"
                                name="start"
                                (click)="verFecha()"
                            />

                            <mat-datepicker-toggle
                                matSuffix
                                [for]="endDatePicker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                            <mat-error style="height: 27px"
                                >Ingrese la fecha de entrega.</mat-error
                            >
                        </mat-form-field>
                    </div>

                    <div fxFlex="1 0 auto" fxLayout="row">
                        <mat-form-field
                            appearance="outline"
                            fxFlex="75"
                            class="pr-4"
                        >
                            <mat-label>Titulo</mat-label>
                            <input
                                matInput
                                formControlName="titulo"
                                placeholder="Escriba el titulo del trabajo aqui"
                            />
                            <mat-error>Por favor ingrese un titulo.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="25">
                            <mat-label>Calificacion Total</mat-label>
                            <input matInput formControlName="calificacion" />
                            <mat-error
                                >Por favor ingrese la calificacion.</mat-error
                            >
                        </mat-form-field>
                    </div>

                    <div fxFlex=" 1 0 auto">
                        <mat-form-field
                            appearance="outline"
                            fxFlex="60"
                            class="pr-4"
                        >
                            <mat-label>Formato de entrega</mat-label>
                            <input
                                matInput
                                formControlName="formatoEntrega"
                                placeholder="Ejemplo: PDF 5 páginas máximo "
                            />
                            <mat-error
                                >Por favor ingrese el formato de
                                entrega.</mat-error
                            >
                        </mat-form-field>
                        <mat-form-field
                            appearance="outline"
                            fxFlex="20"
                            class="pr-4"
                        >
                            <mat-label>Hora Inicio</mat-label>
                            <input
                                matInput
                                type="time"
                                step="1"
                                formControlName="hora_inicio"
                                name="start"
                            />

                            <mat-error>Ingrese la hora de inicio.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="20">
                            <mat-label>Hora Fin</mat-label>
                            <input
                                matInput
                                type="time"
                                step="1"
                                formControlName="hora_fin"
                                name="start"
                            />

                            <mat-error>Ingrese la hora limite.</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxFlex=" 1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Instrucciones</mat-label>
                            <textarea
                                style="height: 150px"
                                matInput
                                formControlName="instrucciones"
                                placeholder="Escriba los requisitos técnicos del trabajo"
                            ></textarea>
                            <mat-error
                                >Por favor ingrese las intrucciones.</mat-error
                            >
                        </mat-form-field>
                    </div>

                    <div fxFlex=" 1 0 auto" fxLayout="row">
                        <mat-form-field
                            appearance="outline"
                            fxFlex="70"
                            class="pr-4"
                        >
                            <mat-label>Adjuntar enlace</mat-label>
                            <input
                                matInput
                                formControlName="link"
                                placeholder="Ingrese el link aqui"
                            />
                            <mat-error>Por favor ingrese un link.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="30">
                            <mat-label>Elegir Archivo </mat-label>
                            <ngx-mat-file-input
                                formControlName="file"
                                [multiple]="false"
                                [color]="color"
                            >
                            </ngx-mat-file-input>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="end">
                        <button mat-button color="accent" (click)="confirmar()">
                            Subir Trabajo Práctico
                        </button>
                    </div>
                </form>
            </div>
        </mat-tab>

        <mat-tab label="Revisar trabajos prácticos" >
            <div [fxHide]="revisarBoolean === true">
                <div
                    class="mb-24 p-12"
                    fxLayout="column"
                    fxLayoutAlign="start"
                    fxLayout.gt-lg="row"
                    fxLayout.gt-md="column"
                >
                    <form
                        class="mat-card mat-elevation-z4 p-24 mr-24"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        fxFlex="1 0 auto"
                        name="form"
                        [formGroup]="form"
                    >
                        <div>
                            <mat-icon
                                style="
                                    font-weight: lighter;
                                    font-size: 100px;
                                    width: fit-content;
                                    height: auto;
                                    color: #2295d3;
                                "
                                >info</mat-icon
                            >
                        </div>

                        <div>
                            <h1 style="font-size: 30px">
                                Acá puedes revisar y editar los trabajos
                                prácticos
                            </h1>
                        </div>
                    </form>
                </div>

                <div class="p-12 paneles">
                    <mat-accordion>
                        <mat-expansion-panel
                            *ngFor="let tp of dataSource"
                            class="mb-12"
                            (opened)="panelOpenState = true"
                            (closed)="panelOpenState = false"
                        >
                            <mat-expansion-panel-header
                                style="background-color: #2295d3"
                            >
                                <!--<mat-panel-title> </mat-panel-title>-->

                                <mat-panel-description style="color: #fff">
                                    {{tp.dados_materia.nome_filial}} {{tp.dados_materia.nome}} {{tp.dados_materia.nome_turma}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div fxLayout="row" fxFlex="1 0 auto">
                                <div
                                    fxLayout="column"
                                    fxFlex="30"
                                    fxLayoutAlign="center center"
                                >
                                    <h1 style="font-weight: bold">
                                        {{ tp.titulo }}
                                    </h1>
                                    <label>Trabajo practico: 1</label>
                                </div>

                                <div
                                    class="mt-16"
                                    fxLayout="row"
                                    fxFlex="70"
                                    fxLayoutAlign="center"
                                    fxHide.xs
                                >
                                    <div fxLayout="column" class="mr-48">
                                        <label>Recibidos</label>
                                        <label
                                            ><strong
                                                >{{ tp.trabalhos_entregues }}/{{
                                                    tp.total_alunos
                                                }}</strong
                                            ></label
                                        >
                                    </div>

                                    <div fxLayout="column" class="mr-48">
                                        <label>Fecha de creacion</label>
                                        <label
                                            ><strong>{{
                                                tp.data_inicio
                                            }}</strong></label
                                        >
                                        <label
                                            ><strong>{{
                                                tp.hora_inicio
                                            }}</strong></label
                                        >
                                    </div>

                                    <div fxLayout="column" class="mr-48">
                                        <label>Fecha de entrega</label>
                                        <label
                                            ><strong>{{
                                                tp.data_fin
                                            }}</strong></label
                                        >
                                        <label
                                            ><strong>{{
                                                tp.hora_fin
                                            }}</strong></label
                                        >
                                    </div>
                                    <div fxLayoutAlign="start center">
                                        <button
                                            class="revisar"
                                            mat-raised-button
                                            color="accent"
                                            (click)="revisar(tp.id)"
                                        >
                                            Revisar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>

            <div [fxShow]="revisarBoolean === true">
                <!-- REACTIVE FORM EXAMPLE -->
                <div class="p-12">
                    <form
                        class="mat-card mat-elevation-z4 p-24 mr-24"
                        fxLayout="column"
                        fxLayoutAlign="start"
                        fxFlex="1 0 auto"
                    >
                        <div fxLayout="column">
                            <div fxLayout="column">
                                <div
                                    fxLayout="row"
                                    fxFlex="1 0 auto"
                                    fxLayoutAlign=" center"
                                >
                                    <div
                                        fxLayout="column"
                                        fxFlex="40"
                                        fxLayoutAlign="start"
                                    >
                                        <button
                                            mat-raised-button
                                            color="accent"
                                            (click)="revisarEsFalse()"
                                        >
                                            Volver
                                        </button>
                                        <h1>{{ trabajoPractico.titulo }}</h1>
                                        <label>Calificacion total</label>
                                        <label style="font-weight: bolder"
                                            >{{
                                                trabajoPractico.calificacion
                                            }}/{{
                                                trabajoPractico.total_pontos
                                            }}</label
                                        >
                                        <a
                                            style="
                                                margin-top: 10px;
                                                cursor: pointer;
                                            "

                                            >Descargar planilla de notas</a
                                        >
                                    </div>

                                    <div
                                        fxFlex="60"
                                        fxLayoutAlign="end"
                                        fxLayout="row"
                                    >
                                        <div fxLayout="column" class="mr-48">
                                            <label>Recibidos</label>
                                            <label style="font-weight: bolder"
                                                >250/1000</label
                                            >
                                        </div>
                                        <div fxLayout="column" class="mr-48">
                                            <label>Fecha entrega</label>
                                            <label
                                                style="font-weight: bolder"
                                                >{{
                                                    trabajoPractico.data_fin
                                                }}</label
                                            >
                                            <label
                                                style="font-weight: bolder"
                                                >{{
                                                    trabajoPractico.hora_fin
                                                }}</label
                                            >
                                        </div>

                                        <div fxLayout="column">
                                            <label>Formato entrega</label>
                                            <label
                                                style="font-weight: bolder"
                                                >{{
                                                    trabajoPractico.forma_entrega
                                                }}</label
                                            >
                                        </div>
                                    </div>
                                </div>
                                <hr
                                    style="
                                        margin-bottom: 10px;
                                        margin-top: 10px;
                                    "
                                />
                            </div>
                            <div>
                                <mat-tab-group>
                                    <mat-tab label="Instrucciones">
                                        <div fxLayoutAlign="start">
                                            <div fxLayout="column">
                                                <h3
                                                    style="
                                                        margin-bottom: 0;
                                                        margin-top: 50px;
                                                    "
                                                >
                                                    PASO A PASO
                                                </h3>

                                                <hr
                                                    style="margin-bottom: 10px"
                                                />

                                                <label>{{
                                                    trabajoPractico.instrucao
                                                }}</label>

                                                <h3 style="margin-bottom: 0">
                                                    ANEXOS
                                                </h3>

                                                <hr
                                                    style="margin-bottom: 10px"
                                                />

                                                <div fxLayout="column">
                                                    <label>anexo 1</label>
                                                    <label>anexo 2</label>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>

                                    <mat-tab label="Entregas">
                                        <div
                                            *ngFor="
                                                let entregados of listaEntregados
                                            "
                                            fxLayout="column"
                                            fxLayoutAlign="start"
                                            fxFlex="1 0 auto"
                                        >
                                            <h3
                                                style="
                                                    margin-bottom: 15px;
                                                    margin-top: 50px;
                                                "
                                            >
                                                LISTA DE TRABAJOS ENTREGADOS
                                            </h3>

                                            <hr style="margin-bottom: 6px" />

                                            <div
                                                fxLayout="row"
                                                fxFlex="1 0 auto"
                                                fxLayoutAlign="start center"
                                            >
                                                <label fxFlex="25">{{
                                                    entregados.titulo
                                                }}</label>
                                                <label fxFlex="25"
                                                    >{{ entregados.user.nome }}
                                                    {{
                                                        entregados.user
                                                            .sobrenome
                                                    }}</label
                                                >
                                                <label fxFlex="17">{{
                                                    entregados.fecha_entrega
                                                }}</label>
                                                <div
                                                    fxLayout="row"
                                                    fxFlex="33"
                                                    fxLayoutAlign="end"
                                                >
                                                    <button
                                                        style="
                                                            margin-right: 15px;
                                                        "
                                                        mat-raised-button
                                                        color="accent"
                                                        class="descargar"
                                                        (click)="descargarTrabajo(entregados.caminho)"
                                                    >
                                                        Descargar
                                                    </button>
                                                    <button
                                                        class="comentar"
                                                        mat-raised-button
                                                        color="accent"
                                                        (click)="comentar()"
                                                    >
                                                        Comentar
                                                    </button>
                                                </div>
                                            </div>

                                            <hr style="margin-bottom: 6px" />
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <!--<div
                class="mb-24"
                fxLayout="column"
                fxLayoutAlign="start"
                fxLayout.gt-lg="row"
                fxLayout.gt-md="column"
            >
                <form
                    class="mat-card mat-elevation-z4 p-24"
                    fxLayout="column"
                    fxLayoutAlign="start"
                    fxFlex="1 0 auto"
                    name="form"
                    [formGroup]="form"
                >
                    <div class="h2 mb-24">Revisar Trabajos</div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-form-field
                            fxLayoutAling="center center"
                            appearance="outline"
                            fxFlex="40"
                            class="pr-4"
                        >
                            <mat-label>Buscar</mat-label>
                            <input
                                [(ngModel)]="term"
                                matInput
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="20">
                            <mat-label>Mostrar</mat-label>
                            <mat-select
                                [(value)]="itemsPerPage"
                                (selectionChange)="p = 1"
                            >
                                <mat-option value="5"> 5 </mat-option>
                                <mat-option value="10"> 10 </mat-option>
                                <mat-option value="15"> 15 </mat-option>
                                <mat-option value="20"> 20 </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <mat-table
                        fxFlex="1 0 auto"
                        class="products-table"
                        #table
                        [dataSource]="
                            dataSource
                                | filter: term
                                | paginate
                                    : {
                                          itemsPerPage: itemsPerPage,
                                          currentPage: p
                                      }
                        "
                        matSort
                        [@animateStagger]="{ value: '50' }"
                        fusePerfectScrollbar
                    >
                         Columna Titulo 
                        <ng-container matColumnDef="NombreTrabajo">
                            <mat-header-cell
                                fxFlex="50"
                                *matHeaderCellDef
                                mat-sort-header
                                >Titulo
                            </mat-header-cell>
                            <mat-cell fxFlex="50" *matCellDef="let listado">
                                <p class="text-truncate">
                                    {{ listado.titulo }}
                                </p>
                            </mat-cell>
                        </ng-container>
                         Columna Acciones 
                        <ng-container matColumnDef="Acciones">
                            <mat-header-cell
                                fxFlex="30"
                                *matHeaderCellDef
                                mat-sort-header
                                >Acciones</mat-header-cell
                            >
                            <mat-cell fxFlex="30" *matCellDef="let product">
                                <a
                                    mat-button
                                    color="success"
                                    href="https://api.ucpvirtual.com.br/storage/medicina/trabalhos/{{
                                        product.caminho
                                    }} "
                                    target="_blank"
                                >
                                    Ver
                                </a>
                                <button
                                    mat-button
                                    color="warn"
                                    (click)="
                                        eliminarTrabajoPractico(product.id)
                                    "
                                >
                                    Eliminar
                                </button>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row
                            *matHeaderRowDef="displayedColumns; sticky: true"
                        ></mat-header-row>

                        <mat-row
                            *matRowDef="let product; columns: displayedColumns"
                            class="product"
                            matRipple
                        >
                        </mat-row>
                    </mat-table>
                    <pagination-controls
                        (pageChange)="p = $event"
                        autoHide="true"
                        responsive="true"
                        previousLabel="Anterior"
                        nextLabel="Siguiente"
                    ></pagination-controls>
                </form>
            </div>-->
        </mat-tab>
    </mat-tab-group>
</div>

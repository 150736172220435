<div id="academy-courses" class="page-layout simple inner-scroll">
    <div fusePerfectScrollbar>
        <!-- HEADER -->
        <div
            class="header accent p-16 p-sm-24"
            fxLayout="column"
            fxLayoutAlign="center center"
        >
            <div class="hero-text">
                <mat-icon class="hero-icon">school</mat-icon>
                <h1
                    [@animate]="{
                        value: '*',
                        params: { delay: '100ms', y: '25px' }
                    }"
                >
                    Examenes
                </h1>
            </div>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT -->
        <div class="content p-24">
            <div fxLayout="column" fxLayoutAlign="center">
                <div
                    class="courses"
                    fxLayout="row wrap"
                    fxLayoutAlign="center"
                    [@animateStagger]="{ value: '50' }"
                >
                    <!--INICIO DEL CURSO 1-->
                    <div
                        class="course"
                        fxFlex="100"
                        fxFlex.gt-xs="50"
                        fxFlex.gt-sm="33"
                        [@animate]="{ value: '*', params: { y: '100%' } }"
                        *ngFor="let examen of examenes"
                    >
                        <div
                            (click)="
                                cargar_examen(
                                    examen.ID_EXAMEN,
                                    examen.DURACION,
                                    examen.TEMA,
                                    examen.ID_PERIODO_LETIVO,
                                    examen.ID_OFERTA_DISCIPLINA,
                                    examen.NOMBRE_DISCIPLINA,
                                    examen.NOTA
                                )
                            "
                            mat-button
                            color="accent"
                            class="course-content"
                            fxLayout="column"
                            fxFlex="1 1 auto"
                        >
                            <div
                                class="header"
                                fxLayout="row"
                                fxLayoutAlign="center center"
                            >
                                <div
                                    class="length"
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                >
                                    <mat-icon
                                        class="length-icon s-20"
                                        style="color: rgba(255, 255, 255, 0.75)"
                                        >school
                                    </mat-icon>
                                    <div
                                        style="color: rgba(255, 255, 255, 0.75)"
                                    >
                                        {{ examen.NOMBRE_DISCIPLINA }}
                                    </div>
                                </div>
                            </div>

                            <div
                                class="content"
                                fxLayout="column"
                                fxLayoutAlign="center center"
                                fxFlex="1 1 auto"
                            >
                                <div class="h1">Tema : {{ examen.TEMA }}</div>
                                <div class="h1">
                                    Habilitado:
                                    {{
                                        examen.FECHA_INICIO | date: "dd/MM/yyyy"
                                    }}
                                    {{ examen.HORA_INICIO }}
                                </div>
                                <div class="h1">
                                    Hasta:
                                    {{ examen.FECHA_FIN | date: "dd/MM/yyyy" }}
                                    {{ examen.HORA_FIN }}
                                </div>
                                <div
                                    style="margin-top: 10px"
                                    class="length"
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                >
                                    <mat-icon
                                        class="length-icon s-20"
                                        style="margin-right: 5px"
                                        >access_alarm
                                    </mat-icon>
                                    <div class="h1">
                                        {{ examen.DURACION }} min
                                    </div>
                                </div>

                                <div
                                    class="length"
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                >
                                    <mat-icon
                                        class="length-icon s-20"
                                        style="margin-right: 5px"
                                        >star_border
                                    </mat-icon>
                                    <div class="h1">
                                        {{
                                            !examen.NOTA && examen.NOTA !== 0
                                                ? "_"
                                                : examen.NOTA
                                        }}
                                        /{{ examen.CANTIDAD_PREGUNTAS }}
                                    </div>
                                </div>

                                <div
                                    class="length"
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                    style="color: green"
                                    [fxHide]="!examen.STATUS"
                                >
                                    <mat-icon
                                        class="length-icon s-20"
                                        style="margin-right: 5px"
                                        >check_circle_outline
                                    </mat-icon>
                                    <div class="h1">Rendido</div>
                                </div>

                                <!--  <div class="updated">Updated {{course.updated}}</div>-->
                            </div>

                            <div
                                class="footer"
                                fxLayout="row"
                                fxLayoutAlign="center center"
                            >
                                <button
                                    (click)="
                                        cargar_examen(
                                            examen.ID_EXAMEN,
                                            examen.DURACION,
                                            examen.TEMA,
                                            examen.ID_PERIODO_LETIVO,
                                            examen.ID_OFERTA_DISCIPLINA,
                                            examen.NOMBRE_DISCIPLINA,
                                            examen.NOTA
                                        )
                                    "
                                    mat-button
                                    color="accent"
                                >
                                    {{
                                        !examen.STATUS
                                            ? "Iniciar"
                                            : "Ver Examen"
                                    }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <!--FIN DEL CURSO 1-->
                </div>
            </div>
        </div>
    </div>
</div>

<div id="academy-courses" class="page-layout simple inner-scroll">
    <!-- HEADER -->
    <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="center center">
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1 [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }">
                Lista de Preguntas
            </h1>
        </div>
    </div>

    <div class="content mt-16" fxLayout="row" fusePerfectScrollbar>
        <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
            <mat-form-field fxLayoutAling="center center" appearance="outline" fxFlex="60" class="pr-4">
                <mat-label>Buscar</mat-label>
                <input [(ngModel)]="term" matInput [ngModelOptions]="{ standalone: true }" />
            </mat-form-field>

            <!--ACA EMPIEZA LA TABLA-->

            <mat-table fxFlex="1 0 auto" class="products-table" #table [dataSource]="listaPreguntas | filter: term"
                [@animateStagger]="{ value: '50' }" matSort>
                <!-- Columna Index -->
                <ng-container matColumnDef="index">
                    <mat-header-cell fxFlex="8" fxLayoutAlign="start" *matHeaderCellDef>#
                    </mat-header-cell>
                    <mat-cell fxFlex="8" fxLayoutAlign="start" *matCellDef="let lista; let i = index">
                        <p class="text-truncate">{{ i + 1 }}.</p>
                    </mat-cell>
                </ng-container>

                <!-- Columna PTRGUNTAS -->
                <ng-container matColumnDef="preguntas">
                    <mat-header-cell fxFlex="78" fxLayoutAlign="start" *matHeaderCellDef>Enunciado
                    </mat-header-cell>
                    <mat-cell fxFlex="78" fxLayoutAlign="start" *matCellDef="let lista">
                        <p class="text-truncate" style="white-space: normal">
                            {{ lista?.ENUNCIADO }}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Columna ACCIONES -->
                <ng-container matColumnDef="accion">
                    <mat-header-cell fxFlex="15" fxLayoutAlign="start" *matHeaderCellDef>Acciones
                    </mat-header-cell>
                    <mat-cell fxFlex="15" fxLayoutAlign="start" *matCellDef="let lista">
                        <div fxLayout="column">
                            <button mat-button color="accent" (click)="editarPregunta(lista)">
                                Editar
                            </button>
                            <button mat-button color="warn" (click)="eliminarPregunta(lista)">
                                Eliminar
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                <mat-row *matRowDef="let product; columns: displayedColumns" class="product" matRipple>
                </mat-row>
            </mat-table>

            <!--<pagination-controls
                    (pageChange)="p = $event"
                    autoHide="true"
                    responsive="true"
                    previousLabel="Anterior"
                    nextLabel="Siguiente"
                ></pagination-controls>-->

            <!--ACA TERMINA LA TABLA-->
        </form>
    </div>
</div>
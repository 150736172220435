import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import {
    FormBuilder,
    Validators,
    FormGroup,
    FormControl,
} from "@angular/forms";
import { ListadoContenido } from "../administradores.modal";
import { MatDialog } from "@angular/material/dialog";
import { ModalEditarComponent } from "./modal-editar/modal-editar.component";

@Component({
    selector: "app-carga-de-contenido",
    templateUrl: "./carga-de-contenido.component.html",
    styleUrls: ["./carga-de-contenido.component.scss"],
    animations: fuseAnimations,
})
export class CargaDeContenidoComponent implements OnInit {
    contenido: any;
    editorStyle = {
        height: "500px",
    };
    configuracion = {
        toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote" /*"code-block"*/],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            //["clean"], // remove formatting button

            [, /*"link"*/ "image" /*"video"*/], // link and image, video
        ],
    };

    displayedColumns: string[] = ["Materia", "Unidad", "Sede", "Acciones"];
    source: string;
    imageFile: File;
    form: FormGroup;
    dataSource: ListadoContenido[] = [
        {
            ID: 1,
            MATERIA: "Biologia",
            UNIDAD: 5,
        },
        {
            ID: 2,
            MATERIA: "Quimica",
            UNIDAD: 1,
        },
        {
            ID: 3,
            MATERIA: "Anatomia",
            UNIDAD: 4,
        },
    ];

    @Output() onChange: EventEmitter<File> = new EventEmitter<File>();


    constructor(private _formBuilder: FormBuilder, public dialog: MatDialog) {}

    ngOnInit(): void {
        // Reactive Form
        this.form = this._formBuilder.group({
            materia: ["", Validators.required],
            unidad: ["", Validators.required],
            link: ["", Validators.required],
            respuesta: ["", Validators.required],
            titulo: ["", Validators.required],
            respuesta1: ["", Validators.required],
            respuesta2: ["", Validators.required],
            respuesta3: [""],
            respuesta4: [""],
            respuesta5: [""],
            sede: ["", Validators.required],
            contenidoUnidad: ["", Validators.required],
        });
    }

    // If the input has changed(file picked) we project the file into the img previewer
    updateSource($event: Event) {
        // We access he file with $event.target['files'][0]
        this.projectImage($event.target["files"][0]);
    }

    // Uses FileReader to read the file from the input
    projectImage(file: File) {
        let reader = new FileReader();
        // TODO: Define type of 'e'
        reader.onload = (e: any) => {
            // Simply set e.target.result as our <img> src in the layout
            this.source = e.target.result;
            this.onChange.emit(file);
        };
        // This will process our file and get it's attributes/data
        reader.readAsDataURL(file);
        this.imageFile = file;

        this.form = new FormGroup({
            editor: new FormControl(null),
        });
    }

    agregarContenido() { }


    openDialog() {
        const dialogRef = this.dialog.open(ModalEditarComponent, {
            data: {
                form: this.form,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            //console.log(`Dialog result: ${result}`);
        });
    }


    eliminarContenido() { }

    enviar() {

        console.log(this.form.value);

    }
}

<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Unidades Cargadas
            </h1>
        </div>
    </div>

    <div class="content p-12">
        <div
            class="mb-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxLayout.gt-md="row"
            id="products"
        >
            <form
                class="mat-card mat-elevation-z4 p-24 mr-24"
                fxLayout="column"
                fxLayoutAlign="start"
                fxFlex="1 0 auto"
                name="form"
                [formGroup]="form"
            >
                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <!-- ACA EMPIEZA SELECCIONAR SEMESTRE-->
                    <mat-form-field appearance="outline" fxFlex="33">
                        <mat-label>Semestres</mat-label>
                        <mat-select required formControlName="semestre">
                            <mat-option
                                *ngFor="let semestre of listaSemestre"
                                [value]="semestre.id"
                                (click)="getMaterias(semestre.id)"
                            >
                                {{ semestre.nome }}
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="secondary-text"
                            >outlined_flag</mat-icon
                        >
                        <mat-error>Por favor elija una sede.</mat-error>
                    </mat-form-field>
                    <!-- ACA TERMINA SELECCION MATERIA-->

                    <!-- ACA EMPIEZA SELECCIONAR MATERIA-->
                    <mat-form-field
                        appearance="outline"
                        fxFlex="34"
                        class="pl-4"
                    >
                        <mat-label>Materias</mat-label>
                        <mat-select required formControlName="materia">
                            <mat-option
                                *ngFor="let materia of listaMateriaSemestre"
                                [value]="materia.id"
                                (click)="getProfesores(materia.id)"
                            >
                                {{ materia.nome }}
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="secondary-text"
                            >outlined_flag</mat-icon
                        >
                        <mat-error>Por favor elija una materia.</mat-error>
                    </mat-form-field>
                    <!-- ACA TERMINA SELECCION MATERIA-->

                    <!-- ACA EMPIEZA SELECCIONAR PROFESOR-->
                    <mat-form-field
                        appearance="outline"
                        fxFlex="33"
                        class="pl-4"
                    >
                        <mat-label>Profesores</mat-label>
                        <mat-select required formControlName="profesor">
                            <mat-option
                                *ngFor="let profesor of listaProfesores"
                                [value]="profesor.funcionario_id"
                                (onSelectionChange)="
                                    guardarIdProfesor(
                                        profesor.funcionario_id,
                                        profesor.disciplinas_id
                                    )
                                "
                            >
                                {{ profesor.nome_professor }}
                                {{ profesor.sobrenome }}
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="secondary-text"
                            >outlined_flag</mat-icon
                        >
                        <mat-error>Por favor elija un profesor.</mat-error>
                    </mat-form-field>
                    <!-- ACA TERMINA SELECCION PROFESOR-->
                </div>
            </form>
        </div>

        <!-- CONTENT -->
        <div>
            <!-- CONTENT -->
            <div
                class="mb-24"
                fxLayout="column"
                fxLayoutAlign="start"
                fxLayout.gt-md="row"
            >
                <!-- REACTIVE FORM EXAMPLE -->

                <form
                    class="mat-card mat-elevation-z4 p-24 mr-24"
                    fxLayout="column"
                    fxLayoutAlign="start"
                    fxFlex="1 0 auto"
                    name="form"
                >
                    <!--SELECCIONAR MATERIA-->
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxFlex="1 0 auto"
                    >
                        <div
                            style="margin-top: 20px"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                        >
                            <!--ACA EMPIEZA SELECCIONAR EXAMEN-->
                            <!--Materia comentada
                        <mat-form-field
                            appearance="outline"
                            class="pl-4"
                            fxFlex="40"
                        >
                            <mat-label>Materia</mat-label>
                            <mat-select required>
                                <mat-option value="1"> Materia 1 </mat-option>
                                <mat-option value="2"> Materia 2 </mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="secondary-text"
                                >outlined_flag</mat-icon
                            >
                            <mat-error>Por favor elija una materia.</mat-error>
                        </mat-form-field>
                        -->
                            <!--ACA TERMINA SELECCIONAR EXAMEN-->
                            <!--ACA EMPIEZA SELECCIONAR EXAMEN-->
                            <mat-form-field
                                appearance="outline"
                                class="pl-4"
                                fxFlex="40"
                            >
                                <mat-label>Unidad</mat-label>
                                <mat-select
                                    (selectionChange)="
                                        listarPreguntas(id_unidad)
                                    "
                                    required
                                    [(ngModel)]="id_unidad"
                                    [ngModelOptions]="{ standalone: true }"
                                >
                                    <mat-option
                                        *ngFor="
                                            let unidad of listaUnidadesTemporal
                                        "
                                        value="{{ unidad.id }}"
                                    >
                                        {{ unidad.nome }}
                                    </mat-option>
                                </mat-select>
                                <mat-icon matSuffix class="secondary-text"
                                    >outlined_flag</mat-icon
                                >
                                <mat-error
                                    >Por favor elija una unidad.</mat-error
                                >
                            </mat-form-field>
                            <!--ACA TERMINA SELECCIONAR EXAMEN-->
                            <!-- ACA EMPIEZASELECCIONAR MATERIA-->
                            <!--
                        <mat-form-field
                            appearance="outline"
                            class="pl-4"
                            fxFlex="20"
                        >
                            <mat-label>Nro Preguntas</mat-label>
                            <mat-select
                                (selectionChange)="
                                    cambiarDimension($event.value)
                                "
                                required
                                [disabled]="preguntasNuevas.length > 0"
                            >
                                <mat-option value="10"> 10 </mat-option>
                                <mat-option value="15"> 15 </mat-option>
                                <mat-option value="20"> 20 </mat-option>
                                <mat-option value="25"> 25 </mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="secondary-text"
                                >outlined_flag</mat-icon
                            >
                            <mat-error
                                >Por favor elija la cantidad de
                                preguntas.</mat-error
                            >
                        </mat-form-field>
                        -->

                            <!--ACA TERMINA SELECCIONAR MATERIA-->
                        </div>
                        <button
                            mat-fab
                            color="primary"
                            (click)="agregarPregunta()"
                            style="display: block; margin: 5px"
                            aria-label="Agregar nuevo"
                            title="Agregar nueva pregunta"
                        >
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </form>
            </div>

            <div
                class="mb-24"
                fxLayout="column"
                fxLayoutAlign="start"
                fxLayout.gt-md="row"
                *ngFor="let item of preguntasNuevas; let i = index"
            >
                <!-- REACTIVE FORM EXAMPLE -->

                <form
                    class="mat-card mat-elevation-z4 p-24 mr-24"
                    fxLayout="column"
                    fxLayoutAlign="start"
                    fxFlex="1 0 auto"
                    name="form"
                >
                    <div class="h2 mb-24">Preguntas de la Unidad</div>
                    <!--ESTO ES EL PRIMER ROW-->
                    <div
                        class="inputs"
                        fxLayout="row"
                        fxLayoutAlign="start"
                        fxFlex="1 0 auto"
                    >
                        <div class="row" fxFlex="20">
                            <mat-label style="margin-right: 5%; margin-top: 13%"
                                >{{ i + 1 }}.</mat-label
                            >
                            <mat-form-field
                                fxFlex="1 0 auto"
                                appearance="outline"
                                class="pl-8"
                            >
                                <mat-label>Metodo Preguntas</mat-label>
                                <mat-select
                                    [disabled]="item.nueva == false"
                                    title="Seleccione el tipo de pregunta, solo disponible para preguntas nuevas"
                                    required
                                    (selectionChange)="
                                        cambiarMetodoPreguntas(i, $event.value)
                                    "
                                >
                                    <mat-option value="1">
                                        Opcion Multiple
                                    </mat-option>
                                    <mat-option value="2">
                                        Verdaro - Falso
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    >Por favor ingrese el metodo
                                    pregunta.</mat-error
                                >
                            </mat-form-field>
                        </div>

                        <div fxLayout="column" appearance="outline" fxFlex="40">
                            <mat-form-field
                                appearance="outline"
                                fxFlex="40"
                                class="pl-12"
                            >
                                <mat-label>Enunciado</mat-label>
                                <input
                                    matInput
                                    maxlength="4000"
                                    [(ngModel)]="item.enunciado"
                                    [ngModelOptions]="{ standalone: true }"
                                />
                                <mat-hint align="end"
                                    >{{
                                        item.enunciado?.length || 0
                                    }}/4000</mat-hint
                                >
                            </mat-form-field>
                            <div
                                class="inputs"
                                fxLayout="row"
                                fxFlex="1 0 auto"
                            >
                                <!--RESPUESTAS CON RADIO BUTTON-->

                                <mat-radio-group
                                    fxFlex="1 0 auto"
                                    fxLayout="column"
                                    (change)="cambiarRespuesta($event.value, i)"
                                >
                                    <div
                                        fxLayout="row"
                                        *ngFor="
                                            let respuesta of item.respuestas
                                        "
                                    >
                                        <mat-radio-button
                                            fxLayoutAlign
                                            [value]="respuesta.id"
                                            [checked]="respuesta.correcta === 1"
                                        >
                                        </mat-radio-button>
                                        <!--INPUT DE LA RESPUESTA 1-->
                                        <mat-form-field
                                            appearance="outline"
                                            fxFlex="1 0 auto"
                                        >
                                            <mat-label
                                                >Escriba una
                                                respuesta</mat-label
                                            >
                                            <textarea
                                                matInput
                                                required
                                                maxlength="2000"
                                                style="min-height: 50px"
                                                [(ngModel)]="respuesta.texto"
                                                [ngModelOptions]="{
                                                    standalone: true
                                                }"
                                            ></textarea>
                                            <mat-hint align="end"
                                                >{{
                                                    respuesta.texto?.length ||
                                                        0
                                                }}/2000</mat-hint
                                            >
                                        </mat-form-field>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>

                        <mat-form-field
                            appearance="outline"
                            fxFlex="40"
                            class="pl-12"
                        >
                            <mat-label>Explicacion de la respuesta</mat-label>
                            <textarea
                                [(ngModel)]="item.explicacion"
                                matInput
                                required
                                maxlength="2000"
                                style="max-height: 370px; min-height: 370px"
                                [ngModelOptions]="{ standalone: true }"
                            ></textarea>
                            <mat-hint align="end"
                                >{{
                                    item.explicacion?.length || 0
                                }}/2000</mat-hint
                            >
                            <mat-error
                                >Por favor ingrese la explicacion de la
                                respuesta</mat-error
                            >
                        </mat-form-field>
                    </div>
                    <!--
                <div class="row">
                    <input
                    #file
                    type="file"
                    nbButton
                    multiple
                    (change)="upload(file.files)"
                    
                />
                </div>
                -->
                    <!--ACA TERMINA LO QUE PODES TOCAR-->

                    <!--ESTO DE ABAJO NO SE TOCA AMIGO COMENTA SI QUERES PERO NO SE TOCA-->
                    <!--NO CHERAA-->
                    <!--SUSPENDE-->
                    <!--RADIO BUTTONS-->

                    <!--ULTIMO AVISO-->
                </form>
                <div name="menu" class="mat-card" style="padding: 5px; margin-top: 15px;">
                    <button
                        style="display: block; margin: 5px"
                        mat-fab
                        color="primary"
                        aria-label="Eliminar"
                        (click)="quitarPregunta(i)"
                        title="Eliminar pregunta"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button
                        mat-fab
                        color="primary"
                        (click)="agregarPregunta()"
                        style="display: block; margin: 5px"
                        aria-label="Agregar nuevo"
                        title="Agregar nueva pregunta"
                    >
                        <mat-icon>add</mat-icon>
                    </button>
                    <button
                        mat-fab
                        color="primary"
                        style="display: block; margin: 5px"
                        aria-label="Agregar nuevo"
                        title="Guardar las preguntas"
                    >
                        <mat-icon>save</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <button
        (click)="guardarPreguntas()"
        mat-flat-button
        style="
            font-size: 20px;
            height: 60px;
            color: white;
            background-color: green;
            margin: 10px;
        "
    >
        <mat-icon>save</mat-icon> Guardar
    </button>
</div>

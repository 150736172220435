import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatColors } from "@fuse/mat-colors";
import { CalendarEvent } from "angular-calendar";
import Swal from "sweetalert2";
import { fuseAnimations } from "@fuse/animations";

@Component({
    selector: "app-modal-agregar-pregunta",
    templateUrl: "./modal-agregar-pregunta.component.html",
    styleUrls: ["./modal-agregar-pregunta.component.scss"],
    animations: fuseAnimations,
})
export class ModalAgregarPreguntaComponent implements OnInit {
    action: string;
    event: CalendarEvent;
    eventForm: FormGroup;
    metodoPregunta: number;
    eligioMetodoPregunta: boolean = false;
    //Creando el form group de Preguntas
    preguntaForm: FormGroup;
    dialogTitle: string;
    presetColors = MatColors.presets;
    source: string;
    imageFile: File;
    @Output() onChange: EventEmitter<File> = new EventEmitter<File>();

    /**
     * Constructor
     *
     * @param {MatDialogRef<ModalAgregarPreguntaComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<ModalAgregarPreguntaComponent>,
        private _formBuilder: FormBuilder
    ) {}
    /**
     * Create the event form
     *
     * @returns {FormBuilder}
     */
    ngOnInit() {
        this.preguntaForm = this._formBuilder.group({
            enunciado: ["", Validators.required],
            respuesta1: ["", Validators.required],
            respuesta2: ["", Validators.required],
            respuesta3: [""],
            respuesta4: [""],
            respuestaCorrecta: ["", Validators.required],
            //puntos: ["", Validators.required],
            descripcion: ["", Validators.required],
        });
    }

    loading(titulo) {
        Swal.fire({
            title: titulo,

            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

    SelectMetodoPregunta(value) {
        this.loading("Recopilando Datos");
        this.metodoPregunta = +value;
        this.eligioMetodoPregunta = true;
        //  console.log("Eligio el metodo de pregunta", value);
        this.preguntaForm.setValue({
            respuesta1: "",
            respuesta2: "",
            respuesta3: "",
            respuesta4: "",
            respuestaCorrecta: "",
            enunciado: this.preguntaForm.value.enunciado,
            descripcion: this.preguntaForm.value.descripcion,
        });
        //  console.log(this.preguntaForm.value);
        sessionStorage.setItem("metodoPregunta", value);
        Swal.close();
    }

    // If the input has changed(file picked) we project the file into the img previewer
    updateSource($event: Event) {
        // We access he file with $event.target['files'][0]
        this.projectImage($event.target["files"][0]);
    }

    // Uses FileReader to read the file from the input
    projectImage(file: File) {
        let reader = new FileReader();
        // TODO: Define type of 'e'
        reader.onload = (e: any) => {
            // Simply set e.target.result as our <img> src in the layout
            this.source = e.target.result;
            this.onChange.emit(file);
        };
        // This will process our file and get it's attributes/data
        reader.readAsDataURL(file);
        this.imageFile = file;
    }
    verImagen(img) {
        Swal.fire({
            // title: 'Sweet!',
            // text: 'Modal with a custom image.',
            width: 600,
            imageUrl: img,
            imageWidth: 800,
            imageHeight: 400,
            imageAlt: "Custom image",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Eliminar Imagen",
            reverseButtons: true,
            confirmButtonColor: "red",
            focusConfirm: false,
        }).then((result) => {
            if (result.value) {
                this.source = null;
            }
        });
    }
}

import { Component, OnInit } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AlumnosService } from "app/alumnos/alumnos.service";
import { DatosComprobantes } from "app/alumnos/alumnos";
import Swal from "sweetalert2";

@Component({
    selector: "app-confirmar-pago",
    templateUrl: "./confirmar-pago.component.html",
    styleUrls: ["./confirmar-pago.component.scss"],
    animations: fuseAnimations,
})
export class ConfirmarPagoComponent implements OnInit {
    form1: FormGroup;
    form2: FormGroup;
    metodoPago: number;
    datosComprobantes: DatosComprobantes = {};
    descuentos: any = [
        {
            porcentaje: 0,
        },
        {
            porcentaje: 10,
        },
        {
            porcentaje: 12,
        },
        {
            porcentaje: 15,
        },
        {
            porcentaje: 20,
        },
        {
            porcentaje: 30,
        },
        {
            porcentaje: 40,
        },
    ];
    constructor(
        private _Apialumno: AlumnosService,
        private _formBuilder: FormBuilder
    ) {}

    ngOnInit(): void {
        this.loading("Recopilando Datos");

        this.metodoPago = +sessionStorage.getItem("metodoPago");

        this._Apialumno.getDatosComprobantes().subscribe((data) => {
            this.datosComprobantes = data;
            Swal.close();
        });
    }

    loading(titulo) {
        Swal.fire({
            title: titulo,
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }
}

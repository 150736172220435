import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
    iniciar_examen,
    EXAMENDELALUMNO,
    EXAMENESDELALUMNO,
    ENTREGAREXAMEN,
} from "./alumnos";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { Identifiers } from "@angular/compiler/src/render3/r3_identifiers";

@Injectable({
    providedIn: "root",
})
export class AlumnosService {
    public url: string = "http://apiucp.harasgb.com/";
    public urlUCP: string = "https://api.ucpvirtual.com.br/api/";
    constructor(private http: HttpClient, private route: Router) {}
    iniciarExamen(alumno: iniciar_examen) {
        return this.http.post<EXAMENDELALUMNO>(
            this.url + "api/examen/alumno/iniciar",
            alumno
        );
    }

    listarExamenes(id_alumno: number) {
        return this.http.get<EXAMENESDELALUMNO[]>(
            this.url + "api/examenes/alumno/" + id_alumno
        );
    }

    entregarExamen(pregunta: ENTREGAREXAMEN) {
        return this.http.post(this.url + "api/examen/entregar", pregunta);
    }

    verPuntaje(id_examen: number) {
        return this.http.get(this.url + "api/examen/verpuntaje/" + id_examen);
    }

    /*
     *   MATERIAS
     *
     */

    //GET MATERIAS
    getMaterias(): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.get(this.urlUCP + "v4/plataforma/materias", {
            headers: new HttpHeaders().set("Authorization", "bearer " + token),
        });
    }
    //GET UNIDADES MATERIA
    getUnidadesMateria(id_materia): Observable<any> {
        let token = localStorage.getItem("token").toString();

        return this.http.get(
            this.urlUCP + "v4/plataforma/getUnidadesDisciplina/" + id_materia,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    //GET PRESENCIA
    getPresenciaMateria(id_anho, id_materia): Observable<any> {
        let token = localStorage.getItem("token").toString();

        return this.http.get(
            this.urlUCP +
                "v4/plataforma/aluno/assistencia/" +
                id_anho +
                "/" +
                id_materia,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //GET LIVES DE LA UNIDAD
    getLivesUnidad(id_unidad): Observable<any> {
        let token = localStorage.getItem("token").toString();

        return this.http.get(
            this.urlUCP + "new/plataforma/ListarLivesMateria/" + id_unidad,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //GET LIBROS DE DISCIPLINA DE LA MATERIA
    getLibrosDisciplia(id_materia): Observable<any> {
        let token = localStorage.getItem("token").toString();

        return this.http.get(
            "https://api.ucpvirtual.com.br/api/new/plataforma/get_biblioteca_virtual_disciplina/" +
                id_materia,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //GET PDF DE LA UNIDAD
    getPdfUnidad(id_unidad): Observable<any> {
        let token = localStorage.getItem("token").toString();

        return this.http.get(
            "https://api.ucpvirtual.com.br/api/new/plataforma/listar_arquivo_unidade/" +
                id_unidad,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //GET SECCIONES DE LA UNIDAD
    getSeccionesUnidad(id_unidad): Observable<any> {
        let token = localStorage.getItem("token").toString();

        return this.http.get(
            this.urlUCP + "v4/plataforma/GetAulasUnidade/" + id_unidad,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //GET DE LOS TRABAJOS PRACTICOS
    getTrabajosPracticosDeLaMateria(id_materia): Observable<any> {
        let token = localStorage.getItem("token");

        return this.http.get(
            this.urlUCP + "v4/plataforma/aluno/trabalho_praticos/" + id_materia,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //POST DE LA RESPUESTA DE LAS SECCIONES
    postRespuestaSeccion(respuesta): Observable<any> {
        let token = localStorage.getItem("token").toString();

        return this.http.post(
            this.urlUCP + "v4/plataforma/SalvarResposta",
            respuesta,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    /*
     *   EXAMENES
     *
     */

    //GET LISTA DE EXAMENES
    getExamenes(): Observable<any> {
        let token = localStorage.getItem("token");

        return this.http.get(
            this.urlUCP + "v4/plataforma/examenes/lista/alunos/examen",
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //GET EXAMEN DEL ALUMNO
    getExamenAlumno(id_examen): Observable<any> {
        let token = localStorage.getItem("token").toString();

        return this.http.get(
            this.urlUCP + "v4/plataforma/examen/preguntas/aluno/" + id_examen,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //Lista Calificaciones Alumno
    getCalificaciones(): Observable<any> {
        let token = localStorage.getItem("token").toString();

        return this.http.get(
            this.urlUCP + "v4/plataforma/examenes/visualizar/lista/nota/aluno",
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    //POST EXAMEN ALUMNO
    postExamen(examenRealizando): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.post(
            this.urlUCP + "v4/plataforma/examenes/salvar/respostas/aluno",
            examenRealizando,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //GET VER EXAMEN DEL ALUMNO
    getVerExamen(id_examen): Observable<any> {
        let token = localStorage.getItem("token").toString();

        return this.http.get(
            this.urlUCP + "v4/plataforma/examenes/visualizar/" + id_examen,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    /*
     *  PAGOS
     *
     */

    //GET LISTA MATRICULA
    getListaMatricula(): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.get(
            "https://api.ucpvirtual.com.br/api/v2/med/ListaMatricula",
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //GET LISTA SEMESTRES
    getListaSemestres(id_matricula): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.get(
            "https://api.ucpvirtual.com.br/api/v2/med/listaSemestre/" +
                id_matricula,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //GET LISTA PAGOS
    getListaFacturas(id_semestre): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.get(
            "https://api.ucpvirtual.com.br/api/v2/med/ListaFaturas/" +
                id_semestre,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //GET LISTA PEDIDOS
    getListaPedidos(): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.get(
            "https://api.ucpvirtual.com.br/api/v2/med/listaPedidos",
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    //GET COTIZACION
    getCotizacion(): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.get(
            "https://api.ucpvirtual.com.br/api/contacaoChaco",
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    //GET DATOS COMPROBANTES
    getDatosComprobantes(): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.get("https://api.ucpvirtual.com.br/api/me", {
            headers: new HttpHeaders().set("Authorization", "bearer " + token),
        });
    }

    //TRAE LOS TRABAJOS PRACTICOS
    getTrabajosPracticos(id_materia, id_usuario): Observable<any> {
        let token = localStorage.getItem("token");
        return this.http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/aluno/trabalho_praticos/lista/oferta_disciplina/" +
                id_materia,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    //api para enviar los datos del comproobante de pago

    postDatosComprobantes(datosComprobante): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.post(
            "https://api.ucpvirtual.com.br/api/v2/med/Pagamento_boleto",
            datosComprobante,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    //EMPIEZAN APIS PARA ACTUALIZAR DATOS
    getActualizarDatos(): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.get("https://api.ucpvirtual.com.br/api/me", {
            headers: new HttpHeaders().set("Authorization", "bearer " + token),
        });
    }
    getPais(): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.get("https://api.ucpvirtual.com.br/api/v2/med/pais", {
            headers: new HttpHeaders().set("Authorization", "bearer " + token),
        });
    }
    getEstados(pais_id): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.get(
            "https://api.ucpvirtual.com.br/api/v2/med/estados/" + pais_id,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    getCiudad(estados_id): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.get(
            "https://api.ucpvirtual.com.br/api/v2/med/cidades/" + estados_id,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    postDatosUsuarioEditados(): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.post(
            "https://api.ucpvirtual.com.br/api/v2/med/editarUsuario",

            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //TERMINA ACTUALIZAR DATOS

    getTrabajoPracticoAlumno(oferta_disciplina_id): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/aluno/trabalho_praticos/lista/oferta_disciplina/" +
                oferta_disciplina_id,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    getDatosTP(id): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/aluno/trabalho_praticos/" +
                id,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    getComentarioProf(id): Observable<any> {
        let token = localStorage.getItem("token").toString();
        return this.http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/aluno/comentario/trabalho_pratico/" +
                id,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    postTrabajoPractico(trabajo :  any): Observable<any> {
        let token = localStorage.getItem("token").toString();
        const formData: FormData = new FormData();
        formData.append("titulo", trabajo.titulo);
        formData.append("file", trabajo.file);
        formData.append("oferta_disciplina_id", sessionStorage.getItem("id_materia"));
        formData.append("trabalho_id", trabajo.trabalho_id);
        return this.http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/aluno/trabalho_praticos",
            formData,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
}

import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { ListadoSedes } from "../administradores.modal";

@Component({
    selector: "app-crear-sede",
    templateUrl: "./crear-sede.component.html",
    styleUrls: ["./crear-sede.component.scss"],
    animations: fuseAnimations,
})
export class CrearSedeComponent implements OnInit {
    displayedColumns: string[] = [
        "Nombre",
        "Departamento",
        "Direccion",
        "Acciones",
    ];
    formSede: FormGroup;
    dataSource: ListadoSedes[] = [
        {
            ID: 1,
            NOMBRE: "Universidad Central del Paraguay",
            DEPARTAMENTO: "Central",
            DIRECCION: "Brasilia N°1158",
        },
    ];

    constructor(private _formBuilder: FormBuilder) {}

    ngOnInit(): void {
        // Reactive Form
        this.formSede = this._formBuilder.group({
            departamento: ["", Validators.required],
            nombre: ["", Validators.required],
            direccion: ["", Validators.required],
        });
    }

    crearSede() {}

    editarSede() {}

    eliminarSede() {}
}

import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "alumnos",
        title: "Alumnos",
        //translate: "NAV.EXAMENES",
        type: "group",

        children: [
            {
                id: "materias",
                title: "Materias",
                //translate: "NAV.EXAMENES.LIST",
                type: "item",
                icon: "assignment_returned",
                url: "/materias",
            },
            {
                id: "examenes",
                title: "Examenes",
                //translate: "NAV.EXAMENES.LIST",
                type: "item",
                icon: "event_note",
                url: "/examenes",
            },

            {
                id: "calificaciones",
                title: "Calificaciones",
                //translate: "NAV.EXAMENES.LIST",
                type: "item",
                icon: "assignment_turned_in",
                url: "/calificaciones",
            },
            {
                id: "pagos",
                title: "Pagos",
                //translate: "NAV.EXAMENES.LIST",
                type: "item",
                icon: "assignment_turned_in",
                url: "/pagos",
            },
            {
                id: "financiero",
                title: "Financiero",
                //translate: "NAV.EXAMENES.LIST",
                type: "item",
                icon: "assignment_turned_in",
                url: "https://examen.ucpvirtual.com.br/",
                externalUrl: true,
                openInNewTab: true,
            },
        ],
    },
    {
        id: "Profesores",
        title: "Profesores",
        //translate: "NAV.EXAMENES",
        type: "group",
        children: [
            {
                id: "profesor-materias",
                title: "Materias",
                //translate: "NAV.CARGAR.EXAMEN",
                type: "item",
                icon: "class",
                url: "/profesor-materias",
            },
            {
                id: "trabajo-practico",
                title: "Cargar Trabajo Practico",
                //translate: "NAV.CARGAR.EXAMEN",
                type: "item",
                icon: "add_to_queue",
                url: "/cargar-trabajo-practico",
            },
            /* {
                id: "listar-examenes",
                title: "Listar Examenes",
                //translate: "NAV.CARGAR.EXAMEN",
                type: "item",
                icon: "library_books",
                url: "/listar-examenes",
            },
            {
                id: "habilitaciones",
                title: "Habilitaciones",
                //translate: "NAV.CARGAR.EXAMEN",
                type: "item",
                icon: "assignment_turned_in",
                url: "/habilitaciones",
            },
            {
                id: "cargar-puntaje",
                title: "Cargar Puntaje",
                //translate: "NAV.CARGAR.EXAMEN",
                type: "item",
                icon: "insert_chart",
                url: "/cargar-puntaje",
            },*/
        ],
    },
    {
        id: "Administradores",
        title: "Administradores",
        //translate: "NAV.EXAMENES",
        type: "group",
        children: [
            //   {
            //       id: "crear-materia",
            //       title: "Crear Materia",
            //       //translate: "NAV.CARGAR.EXAMEN",
            //       type: "item",
            //       icon: "assignment",
            //       url: "/crear-materia",
            //   },
            //   {
            //       id: "crear-sede",
            //       title: "Crear Sede",
            //       //translate: "NAV.CARGAR.EXAMEN",
            //       type: "item",
            //       icon: "store",
            //       url: "/crear-sede",
            //   },
            //   {
            //       id: "agregar-profesor-a-materia",
            //       title: "Agregar profesor a materia",
            //       //translate: "NAV.CARGAR.EXAMEN",
            //       type: "item",
            //       icon: "group_add",
            //       url: "/agregar-profesor-a-materia",
            //   },
            //   {
            //       id: "carga-de-contenido",
            //       title: "Carga de Contenido",
            //       //translate: "NAV.CARGAR.EXAMEN",
            //       type: "item",
            //       icon: "create_new_folder",
            //       url: "/carga-de-contenido",
            //   },
            //   {
            //       id: "crear-trabajo-practico",
            //       title: "Crear trabajo practico",
            //       //translate: "NAV.CARGAR.EXAMEN",
            //       type: "item",
            //       icon: "assignment_late",
            //       url: "/crear-trabajo-practico",
            //   },
            {
                id: "admin-materias",
                title: "Materias",
                //translate: "NAV.CARGAR.EXAMEN",
                type: "item",
                icon: "assignment_late",
                url: "/admin-materias",
            },
            {
                id: "lista-examenes-admin",
                title: "Lista Examenes",
                //translate: "NAV.CARGAR.EXAMEN",
                type: "item",
                icon: "assignment_late",
                url: "/lista-examenes-admin",
            },
            {
                id: "cargar-examen-admin",
                title: "Cargar Examen",
                //translate: "NAV.CARGAR.EXAMEN",
                type: "item",
                icon: "assignment_late",
                url: "/cargar-examen-admin",
            },
            {
                id: "ver-unidades-cargadas",
                title: "Ver Unidades Cargadas",
                //translate: "NAV.CARGAR.EXAMEN",
                type: "item",
                icon: "assignment_late",
                url: "/ver-unidades-cargadas",
            },
            {
                id: "informe-de-evaluaciones",
                title: "Informe de Evaluaciones",
                //translate: "NAV.CARGAR.EXAMEN",
                type: "item",
                icon: "assignment_late",
                url: "/informe-de-evaluaciones",
            },
        ],
    },
];

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    url: string = "https://api.ucpvirtual.com.br/api/Authenticate";
    isLogin = false;
    roleAs: string;
    constructor(
        private route: Router,
        private _fuseNavigationService: FuseNavigationService,
        private http: HttpClient
    ) { }
    validacion(usuario, password): Observable<any> {
        let user = {
            login: usuario,
            password: password,
        };
        return this.http.post(this.url, user);
    }
    login(status, role, token) {
        this.isLogin = status;
        localStorage.setItem("STATE", status);
        if (role === 1) {
            role = "ROLE_ALUMNO";
            this.route.navigateByUrl("/materias");
            sessionStorage.setItem("mostrarAdvertencia", "1");
        } else if (role === 2) {
            role = "ROLE_PROFESOR";
            this.route.navigateByUrl("/profesor-materias");
        } else if (role === 3) {
            role = "ROLE_ADMIN";
            this.route.navigateByUrl("/admin-materias");
        } else if (role === 4) {
            role = "ROLE_SUPERADMIN";
            this.route.navigateByUrl("/admin-materias");
        }
        localStorage.setItem("ROLE", role);
        localStorage.setItem("token", token);
        return { success: this.isLogin, role: role, token: token };
    }
    logout() {
        this.isLogin = false;
        this.roleAs = "";
        localStorage.removeItem("STATE");
        localStorage.removeItem("ROLE");
        localStorage.removeItem("token");
        localStorage.removeItem("Notificaciones");
        sessionStorage.removeItem("id_unidad");
        sessionStorage.removeItem("link_video");
        sessionStorage.removeItem("porcentage");
        sessionStorage.removeItem("matricula_curso_id");
        sessionStorage.removeItem("id_materia");
        sessionStorage.removeItem("periodo_letivo_id");
        sessionStorage.removeItem("usuario");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("sobrenome");
        sessionStorage.removeItem("id_usuario");

        localStorage.clear();
        sessionStorage.clear();

        this.route.navigateByUrl("/auth");

        return { success: this.isLogin, role: "", token: "" };
    }

    isLoggedIn() {
        const loggedIn = localStorage.getItem("token");
        if (loggedIn !== "") {
            this.isLogin = true;
        } else {
            this.isLogin = false;
        }
        return this.isLogin;
    }

    getRole() {
        this.roleAs = localStorage.getItem("ROLE");
        return this.roleAs;
    }
}

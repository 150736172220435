import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import Swal from "sweetalert2";
import { ExamenesList, InformeDeEvaluaciones } from "../administradores.modal";
import { AdministradoresService } from "../administradores.service";

export interface filtroExamen {
    id?: number;
    texto?: string;
}

@Component({
    selector: "app-informe-de-evaluaciones",
    templateUrl: "./informe-de-evaluaciones.component.html",
    styleUrls: ["./informe-de-evaluaciones.component.scss"],
    animations: fuseAnimations,
})
export class InformeDeEvaluacionesComponent implements OnInit {
    print = false;
    displayedColumns: string[] = [
        "fecha",
        "tipo",
        "sede",
        "semestre",
        "materia",
        "contenidos",
        "profesor",
        "status",
        "avance",
        "acciones",
    ];
    listadoExamenes: ExamenesList[] = [];
    filtradoExamenes: ExamenesList[] = [];
    listaSede: any[] = [];
    p: number = 1;
    form: FormGroup;
    itemsPerPage: number = 5;
    term: string;

    filtroTipoEval: filtroExamen[] = [];
    filtroSemestre: filtroExamen[] = [];
    filtroSede: filtroExamen[] = [];

    filtradoSeleccionados = [];

    informeDeEvaluaciones: InformeDeEvaluaciones[] = [];

    constructor(
        private administradoresService: AdministradoresService,
        private _formBuilder: FormBuilder,
        private route: Router
    ) {}

    ngOnInit(): void {
        // Reactive Form
        this.form = this._formBuilder.group({
            sede: ["", Validators.required],
            semestre: ["", Validators.required],
            materia: ["", Validators.required],
        });
        this.listaSede = [
            {
                ID: 1,
                NOMBRE_SEDE: "CDE LAGO",
            },
            {
                ID: 2,
                NOMBRE_SEDE: "PJC",
            },
            {
                ID: 3,
                NOMBRE_SEDE: "CDE JESUITICA",
            },
        ];

        this.administradoresService
            .getInformeDeEvaluaciones()
            .subscribe((data) => {
                this.informeDeEvaluaciones = data;
                console.log("se llamo a la api", this.informeDeEvaluaciones);
            });
    }

    loading(titulo) {
        Swal.fire({
            title: titulo,
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

    filtrosSeleccionados = [];
    filtrado2(valor, propiedad) {
        //Logica del vector de filtros

        if (this.filtrosSeleccionados.length == 0) {
            this.filtrosSeleccionados.push({
                value: valor,
                propiedad: propiedad,
            });
        } else {
            if (
                this.filtrosSeleccionados.filter(
                    (x) => x.value == valor && x.propiedad == propiedad
                ).length > 0
            ) {
                let posicion = -1;
                let i = 0;
                for (let eliminar of this.filtrosSeleccionados) {
                    if (
                        eliminar.value == valor &&
                        eliminar.propiedad == propiedad
                    ) {
                        posicion = i;
                    }
                    i++;
                }
                this.filtrosSeleccionados.splice(posicion, 1);
            } else {
                this.filtrosSeleccionados.push({
                    value: valor,
                    propiedad: propiedad,
                });
            }
        }

        //Vector de filtros con datos correctos
        if (this.filtrosSeleccionados.length > 0) {
            //Si tenemos filtros

            //Preparar para los filtros internos
            let cantidadTipo = this.filtrosSeleccionados.filter(
                (x) => x.propiedad == "NOMBRE_TIPO_EXAMEN"
            ).length;
            let cantidadSede = this.filtrosSeleccionados.filter(
                (x) => x.propiedad == "NOMBRE_FILIAL"
            ).length;
            let cantidadSemestre = this.filtrosSeleccionados.filter(
                (x) => x.propiedad == "nome_semestre"
            ).length;

            console.log("Esots son los filtros", this.filtrosSeleccionados);
            console.log(
                "Cantidades ",
                cantidadTipo,
                cantidadSede,
                cantidadSemestre
            );

            //Agregar todos los filtros de una
            this.filtradoExamenes = this.listadoExamenes.filter((x) => {
                let pasa = false;

                let cumpleTipo = false;
                let cumpleSede = false;
                let cumpleSemestre = false;

                if (cantidadTipo == 0) {
                    cumpleTipo = true;
                }
                if (cantidadSede == 0) {
                    cumpleSede = true;
                }
                if (cantidadSemestre == 0) {
                    cumpleSemestre = true;
                }

                for (let filtro of this.filtrosSeleccionados) {
                    if (x[filtro.propiedad] == filtro.value) {
                        if (filtro.propiedad == "nome_semestre") {
                            cumpleSemestre = true;
                        }
                        if (filtro.propiedad == "NOMBRE_FILIAL") {
                            cumpleSede = true;
                        }
                        if (filtro.propiedad == "NOMBRE_TIPO_EXAMEN") {
                            cumpleTipo = true;
                        }
                    }
                }
                return cumpleTipo && cumpleSede && cumpleSemestre;
            });
        } else {
            this.filtradoExamenes = this.listadoExamenes;
        }
    }

    verEvaluacion(id_examen) {
        sessionStorage.setItem("id_examen", id_examen);
        this.route.navigateByUrl("/evaluaciones");
    }

    descargarEvaluacion() {}

    sortData(sort: MatSort) {
        console.log("Se llama al sort", sort);
        const data = this.informeDeEvaluaciones.slice();
        if (!sort.active || sort.direction === "") {
            this.informeDeEvaluaciones = data;
            return;
        }
        this.informeDeEvaluaciones = data.sort((a, b) => {
            const isAsc = sort.direction === "asc";
            switch (sort.active) {
                case "FECHA_FIN":
                    return this.compare(a.FECHA_FIN, b.FECHA_FIN, isAsc);
                case "NOMBRE_TIPO_EXAMEN":
                    return this.compare(
                        a.NOMBRE_TIPO_EXAMEN,
                        b.NOMBRE_TIPO_EXAMEN,
                        isAsc
                    );
                case "NOMBRE_FILIAL":
                    return this.compare(
                        a.NOMBRE_FILIAL,
                        b.NOMBRE_FILIAL,
                        isAsc
                    );
                case "nome_semestre":
                    return this.compare(
                        a.nome_semestre,
                        b.nome_semestre,
                        isAsc
                    );
                case "nome":
                    return this.compare(a.nome, b.nome, isAsc);
                case "NOMBRE":
                    return this.compare(a.NOMBRE, b.NOMBRE, isAsc);
                case "status":
                    return this.compare(a.status, b.status, isAsc);

                default:
                    return 0;
            }
        });
    }
    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}

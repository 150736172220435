import { Component, ViewEncapsulation } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';

// importar locales
import localePy from '@angular/common/locales/es-PY';
registerLocaleData(localePy, 'es');

@Component({
    selector: 'quick-panel',
    templateUrl: './quick-panel.component.html',
    providers: [{ provide: LOCALE_ID, useValue: 'es' }],
    styleUrls: ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent {


    date: Date;
    events: any[];
    notes: any[];
    settings: any;

    /**
     * Constructor
     */
    constructor() {
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud: false,
            retro: true
        };
        /*
        this.events = [
            {
                title: 'Habilitado ',
                detail: 'Examen de Histologia 1'
            },
            {
                title: 'Habilitado',
                detail: 'Examen de Historia de la Medicina 1'
            },
            {
                title: 'Habilitado',
                detail: 'Examen de Anatomia 2'
            },
            {
                title: 'Faltante ',
                detail: 'Trabajo Practico de Bioquimica 1'
            }
        ]*/

    }



}

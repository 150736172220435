import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { AdministradoresService } from "../administradores.service";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import {
    Respuesta,
    ListaUnidades,
    ListaSedes,
    Pregunta,
    Materias,
    SemestresMateria,
    UnidadesMateria,
    TipoExamen,
    getUnidadesTemporal,
} from "../administradores.modal";
import { isUndefined } from "lodash";
import { fuseAnimations } from "@fuse/animations";
import { DatePipe } from "@angular/common";
import { DateAdapter } from '@angular/material/core';

@Component({
    selector: "app-cargar-examen-admin",
    templateUrl: "./cargar-examen-admin.component.html",
    styleUrls: ["./cargar-examen-admin.component.scss"],
    providers: [DatePipe],
    animations: fuseAnimations,
})
export class CargarExamenAdminComponent implements OnInit {
    public allUnits: boolean = false;
    public habilitarPor: string;
    public endDatePicker: any;
    unidadesExamen = new FormControl();

    //  unidades: ListaUnidades[] = [];
    // pregunta: Pregunta = {};
    //   respuesta1: Respuesta = {};
    //  respuesta2: Respuesta = {};
    // respuesta3: Respuesta = {};
    // respuesta4: Respuesta = {};
    // dialogRef: any;
    // form: FormGroup;
    crearExamen: FormGroup;
    //id_examen: any;
    //examen_creado: any;
    listaSede: ListaSedes[] = [];
    listaMateriasReal: Materias[] = [];
    listaSemestreReal: SemestresMateria[] = [];
    listaUnidadesReal: UnidadesMateria[] = [];
    listaTipoExamenReal: TipoExamen[] = [];
    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _formBuilder: FormBuilder,
        private administradoresService: AdministradoresService,
        private datePipe: DatePipe,
        private _adapter: DateAdapter<any>
    ) {}

    ngOnInit(): void {

      //  this._adapter.setLocale('es');
        // Reactive Form
        this.crearExamen = this._formBuilder.group({
            id_sede: ["", Validators.required],
            oferta_disciplina_id: ["", Validators.required],
            id_semestre: ["", Validators.required],
            tipo_examen_id: ["", Validators.required],
            total: [0, Validators.required],
            tema: ["", Validators.required],
            oportunidades: ["", Validators.required],
            por_fecha: [true, Validators.required],
            fecha_inicio: [""],
            duracion: ["", Validators.required],
            cantidad_pregunta: ["", Validators.required],
            id_unidad_terminada: [""],
            hora_inicio: [""],
            hora_fin: ["", Validators.required],
            fecha_fin: ["", Validators.required],
        });
        this.listaSede = [
            {
                ID: 1,
                NOMBRE_SEDE: "CDE LAGO",
            },
            {
                ID: 2,
                NOMBRE_SEDE: "PJC",
            },
            {
                ID: 3,
                NOMBRE_SEDE: "CDE JESUITICA",
            },
        ];
        this.getTipoExamen();
    }

    agarrarUnidades(unidades) {
        console.log("Unidades Para el Examen", this.unidadesExamen.value);
    }

    AllUnits() {
        this.allUnits = !this.allUnits;
    }
    getHabilitadoPor(event) {
        console.log("Habilitado por", event);
        this.habilitarPor = event;
    }

    getSemetres(id_sede) {
        this.loading("Cargando Semestres");
        this.administradoresService.getListadoSemestres(id_sede).subscribe(
            (response) => {
                this.listaSemestreReal = response;
                console.log("Se llamo correctamente a get Semestres");
                console.log("Estos son los datos", response);
                Swal.close();
            },
            (error) => {
                console.log("Hubo un problema al llamar la api");
            }
        );
    }

    getMaterias(id_semestre) {
        this.loading("Cargando Materias");
        this.administradoresService.getListaMaterias(id_semestre).subscribe(
            (data) => {
                this.listaMateriasReal = data;
                console.log("Se llamo correctamente a get materias");
                console.log("Estos son los datos", data);
                Swal.close();
            },
            (error) => {
                console.log("Hubo un problema al llamar a la api");
            }
        );
    }

    getUnidades(id_materia) {
        this.loading("Cargando Unidades");
        console.log("Este id me paso", id_materia);
        this.administradoresService
            .getListaUnidadesCrearExamen(id_materia)
            .subscribe((data) => {
                console.log("Unidades de la Materia", data);
                this.listaUnidadesReal = data["data"];
                Swal.close();
            });
    }
    getTipoExamen() {
        this.administradoresService.getTipoExamen().subscribe((response) => {
            console.log("Tipo examen", response);
            this.listaTipoExamenReal = response;
        });
    }

    
    
    postCrearExamen() {
        
        this.loading("Creando Examen");
        if (this.crearExamen.valid) {
            if (
                this.habilitarPor === "fecha" &&
                !isUndefined(this.crearExamen.value.fecha_inicio._i.date)
            ) {
                this.crearExamen.value.por_fecha = true;
                this.crearExamen.value.fecha_inicio =
                    this.crearExamen.value.fecha_inicio._i.year +
                    "/" +
                    (this.crearExamen.value.fecha_inicio._i.month + 1).toString() +
                    "/" +
                    this.crearExamen.value.fecha_inicio._i.date;

                //Para fecha fin
                this.crearExamen.value.fecha_fin =
                    this.crearExamen.value.fecha_fin._i.year +
                    "/" +
                    (this.crearExamen.value.fecha_fin._i.month + 1).toString() +
                    "/" +
                    this.crearExamen.value.fecha_fin._i.date;
                this.crearExamen.value.id_unidad_terminada = null;
                // console.log("Por fecha", this.crearExamen.value.por_fecha);
                this.administradoresService
                    .postCrearExamen(this.crearExamen.value)
                    .subscribe(
                        (response) => {
                            let unidadExamen = {
                                id_unidad: 0,
                                id_examen: response,
                            };
                            for (let unidad of this.unidadesExamen.value) {
                                unidadExamen.id_unidad = unidad;
                                this.administradoresService
                                    .postUnidadExamenes(unidadExamen)
                                    .subscribe((response) => {
                                        console.log(
                                            "Se hizo el post  la unidad",
                                            unidadExamen
                                        );
                                    });
                            }
                            Swal.close();
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "El examen se ha creado con exito!",
                                showConfirmButton: false,
                                timer: 1500,
                            });
                            this.crearExamen.reset();
                        },
                        (err) => {
                            Swal.fire(
                                "Upps",
                                "Hubo un problema con el servidor",
                                "error"
                            );
                        }
                    );
            } else if (this.habilitarPor === "unidad") {
                this.crearExamen.value.por_fecha = false;
                //Para fecha fin
                this.crearExamen.value.fecha_inicio = this.datePipe.transform(
                    new Date(),
                    "yyyy/MM/dd"
                );
                this.crearExamen.value.fecha_fin =
                    this.crearExamen.value.fecha_fin._i.year +
                    "/" +
                    (this.crearExamen.value.fecha_fin._i.month + 1).toString() +
                    "/" +
                    this.crearExamen.value.fecha_fin._i.date;
                this.administradoresService
                    .postCrearExamen(this.crearExamen.value)
                    .subscribe(
                        (response) => {
                            let unidadExamen = {
                                id_unidad: 0,
                                id_examen: response,
                            };
                            for (let unidad of this.unidadesExamen.value) {
                                unidadExamen.id_unidad = unidad;
                                this.administradoresService
                                    .postUnidadExamenes(unidadExamen)
                                    .subscribe((response) => {
                                        console.log(
                                            "Se hizo el post  la unidad",
                                            unidadExamen
                                        );
                                    });
                            }
                            Swal.close();
                            this.crearExamen.reset();
                            this.unidadesExamen = new FormControl();
                        },
                        (err) => {
                            Swal.fire(
                                "Upps",
                                "Hubo un problema con el servidor",
                                "error"
                            );
                        }
                    );
            } else {
                Swal.fire("Favor complete campos", "Revise la fecha", "error");
            }
        } else {
            Swal.fire("Favor complete campos", "Revise la fecha", "error");
        }
    }
    
    loading(titulo) {
        Swal.fire({
            title: titulo,

            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

    verFecha() {
        console.log(this.crearExamen.value.fecha_inicio);
    }
}

<div id="academy-course" class="page-layout simple left-sidebar inner-scroll">
    <!-- SIDEBAR -->
    <fuse-sidebar
        class="sidebar"
        name="academy-course-left-sidebar-1"
        position="left"
        lockedOpen="gt-md"
    >
        <!-- SIDEBAR CONTENT -->
        <div class="content" fusePerfectScrollbar>
            <div class="steps">
                <div
                    type="button"
                    class="step"
                    *ngFor="
                        let seccion of seccionesUnidad;
                        let i = index;
                        let last = last;
                        let first = first
                    "
                    (click)="gotoStep(i, target)"
                    [ngClass]="{
                        current: currentStep === i,
                        completed: currentStep > i,
                        last: last,
                        first: first
                    }"
                >
                    <div class="index">
                        <span>{{ i + 1 }}</span>
                    </div>

                    <div style="font-size: 10px" class="title">
                        {{ seccion.titulo }}
                    </div>
                </div>
            </div>
        </div>
        <!-- / SIDEBAR CONTENT -->
    </fuse-sidebar>
    <!-- / SIDEBAR -->

    <!-- CENTER -->
    <div class="center">
        <!-- HEADER -->
        <div
            class="header accent p-24"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <button
                mat-icon-button
                class="sidebar-toggle mr-16"
                fxHide.gt-md
                (click)="toggleSidebar('academy-course-left-sidebar-1')"
            >
                <mat-icon>menu</mat-icon>
            </button>

            <button
                mat-icon-button
                class="mr-16"
                [routerLink]="'/unidades-materia'"
            >
                <mat-icon>arrow_back</mat-icon>
            </button>

            <div fxFlex="100">
                <h2 style="font-size: 15px">
                    {{ seccionesUnidad[currentStep]?.titulo }}
                </h2>
            </div>
            <div fxFlex="50">
                <p style="margin-left: 48%">{{ porcentage }}%</p>
                <mat-progress-bar
                    mode="determinate"
                    [value]="porcentage"
                    color="warn"
                >
                </mat-progress-bar>
            </div>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT -->
        <div id="course-content" class="content">
            <ng-container>
                <div
                    class="course-step"
                    fusePerfectScrollbar
                    [@slideIn]="animationDirection"
                >
                    <div #target></div>

                    <div *ngIf="terminoSanitizer">
                        <div
                            id="course-step-content"
                            class="course-step-content"
                        >
                            <!--ACA VA EL VIDEO-->

                            <div fxLayoutAlign="center">
                                <div class="container">
                                    <iframe
                                        class="responsive-iframe"
                                        [src]="
                                            seccionesUnidad[currentStep]
                                                ?.url_video
                                        "
                                        frameborder="0"
                                        allowfullscreen
                                        allow="autoplay; encrypted-media"
                                    ></iframe>
                                </div>
                            </div>

                            <!--ACA TERMINA EL VIDEO-->

                            <!--ACA EMPIEZA EL CONTENIDO-->

                            <div
                                class="ql-container ql-snow"
                                style="border-width: 0"
                            >
                                <div
                                    class="ql-editor"
                                    [innerHTML]="
                                        seccionesUnidad[currentStep].texto
                                    "
                                ></div>
                            </div>

                            <!--ACA TERMINA EL CONTENIDO-->

                            <!--ACA EMPIEZA LA PREGUNTA-->
                            <h1>
                                {{ seccionesUnidad[currentStep]?.pergunta }}
                            </h1>

                            <mat-radio-group
                                class="example-radio-group"
                                [(ngModel)]="respuestas[currentStep].respuesta"
                                [disabled]="
                                    seccionesUnidad[currentStep].resposta
                                "
                            >
                                <mat-radio-button
                                    class="example-radio-button"
                                    style="display: block"
                                    value="resposta_1"
                                >
                                    <p
                                        style="
                                            margin: 5px;
                                            white-space: normal !important;
                                        "
                                    >
                                        {{
                                            seccionesUnidad[currentStep]
                                                .resposta_1
                                        }}
                                    </p>
                                </mat-radio-button>
                                <mat-radio-button
                                    class="example-radio-button"
                                    style="display: block"
                                    value="resposta_2"
                                >
                                    <p
                                        style="
                                            margin: 5px;
                                            white-space: normal !important;
                                        "
                                    >
                                        {{
                                            seccionesUnidad[currentStep]
                                                .resposta_2
                                        }}
                                    </p>
                                </mat-radio-button>
                                <mat-radio-button
                                    class="example-radio-button"
                                    style="display: block"
                                    value="resposta_3"
                                    *ngIf="
                                        seccionesUnidad[currentStep].resposta_3
                                    "
                                >
                                    <p
                                        style="
                                            margin: 5px;
                                            white-space: normal !important;
                                        "
                                    >
                                        {{
                                            seccionesUnidad[currentStep]
                                                .resposta_3
                                        }}
                                    </p>
                                </mat-radio-button>
                                <mat-radio-button
                                    class="example-radio-button"
                                    style="display: block"
                                    value="resposta_4"
                                    *ngIf="
                                        seccionesUnidad[currentStep].resposta_4
                                    "
                                >
                                    <p
                                        style="
                                            margin: 5px;
                                            white-space: normal !important;
                                        "
                                    >
                                        {{
                                            seccionesUnidad[currentStep]
                                                .resposta_4
                                        }}
                                    </p>
                                </mat-radio-button>
                                <mat-radio-button
                                    class="example-radio-button"
                                    style="display: block"
                                    value="resposta_5"
                                    *ngIf="
                                        seccionesUnidad[currentStep].resposta_5
                                    "
                                >
                                    <p
                                        style="
                                            margin: 5px;
                                            white-space: normal !important;
                                        "
                                    >
                                        {{
                                            seccionesUnidad[currentStep]
                                                .resposta_5
                                        }}
                                    </p>
                                </mat-radio-button>
                            </mat-radio-group>
                            <div fxLayoutAlign="center" style="margin-top: 3%">
                                <button
                                    [disabled]="
                                        seccionesUnidad[currentStep].resposta
                                    "
                                    mat-raised-button
                                    color="accent"
                                    (click)="
                                        gotoNextStep(
                                            target,
                                            true,
                                            seccionesUnidad[currentStep].correta
                                        )
                                    "
                                >
                                    Enviar
                                    <mat-icon style="margin-left: 10%"
                                        >chevron_present_to_all</mat-icon
                                    >
                                </button>
                            </div>

                            <br />
                            <br />
                            <br />
                            <br />
                            <!--ACA TERMINA LA PREGUNTA-->
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- / CONTENT -->

        <div class="step-navigation">
            <button
                mat-fab
                class="previous mat-accent fuse-white-fg"
                (click)="gotoPreviousStep(target)"
                [disabled]="currentStep === 0"
                [fxHide]="currentStep === 0"
            >
                <mat-icon>expand_less</mat-icon>
            </button>

            <button
                mat-fab
                class="next mat-accent fuse-white-fg"
                (click)="
                    gotoNextStep(
                        target,
                        false,
                        seccionesUnidad[currentStep].correta
                    )
                "
                [disabled]="
                    currentStep === seccionesUnidad.length - 1 ||
                    !respuestas[currentStep]?.respuesta
                "
                [fxHide]="currentStep === seccionesUnidad.length - 1"
            >
                <mat-icon>expand_more</mat-icon>
            </button>

            <button
                mat-fab
                class="done green-600"
                [disabled]="
                    currentStep !== seccionesUnidad.length - 1 ||
                    !respuestas[currentStep].respuesta
                "
                [fxShow]="currentStep === seccionesUnidad.length - 1"
                (click)="unidadCompletada()"
            >
                <mat-icon>check</mat-icon>
            </button>
        </div>
    </div>
    <!-- / CENTER -->
</div>

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import Swal from "sweetalert2";
import {
    ExamenesList,
    ListaMateriasProfesor,
    ListaProfesores,
    Materias,
    SemestresMateria,
} from "../administradores.modal";
import { AdministradoresService } from "../administradores.service";

@Component({
    selector: "app-materias-admin",
    templateUrl: "./materias-admin.component.html",
    styleUrls: ["./materias-admin.component.scss"],
    animations: fuseAnimations,
})
export class MateriasAdminComponent implements OnInit {
    form: FormGroup;
    listaSede: any[] = [];
    dialogRef: any;
    term2: string
    listaSemestreReal: SemestresMateria[] = [];
    materias: ListaMateriasProfesor[] = [];
    semestres: string[] = [];
    id_semestre: number;
    profesor_id: number;
    listaMateriasReal: Materias[] = [];
    listaProfesores: ListaProfesores[] = [];
    metodo: number;

    constructor(
        private administradoresService: AdministradoresService,
        private _formBuilder: FormBuilder,
        private _matDialog: MatDialog,
        private route: Router
    ) { }

    ngOnInit(): void {
        // Reactive Form
        this.form = this._formBuilder.group({
            sede: ["", Validators.required],
            semestre: ["", Validators.required],
            materia: ["", Validators.required],
            metodo: ["", Validators.required],
            profesores: ["", Validators.required],
        });
        this.listaSede = [
            {
                ID: 1,
                NOMBRE_SEDE: "CDE LAGO",
            },
            {
                ID: 2,
                NOMBRE_SEDE: "PJC",
            },
            {
                ID: 3,
                NOMBRE_SEDE: "CDE JESUITICA",
            },
        ];

        this.getProfesores();
    }

    loading(titulo) {
        Swal.fire({
            title: titulo,
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

    getSemestreProfesor(id_sede) {
        this.loading("Cargando");
        this.administradoresService
            .getListadoSemestres(id_sede)
            .subscribe((data) => {
                this.listaSemestreReal = [...data];
                console.log("Lista de Semestres", data);
            });

        this.administradoresService.getListaProfesores().subscribe((data) => {
            this.listaProfesores = [...data];
            console.log("Lista de profesores", data);
            Swal.close();
        });
    }

    getProfesores(){
        this.loading("Cargando");
        this.administradoresService.getListaProfesores().subscribe((data) => {
            this.listaProfesores = [...data];
            console.log("Lista de profesores", data);
            Swal.close();
        });
    }

    filtrarMaterias(semestre) {
        return this.materias.filter((x) => x.nome_semestre == semestre);
    }

    guardarId(id, id_profesor) {
        sessionStorage.setItem("id_disciplina", id);
        sessionStorage.setItem("id_profesor", id_profesor);
    }

    getMaterias(id_semestre) {
        this.id_semestre = id_semestre;
        this.loading("Cargando Materias");
        this.administradoresService
            .getListaMaterias(id_semestre)
            .subscribe((data) => {
                this.listaMateriasReal = [...data];
                console.log("Lista de materias semestre", data);
                Swal.close();
            });
    }

    getMateriasProfesor(profesor_id) {
        this.loading("Cargando Materias");
        this.administradoresService
            .getListaMateriasProfesor(profesor_id)
            .subscribe((data) => {
                this.materias = data;
                console.log("Lista de materias profesor", data);
                Swal.close();
            });
    }
}

<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Editar Examen
            </h1>
        </div>
    </div>
    <!--TERMINA HEADER-->
    <!-- CONTENT -->
    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-lg="row"
        fxLayout.gt-md="column"
        fusePerfectScrollbar
    >
        <!-- REACTIVE FORM EXAMPLE -->

        <form
            fxLayout="column"
            fxLayoutAlign="center"
            fxFlex="1 0 auto"
            name="form"
            [formGroup]="editarExamen"
        >
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
                    <mat-label>Total de Puntos</mat-label>
                    <input matInput required formControlName="total" />

                    <mat-error
                        >Por favor ingrese la cantidad de puntos.</mat-error
                    >
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Tema</mat-label>
                    <input matInput required formControlName="tema" />

                    <mat-error>Por favor ingrese el tema.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Oportunidades de Rendir</mat-label>
                    <input matInput required formControlName="oportunidades" />

                    <mat-error
                        >Por favor ingrese las oportunidades de
                        rendir.</mat-error
                    >
                </mat-form-field>
            </div>
            <div
                class="inputs"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
            >
                <!--ACA COMIENZA HABILITAR POR -->
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Habilitar por </mat-label>
                    <mat-select
                        required
                        (selectionChange)="getHabilitadoPor($event.value)"
                        [(value)]="selected"
                    >
                        <mat-option value="unidad">
                            Unidad terminada
                        </mat-option>
                        <mat-option value="fecha"> Fecha </mat-option>
                    </mat-select>

                    <mat-error
                        >Por favor elija una forma de habilitar el
                        examen.</mat-error
                    >
                </mat-form-field>
                <!--ACA TERMINAR HABILITAR POR-->
                <!--ACA COMIENZA DURACION-->
                <mat-form-field appearance="outline" class="pl-4" fxFlex="50">
                    <mat-label>Duracion (en minutos)</mat-label>
                    <input matInput formControlName="duracion" />
                    <mat-error>Por favor ingrese la duracion.</mat-error>
                </mat-form-field>
                <!--ACA TERMINA DURACION-->
                <!--ACA COMIENZA CANTIDAD DE PREGUNTAS-->
                <mat-form-field appearance="outline" class="pl-4" fxFlex="50">
                    <mat-label>Cantidad de Preguntas</mat-label>
                    <input matInput formControlName="cantidad_preguntas" />
                    <mat-error
                        >Por favor ingrese la cantidad de preguntas.</mat-error
                    >
                </mat-form-field>
                <!--ACA TERMINA CANTIDAD DE PREGUNTA-->
            </div>
            <!--SELECCIONAR FECHA INICIO-->
            <div
                class="inputs"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
                *ngIf="editarExamen.value.por_fecha === 1"
            >
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Fecha Inicio</mat-label>
                    <input
                        matInput
                        [matDatepicker]="startDatePicker"
                        readonly
                        name="start"
                        formControlName="fecha_inicio"
                    />

                    <mat-datepicker-toggle
                        matSuffix
                        [for]="startDatePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                    <mat-error>Por favor ingrese la fecha de inicio.</mat-error>
                </mat-form-field>
                <!-- TERMINA SELECCIONAR FECHA INICIO-->
                <!-- EMPIEZA HORA INICIO-->
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Hora Inicio</mat-label>
                    <input matInput type="time" formControlName="hora_inicio" />

                    <mat-error>Por favor ingrese la hora de inicio.</mat-error>
                </mat-form-field>
                <!-- TERMINA HORA INICIO-->
            </div>
            <!--SELECCIONAR FECHA FIN-->
            <div
                class="inputs"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
            >
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Fecha Fin</mat-label>
                    <input
                        readonly
                        matInput
                        [matDatepicker]="endDatePicker"
                        name="start"
                        formControlName="fecha_fin"
                    />

                    <mat-datepicker-toggle
                        matSuffix
                        [for]="endDatePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                    <mat-error>Por favor ingrese la fecha limite.</mat-error>
                </mat-form-field>
                <!-- TERMINA SELECCIONAR FECHA FIN-->

                <!-- EMPIEZA HORA FIN-->
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Hora Fin</mat-label>
                    <input
                        matInput
                        type="time"
                        name="start"
                        formControlName="hora_fin"
                    />

                    <mat-error>Por favor ingrese la hora limite.</mat-error>
                </mat-form-field>
                <!-- TERMINA HORA FIN-->
            </div>
            <div
                class="inputs"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
                *ngIf="editarExamen.value.por_fecha === 0"
            >
                <!--ACA COMIENZA UNIDAS AL TERMINAR  -->
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Al terminar Unidad </mat-label>
                    <mat-select formControlName="id_unidad_terminada">
                        <mat-option
                            [value]="unidad.id"
                            *ngFor="let unidad of listaUnidadesNuevas"
                        >
                            {{ unidad.nome }}
                        </mat-option>
                    </mat-select>

                    <mat-error
                        >Por favor elija una forma de habilitar el
                        examen!</mat-error
                    >
                </mat-form-field>
                <!--ACA TERMINA UNIADDES AL TERMINAR-->
            </div>

            <div
                class="inputs"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
            >
                <mat-form-field appearance="fill" fxFlex="40">
                    <mat-label>Unidades Seleccionadas</mat-label>
                    <mat-select multiple [formControl]="unidadesExamen">
                        <mat-option
                            disabled
                            *ngFor="let unidad of examen.unidades"
                            [value]="unidad"
                            (click)="agarrarUnidades()"
                        >
                            {{ unidad.nome_unidad }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="40">
                    <mat-label>Nueva Seleccion de unidades</mat-label>
                    <mat-select multiple [formControl]="unidadesExamen">
                        <mat-option
                            *ngFor="let unidad of listaUnidadesNuevas"
                            [value]="unidad.id"
                            (click)="agregarUnidad(unidad.id)"
                        >
                            {{ unidad.nome }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>

            <!--BOTON AGREGAR-->

            <div
                fxLayout="row wrap"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
            ></div>

            <div fxLayout="row" fxLayoutAlign="end" fxFlex="1 0 auto">
                <button mat-button color="accent" (click)="guardarEditado()">
                    Guardar
                </button>
            </div>
        </form>
    </div>
</div>

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AlumnoMateriasComponent } from "./alumno-materias/alumno-materias.component";
import { RendirExamenComponent } from "./rendir-examen/rendir-examen.component";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule, MatIcon } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { FuseSharedModule } from "@fuse/shared.module";
import { FuseWidgetModule } from "@fuse/components";
import { MatTabsModule } from "@angular/material/tabs";
import { MatGridListModule } from "@angular/material/grid-list";
import { FuseSidebarModule } from "@fuse/components";
import { MatInputModule } from "@angular/material/input";
import { MatStepperModule } from "@angular/material/stepper";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { CalificacionesComponent } from "./calificaciones/calificaciones.component";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ExamenesComponent } from "./examenes/examenes.component";
import { UnidadesMateriaComponent } from "./unidades-materia/unidades-materia.component";
import { RealizarUnidadComponent } from "./realizar-unidad/realizar-unidad.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ModalIntroduccionUnidadComponent } from "./unidades-materia/modal-introduccion-unidad/modal-introduccion-unidad.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { AuthGuard } from "app/auth/auth.guard";
import { VerExamenComponent } from "./calificaciones/ver-examen/ver-examen.component";
import { PagosComponent } from "./pagos/pagos.component";
import { ModalFinalizarPagoComponent } from "./pagos/modal-finalizar-pago/modal-finalizar-pago.component";
import { ConfirmarPagoComponent } from "./pagos/modal-finalizar-pago/confirmar-pago/confirmar-pago.component";
import { NgxPaginationModule } from "ngx-pagination";
import { MatBadgeModule } from "@angular/material/badge";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ModalVerGrabadoComponent } from "./unidades-materia/modal-ver-grabado/modal-ver-grabado.component";
import { ChatComponent } from "app/chat/chat.component";
import { ChatModule } from "app/chat/chat.module";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSortModule } from "@angular/material/sort";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgxMatFileInputModule } from "@angular-material-components/file-input";

const routes = [
    {
        path: "materias",
        component: AlumnoMateriasComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ALUMNO"],
        },
    },
    {
        path: "rendir-examen",
        component: RendirExamenComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ALUMNO"],
        },
    },
    {
        path: "calificaciones",
        component: CalificacionesComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ALUMNO"],
        },
    },
    {
        path: "examenes",
        component: ExamenesComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ALUMNO"],
        },
    },
    {
        path: "unidades-materia",
        component: UnidadesMateriaComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ALUMNO", "ROLE_PROFESOR"],
        },
    },
    {
        path: "realizar-unidad",
        component: RealizarUnidadComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ALUMNO", "ROLE_PROFESOR"],
        },
    },
    {
        path: "ver-examen",
        component: VerExamenComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ALUMNO", "ROLE_ADMIN"],
        },
    },
    {
        path: "pagos",
        component: PagosComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["ROLE_SUPERADMIN", "ROLE_ALUMNO"],
        },
    },
];

@NgModule({
    declarations: [
        AlumnoMateriasComponent,
        RendirExamenComponent,
        CalificacionesComponent,
        ExamenesComponent,
        UnidadesMateriaComponent,
        RealizarUnidadComponent,
        ModalIntroduccionUnidadComponent,
        VerExamenComponent,
        PagosComponent,
        ModalFinalizarPagoComponent,
        ConfirmarPagoComponent,
        ModalVerGrabadoComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        //Imports de Angular Material
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTabsModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatGridListModule,
        FuseSidebarModule,
        MatStepperModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        BrowserAnimationsModule,
        FuseSharedModule,
        ReactiveFormsModule,
        FormsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatSnackBarModule,
        //NoopAnimationsModule,
        NgxPaginationModule,
        MatBadgeModule,
        MatDatepickerModule,
        ChatModule,
        MatExpansionModule,
        MatSortModule,
        NgxChartsModule,
        NgxMatFileInputModule,
    ],
})
export class AlumnosModule {}

<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Cargar Unidad
            </h1>
        </div>
    </div>
    <!-- CONTENT -->
    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-md="row"
    >
        <!-- REACTIVE FORM EXAMPLE -->

        <form
            class="mat-card mat-elevation-z4 p-24 mr-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            name="form"
            [formGroup]="form"
        >
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <!-- ACA EMPIEZASELECCIONAR SEDE-->
                <!-- <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Sede</mat-label>
                    <mat-select required formControlName="sede">
                        <mat-option
                            *ngFor="let sede of listaSede"
                            [value]="sede.ID"
                            (click)="getSemetres(sede.ID)"
                        >
                            {{ sede.NOMBRE_SEDE }}
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor elija una sede.</mat-error>
                </mat-form-field>-->
                <!-- ACA TERMINA SELECCIONAR SEDE-->

                <!--ACA EMPIEZA SEMESTRE-->

                <!--  <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Semestre</mat-label>
                    <mat-select required formControlName="semestre">
                        <mat-option
                            [value]="semestre.id"
                            (click)="getMaterias(semestre.id)"
                            *ngFor="let semestre of listaSemestres"
                        >
                            {{ semestre.nome_semestre }} {{ semestre.nome }}
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor elija un semestre.</mat-error>
                </mat-form-field>-->
                <!--ACA TERMINA SEMESTRE-->
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <!-- ACA EMPIEZASELECCIONAR MATERIA-->
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Materia</mat-label>
                    <mat-select required formControlName="id_materia">
                        <mat-option>
                            <ngx-mat-select-search
                                [(ngModel)]="term2"
                                [ngModelOptions]="{ standalone: true }"
                                placeholderLabel="Buscar Materia..."
                                noEntriesFoundLabel="No existe esta materia"
                            >
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                            *ngFor="
                                let materia of listaMateriasTemporal
                                    | filter: term2
                            "
                            [value]="materia.id_disciplina"
                            (click)="getUnidades(materia.id_disciplina)"
                        >
                            {{ materia.nome }}
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor elija una materia.</mat-error>
                </mat-form-field>
                <!--ACA TERMINA SELECCIONAR MATERIA-->

                <!--ACA EMPIEZA INGRESAR UNIDAD-->
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Unidad</mat-label>
                    <mat-select required formControlName="unidad">
                        <mat-option
                            *ngFor="let unidad of listaUnidadesTemporal"
                            [value]="unidad.id"
                            (click)="preguntasUnidad(unidad.id)"
                        >
                            {{ unidad.nome }}
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >account_circle</mat-icon
                    >
                    <mat-error>Por favor ingrese la unidad.</mat-error>
                </mat-form-field>
                <!--ACA TERMINA INGRESAR UNIDAD-->
            </div>

            <!--BOTON AGREGAR-->

            <div fxLayout="row" fxLayoutAlign="end" fxFlex="1 0 auto">
                <button mat-button color="accent" (click)="agregarPregunta()">
                    Agregar Preguntas
                </button>
            </div>
        </form>
    </div>

    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-lg="row"
        fxLayout.gt-md="column"
    >
        <form
            class="mat-card mat-elevation-z4 p-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            name="form"
            [formGroup]="form"
        >
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <div fxFlex="50" class="h2 mb-24">Preguntas de la Unidad</div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="70">
                    <mat-label>Buscar</mat-label>
                    <input
                        [(ngModel)]="term"
                        matInput
                        [ngModelOptions]="{ standalone: true }"
                    />
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="30" class="pl-4">
                    <mat-label>Mostrar</mat-label>
                    <mat-select
                        [(value)]="itemsPerPage"
                        (selectionChange)="p = 1"
                    >
                        <mat-option value="5"> 5 </mat-option>
                        <mat-option value="10"> 10 </mat-option>
                        <mat-option value="15"> 15 </mat-option>
                        <mat-option value="20"> 20 </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-table
                class="products-table"
                #table
                [dataSource]="
                    tablaUnidades
                        | filter: term
                        | paginate
                            : { itemsPerPage: itemsPerPage, currentPage: p }
                "
                matSort
                [@animateStagger]="{ value: '50' }"
                fusePerfectScrollbar
                fxFlex="100"
            >
                <!-- Columna Index -->
                <ng-container matColumnDef="index">
                    <mat-header-cell
                        fxFlex="10"
                        fxLayoutAlign="center"
                        *matHeaderCellDef
                        mat-sort-header
                        >#
                    </mat-header-cell>
                    <mat-cell
                        fxFlex="10"
                        fxLayoutAlign="center"
                        *matCellDef="let i = index"
                    >
                        <p class="text-truncate">
                            {{ itemsPerPage * (p - 1) + i + 1 }}.
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Columna Pregunta -->
                <ng-container matColumnDef="enunciado">
                    <mat-header-cell
                        fxFlex="60"
                        fxLayoutAlign="center"
                        *matHeaderCellDef
                        mat-sort-header
                        >Enunciado
                    </mat-header-cell>
                    <mat-cell
                        fxFlex="60"
                        fxLayoutAlign="center"
                        *matCellDef="let enunciado"
                    >
                        <p class="text-truncate">{{ enunciado.ENUNCIADO }}</p>
                    </mat-cell>
                </ng-container>
                <!-- Columna Materia -->

                <!-- Columna Unidad 
                <ng-container matColumnDef="unidad">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Unidad</mat-header-cell
                    >
                    <mat-cell *matCellDef="let unidad">
                        <p class="text-truncate">
                            Unidad ingresada
                        </p>
                    </mat-cell>
                </ng-container>-->
                <!-- Columna Peso 
                <ng-container matColumnDef="peso">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Peso</mat-header-cell
                    >
                    <mat-cell *matCellDef="let unidad">
                        <p class="text-truncate">
                            Peso Ingresado
                        </p>
                    </mat-cell>
                </ng-container>-->

                <!-- Columna Acciones -->
                <ng-container matColumnDef="acciones">
                    <mat-header-cell
                        fxFlex="30"
                        fxLayoutAlign="center"
                        *matHeaderCellDef
                        mat-sort-header
                        >Acciones
                    </mat-header-cell>
                    <mat-cell
                        fxFlex="30"
                        fxLayoutAlign="center"
                        *matCellDef="let pregunta"
                    >
                        <button
                            mat-button
                            color="accent"
                            (click)="editarPregunta(pregunta)"
                        >
                            Ver Pregunta
                        </button>
                        <button
                            mat-button
                            color="warn"
                            (click)="eliminarPregunta(pregunta.ID)"
                        >
                            Eliminar
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>

                <mat-row
                    *matRowDef="let product; columns: displayedColumns"
                    class="product"
                    matRipple
                >
                </mat-row>
            </mat-table>

            <pagination-controls
                (pageChange)="p = $event"
                autoHide="true"
                responsive="true"
                previousLabel="Anterior"
                nextLabel="Siguiente"
            ></pagination-controls>
        </form>
    </div>
</div>

import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, EventEmitter, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ThemePalette } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import {
    ListadoMaterias,
    ListaMateriasProfesor,
} from "app/administradores/administradores.modal";
import Swal from "sweetalert2";
import {
    ListaTpentregados,
    ListaTrabajosPracticos,
    TrabajoPractico,
} from "../profesores.modal";
import { ProfesoresService } from "../profesores.service";
import { ModalVistaPreviaComponent } from "./modal-vista-previa/modal-vista-previa.component";

@Component({
    selector: "app-cargar-trabajo-practico",
    templateUrl: "./cargar-trabajo-practico.component.html",
    styleUrls: ["./cargar-trabajo-practico.component.scss"],
    animations: fuseAnimations,
})
export class CargarTrabajoPracticoComponent implements OnInit {
    displayedColumns: string[] = ["NombreTrabajo", "Acciones"];
    form: FormGroup;
    color: ThemePalette = "primary";
    fileToUpload: File = null;
    materias: ListaMateriasProfesor[] = [];
    semestres: string[] = [];
    itemsPerPage: number = 10;
    term: string;
    p: number = 1;
    panelOpenState = false;
    id: any;
    dataSource: ListaTrabajosPracticos[] = [];
    dialogReff: any;
    trabajoPractico: TrabajoPractico = {};
    revisarBoolean: boolean = false;
    listaEntregados: ListaTpentregados[] = [];

    constructor(
        private _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private profesoresService: ProfesoresService
    ) {}

    

    ngOnInit(): void {
        // Reactive Form
        this.form = this._formBuilder.group({
            oferta_disciplina_id: [null, Validators.required],
            fecha_inicio: ["", Validators.required],
            fecha_fin: ["", Validators.required],
            titulo: ["", Validators.required],
            calificacion: ["", Validators.required],
            formatoEntrega: ["", Validators.required],
            hora_inicio: ["", Validators.required],
            hora_fin: ["", Validators.required],
            instrucciones: ["", Validators.required],
            link: ["", Validators.required],
            file: ["", Validators.required],
        });
        this.loading("Cargando Materias..");
        this.profesoresService.getMateriasProfesor().subscribe((data) => {
            this.materias = data;
            //for (let mat of data) {
            //    if (this.materias.filter(x => x.id_disciplina == mat.id_disciplina).length == 0) {
            //      this.materias.push(mat);
            //   }
            // }
            for (let materia of this.materias) {
                if (!this.semestres.includes(materia.nome_semestre)) {
                    this.semestres.push(materia.nome_semestre);
                }
            }
            Swal.close();
        });

        this.listaTrabajosPracticos();
    }

    //agregarTrabajoPractico() {
    //    this.loading("Agregando Trabajo Practico");
    //    console.log(this.form.value);
    //    //console.log("Esto es lo que quiero subir", this.fileToUpload);
    //
    //    this.profesoresService.postTrabajoPractico(this.form.value).subscribe(
    //        (response) => {
    //            console.log(response);
    //            Swal.fire({
    //                position: "top-end",
    //                icon: "success",
    //                title: "Trabajo Practico agregado con exito!",
    //                showConfirmButton: false,
    //                timer: 1500,
    //            });
    //            this.listaTrabajosPracticos(
    //                this.form.value.oferta_disciplina_id
    //            );
    //        },
    //        (err) => {
    //            Swal.fire("", "Hubo un problema con el servidor", "error");
    //        }
    //    );
    //}

    listaTrabajosPracticos() {
        this.loading("Cargando lista de Trabajos Practicos");
        this.profesoresService.getListaTrabajosPracticos().subscribe(
            (data) => {
                console.log("Lista de Trabajos", data);
                this.dataSource = data;
                Swal.close();
            },
            (err) => {
                Swal.fire("Error", "Hubo un problema con el servidor", "error");
            }
        );
    }

    eliminarTrabajoPractico(id_trabajo) {
        Swal.fire({
            title: "Estas seguro?",
            text: "Esto no se podra revertir!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminalo!",
        }).then((result) => {
            if (result.isConfirmed) {
                this.loading("Eliminando Trabajo Practico");
                this.profesoresService
                    .deleteTrabajoPractico(id_trabajo)
                    .subscribe(
                        (response) => {
                            Swal.fire(
                                "Eliminado!",
                                "El Trabajo Practico ha sido eliminado con exito.",
                                "success"
                            );
                            this.listaTrabajosPracticos();
                        },
                        (err) => {
                            Swal.fire(
                                "Error",
                                "Hubo un problema con el servidor",
                                "error"
                            );
                        }
                    );
            }
        });
    }

    loading(titulo) {
        Swal.fire({
            title: titulo,

            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

    verFecha() {
        console.log(this.form.value.fecha_inicio);
    }

    confirmar() {
        this.dialogReff = this._matDialog.open(ModalVistaPreviaComponent, {
            data: { form: this.form.value },
        });
        this.dialogReff.afterClosed((result) => {
            //Aca hay que actualizar la lista de trabajos practicos
        });
    }

    revisar(id_tp) {
        this.revisarBoolean = true;
        this.loading("Cargando...");
        this.profesoresService.getTrabajoPractico(id_tp).subscribe((data) => {
            this.trabajoPractico = data;
            console.log("Se trajo el trabajo", this.trabajoPractico);
        });
        this.profesoresService
            .getListaTrabajosPracticosEntregados(id_tp)
            .subscribe((data) => {
                this.listaEntregados = data;
                console.log("lista de entregados", this.listaEntregados);
                Swal.close();
            });
    }

    revisarEsFalse() {
        this.revisarBoolean = false;
    }

   async comentar() {
      /*  Swal.fire({
            title: "Caso trabajo 1",
            text: "Comentarios para Cristian Gomez",
            input: "textarea",
            inputAttributes: {
                autocapitalize: "off",
                placeholder:
                    "Escriba sus comentarios sobre el trabajo practico.",
            },
            showCancelButton: true,
            confirmButtonText: "Enviar",
            showLoaderOnConfirm: true,
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
            }
        });*/

       

        const { value: formValues } = await Swal.fire({
            title: 'Comentarios',
            width:'600px',
          
            html:
              '<input id="swal-input1" placeholder="Ingresar Puntos" class="swal2-input">' +
              '<textarea id="swal-input2" height="100px" placeholder="Ingresar Comentario" class="swal2-input">',
            focusConfirm: false,
            preConfirm: () => {
              return [                  
              // document.getElementById('swal-input1').value,
              // document.getElementById('swal-input2').value
              ]
            }
          })
          
          if (formValues) {
            let comentario = {
                puntos: formValues[0],
                comentario: formValues[1]
            }
            console.log('Esto esta para enviar en el post comentarios', comentario)
          }

        //    (async () => {
        //        const { value: formValues } = await Swal.fire({
        //            title: "Multiple inputs",
        //            html:
        //                '<input id="swal-input1" class="swal2-input">' +
        //                '<input id="swal-input2" class="swal2-input">',
        //            focusConfirm: false,
        //            preConfirm: () => {
        //                return [
        //                    document.getElementById("swal-input1").value,
        //                    document.getElementById("swal-input2").value,
        //                ];
        //            },
        //        });
        //
        //        if (formValues) {
        //            Swal.fire(JSON.stringify(formValues));
        //        }
        //    })();
    }

    postComentario() {
        this.profesoresService.postComentario({comentario : "hola"}).subscribe((data) => {
            console.log("se envio el comentario", data);
        });
    }
    descargarTrabajo(caminho){
        window.open(
            "https://api.ucpvirtual.com.br/storage/medicina/trabalhos/" +
                caminho
        );
        //console.log("Camino", caminho);
    }
}

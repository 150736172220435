<!-- CHAT -->
<div class="chat" fxFlex fxLayout="column">

    <!-- CHAT TOOLBAR -->
    <mat-toolbar class="chat-toolbar">

        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">

            <div fxLayout="row" fxLayoutAlign="start center">

                <!-- RESPONSIVE CHATS BUTTON-->
                <button mat-icon-button fxHide.gt-md class="responsive-chats-button mr-16"
                    fuseMatSidenavToggler="chat-left-sidenav" aria-label="chats button">
                    <mat-icon>chat</mat-icon>
                </button>
                <!-- / RESPONSIVE CHATS BUTTON-->

                <!-- CHAT CONTACT-->


                <div class="avatar-wrapper" fxFlex="0 1 auto" fxLayoutAlign="center center">
                    <img src="assets/images/avatars/profile.jpg" class="avatar" />
                </div>
                <div fxLayout="row" fxFlex>

                    <div class="pr-4" fxFlex fxLayout="column" fxLayoutAlign="center start">

                        <div class="contact-name"
                            style="font-size: 16px; white-space: nowrap; text-overflow: ellipsis;">
                            {{selectedChat.nome}}
                        </div>
                        <div class="contact-last-message" style="font-size: 12px; margin-top:4px">
                            {{selectedChat.pergunta}}</div>
                    </div>

                </div>

                <!-- / CHAT CONTACT-->
            </div>



        </div>
    </mat-toolbar>
    <!-- / CHAT TOOLBAR -->

    <!-- CHAT CONTENT -->
    <div id="chat-content" fxFlex="1 1 auto" fusePerfectScrollbar>

        <!-- CHAT MESSAGES -->
        <div class="chat-messages">
            <div [fxHide]="foroMensajes.length > 0" [fxShow]="foroMensajes.length < 1" fxLayoutAlign="center center">
                <h2>Actualmente no hay comentarios en este topico.</h2>
            </div>
            <!-- MESSAGE -->
            <div *ngFor="let message of foroMensajes; let i = index" class="message-row" [ngClass]="{
                            'me': message.usuarios_id === id_usuario,
                            'contact': message.usuarios_id !== id_usuario,
                            'first-of-group': isFirstMessageOfGroup(message, i),
                            'last-of-group': isLastMessageOfGroup(message, i)
                            }">

                <img *ngIf="message.usuarios_id !== id_usuario" src="assets/images/avatars/profile.jpg" class="avatar">
                <div *ngIf="message.usuarios_id !== id_usuario">{{message.usuarios_nome}}</div>
                <div class="bubble">
                    <div class="message">{{message.resposta}} </div>
                </div>

            </div>
            <!-- / MESSAGE -->

        </div>
        <!-- CHAT MESSAGES -->

    </div>
    <!-- / CHAT CONTENT -->

    <!-- CHAT FOOTER -->
    <div class="chat-footer" fxFlex="0 0 auto" fxLayout="column">

        <!-- REPLY FORM -->
        <div class="reply-form" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center">

            <form #replyForm="ngForm" (ngSubmit)="reply($event)" (keydown.enter)="reply($event)" fxFlex fxLayout="row"
                fxLayoutAlign="start center">

                <mat-form-field class="message-text" fxFlex floatLabel="never" appearance="standard">
                    <textarea matInput #replyInput placeholder="Escribe tu comentario" ngModel name="message" [rows]="1"
                        [matTextareaAutosize]="true"></textarea>
                </mat-form-field>

                <button class="send-message-button" mat-icon-button type="submit" aria-label="Send message">
                    <mat-spinner [fxHide]="!enviando" [fxShow]="enviando" class="secondary-text" style="margin-top: 3%;"
                        diameter="25"></mat-spinner>

                    <mat-icon [fxHide]="enviando" [fxShow]="!enviando" class="secondary-text">send</mat-icon>
                </button>
            </form>
        </div>
        <!-- / REPLY FORM -->

    </div>
    <!-- / CHAT FOOTER-->

</div>
<!-- / CHAT -->
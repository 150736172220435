<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Crear Sede
            </h1>
        </div>
    </div>
    <!-- CONTENT -->
    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-lg="row"
        fxLayout.gt-md="column"
    >
        <!-- REACTIVE FORM EXAMPLE -->

        <form
            class="mat-card mat-elevation-z4 p-24 mr-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            name="form"
            [formGroup]="formSede"
            style="height: 200px;"
        >
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="nombre" required />
                    <mat-icon matSuffix class="secondary-text"
                        >account_circle</mat-icon
                    >
                    <mat-error>Por favor ingrese el nombre.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Seleccionar Departamento</mat-label>
                    <mat-select required formControlName="departamento">
                        <mat-option value="Departamento 1">
                            Departamento 1
                        </mat-option>
                        <mat-option value="Departamento 2">
                            Departamento 2
                        </mat-option>
                        <mat-option value="Departamento 3">
                            Departamento 3
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text"
                        >outlined_flag</mat-icon
                    >
                    <mat-error>Por favor seleccione un Departamento.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Direccion</mat-label>
                    <input matInput formControlName="direccion" required />
                    <mat-icon matSuffix class="secondary-text"
                        >account_circle</mat-icon
                    >
                    <mat-error>Por favor ingrese la direccion.</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="end">
                <button mat-button color="accent" (click)="crearSede()">
                    Crear Sede
                </button>
            </div>
        </form>
        <form
            class="mat-card mat-elevation-z4 p-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            name="form"
            [formGroup]="formSede"
            style="height: 500px;"
        >
            <div class="h2 mb-24">Sedes en Existencia</div>

            <mat-table
                class="products-table"
                #table
                [dataSource]="dataSource"
                matSort
                [@animateStagger]="{ value: '50' }"
                fusePerfectScrollbar
            >
                <!-- Columna Nombre -->
                <ng-container matColumnDef="Nombre">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Nombre
                    </mat-header-cell>
                    <mat-cell *matCellDef="let sede">
                        <p class="text-truncate">
                            {{ sede.NOMBRE }}
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Columna Departamento -->
                <ng-container matColumnDef="Departamento">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Departamento
                    </mat-header-cell>
                    <mat-cell *matCellDef="let sede">
                        <p class="text-truncate">
                            {{ sede.DEPARTAMENTO }}
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Columna Direccion -->
                <ng-container matColumnDef="Direccion">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Direccion
                    </mat-header-cell>
                    <mat-cell *matCellDef="let sede">
                        <p class="text-truncate">
                            {{ sede.DIRECCION }}
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Columna Acciones -->
                <ng-container matColumnDef="Acciones">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        fxShow.gt-md
                        >Acciones</mat-header-cell
                    >
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-md>
                        <button
                            mat-button
                            color="accent"
                            (click)="editarSede()"
                        >
                            Editar
                        </button>
                        <button
                            mat-button
                            color="warn"
                            (click)="eliminarSede()"
                        >
                            Eliminar
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Columna Unidad 
                <ng-container matColumnDef="unidad">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Unidad</mat-header-cell
                    >
                    <mat-cell *matCellDef="let unidad">
                        <p class="text-truncate">
                            Unidad ingresada
                        </p>
                    </mat-cell>
                </ng-container>-->
                <!-- Columna Peso 
                <ng-container matColumnDef="peso">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Peso</mat-header-cell
                    >
                    <mat-cell *matCellDef="let unidad">
                        <p class="text-truncate">
                            Peso Ingresado
                        </p>
                    </mat-cell>
                </ng-container>-->

                <!-- Columna Acciones -->

                <mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>

                <mat-row
                    *matRowDef="let product; columns: displayedColumns"
                    class="product"
                    matRipple
                >
                </mat-row>
            </mat-table>
        </form>
    </div>
</div>

<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Evaluaciones
            </h1>
        </div>
    </div>

    <div class="content p-12" fxLayout="column">
        <div
            class="mb-24"
            fxLayout="row"
            fxLayoutAlign="start"
            id="products"
            fxFlex="1 0 auto"
        >
            <form
                class="mat-card mat-elevation-z4 p-36"
                fxLayout="column"
                fxLayoutAlign="start"
                fxFlex="1 0 auto"
                name="form"
            >
                <div
                    fxLayout="row"
                    fxLayoutAlign="start"
                    fxFlex="1 0 auto"
                    class="mb-24"
                >
                    <div
                        fxLayout="column"
                        fxLayoutAlign="start"
                        fxFlex="1 0 auto"
                    >
                        <label class="titulo1">{{
                            evaluaciones.nome_materia
                        }}</label>
                        <label class="titulo2"
                            >Profesor:
                            <label class="texto1">{{
                                evaluaciones.nome_professor
                            }}</label></label
                        >
                        <label class="titulo2"
                            >Seccion:
                            <label class="texto1">{{
                                evaluaciones.nome_turma
                            }}</label></label
                        >
                        <label class="titulo2"
                            >Sede:
                            <label class="texto1">{{
                                evaluaciones.nome_filial
                            }}</label></label
                        >
                    </div>
                    <div
                        fxLayout="column"
                        fxLayoutAlign="start "
                        fxFlex="1 0 auto"
                    >
                        <label class="titulo2">Estado de Examen</label>
                        <div fxLayoutAlign=" center">
                            <mat-icon class="verde">check_circle</mat-icon
                            ><label
                                >Rendidos:
                                <strong>{{
                                    evaluaciones.rendidos
                                }}</strong></label
                            >
                        </div>
                        <div fxLayoutAlign=" center">
                            <mat-icon class="rojo">highlight_off</mat-icon
                            ><label
                                >Sin rendir:
                                <strong>{{
                                    evaluaciones.no_rendidos
                                }}</strong></label
                            >
                        </div>
                    </div>
                    <div
                        fxLayout="column"
                        fxLayoutAlign="start "
                        fxFlex="1 0 auto"
                    >
                        <label class="titulo2">Informe de notas</label>
                        <label
                            >Promedio:
                            <strong>{{
                                evaluaciones.promedio_nota
                            }}</strong></label
                        >
                        <label
                            >Minima:
                            <strong>{{
                                evaluaciones.minimo_nota
                            }}</strong></label
                        >
                        <label
                            >Maxima:
                            <strong>{{ evaluaciones.max_nota }}</strong></label
                        >
                    </div>
                    <div
                        fxLayout="column"
                        fxLayoutAlign="start "
                        fxFlex="1 0 auto"
                    >
                        <label class="titulo2">Informacion Tiempo</label>
                        <label
                            >Promedio:
                            <strong>{{
                                evaluaciones.promedio_tiempo
                            }}</strong></label
                        >
                        <label
                            >Minimo:
                            <strong>{{
                                evaluaciones.minimo_tiempo
                            }}</strong></label
                        >
                        <label
                            >Maximo:
                            <strong>{{
                                evaluaciones.max_tiempo
                            }}</strong></label
                        >
                    </div>
                </div>
                <!-- <div class="h2 mb-24">Preguntas</div>-->
                <!--ACA EMPIEZA LA TABLA-->
                <!-- ACA EMPIEZASELECCIONAR mostrar-->
                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="25"
                        class="pr-4"
                    >
                        <mat-label>Filtro</mat-label>
                        <mat-select multiple>
                            <div fxLayout="column" class="p-16">
                                <div
                                    fxLayout="column"
                                    fxLayoutAlign="start"
                                    fxFlex="1 0 auto"
                                >
                                    <label class="mb-4"
                                        ><strong>Sede</strong></label
                                    >

                                    <mat-option
                                        (click)="
                                            filtrado2(
                                                sede.texto,
                                                'NOMBRE_FILIAL'
                                            )
                                        "
                                        *ngFor="let sede of filtroSede"
                                        [value]="sede.texto"
                                    >
                                        {{ sede.texto }}
                                    </mat-option>
                                    <label class="mb-4"
                                        ><strong
                                            >Tipo de Evaluacion</strong
                                        ></label
                                    >
                                    <div fxLayout="column" class="pr-8">
                                        <mat-option
                                            [value]="tipo.id"
                                            (click)="
                                                filtrado2(
                                                    tipo.texto,
                                                    'NOMBRE_TIPO_EXAMEN'
                                                )
                                            "
                                            *ngFor="let tipo of filtroTipoEval"
                                        >
                                            {{ tipo.texto }}
                                        </mat-option>
                                    </div>
                                </div>

                                <div
                                    fxLayout="column"
                                    fxLayoutAlign="start"
                                    fxFlex="1 0 auto"
                                >
                                    <label class="mb-4"
                                        ><strong>Semestre</strong></label
                                    >
                                    <div fxLayout="column" class="pr-8">
                                        <mat-option
                                            (click)="
                                                filtrado2(
                                                    semestre.texto,
                                                    'nome_semestre'
                                                )
                                            "
                                            *ngFor="
                                                let semestre of filtroSemestre
                                            "
                                            [value]="semestre.texto"
                                        >
                                            {{ semestre.texto }}
                                        </mat-option>
                                    </div>
                                </div>
                            </div>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="60">
                        <mat-label>Buscar</mat-label>
                        <input
                            [(ngModel)]="term"
                            matInput
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </mat-form-field>
                    <mat-form-field
                        appearance="outline"
                        fxFlex="15"
                        class="pl-4"
                    >
                        <mat-label>Mostrar</mat-label>
                        <mat-select
                            [(value)]="itemsPerPage"
                            (selectionChange)="p = 1"
                        >
                            <mat-option value="5"> 5 </mat-option>
                            <mat-option value="10"> 10 </mat-option>
                            <mat-option value="15"> 15 </mat-option>
                            <mat-option value="20"> 20 </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!--ACA TERMINA SELECCIONAR MOSTRAR-->

                <mat-table
                    fxFlex="1 0 auto"
                    class="products-table"
                    #table
                    [dataSource]="
                        evaluaciones.notas
                            | filter: term
                            | paginate
                                : {
                                      itemsPerPage: itemsPerPage,
                                      currentPage: p
                                  }
                    "
                    [@animateStagger]="{ value: '50' }"
                    fusePerfectScrollbar
                    matSort
                    (matSortChange)="sortData($event)"
                >
                    <!-- Columna ID -->
                    <ng-container matColumnDef="fecha">
                        <mat-header-cell
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="fecha"
                            >Fecha
                        </mat-header-cell>
                        <mat-cell fxLayoutAlign="start" *matCellDef="let lista">
                            <p
                                class="text-truncate"
                                style="white-space: normal"
                            >
                                {{ lista.fecha }}
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna nombre -->
                    <ng-container matColumnDef="tipo">
                        <mat-header-cell
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header
                            >Tipo
                        </mat-header-cell>
                        <mat-cell fxLayoutAlign="start" *matCellDef="let lista">
                            <p>{{ evaluaciones.tipo }}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna estado -->
                    <ng-container matColumnDef="status">
                        <mat-header-cell
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="status"
                            >Estado
                        </mat-header-cell>
                        <mat-cell fxLayoutAlign="start" *matCellDef="let lista">
                            <p>
                                <mat-icon
                                    *ngIf="lista.status === 1"
                                    class="verde"
                                    >check_circle</mat-icon
                                >
                                <mat-icon
                                    *ngIf="lista.status === 0"
                                    class="rojo"
                                    >highlight_off</mat-icon
                                >
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna ALUMNO -->
                    <ng-container matColumnDef="alumno">
                        <mat-header-cell
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="nome"
                            >Alumno
                        </mat-header-cell>
                        <mat-cell fxLayoutAlign="start" *matCellDef="let lista">
                            <p class="text-truncate">
                                {{ lista.nome }}
                                {{ lista.sobrenome }}
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna NOTA -->
                    <ng-container matColumnDef="nota">
                        <mat-header-cell
                            fxLayoutAlign="center"
                            *matHeaderCellDef
                            mat-sort-header="nota"
                            >Nota
                        </mat-header-cell>
                        <mat-cell
                            fxLayoutAlign="center"
                            *matCellDef="let lista"
                        >
                            <p>{{ lista.nota }}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna respuestas -->
                    <ng-container matColumnDef="respuestas">
                        <mat-header-cell
                            fxLayoutAlign="center"
                            *matHeaderCellDef
                            mat-sort-header="porcentagem_nota"
                            >% Respuestas correctas
                        </mat-header-cell>
                        <mat-cell
                            fxLayoutAlign="center"
                            *matCellDef="let lista"
                        >
                            <p class="text-truncate">
                                {{ lista.porcentagem_nota }} %
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna TIEMPO -->
                    <ng-container matColumnDef="tiempo">
                        <mat-header-cell
                            fxLayoutAlign="center"
                            *matHeaderCellDef
                            mat-sort-header="tiempo"
                            >Tiempo
                        </mat-header-cell>
                        <mat-cell
                            fxLayoutAlign="center"
                            *matCellDef="let lista"
                        >
                            <p>{{ lista.tiempo }}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna ACCIONES -->
                    <ng-container matColumnDef="acciones">
                        <mat-header-cell
                            fxLayoutAlign="center"
                            *matHeaderCellDef
                            mat-sort-header
                            >Acciones
                        </mat-header-cell>
                        <mat-cell
                            fxLayout="column"
                            fxLayoutAlign="center"
                            *matCellDef="let product"
                        >
                            <button
                                mat-button
                                color="accent"
                                (click)="
                                    verExamen(
                                        product.usuario_id,
                                        product.nome,
                                        product.sobrenome
                                    )
                                "
                            >
                                Ver Examen
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row
                        *matHeaderRowDef="displayedColumns; sticky: true"
                    ></mat-header-row>

                    <mat-row
                        *matRowDef="let product; columns: displayedColumns"
                        class="product"
                        matRipple
                    >
                    </mat-row>
                </mat-table>
                <pagination-controls
                    (pageChange)="p = $event"
                    autoHide="true"
                    responsive="true"
                    previousLabel="Anterior"
                    nextLabel="Siguiente"
                ></pagination-controls>

                <!--ACA TERMINA LA TABLA-->

                <div
                    fxLayout="row"
                    fxLayoutAlign="end"
                    fxFlex="1 0 auto"
                    style="margin-top: 20px"
                >
                    <h1 *ngIf="print" style="margin-right: 15%">
                        --------------
                    </h1>

                    <div style="margin-top: 2.5%">
                        <button (click)="Imprimir()" mat-button color="accent">
                            Imprimir
                        </button>
                        <button
                            (click)="exportarExcel()"
                            mat-button
                            color="accent"
                        >
                            Exportar a Excel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

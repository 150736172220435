import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { dataPreguntas } from "./profesores.modal";

@Injectable({
    providedIn: "root",
})
export class ProfesoresService {
    //url = "http://apiucp.harasgb.com/api/";
    constructor(private _http: HttpClient) {}
    // token = localStorage.getItem('token')
    // EMPIEZAN LOS GET
    // autorizacion = {
    //    headers: new HttpHeaders().set(
    //      "Authorization",
    //      "bearer " + this.token
    //  ),
    // };

    //ESTO ESTA EN CARGAR UNIDAD
    //NO SE TOCA DE MOMENTO
    getUnidadesTemporal(id_materia): Observable<any> {
        let token = localStorage.getItem("token");
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/new/plataforma/unidades_disciplina/" +
                id_materia,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //nuevos de los profesores
    //ESTO ESTA EN CARGAR UNIDAD
    //NO SE TOCA
    crearPreguntaRespuesta(preguntas) {
        let token = localStorage.getItem("token");
        return this._http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/profesor/unidad/addpreguntarespuesta",
            preguntas,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //ESTO ESTA EN CARGAR UNIDAD
    //NO SE TOCA
    crearPreguntaRespuestaAdmin(preguntas, id_profesor) {
        let token = localStorage.getItem("token");
        return this._http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/admin/unidad/addpreguntarespuesta/" +
                id_profesor,
            preguntas,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //ESTO SE USA EN CARGAR UNIDAD
    //NO SE TOCA
    getListaPreguntas(id_unidad) {
        let token = localStorage.getItem("token");
        return this._http.get<dataPreguntas[]>(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/profesor/unidade/perguntas/lista/" +
                id_unidad,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //ESTO ESTA EN CARGAR UNIDAD
    //NO SE TOCA
    getListaPreguntasAdmin(id_unidad, id_profesro) {
        let token = localStorage.getItem("token");
        return this._http.get<dataPreguntas[]>(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/profesor/unidade/perguntas/lista/" +
                id_unidad +
                "/" +
                id_profesro,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //fin nuevos de los profesores
    //ESTO ESTA EN CARGAR UNIDAD
    //NO SE TOCA
    putEditarPregunta(pregunta): Observable<any> {
        let token = localStorage.getItem("token");
        return this._http.put(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/pregunta/edit",
            pregunta,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //ESTO ESTA EN CARGAR UNIDAD
    //NO SE TOCA
    putEditarRespuesta(respuesta): Observable<any> {
        let token = localStorage.getItem("token");
        return this._http.put(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/respuesta/edit",
            respuesta,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //ESTO ESTA EN CARGAR UNIDAD
    //NO SE TOCA
    deletePreguntaUnidad(id): Observable<any> {
        let token = localStorage.getItem("token");
        return this._http.delete(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examen/pregunta/eliminar/" +
                id,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    //ESTO SE USA EN MATERIAS
    //NO SE ELIMINA NO SE TOCA
    getMateriasProfesor(): Observable<any> {
        let token = localStorage.getItem("token");
        return this._http.get(
            "https://api.ucpvirtual.com.br/public/api/v4/plataforma/profesor/materias",
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    postTrabajoPractico(trabajo): Observable<any> {
        let token = localStorage.getItem("token");
        const formData: FormData = new FormData();
        formData.append("oferta_disciplina_id", trabajo.oferta_disciplina_id);
        formData.append("data_inicio", trabajo.fecha_inicio);
        formData.append("data_fin", trabajo.fecha_fin);
        formData.append("titulo", trabajo.titulo);
        formData.append("calificacion", trabajo.calificacion);
        formData.append("forma_entrega", trabajo.formatoEntrega);
        formData.append("hora_inicio", trabajo.hora_inicio);
        formData.append("hora_fin", trabajo.hora_fin);
        formData.append("instrucao", trabajo.instrucciones);
        formData.append("link", trabajo.link);
        formData.append("file", trabajo.file);

        return this._http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/professor/trabalho_praticos",
            formData,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    getTrabajoPractico(id_tp): Observable<any> {
        let token = localStorage.getItem("token");

        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/professor/trabalho_praticos/" +
                id_tp,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    getListaTrabajosPracticos(): Observable<any> {
        let token = localStorage.getItem("token");
        let profesor_id = +sessionStorage.getItem("id_usuario");
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/professor/trabalho_praticos/lista/" +
                profesor_id,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    getListaTrabajosPracticosEntregados(trabajo_id): Observable<any> {
        let token = localStorage.getItem("token");
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/professor/trabalho_praticos/ex_trabalho/" +
                trabajo_id,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    deleteTrabajoPractico(id): Observable<any> {
        let token = localStorage.getItem("token");

        return this._http.delete(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/professor/trabalho_praticos/" +
                id,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }

    
    
    postComentario(comentario): Observable<any> {
        let token = localStorage.getItem("token");
        return this._http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/professor/trabalho_praticos",
            comentario,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + token
                ),
            }
        );
    }
    
    /* 
    /*

    ACA EMPIEZA LO QUE NO SE USA

    /*
    /*****
     * 
     */

    //ESTO SE LLAMABA EN HABILITACIONES
    // getListaSede(): Observable<any> {
    //     return this._http.get(this.url + "examenes/sedes");
    // }

    //ESTO SE LLAMABA EN CARGAR EXAMEN, EN CARGAR PUNTAJE, EN HABILITACIONES, LISTAR EXAMENES

    /*getListadoSemestres(id_sede): Observable<any> {
        //return this._http.get(this.url + "examenes/semestres/" + id_sede);
        return this._http.get<ListaSedes[]>(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/filial/seccion/" +
            id_sede,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token
                ),
            }
        );
    }*/

    //ESTO SE LLAMABA EN CARGAR EXAMEN, EN CARGAR PUNTAJE, EN HABILITACIONES, LISTAR EXAMENES
    /*
    getListaMaterias(id_semestre): Observable<any> {
        //return this._http.get(this.url + "examenes/materias/" + id_semestre);
        return this._http.get<ListaMaterias[]>(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/filial/seccion/materias/" +
            id_semestre,
            this.autorizacion
        );
    }*/
    //Empiezan los DELETE
    //SE LLAMABA EN LISTAR EXAMENES
    /*deleteExamen(id_examen): Observable<any> {
        return this._http.delete(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examen/eliminar/" +
            id_examen,
            this.autorizacion
        );
    }*/
    /* postRespuesta(Respuesta): Observable<any> {
        /*return this._http.post(
            this.url + "examenes/pregunta/addrespuesta",
            Respuesta
        );
        return this._http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/pregunta/addrespuesta",
            Respuesta,
            this.autorizacion
        );
    }*/

    //ESTO SE LLAMABA EN CARGAR EXAMEN

    /*postCrearExamen(crearExamen): Observable<any> {
        //return this._http.post(this.url + "examen/nuevo", crearExamen);
        return this._http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examen/nuevo",
            crearExamen,
            this.autorizacion
        );
    }*/
    //ESTO SE LLAMABA EN CARGAR EXAMEN

    /*postUnidadExamenes(unidadExamenes): Observable<any> {
        /*return this._http.post(
            this.url + "examen/nuevo/addunidades",
            unidadExamenes
        );

        return this._http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examen/nuevo/addunidades",
            unidadExamenes,
            this.autorizacion
        );
    }*/

    //EMPIEZAN LOS PUT

    // putEditarExamen(examen): Observable<any> {
    //    return this._http.put(this.url + "examen/editar", examen);
    // }

    /*putEditarPreguntaNuevo(preguntas) {
        return this._http.put(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/profesor/unidad/editarpregunta",
            preguntas,
            this.autorizacion
        )
    }*/
    //SE LLAMABA EN LISTAR EXAMENES

    /*getListaExamenes(): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/listar",
            this.autorizacion
        );
    }*/

    // EMPIEZAN LOS POST

    /*postPregunta(NuevaPregunta): Observable<any> {
        /*return this._http.post(
            this.url + "examenes/unidad/addpregunta",
            NuevaPregunta
        );
        return this._http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/unidad/addpregunta",
            NuevaPregunta,
            this.autorizacion
        );
    }*/
    /*
    getTablaUnidades(id_unidad): Observable<any> {
        /*return this._http.get(
            this.url + "examenes/preguntas/unidad/" + id_unidad
        );
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/pregunta/unidad/" +
            id_unidad,
            this.autorizacion
        );
    }*/

    //ESTO SE LLAMABA EN MODAL-EDITAR-PREGUNTA

    /*getEditarPregunta(id_pregunta): Observable<any> {
        //return this._http.get(this.url + "examenes/respuestas/" + id_pregunta);
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/respuesta/" +
            id_pregunta,
            this.autorizacion
        );
    }*/

    //ESTO SE LLABA EN HABILITACIONES, EN LISTA EXAMENES
    // getListExamenes(id_materia): Observable<any> {
    //    return this._http.get(this.url + "examenes/listar/" + id_materia);
    // }
    // getUnidadesExamen(id_examen): Observable<any> {
    //     return this._http.get(this.url + "examen/unidades/" + id_examen);
    // }

    //ESTO SE LLAMABA EN CARGAR EXAMEN

    /*getMateriasTemporal(): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/new/plataforma/disciplinas",
            this.autorizacion
        );
    }*/
    //ESTO ESTA EN CARGAR UNIDAD
    //PERO COMENTADO ASI QUE POSIBLEMENTE SE PUEDA ELIMINAR
    /* getListaUnidades(id_materia): Observable<any> {
         let token = localStorage.getItem('token')
         return this._http.get("https://api.ucpvirtual.com.br/api/v4/plataforma/filial/seccion/unidades/" + id_materia,
             {
                 headers: new HttpHeaders().set(
                     "Authorization",
                     "bearer " + token
                 ),
             }
         );
     }*/
}

<div
    id="forms"
    class="page-layout simple fullwidth inner-scroll"
    fxLayout="column"
>
    <!-- HEADER -->
    <div
        class="header accent p-24 h-130"
        fxLayout="row"
        fxLayoutAlign=" center"
        fxFlex="1 0 auto"
    >
        <div fxLayoutAlign="start">
            <button mat-icon-button class="mr-16" [routerLink]="ruta">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>

        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
            <div class="h1 mt-16">
                Evaluacion Materia:
                {{ desde !== "admin" ? nombre_materia : nombre_alumno }}
            </div>
        </div>
        <div
            fxLayoutAlign="end"
            fxFlex="1 0 auto"
            *ngIf="
                authService.isLoggedIn() &&
                authService.getRole() === 'ROLE_ADMIN'
            "
        >
            <button
                [fxHide]="habilitarEdicion === false"
                mat-raised-button
                (click)="habilitarEdit()"
            >
                Habilitar edicion
            </button>
            <button
                [fxShow]="habilitarEdicion === false"
                mat-raised-button
                (click)="guardar()"
            >
                Guardar
            </button>
        </div>
    </div>
    <!-- / HEADER -->
    <!-- CONTENT -->
    <!-- CONTENT -->
    <div class="content p-24" fusePerfectScrollbar>
        <div class="vertical-stepper-wrapper">
            <!--PRUEBA STEPPER-->
            <mat-vertical-stepper [linear]="true" #stepper>
                <mat-step *ngFor="let step of verExamen; let i = index">
                    <ng-template matStepLabel>Pregunta </ng-template>
                    <div class="h2 mb-24">{{ step.enunciado }}</div>
                    <mat-form-field
                        style="width: 100%"
                        class="example-full-width"
                    >
                        <mat-label>Explicación</mat-label>
                        <textarea
                            matInput
                            disabled
                            [value]="step.explicacion"
                        ></textarea>
                    </mat-form-field>

                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxFlex="1 0 auto"
                        *ngFor="let option of step.respuestas; let n = index"
                    >
                        <mat-radio-group
                            class="example-radio-group"
                            [value]="option.marcada"
                            (change)="
                                editarExamenAlumno(
                                    option.id_respuesta,
                                    step.respuestas
                                )
                            "
                        >
                            <mat-radio-button
                                class="example-radio-button"
                                [value]="option.id_respuesta"
                                style="display: block"
                                [checked]="option.marcada"
                                [disabled]="habilitarEdicion"
                                [fxHide]="!option.texto"
                            >
                            </mat-radio-button>
                        </mat-radio-group>
                        <!--ESTE TEXTO SALE CON ESTE ESTILO SI ES QUE NO ELIGIO ESTA RESPUESTA Y NO ES LA RESPUESTA CORRECTA-->
                        <label *ngIf="!option.marcada && !option.correcta">
                            {{ option.texto }}</label
                        >
                        <!--SALE CON ESTE STYLE SI SELECCIONO ESA RESPUESTA O ES LA RESPUESTA CORRECTA-->
                        <label
                            *ngIf="option.marcada || option.correcta"
                            style="
                                font-size: large;
                                color: black;
                                font-weight: bold;
                            "
                        >
                            {{ option.texto }}
                        </label>
                        <!--SALE CON ESTE STYLE SI ES LA RESPUESTA CORRECTA-->

                        <label
                            *ngIf="option.correcta"
                            style="
                                color: green;
                                font-size: large;
                                margin-left: 1%;
                                font-weight: bolder;
                            "
                        >
                            Correcto!
                        </label>
                        <!--SALE CON ESTE STYLE SI SELECCIONOLA RESPUESTA INCORRECTA-->

                        <label
                            *ngIf="!option.correcta && option.marcada"
                            style="
                                color: red;
                                font-size: large;
                                margin-left: 1%;
                                font-weight: bolder;
                            "
                        >
                            Respondido
                        </label>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button
                            *ngIf="i > 0"
                            class="mr-8"
                            mat-raised-button
                            matStepperPrevious
                            type="button"
                            color="accent"
                        >
                            Volver
                        </button>
                        <button
                            *ngIf="i < this.verExamen.length - 1"
                            mat-raised-button
                            matStepperNext
                            type="button"
                            color="accent"
                        >
                            Siguiente
                        </button>
                    </div>
                </mat-step>
                <!--ACA ES PARA ENTREGAR-->
                <!--ACA TERMINAR LA PARTE DE ENTREGA-->
            </mat-vertical-stepper>
            <!--ACA TERMINA LA PRUEBA DEL VERTICAL STEPPER-->
        </div>
    </div>
    <!--HASTA ACA EL VERDADERO-->
</div>

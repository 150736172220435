import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { ProfesoresService } from 'app/profesores/profesores.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-modal-modal-editar-pregunta',
    templateUrl: './modal-modal-editar-pregunta.component.html',
    styleUrls: ['./modal-modal-editar-pregunta.component.scss'],
    animations: fuseAnimations,
})
export class ModalModalEditarPreguntaComponent implements OnInit {
    pregunta: any
    metodoPregunta: string;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private profesoresService: ProfesoresService, private _matDialog: MatDialogRef<ModalModalEditarPreguntaComponent>) { }

    ngOnInit(): void {
        console.log('Dentro del editar', this.data.pregunta)
        this.pregunta = JSON.parse(JSON.stringify(this.data.pregunta))

        for (let respuesta of this.pregunta.respostas) {
            if (respuesta.TEXTO.toLocaleLowerCase() === 'verdadero' || respuesta.TEXTO.toLocaleLowerCase() === 'falso') {
                this.metodoPregunta = '2'
            } else {
                this.metodoPregunta = '1'
            }
        }

    }
    cambioRespuestaCorrecta(id_respuesta) {
        for (let respuesta of this.pregunta.respostas) {
            if (respuesta.ID === id_respuesta) {
                respuesta.CORRECTA = 1
                console.log('Marco que esta es la respuesta correcta', respuesta.TEXTO)
            } else {
                respuesta.CORRECTA = 0
            }
        }
    }

    guardar() {
        this.loading('Guardando Cambios..')
        let pregunta = {
            id_pregunta: this.pregunta.ID,
            enunciado: this.pregunta.ENUNCIADO,
            explicacion: this.pregunta.EXPLICACION,
        };
        this.profesoresService.putEditarPregunta(pregunta).subscribe(
            (response) => {
                // console.log("Editado con exito la pregunta!", pregunta);
                //  console.log(response, 'Respuesta de editar pregunta')

                for (let respuesta of this.pregunta.respostas) {
                    let respuestaEditado = {
                        id_respuesta: respuesta.ID,
                        texto: respuesta.TEXTO,
                        correcta: respuesta.CORRECTA,
                    };
                    this.profesoresService
                        .putEditarRespuesta(respuestaEditado)
                        .subscribe(
                            (response) => {
                                //  console.log("Se envio la respuesta", respuestaEditado);
                                // console.log('Respuesta de la respuestas', response)

                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Se guardaron los cambios exitosamente',
                                    showConfirmButton: false,
                                    timer: 1500
                                }).then(() => {
                                    this._matDialog.close('editado')
                                })
                            },
                            (error) => {
                                Swal.fire(
                                    "Ocurrio un error",
                                    "Verifique los datos ingresados o contacte con su administrador de sistemas",
                                    "error"
                                );
                            }
                        );
                }
            },
            (err) => {
                Swal.fire(
                    "Hubo un problema con el servidor, o no completo correctamente los campos",
                    "Contacte con su administrador de sistemas",
                    "error"
                );
            }
        );

    }
    eliminar() {
        Swal.fire({
            title: 'Esta seguro de eliminar la pregunta?',
            text: "Esto no se podra revertir!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminalo!',
            cancelButtonText: 'cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                this.loading('Eliminando Pregunta..')
                this.profesoresService
                    .deletePreguntaUnidad(this.pregunta.ID)
                    .subscribe((data) => {
                        Swal.fire(
                            'Eliminado!',
                            'La pregunta se ha eliminado correctamente.',
                            'success'
                        )
                        this._matDialog.close('editado')

                    }, err => {
                        Swal.fire('Error', 'Hubo un problema con el servidor', 'error')
                    });

            }
        })
    }
    loading(titulo) {
        Swal.fire({
            title: titulo,
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

}

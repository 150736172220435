<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Cargar Examen
            </h1>
        </div>
    </div>
    <!-- CONTENT -->
    <div class="content p-12">
        <div
            class="mb-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxLayout.gt-md="row"
        >
            <!-- REACTIVE FORM EXAMPLE -->

            <form
                class="mat-card mat-elevation-z4 p-24 mr-24"
                fxLayout="column"
                fxLayoutAlign="start"
                fxFlex="1 0 auto"
                name="form"
                [formGroup]="crearExamen"
            >
                <!--SELECCIONAR SEDE-->
                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field appearance="outline" fxFlex="33">
                        <mat-label>Sede</mat-label>
                        <mat-select formControlName="id_sede" required>
                            <mat-option
                                *ngFor="let sede of listaSede"
                                [value]="sede.ID"
                                (click)="getSemetres(sede.ID)"
                            >
                                {{ sede.NOMBRE_SEDE }}
                            </mat-option>
                        </mat-select>
                        <!--SELECCIONAR SEMESTRE-->
                        <mat-error>Por favor elija una sede.</mat-error>
                    </mat-form-field>
                    <mat-form-field
                        appearance="outline"
                        fxFlex="33"
                        class="pl-4"
                    >
                        <mat-label>Semestres</mat-label>
                        <mat-select formControlName="id_semestre" required>
                            <mat-option
                                *ngFor="let semestre of listaSemestreReal"
                                [value]="semestre.id"
                                (click)="getMaterias(semestre.id)"
                            >
                                {{ semestre.nome_semestre }} {{ semestre.nome }}
                            </mat-option>
                        </mat-select>

                        <mat-error>Por favor elija un semestre.</mat-error>
                    </mat-form-field>
                    <!--SELECCIONAR MATERIA-->
                    <mat-form-field
                        appearance="outline"
                        fxFlex="100"
                        class="pl-4"
                    >
                        <mat-label>Materia</mat-label>
                        <mat-select
                            required
                            formControlName="oferta_disciplina_id"
                        >
                            <mat-option
                                *ngFor="let materia of listaMateriasReal"
                                [value]="materia.id"
                                (click)="getUnidades(materia.disciplinas_id)"
                            >
                                {{ materia.nome }}
                            </mat-option>
                        </mat-select>
                        <mat-error>Por favor elija una materia.</mat-error>
                    </mat-form-field>
                    <!--SELECCIONAR TIPO EXAMEN-->
                    <mat-form-field
                        appearance="outline"
                        fxFlex="100"
                        class="pl-4"
                    >
                        <mat-label>Tipo Examen</mat-label>
                        <mat-select required formControlName="tipo_examen_id">
                            <mat-option
                                *ngFor="let tipoExamen of listaTipoExamenReal"
                                [value]="tipoExamen.ID"
                            >
                                {{ tipoExamen.NOMBRE }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            >Por favor elija un tipo de examen.</mat-error
                        >
                    </mat-form-field>
                </div>

                <!--TERMINA PRIMERA LINEA DE SELECTORES-->
                <div
                    class="inputs"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field appearance="outline" fxFlex="33">
                        <mat-label>Total de Puntos</mat-label>
                        <input matInput formControlName="total" required />

                        <mat-error
                            >Por favor ingrese la cantidad de puntos.</mat-error
                        >
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="34"
                        class="pl-4"
                    >
                        <mat-label>Tema</mat-label>
                        <input matInput formControlName="tema" required />

                        <mat-error>Por favor ingrese el tema.</mat-error>
                    </mat-form-field>
                    <mat-form-field
                        appearance="outline"
                        fxFlex="33"
                        class="pl-4"
                    >
                        <mat-label>Oportunidades de Rendir</mat-label>
                        <input
                            matInput
                            formControlName="oportunidades"
                            required
                        />

                        <mat-error
                            >Por favor ingrese las oportunidades de
                            rendir.</mat-error
                        >
                    </mat-form-field>
                </div>
                <div
                    class="inputs"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <!--ACA COMIENZA HABILITAR POR -->
                    <mat-form-field appearance="outline" fxFlex="33">
                        <mat-label>Habilitar por </mat-label>
                        <mat-select
                            required
                            (selectionChange)="getHabilitadoPor($event.value)"
                        >
                            <mat-option value="unidad">
                                Unidad terminada
                            </mat-option>
                            <mat-option value="fecha"> Fecha </mat-option>
                        </mat-select>

                        <mat-error
                            >Por favor elija una forma de habilitar el
                            examen.</mat-error
                        >
                    </mat-form-field>
                    <!--ACA TERMINAR HABILITAR POR-->
                    <!--ACA COMIENZA DURACION-->
                    <mat-form-field
                        appearance="outline"
                        class="pl-4"
                        fxFlex="34"
                    >
                        <mat-label>Duracion (en minutos)</mat-label>
                        <input matInput formControlName="duracion" />
                        <mat-error>Por favor ingrese la duracion.</mat-error>
                    </mat-form-field>
                    <!--ACA TERMINA DURACION-->
                    <!--ACA COMIENZA CANTIDAD DE PREGUNTAS-->
                    <mat-form-field
                        appearance="outline"
                        class="pl-4"
                        fxFlex="33"
                    >
                        <mat-label>Cantidad de Preguntas</mat-label>
                        <input matInput formControlName="cantidad_pregunta" />
                        <mat-error
                            >Por favor ingrese la cantidad de
                            preguntas.</mat-error
                        >
                    </mat-form-field>
                    <!--ACA TERMINA CANTIDAD DE PREGUNTA-->
                </div>
                <!--SELECCIONAR FECHA INICIO-->
                <div
                    class="inputs"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                    *ngIf="habilitarPor == 'fecha'"
                >
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Fecha Inicio</mat-label>
                        <input
                            readonly
                            matInput
                            [matDatepicker]="startDatePicker"
                            formControlName="fecha_inicio"
                            name="start"
                            (click)="verFecha()"
                        />

                        <mat-datepicker-toggle
                            matSuffix
                            [for]="startDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                        <mat-error
                            >Por favor ingrese la fecha de inicio.</mat-error
                        >
                    </mat-form-field>
                    <!-- TERMINA SELECCIONAR FECHA INICIO-->

                    <!-- EMPIEZA HORA INICIO-->
                    <mat-form-field
                        appearance="outline"
                        fxFlex="50"
                        class="pl-4"
                    >
                        <mat-label>Hora Inicio</mat-label>
                        <input
                            matInput
                            type="time"
                            step="1"
                            formControlName="hora_inicio"
                            name="start"
                        />

                        <mat-error
                            >Por favor ingrese la hora de inicio.</mat-error
                        >
                    </mat-form-field>
                    <!-- TERMINA HORA INICIO-->
                </div>
                <!--SELECCIONAR FECHA FIN-->
                <div
                    class="inputs"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Fecha Fin</mat-label>
                        <input
                            readonly
                            matInput
                            [matDatepicker]="endDatePicker"
                            formControlName="fecha_fin"
                            name="start"
                        />

                        <mat-datepicker-toggle
                            matSuffix
                            [for]="endDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                        <mat-error
                            >Por favor ingrese la fecha limite.</mat-error
                        >
                    </mat-form-field>
                    <!-- TERMINA SELECCIONAR FECHA FIN-->

                    <!-- EMPIEZA HORA INICIO-->
                    <mat-form-field
                        appearance="outline"
                        fxFlex="50"
                        class="pl-4"
                    >
                        <mat-label>Hora Fin</mat-label>
                        <input
                            matInput
                            type="time"
                            step="1"
                            formControlName="hora_fin"
                            name="start"
                        />

                        <mat-error>Por favor ingrese la hora limite.</mat-error>
                    </mat-form-field>
                    <!-- TERMINA HORA INICIO-->
                </div>
                <div
                    class="inputs"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                    *ngIf="habilitarPor == 'unidad'"
                >
                    <!--ACA COMIENZA UNIDAS AL TERMINAR  -->
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Al terminar Unidad </mat-label>
                        <mat-select formControlName="id_unidad_terminada">
                            <mat-option
                                [value]="unidad.id"
                                *ngFor="let unidad of listaUnidadesReal"
                            >
                                {{ unidad.nome }}
                            </mat-option>
                        </mat-select>

                        <mat-error
                            >Por favor elija una forma de habilitar el
                            examen!</mat-error
                        >
                    </mat-form-field>
                    <!--ACA TERMINA UNIADDES AL TERMINAR-->
                </div>

                <div
                    class="inputs"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field appearance="fill" fxFlex="40">
                        <mat-label>Seleccionar Unidades</mat-label>
                        <mat-select [formControl]="unidadesExamen" multiple>
                            <mat-option
                                *ngFor="let unidad of listaUnidadesReal"
                                [value]="unidad.id"
                                (click)="agarrarUnidades()"
                            >
                                {{ unidad.nome }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>

                <!--BOTON AGREGAR-->
               
                <div
                    style="margin-top: 20px"
                    fxLayout="row"
                    fxLayoutAlign="end"
                    fxFlex="1 0 auto"
                >
                    <button
                        mat-button
                        color="accent"
                        [disabled]="
                            !this.crearExamen.valid || !unidadesExamen.value
                        "
                        (click)="postCrearExamen()"
                    >
                        Nuevo examen
                    </button>
                    
                </div>
            </form>
        </div>
    </div>
</div>

import { Component, OnInit, Input, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";

@Component({
    selector: "app-modal-editar",
    templateUrl: "./modal-editar.component.html",
    styleUrls: ["./modal-editar.component.scss"],
    animations: fuseAnimations,
})
export class ModalEditarComponent implements OnInit {
    action: string;
    form: FormGroup;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ModalEditarComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _formBuilder: FormBuilder,
        public matDialogRef: MatDialogRef<ModalEditarComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        this.form = _data.form;
        //console.log("Este es el form", this.form);
    }
    editorStyle = {
        height: "500px",
    };
    configuracion = {
        toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            //["clean"], // remove formatting button

            [, /*"link"*/ "image" /*"video"*/], // link and image, video
        ],
    };
    ngOnInit(): void { }
}

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseMatSidenavHelperService } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.service';

import { ChatService } from 'app/chat/chat.service';
import { TopicosForo } from 'app/alumnos/alumnos';
import Swal from 'sweetalert2';
@Component({
    selector: 'chat-chats-sidenav',
    templateUrl: './chats.component.html',
    styleUrls: ['./chats.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ChatChatsSidenavComponent implements OnInit, OnDestroy {
    chats: any[];
    chatSearch: any;
    contacts: any[];
    searchText: string;
    user: any;
    topicos: TopicosForo[] = []
    id_materia: number;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     * @param {FuseMatSidenavHelperService} _fuseMatSidenavHelperService
     * @param {MediaObserver} _mediaObserver
     */
    constructor(
        private _chatService: ChatService,
        private _fuseMatSidenavHelperService: FuseMatSidenavHelperService,
        public _mediaObserver: MediaObserver
    ) {
        // Set the defaults
        this.chatSearch = {
            name: ''
        };
        this.searchText = '';

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.id_materia = +sessionStorage.getItem('id_materia')
        this.loading('Cargando Topicos..')
        this._chatService.getForoTopicos(this.id_materia).subscribe(
            response => {
                //  console.log('Llamada a la api', response)
                this.topicos = response;
                Swal.close()
            }, err => {
                Swal.fire('Opss..', 'Hubo un problema con el servidor', 'error')
            }
        )
        //   this.user = this._chatService.user;
        //  this.chats = this._chatService.chats;
        //  this.contacts = this._chatService.contacts;
        //   console.log(this.contacts)

        //   this._chatService.onChatsUpdated
        //       .pipe(takeUntil(this._unsubscribeAll))
        //      .subscribe(updatedChats => {
        //         this.chats = updatedChats;
        //     });

        //   this._chatService.onUserUpdated
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(updatedUser => {
        //        this.user = updatedUser;
        //    });
    }
    addPregunta() {
        Swal.fire({
            title: 'Ingrese su pregunta',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'on'
            },
            showCancelButton: true,
            confirmButtonText: 'Agregar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: (pergunta) => {
                let pregunta = { oferta_disciplina_id: this.id_materia, pergunta: pergunta }
                return this._chatService.postPregunta(pregunta).subscribe(
                    response => {
                        return response
                    }, err => {
                        Swal.showValidationMessage(
                            `Request failed: ${err}`
                        )
                    }
                )
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'La pregunta se realizo correctamente',
                    showConfirmButton: false,
                    timer: 1500
                }).then(
                    () => {
                        this.loading('Cargando Topicos..')
                        this._chatService.getForoTopicos(this.id_materia).subscribe(
                            response => {
                                //  console.log('Llamada a la api', response)
                                this.topicos = response;
                                Swal.close()
                            }, err => {
                                Swal.fire('Opss..', 'Hubo un problema con el servidor', 'error')
                            }
                        )
                    }
                )

            }
        })
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get chat
     *
     * @param contact
     */
    getChat(contact): void {
        // console.log(contact)
        this._chatService.getSeleccion(contact);
        //sessionStorage.setItem('selected', 'true')
        if (!this._mediaObserver.isActive('gt-md')) {
            this._fuseMatSidenavHelperService.getSidenav('chat-left-sidenav').toggle();
        }
    }

    /**
     * Set user status
     *
     * @param status
     */
    //setUserStatus(status): void {
    //    this._chatService.setUserStatus(status);
    // }

    /**
     * Change left sidenav view
     *
     * @param view
     */
    changeLeftSidenavView(view): void {
        this._chatService.onLeftSidenavViewChanged.next(view);
    }

    /**
     * Logout
     */
    logout(): void {
        console.log('logout triggered');
    }
    loading(title) {
        Swal.fire({
            title: title,
            //timer: 2000,
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }
}
import { Component, OnInit } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import {
    Cotizacion,
    ListaMatricula,
    ListaSemestre,
    TablaPagos,
    AgregadoPago,
    ListaPedidos,
} from "../alumnos";
import { AlumnosService } from "../alumnos.service";
//import { TablaUnidades } from "app/profesores/profesores.modal";
import Swal from "sweetalert2";
import { ModalFinalizarPagoComponent } from "./modal-finalizar-pago/modal-finalizar-pago.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "app-pagos",
    templateUrl: "./pagos.component.html",
    styleUrls: ["./pagos.component.scss"],
    animations: fuseAnimations,
})
export class PagosComponent implements OnInit {
    displayedColumns: string[] = [
        "nombre",
        "contrato",
        "fechavenc",
        "fechapago",
        "valor",
        "valorpago",
        "estado",
    ];
    displayedColumnsPedidos: string[] = [
        "datadopedido",
        "Valorguarani",
        "comprovante/boleto",
        "cambio",
        "tipopagamento",
        "status",
        "botoes",
    ];
    p: number = 1;
    pe: number = 1;
    form: FormGroup;
    public factura: TablaPagos[] = [];
    public cotizacion: Cotizacion = {};
    public listaSemestres: ListaSemestre[] = [];
    public listaMatricula: ListaMatricula[] = [];
    public tablaPago: TablaPagos[] = [];
    public tablaPedidos: ListaPedidos[] = [];
    dialogRef: any;
    public agregado: AgregadoPago[] = [];
    constructor(
        private _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private _Apialumno: AlumnosService
    ) { }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            matricula: ["", Validators.required],
            semestre: ["", Validators.required],
        });

        this._Apialumno.getCotizacion().subscribe((data) => {
            this.cotizacion = data.data;
            // console.log("se llamo a cotizacion", data);
        });

        this.ListaMatriculas();
        this.ListaPedidos();
    }

    ListaMatriculas() {
        this.loading("Cargando Matriculas");
        this._Apialumno.getListaMatricula().subscribe((data) => {
            this.listaMatricula = [...data];
            //  console.log("se llamo a matriculas", this.listaMatricula);
            Swal.close();
        });
    }

    ListaSemestre(id_matricula, nome_filial) {
        sessionStorage.setItem("nome_filial", nome_filial);
        this.loading("Cargando Semestres");
        this._Apialumno.getListaSemestres(id_matricula).subscribe((data) => {
            this.listaSemestres = [...data];
            //  console.log("se llamo a semestres", this.listaSemestres);
            Swal.close();
        });
    }

    ListaFacturas(id_semestre) {
        this.loading("Cargando Tabla");
        this._Apialumno.getListaFacturas(id_semestre).subscribe((data) => {
            this.tablaPago = data;
            //  console.log("se llamo a facturas", this.tablaPago);
            for (let cantidad of this.tablaPago) {
                this.agregado.push({ a: true, id: cantidad.id });
            }
            Swal.close();
        });
    }

    loading(titulo) {
        Swal.fire({
            title: titulo,
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

    finalizarPago() {
        sessionStorage.setItem("factura", JSON.stringify(this.factura));
        this.dialogRef = this._matDialog.open(ModalFinalizarPagoComponent, {});
        this.dialogRef.afterClosed().subscribe((result) => {
            sessionStorage.removeItem("metodoPago");
            // console.log(result);
            let prueba = JSON.parse(sessionStorage.getItem("factura"));
            //console.log("Esto es prueba", prueba);
            if (prueba.length > 0) {
                for (let agrega of this.agregado) {
                    agrega.a = true;
                    this.factura = [];
                }
            } else {
                for (let agrega of this.agregado) {
                    agrega.a = true;
                    this.factura = [];
                }
            }
        });
    }

    agregar(obj, i) {
        if (!this.agregado[i].a) {
            this.agregado[i].a = !this.agregado[i].a;
            this.factura = this.factura.filter((item) => item !== obj);

            //console.log("Queda asi factura", this.factura);
        } else {
            this.agregado[i].a = !this.agregado[i].a;
            this.factura.push(obj);
            // console.log("Se agrego a la factura", this.factura);
        }
    }

    ListaPedidos() {
        this.loading("Cargando Pedidos");
        this._Apialumno.getListaPedidos().subscribe((data) => {
            this.tablaPedidos = data;
            // console.log("Se llamo a api pedidos", this.tablaPedidos);
            Swal.close();
        });
    }
}

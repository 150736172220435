import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";

@Component({
    selector: "app-modal-ver-examen",
    templateUrl: "./modal-ver-examen.component.html",
    styleUrls: ["./modal-ver-examen.component.scss"],
})
export class ModalVerExamenComponent implements OnInit {
    form: FormGroup;
    // Vertical Stepper
    verticalStepperStep1: FormGroup;
    verticalStepperStep2: FormGroup;
    verticalStepperStep3: FormGroup;
    verticalStepperStep4: FormGroup;
    verticalStepperStep5: FormGroup;
    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(private _formBuilder: FormBuilder) {}

    ngOnInit(): void {
        // Vertical Stepper form stepper
        this.verticalStepperStep1 = this._formBuilder.group({
            idRespuesta: [""],
            respuestaSeleccionada1: ["idrespuesta1"],
            idPregunta: [""],
        });

        this.verticalStepperStep2 = this._formBuilder.group({
            idRespuesta2: [""],
            idPregunta: [""],
            respuestaSeleccionada2: ["idrespuesta2"],
        });

        this.verticalStepperStep3 = this._formBuilder.group({
            idRespuesta3: [""],
            idPregunta: [""],
            respuestaSeleccionada3: ["respuesta3"],
        });
        this.verticalStepperStep4 = this._formBuilder.group({
            idRespuesta4: [""],
            idPregunta: [""],
            respuestaSeleccionada4: ["respuesta3"],
        });
        this.verticalStepperStep5 = this._formBuilder.group({
            idRespuesta5: [""],
            idPregunta: [""],
            respuestaSeleccionada5: ["respuesta4"],
        });
    }
    /**
     * Finish the vertical stepper
     */
    finishVerticalStepper(): void {
        //console.log("Este es la respuesta dada en el primer step");
        //console.log(this.verticalStepperStep1.value);
        alert("You have finished the vertical stepper!");
    }
}

<!-- SIDENAV HEADER -->
<div class="sidenav-header">
    <!-- CHATS TOOLBAR -->
    <mat-toolbar>

        <!-- TOOLBAR BOTTOM -->
        <mat-toolbar-row>
            <button mat-icon-button class="mr-16" routerLink="/unidades-materia" style="overflow: visible">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <!-- SEARCH -->
            <div class="search-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center">

                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="s-16 secondary-text">search</mat-icon>
                    <input [(ngModel)]="searchText" type="text" placeholder="Buscar un topico" fxFlex>
                </div>

            </div>
            <!-- / SEARCH -->
            <button mat-icon-button class="mr-16" (click)="addPregunta()" style="overflow: visible">
                <mat-icon>add</mat-icon>
            </button>

        </mat-toolbar-row>
        <!-- / TOOLBAR BOTTOM -->

    </mat-toolbar>
    <!-- / CHATS TOOLBAR -->

</div>
<!-- / SIDENAV HEADER -->

<!-- SIDENAV CONTENT -->

<!-- CHATS CONTENT -->
<div *fuseIfOnDom [@animateStagger]="{value:'50'}">

    <!-- CHATS LIST-->
    <div class="chat-list" fxLayout="column">

        <div matSubheader *ngIf="(topicos | filter: searchText).length > 0">
            Topicos
        </div>
        <!--BOTON DE LOS CHATS-->
        <button mat-button *ngFor="let chat of topicos | filter: searchText" (click)="getChat(chat)"
            [@animate]="{value:'*',params:{y:'100%'}}" style="margin-bottom: 3%;">

            <div fxLayout="row" fxLayoutAlign="start center">

                <div class="avatar-wrapper" fxFlex="0 1 auto" fxLayoutAlign="center center">
                    <img class="avatar" src="assets/images/avatars/profile.jpg" />
                </div>

                <div fxLayout="row" fxFlex>

                    <div class="pr-4" fxFlex fxLayout="column" fxLayoutAlign="center start">
                        <div class="contact-name">{{chat.nome}}</div>
                        <div class="contact-last-message"> {{chat.pergunta}}</div>
                    </div>


                </div>
            </div>
            <div style="float: right;">

                <div class="contact-last-message-time">
                    {{chat.created | date}}
                </div>

            </div>

        </button>
        <!-- TERMINA BOTON DE LOS CHATS-->


    </div>
    <!-- / CHATS LIST-->

    <!-- NO RESULTS MESSAGE -->
    <div *ngIf="(topicos | filter: searchText).length === 0" class="no-results-message">
        No se encontro el topico
    </div>
    <!-- NO RESULTS MESSAGE-->

</div>
<!-- / CHATS CONTENT -->

<!-- / SIDENAV CONTENT -->
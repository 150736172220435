import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ProfesoresService } from '../profesores.service';
import { ListaMateriasProfesor } from '../profesores.modal';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
    selector: 'app-materias',
    templateUrl: './materias.component.html',
    styleUrls: ['./materias.component.scss'],
    animations: fuseAnimations,
})
export class MateriasComponent implements OnInit {
    materias: ListaMateriasProfesor[] = [];
    semestres: string[] = [];

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private profesoresService: ProfesoresService,
        private route: Router
    ) { }

    ngOnInit(): void {
        this.loading('Cargando Materias..')
        this.profesoresService.getMateriasProfesor().subscribe(
            data => {
                console.log(data)
                //this.materias = data;
                for (let mat of data) {
                    if (this.materias.filter(x => x.id_disciplina == mat.id_disciplina).length == 0) {
                        this.materias.push(mat);
                    }
                }
                for (let materia of this.materias) {
                    if (!this.semestres.includes(materia.nome_semestre)) {
                        this.semestres.push(materia.nome_semestre);
                    }
                }
                Swal.close()
            }
        );


    }

    filtrarMaterias(semestre) {
        return this.materias.filter(x => x.nome_semestre == semestre);
    }

    guardarId(id) {
        sessionStorage.setItem("id_disciplina", id)
        sessionStorage.setItem("id_profesor", "-1");
    }
    loading(titulo) {
        Swal.fire({
            title: titulo,

            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

    verUnidadesComoAlumno(id_materia, nombre_materia, id_disciplina, tipo_materia, nome_profesor, sobrenome) {
        sessionStorage.setItem("id_materia", id_materia);
        sessionStorage.setItem("nombre_materia", nombre_materia);
        sessionStorage.setItem("disciplinas_id", id_disciplina);
        sessionStorage.setItem("tipo_materia", tipo_materia);
        sessionStorage.setItem(
            "nombre_profesor",
            nome_profesor + " " + sobrenome
        );
        this.route.navigateByUrl("/unidades-materia");
    }

}

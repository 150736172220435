<div
    id="academy-courses"
    class="page-layout simple fullwidth"
    fxLayout="column"
>
    <!-- HEADER -->
    <div
        class="header accent p-24 h-130"
        fxLayout="row"
        fxLayoutAlign="start center"
    >
        <button mat-icon-button class="mr-16" [routerLink]="'/listar-examenes'">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <div fxLayout="column" fxLayoutAlign="center start">
            <div class="h1 mt-16">Lista de Puntajes de Alumnos</div>
        </div>
    </div>
    <!-- / HEADER -->
    <!-- CONTENT -->
    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-lg="row"
        fxLayout.gt-md="column"
        id="products"
    >
        <form
            class="mat-card mat-elevation-z4 p-24 mr-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            name="form"
        >
            <mat-table
                class="products-table"
                #table
                [dataSource]="dataSource"
                matSort
                [@animateStagger]="{ value: '50' }"
                fusePerfectScrollbar
            >
                <!-- Columna Alumno -->
                <ng-container matColumnDef="Alumno">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Alumno</mat-header-cell
                    >
                    <mat-cell *matCellDef="let examen">
                        <p class="text-truncate">{{ examen.ID_EXAMEN }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Columna Puntaje -->
                <ng-container matColumnDef="Puntaje">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Puntaje</mat-header-cell
                    >
                    <mat-cell *matCellDef="let examen">
                        <p class="text-truncate">
                            {{ examen.NOMBRE_MATERIA }}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Columna Acciones -->
                <ng-container matColumnDef="acciones">
                    <mat-header-cell
                        *matHeaderCellDef
                        fxHide
                        mat-sort-header
                        fxShow.gt-md
                        >Acciones</mat-header-cell
                    >
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-md>
                        <button
                            mat-button
                            color="accent"
                            routerLink="/ver-examen-alumno"
                        >
                            Ver Examen
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>

                <mat-row
                    *matRowDef="let product; columns: displayedColumns"
                    class="product"
                    matRipple
                >
                </mat-row>
            </mat-table>

            <mat-paginator
                #paginator
                [length]="dataSource.length"
                [pageIndex]="0"
                [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator>
        </form>
    </div>
</div>

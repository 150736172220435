<div>
    <div style="height: 64px; background: #fff" fxLayoutAlign="row">
        <div fxLayout="center center" fxFlex="1 0 auto">
            <div fxLayoutAlign=" center" fxFlex="1 0 auto">
                <!--<img fxHide.gt-md src="assets/images/logos/logochico.jpg" />-->
                <img src="assets/images/logos/logoucp.jpg" />
            </div>

            <div class="buttons" fxLayoutAlign="end center" fxFlex="1 0 auto">
                <button
                    mat-icon-button
                    class="toggle-sidebar-folded"
                    (click)="toggleSidebarFolded()"
                    fxHide.lt-lg
                >
                    <mat-icon class="secondary-text">menu</mat-icon>
                </button>

                <button
                    mat-icon-button
                    class="toggle-sidebar-opened"
                    (click)="toggleSidebarOpened()"
                    fxHide.gt-md
                >
                    <mat-icon class="secondary-text">arrow_back</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>

<div
    class="navbar-scroll-container"
    [ngClass]="fuseConfig.layout.navbar.primaryBackground"
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
>
    <!-- <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
        <div class="h3 username">{{ usuario }}</div>
        <div class="h5 email hint-text mt-8">{{ email ? email : sobrenome }}</div>
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img class="avatar" src="assets/images/avatars/Velazquez.jpg" />
        </div>
    </div>-->

    <div class="navbar-content">
        <!--<span>Módulo de exámenes</span>-->
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>
</div>

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import {
    ActualizarDatos,
    Ciudades,
    Estados,
    Paises,
} from "app/alumnos/alumnos";
import { AlumnosService } from "app/alumnos/alumnos.service";

@Component({
    selector: "app-modal-actualizar-datos",
    templateUrl: "./modal-actualizar-datos.component.html",
    styleUrls: ["./modal-actualizar-datos.component.scss"],
    animations: fuseAnimations,
})
export class ModalActualizarDatosComponent implements OnInit {
    datosAlumno: FormGroup;
    datos: ActualizarDatos = {};
    paises: Paises[] = [];
    estados: Estados[] = [];
    ciudades: Ciudades[] = [];

    constructor(
        private alumnoService: AlumnosService,
        private _formBuilder: FormBuilder
    ) {}

    ngOnInit(): void {
        //ESTE ES EL FORMGROUP DE LOS DATOS DEL ALUMNO
        this.datosAlumno = this._formBuilder.group({
            nombre: ["", Validators.required],
            sobrenome: ["", Validators.required],
            nascimento: ["", Validators.required],
            tipoDocumento: ["", Validators.required],
            docOficial: ["", Validators.required],
            email: ["", Validators.required],
            nacionalidad: ["", Validators.required],
            cpf: [""],
            cep: [""],
            sexo: ["", Validators.required],
            estadoCivil: ["", Validators.required],
            escolaridad: ["", Validators.required],
            bairro: ["", Validators.required],
            telFixo: [""],
            tipoNumero: ["", Validators.required],
            celular1: ["", Validators.required],
            celular2: [""],
            profissao: ["", Validators.required],
            pais: ["", Validators.required],
            estado: ["", Validators.required],
            cidade: ["", Validators.required],
            tipoEndereco: ["", Validators.required],
            endereco: ["", Validators.required],
            numeroEndereco: ["", Validators.required],
            complemento: ["", Validators.required],
            paisEndereco: ["", Validators.required],
            estadoEndereco: ["", Validators.required],
            cidadeEndereco: ["", Validators.required],
        });
        //ACA ME TRAIGO LOS DATOS DEL ALUMNO
        this.alumnoService.getActualizarDatos().subscribe((data) => {
            console.log("se llamo a la api", data);
            //ESTE ES EL FORMGROUP DE LOS DATOS DEL ALUMNO
            this.datosAlumno = this._formBuilder.group({
                nombre: [data.nome],
                sobrenome: [data.sobrenome],
                nascimento: [data.data_nascimento],
                tipoDocumento: [""],
                docOficial: [data.doc_oficial],
                email: [data.email],
                nacionalidad: [""],
                cpf: [data.cpf],
                cep: [data.cep],
                sexo: [""],
                estadoCivil: [""],
                escolaridad: [""],
                bairro: [data.bairro],
                telFixo: [data.telefone_fixo],
                tipoNumero: [""],
                celular1: [data.telefone_celular1],
                celular2: [data.telefone_celular2],
                profissao: [""],
                pais: [""],
                estado: [""],
                cidade: [""],
                tipoEndereco: [""],
                endereco: [data.rua],
                numeroEndereco: [data.numero],
                complemento: [data.complemento],
                paisEndereco: [""],
                estadoEndereco: [""],
                cidadeEndereco: [""],
            });
        });

        //ACA LLAMOS LAS FUNCIONES DONDE TRAIGO LOS DATOS PARA LOS SELECTORES
        this.pais();
    }

    guardar() {}

    pais() {
        this.alumnoService.getPais().subscribe((data) => {
            this.paises = data;
            console.log("se llamo pais", data);
        });
    }
    estado(pais_id) {
        this.alumnoService.getEstados(pais_id).subscribe((data) => {
            this.estados = data;
            console.log("se llamo estado", data);
        });
    }
    ciudad(estados_id) {
        this.alumnoService.getCiudad(estados_id).subscribe((data) => {
            this.ciudades = data;
            console.log("se llamo ciudad", data);
        });
    }
}

import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { ListadoMaterias } from "../administradores.modal";

@Component({
    selector: "app-crear-materia",
    templateUrl: "./crear-materia.component.html",
    styleUrls: ["./crear-materia.component.scss"],
    animations: fuseAnimations,
})
export class CrearMateriaComponent implements OnInit {
    displayedColumns: string[] = ["Materia", "Unidades", "Clases", "Acciones"];
    form: FormGroup;

    dataSource: ListadoMaterias[] = [
        {
            ID: 1,
            NOMBRE: "Biologia",
            CLASES: 24,
            UNIDADES: 5,
        },
        {
            ID: 2,
            NOMBRE: "Quimica",
            CLASES: 20,
            UNIDADES: 3,
        },
        {
            ID: 3,
            NOMBRE: "Anatomia",
            CLASES: 30,
            UNIDADES: 7,
        },
        {
            ID: 4,
            NOMBRE: "Anatomia 4",
            CLASES: 30,
            UNIDADES: 7,
        },
        {
            ID: 5,
            NOMBRE: "Anatomia 5",
            CLASES: 30,
            UNIDADES: 7,
        },
        {
            ID: 6,
            NOMBRE: "Anatomia 6",
            CLASES: 30,
            UNIDADES: 7,
        },
        {
            ID: 7,
            NOMBRE: "Anatomia 7",
            CLASES: 30,
            UNIDADES: 7,
        },
        {
            ID: 8,
            NOMBRE: "Anatomia 8",
            CLASES: 30,
            UNIDADES: 7,
        },
        {
            ID: 9,
            NOMBRE: "Anatomia 9",
            CLASES: 30,
            UNIDADES: 7,
        },
        {
            ID: 10,
            NOMBRE: "Anatomia 10",
            CLASES: 30,
            UNIDADES: 7,
        },
    ];

    constructor(private _formBuilder: FormBuilder) {}

    ngOnInit(): void {
        // Reactive Form
        this.form = this._formBuilder.group({
            nombre: ["", Validators.required],
            unidades: ["", Validators.required],
            clases: ["", Validators.required],
            sede: ["", Validators.required],
            correlatividad: ["", Validators.required],
        });
    }

    agregarMateria() {}

    editarMateria() {}

    eliminarMateria() {}
}

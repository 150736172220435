<div id="academy-courses" class="page-layout simple inner-scroll">
    <!-- HEADER -->
    <div class="header accent p-16 p-sm-24" fxLayout="row" fxLayoutAlign="start center">
        <div class="hero-text">
            <h1 [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }">
                Editar Pregunta
            </h1>
        </div>
    </div>
    <!-- CONTENT -->
    <div class="content p-12" fusePerfectScrollbar>
        <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout="row">
            <!-- REACTIVE FORM EXAMPLE -->

            <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">

                <!--ESTO ES EL PRIMER ROW-->
                <div class="inputs" fxLayout="row" fxLayoutAlign="start" fxFlex="1 0 auto">
                    <div class="row" fxFlex="20" style="margin-right: 3%;">
                        <mat-form-field fxFlex="1 0 auto" appearance="outline" class="pr-12">
                            <mat-label>Metodo Preguntas</mat-label>
                            <mat-select title="Seleccione el tipo de pregunta, solo disponible para preguntas nuevas"
                                required [value]="metodoPregunta" [disabled]="true">
                                <mat-option value="1">
                                    Opcion Multiple
                                </mat-option>
                                <mat-option value="2">
                                    Verdaro - Falso
                                </mat-option>
                            </mat-select>
                            <mat-error>Por favor ingrese el metodo
                                pregunta.</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="column" appearance="outline" fxFlex="40" class="pl-12">
                        <mat-form-field appearance="outline" fxFlex="40" class="pl-12">
                            <mat-label>Enunciado</mat-label>
                            <textarea matInput [(ngModel)]="pregunta.ENUNCIADO" [ngModelOptions]="{standalone: true}"
                                maxlength="4000"></textarea>
                            <mat-hint align="end"> {{pregunta.ENUNCIADO.length}} / 4000</mat-hint>
                        </mat-form-field>
                        <div class="inputs" fxLayout="row" fxFlex="1 0 auto">
                            <!--RESPUESTAS CON RADIO BUTTON-->

                            <mat-radio-group fxFlex="1 0 auto" fxLayout="column"
                                (change)="cambioRespuestaCorrecta($event.value)">
                                <div fxLayout="row" *ngFor="let respuesta of pregunta.respostas">
                                    <mat-radio-button fxLayoutAlign [value]="respuesta.ID"
                                        [checked]="respuesta.CORRECTA === 1">
                                    </mat-radio-button>
                                    <!--INPUT DE LA RESPUESTA 1-->
                                    <mat-form-field appearance="outline" fxFlex="1 0 auto">
                                        <mat-label>Escriba una respuesta</mat-label>
                                        <textarea [(ngModel)]="respuesta.TEXTO" [ngModelOptions]="{standalone: true}"
                                            matInput required maxlength="2000" style="min-height: 50px"></textarea>
                                        <mat-hint align="end"> {{ respuesta.TEXTO.length }} / 2000</mat-hint>
                                    </mat-form-field>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>

                    <mat-form-field appearance="outline" fxFlex="40" class="pl-12">
                        <mat-label>Explicacion de la respuesta</mat-label>
                        <textarea [(ngModel)]="pregunta.EXPLICACION" [ngModelOptions]="{standalone: true}" matInput
                            required maxlength="2000" style="max-height: 370px; min-height: 370px"></textarea>
                        <mat-hint align="end"> {{pregunta.EXPLICACION.length}} / 2000</mat-hint>
                        <mat-error>Por favor ingrese la explicacion de la
                            respuesta</mat-error>
                    </mat-form-field>
                </div>
            </form>
            <div class="mat-card" style="padding: 5px; margin-left: 5%;">
                <button (click)="eliminar()" style="display: block; margin: 5px" mat-fab color="primary"
                    aria-label="Eliminar" title="Eliminar pregunta">
                    <mat-icon>delete</mat-icon>
                </button>
                <button (click)="guardar()" mat-fab color="primary" style="display: block; margin: 5px"
                    aria-label="Agregar nuevo" title="Guardar las preguntas">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <button mat-flat-button style="
            font-size: 20px;
            height: 60px;
            color: white;
            background-color: green;
            margin: 10px;
        " (click)="guardar()">
        <mat-icon>save</mat-icon> Guardar
    </button>
</div>
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import {
    dataPreguntas,
    ExamenesList,
    EditarExamen,
} from "./administradores.modal";

@Injectable({
    providedIn: "root",
})
export class AdministradoresService {
    url = "http://apiucp.harasgb.com/api/";
    token_temporal: string;
    constructor(private _http: HttpClient) {
        this.token_temporal = localStorage.getItem("token");
    }

    getListaSede(): Observable<any> {
        return this._http.get(this.url + "examenes/sedes");
    }
    getListadoSemestres(id_sede): Observable<any> {
        //return this._http.get(this.url + "examenes/semestres/" + id_sede);
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/filial/seccion/" +
                id_sede,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    deleteUnidadDeExamen(id_unidad) {
        return this._http.delete(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examen/unidad/eliminar/" +
                id_unidad,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    getListaMaterias(id_semestre): Observable<any> {
        //return this._http.get(this.url + "examenes/materias/" + id_semestre);
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/filial/seccion/materias/" +
                id_semestre,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    //Lista de unidades nuevas
    getListaUnidadesCrearExamen(id_materia) {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/new/plataforma/unidades_disciplina/" +
                id_materia,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    //Get datos del examen para editar
    getDatosExamenEditar(id_examen) {
        return this._http.get<EditarExamen>(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examen/" +
                id_examen,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    putEditarExamenNuevo(examen, id_examen) {
        return this._http.put(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examen/editar/" +
                id_examen,
            examen,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    getListaUnidades(id_materia): Observable<any> {
        // this._http.get(this.url + "examenes/unidades/" + id_materia);
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/filial/seccion/unidades/" +
                id_materia,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }
    getTipoExamen(): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examen/tipo_examen",
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    getTablaUnidades(id_unidad): Observable<any> {
        /*return this._http.get(
            this.url + "examenes/preguntas/unidad/" + id_unidad
        );*/
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/pregunta/unidad/" +
                id_unidad,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }
    getEditarPregunta(id_pregunta): Observable<any> {
        //return this._http.get(this.url + "examenes/respuestas/" + id_pregunta);
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/respuesta/" +
                id_pregunta,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    getListExamenesMateria(id_materia): Observable<any> {
        return this._http.get<ExamenesList>(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/admin/ofertadisciplina/examenes/lista/" +
                id_materia,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }
    getUnidadesExamen(id_examen): Observable<any> {
        return this._http.get(this.url + "examen/unidades/" + id_examen);
    }

    getMateriasTemporal(): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/new/plataforma/disciplinas",
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    getUnidadesTemporal(id_materia): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/new/plataforma/unidades_disciplina/" +
                id_materia,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }
    getListaExamenes(): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/listar",
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    getMateriasProfesor(): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/public/api/v4/plataforma/profesor/materias",
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    getListaProfesores(): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/admin/professor/lista",
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    getListaMateriasProfesor(profesor_id): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/admin/professor/ofertadisciplina/lista/" +
                profesor_id,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    //ESTO FUNCIONA
    getListaPreguntas(id_unidad) {
        return this._http.get<dataPreguntas[]>(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/profesor/unidade/perguntas/lista/" +
                id_unidad,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    //ESTO FUNCIONA
    getlistaSemestres(): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/admin/semestres/listar",
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    //ESTO FUNCIONA
    getListaMateriaPorSemestre(id_semestre): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/admin/semestres/materias/listar/" +
                id_semestre,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    //ESTO FUNCIONA
    getListaProfesoresPorSemestre(materia_id): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/admin/professor/materias/listar/" +
                materia_id,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    // EMPIEZAN LOS POST

    postPregunta(NuevaPregunta): Observable<any> {
        /*return this._http.post(
            this.url + "examenes/unidad/addpregunta",
            NuevaPregunta
        );*/
        return this._http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/unidad/addpregunta",
            NuevaPregunta,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    postRespuesta(Respuesta): Observable<any> {
        /*return this._http.post(
            this.url + "examenes/pregunta/addrespuesta",
            Respuesta
        );*/
        return this._http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/pregunta/addrespuesta",
            Respuesta,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    postCrearExamen(crearExamen): Observable<any> {
        //return this._http.post(this.url + "examen/nuevo", crearExamen);
        return this._http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/professor/examen/nuevo",
            crearExamen,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    postUnidadExamenes(unidadExamenes): Observable<any> {
        /*return this._http.post(
            this.url + "examen/nuevo/addunidades",
            unidadExamenes
        );*/

        return this._http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examen/nuevo/addunidades",
            unidadExamenes,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    crearPreguntaRespuesta(preguntas) {
        return this._http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/profesor/unidad/addpreguntarespuesta",
            preguntas,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    //EMPIEZAN LOS PUT

    putEditarExamen(examen): Observable<any> {
        return this._http.put(this.url + "examen/editar", examen);
    }
    putEditarPregunta(pregunta): Observable<any> {
        return this._http.put(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/pregunta/edit",
            pregunta,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }
    putEditarRespuesta(respuesta): Observable<any> {
        return this._http.put(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/respuesta/edit",
            respuesta,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    //Empiezan los DELETE
    deleteExamen(id_examen): Observable<any> {
        return this._http.delete(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examen/eliminar/" +
                id_examen,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }
    deletePreguntaUnidad(id): Observable<any> {
        return this._http.delete(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examen/pregunta/eliminar/" +
                id,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    deletePreguntaUnidadAdmin(id) {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/admin/pregunta/eliminar/" +
                id,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    getListaPreguntasAdmin(id_unidad, id_profesro) {
        return this._http.get<dataPreguntas[]>(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/profesor/unidade/perguntas/lista/" +
                id_unidad +
                "/" +
                id_profesro,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    crearPreguntaRespuestaAdmin(preguntas, id_profesor) {
        return this._http.post(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/admin/unidad/addpreguntarespuesta/" +
                id_profesor,
            preguntas,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }
    //ESTO SE USA
    getListaExamenesFinal(): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/admin/examenes/listar",
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }
    //ESTE SE USA
    getListaPreguntasDeUnExamen(id): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/admin/examen/pregunta/" +
                id,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }
    //ESTE SE USA
    getEvaluaciones(id_examen): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examen/alunos/" +
                id_examen,
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    //ESTE SE USA
    getVerExamen(id_examen, usuarios_id): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/visualizar/" +
                id_examen +
                "/" +
                usuarios_id,

            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }

    //ESTE SE USA
    getInformeDeEvaluaciones(): Observable<any> {
        return this._http.get(
            "https://api.ucpvirtual.com.br/api/v4/plataforma/examenes/informe/listar",
            {
                headers: new HttpHeaders().set(
                    "Authorization",
                    "bearer " + this.token_temporal
                ),
            }
        );
    }
}

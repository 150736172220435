<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Pagos de Mensualidades
            </h1>
            <h3>
                Cotizaciones: PDJ:
                {{ cotizacion.cotacao_pjc | currency: "PYG":"₲ " }} CDE:
                {{ cotizacion.cotacao_cde2 | currency: "PYG":"₲ " }}
            </h3>
        </div>
    </div>

    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-lg="row"
        fxLayout.gt-md="column"
        fusePerfectScrollbar
    >
        <!--TABLA DE CALIFICACIONES DE LAS  UNIDADES y TRABAJOS DE LA MATERIA-->
        <form
            fxLayout="column"
            fxLayoutAlign="start"
            name="form"
            fxFlex="1 0 auto"
        >
            <!--ACA EMPIEZA LA TABLA-->
            <div class="center">
                <mat-table
                    class="products-table"
                    #table
                    [dataSource]="Tabla"
                    matSort
                    [@animateStagger]="{ value: '50' }"
                    fusePerfectScrollbar
                >
                    <!-- Columna Nombre  -->
                    <ng-container matColumnDef="nombre">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                            >Nombre</mat-header-cell
                        >
                        <mat-cell *matCellDef="let nombre">
                            <p>{{ nombre.nome_centro_custo }}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna Contrato -->
                    <ng-container matColumnDef="contrato">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                            >Contrato</mat-header-cell
                        >
                        <mat-cell *matCellDef="let contrato">
                            <p>
                                {{ contrato.nome_departamento }}
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna Fecha de vencimiento -->
                    <ng-container matColumnDef="fechavenc">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                            >Fecha Vencimiento</mat-header-cell
                        >
                        <mat-cell *matCellDef="let fechavenc">
                            <p>{{ fechavenc.data_vencimento }}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna valor -->
                    <ng-container matColumnDef="valor">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                            >Valor</mat-header-cell
                        >
                        <mat-cell *matCellDef="let valor">
                            <p>{{ valor.valor | currency: "PYG":"₲ " }}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna acciones -->
                    <ng-container matColumnDef="acciones">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                            >Acciones</mat-header-cell
                        >
                        <mat-cell *matCellDef="let dato">
                            <button
                                (click)="borrarItem(dato)"
                                mat-button
                                color="accent"
                            >
                                Borrar
                            </button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row
                        *matHeaderRowDef="displayedColumns; sticky: true"
                    ></mat-header-row>

                    <mat-row
                        *matRowDef="let product; columns: displayedColumns"
                        class="product"
                        matRipple
                    >
                    </mat-row>
                </mat-table>
                <!--ACA TERMINA LA TABLA-->
            </div>

            <div
                fxLayoutAlign="center"
                style="margin-bottom: 24px; margin-top: 30px"
            ></div>

            <div fxLayout="row" fxLayoutAlign="start" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Seimulador de Descuento</mat-label>
                    <mat-select
                        required
                        (selectionChange)="calcularDescuento($event.value)"
                    >
                        <mat-option
                            *ngFor="let descuento of descuentos"
                            [value]="descuento.porcentaje"
                        >
                            {{ descuento.porcentaje }} %
                        </mat-option>
                    </mat-select>

                    <mat-error>Por favor elija una materia.</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Metodo de Pago</mat-label>
                    <mat-select
                        required
                        (selectionChange)="metodoDePago($event.value)"
                    >
                        <mat-option value="1"> Boleto Flash </mat-option>
                        <mat-option value="2"> Comprovante </mat-option>
                    </mat-select>

                    <mat-error>Por favor elija un metodo de pago.</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start" fxFlex="1 0 auto">
                <div appearance="outline" fxFlex="50" class="total">
                    <strong
                        ><label
                            >Total en Guaranies:
                            {{ TotalGuaranies | currency: "PYG":"₲ " }}
                        </label></strong
                    >
                </div>

                <div appearance="outline" fxFlex="50" class="total pl-4">
                    <strong
                        ><label
                            >Total en Reales:
                            {{ TotalReales | currency: "BRL":"R$ " }}
                        </label></strong
                    >
                </div>
            </div>

            <div
                class="mb-24"
                fxLayout="column"
                fxLayoutAlign="start"
                fxLayout.gt-lg="row"
                fxLayout.gt-md="column"
                *ngIf="metodoPago === 1"
                style="margin-top: 5%"
            >
                <!--EMPIEZA PRIMERA LINEA DE INPUTS-->
                <div
                    class="inputs"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Nome</mat-label>
                        <input
                            matInput
                            required
                            [(ngModel)]="datosComprobantes.nome_sobrenome"
                            [disabled]="true"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="pl-4"
                        fxFlex="50"
                    >
                        <mat-label>CPF</mat-label>
                        <input
                            matInput
                            required
                            [(ngModel)]="datosComprobantes.cpf"
                            [disabled]="true"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </mat-form-field>
                </div>
                <!--TERMINA PRIMERA LINEA DE INPUTS-->

                <!--EMPIEZA SEGUNDA LINEA DE INPUTS-->
                <div
                    class="inputs"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Cidade</mat-label>
                        <input
                            matInput
                            required
                            [(ngModel)]="datosComprobantes.nome_cidade"
                            [disabled]="true"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="50"
                        class="pl-4"
                    >
                        <mat-label>Estado</mat-label>
                        <input
                            matInput
                            required
                            [(ngModel)]="datosComprobantes.nome_estado"
                            [disabled]="true"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </mat-form-field>
                </div>
                <!--TERMINA SEGUNDA LINEA DE INPUTS-->

                <!--EMPIEZA TERCERA LINEA DE INPUTS-->
                <div
                    class="inputs"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field appearance="outline" fxFlex="70">
                        <mat-label>Email</mat-label>
                        <input
                            matInput
                            required
                            [(ngModel)]="datosComprobantes.email"
                            [disabled]="true"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="pl-4"
                        fxFlex="30"
                    >
                        <mat-label>CEP</mat-label>
                        <input
                            matInput
                            required
                            [(ngModel)]="datosComprobantes.cep"
                            [disabled]="true"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </mat-form-field>
                </div>
                <!--TERMINA TERCERA LINEA DE INPUTS-->

                <!--EMPIEZA CUARTA LINEA DE INPUTS-->
                <div
                    class="inputs"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Endereco</mat-label>
                        <input
                            matInput
                            required
                            [(ngModel)]="datosComprobantes.rua"
                            [disabled]="true"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="pl-4"
                        fxFlex="50"
                    >
                        <mat-label>Telefone</mat-label>
                        <input
                            matInput
                            required
                            [(ngModel)]="datosComprobantes.telefone_celular1"
                            [disabled]="true"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </mat-form-field>
                </div>
                <!--TERMINA CUARTA LINEA DE INPUTS-->
            </div>

            <div
                class="mb-24"
                fxLayout="column"
                fxLayoutAlign="start"
                fxLayout.gt-lg="row"
                fxLayout.gt-md="column"
                *ngIf="metodoPago === 2"
                style="margin-top: 5%"
            >
                <!--EMPIEZA PRIMERA LINEA DE INPUTS-->
                <div
                    class="inputs"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field appearance="outline" fxFlex="70">
                        <mat-label>Nome</mat-label>
                        <input
                            matInput
                            required
                            [(ngModel)]="datosComprobantes.nome_sobrenome"
                            [ngModelOptions]="{ standalone: true }"
                            [disabled]="true"
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="30"
                        class="pl-4"
                    >
                        <mat-label>Valor del comprovante</mat-label>
                        <input matInput required />
                        <mat-error
                            >Por favor ingrese el valor del
                            comprovante.</mat-error
                        >
                    </mat-form-field>
                </div>
                <!--TERMINA PRIMERA LINEA DE INPUTS-->

                <!--EMPIEZA SEGUNDA LINEA DE INPUTS-->
                <div
                    class="inputs"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field appearance="outline" fxFlex="35">
                        <mat-label>Data e hora do deposito</mat-label>
                        <input
                            readonly
                            matInput
                            [matDatepicker]="startDatePicker"
                            name="start"
                        />

                        <mat-datepicker-toggle
                            matSuffix
                            [for]="startDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                        <mat-error
                            >Por favor elija la data e hora do
                            deposito.</mat-error
                        >
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="65"
                        class="pl-4"
                    >
                        <mat-label>Descricao</mat-label>
                        <input matInput required />
                        <mat-error>Por favor ingrese la descricao.</mat-error>
                    </mat-form-field>
                </div>
                <!--TERMINA SEGUNDA LINEA DE INPUTS-->

                <!--EMPIEZA TERCERA LINEA DE INPUTS-->
                <div
                    class="inputs"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>WhatsApp</mat-label>
                        <input
                            matInput
                            required
                            [(ngModel)]="datosComprobantes.telefone_celular1"
                            [ngModelOptions]="{ standalone: true }"
                            [disabled]="true"
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="50"
                        class="pl-4"
                    >
                        <mat-label>Negociado con</mat-label>
                        <mat-select required>
                            <mat-option> Juan </mat-option>
                            <mat-option> Pedro </mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="secondary-text"
                            >outlined_flag</mat-icon
                        >
                        <mat-error>Por favor elija un negociador.</mat-error>
                    </mat-form-field>
                </div>
                <!--TERMINA TERCERA LINEA DE INPUTS-->

                <!--EMPIEZA CUARTA LINEA DE INPUTS-->
                <div
                    class="inputs"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Conta Banco</mat-label>
                        <input matInput required />
                        <mat-error>Por favor ingrese la conta banco.</mat-error>
                    </mat-form-field>

                    <div class="pl-4">
                        <input
                            style="display: none"
                            type="file"
                            #fileInput
                            name="file"
                            required
                        />
                        <button
                            mat-button
                            color="accent"
                            (click)="fileInput.click()"
                        >
                            Seleccionar archivo comprobante
                        </button>
                    </div>
                </div>
                <!--TERMINA CUARTA LINEA DE INPUTS-->
            </div>

            <div>
                <div fxLayoutAlign="center">
                    <button
                        [disabled]="!eligioMetodoPago"
                        mat-button
                        color="accent"
                        (click)="enviarDatosComprobante()"
                    >
                        Finalizar Pago
                    </button>
                </div>

                <!-- <mat-paginator
                    #paginator
                    [length]="tablaPago.length"
                    [pageIndex]="0"
                    [pageSize]="5"
                    [pageSizeOptions]="[10, 25, 100]"
                >
                </mat-paginator>-->
            </div>
        </form>
    </div>
</div>

<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <button
                mat-icon-button
                class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md
            >
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div
                class="toolbar-separator"
                *ngIf="!hiddenNavbar && !rightNavbar"
                fxHide.gt-md
            ></div>

            <div
                fxLayout="row"
                fxLayoutAlign="start center"
                *ngIf="horizontalNavbar"
            >
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/fuse.svg" />
                </div>
            </div>

            <!--  <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div>-->
        </div>

        <div
            class=""
            fxFlex="0 1 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <button
                mat-button
                [matMenuTriggerFor]="userMenu"
                class="user-button"
            >
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img
                        class="avatar mr-0 mr-sm-16"
                        src="assets/images/avatars/Velazquez.jpg"
                    />
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{
                        usuario
                    }}</span>
                    <mat-icon class="s-16" fxHide.xs
                        >keyboard_arrow_down</mat-icon
                    >
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <!--  <button mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>Mi perfil</span>
                </button>-->

                <button mat-menu-item class="" (click)="actualizarDatos()">
                    <mat-icon>person</mat-icon>
                    <span>Actualizar Datos</span>
                </button>

                <button mat-menu-item class="" (click)="logOut()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Cerrar Sesion</span>
                </button>
            </mat-menu>

            <div class="toolbar-separator"></div>

            <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar>-->

            <div class="toolbar-separator"></div>

            <!--<button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="
                            'assets/icons/flags/' +
                            selectedLanguage.flag +
                            '.png'
                        " />
                    <span class="iso text-uppercase">{{
                        selectedLanguage.id
                    }}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/' + lang.flag + '.png'" />
                        <span class="iso">{{ lang.title }}</span>
                    </span>
                </button>
            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>-->

            <button
                mat-icon-button
                class="quick-panel-toggle-button"
                (click)="toggleSidebarOpen('quickPanel')"
                aria-label="Toggle quick panel"
            >
                <!-- <mat-icon *ngIf="role === 'ROLE_ALUMNO'" class="secondary-text" [matBadge]="cantidadNotificaciones"
                    matBadgeColor="warn">
                    notifications</mat-icon>-->
                <mat-icon *ngIf="role !== 'ROLE_ALUMNO'" class="secondary-text"
                    >reorder</mat-icon
                >
                <mat-icon *ngIf="role === 'ROLE_ALUMNO'" class="secondary-text"
                    >reorder</mat-icon
                >
            </button>

            <div
                class="toolbar-separator"
                *ngIf="!hiddenNavbar && rightNavbar"
                fxHide
                fxShow.gt-xs
            ></div>

            <button
                mat-icon-button
                class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && rightNavbar"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md
            >
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>

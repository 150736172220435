<div id="academy-courses" class="page-layout simple inner-scroll">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Actualizar Datos
            </h1>
        </div>
    </div>
    <!--TERMINA HEADER-->
    <!-- CONTENT -->
    <div fusePerfectScrollbar>
        <div
            class="mb-24"
            fxLayout="column"
            fxLayoutAlign="start"
            fxLayout.gt-lg="row"
            fxLayout.gt-md="column"
        >
            <!-- REACTIVE FORM EXAMPLE -->

            <form
                fxLayout="column"
                fxLayoutAlign="center"
                fxFlex="1 0 auto"
                name="form"
                [formGroup]="datosAlumno"
            >
                <h3>Datos Pessoais</h3>

                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="33"
                        class="pr-4"
                    >
                        <mat-label>Nombre</mat-label>
                        <input matInput required formControlName="nombre" />
                        <mat-error>Por favor ingrese el nombre.</mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="34"
                        class="pr-4"
                    >
                        <mat-label>Sobrenome</mat-label>
                        <input matInput required formControlName="sobrenome" />
                        <mat-error>Por favor ingrese el sobrenome.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="33">
                        <mat-label>Data Nascimento</mat-label>
                        <input matInput required formControlName="nascimento" />
                        <mat-error>Por favor ingrese el nascimento.</mat-error>
                    </mat-form-field>
                </div>

                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="40"
                        class="pr-4"
                    >
                        <mat-label>Tipo Documento</mat-label>
                        <mat-select formControlName="tipoDocumento" required>
                            <mat-option value="Cedula de identidad"
                                >Cedula de identidad</mat-option
                            >
                            <mat-option
                                value="Certificado de nacimiento/casamiento"
                                >Certificado de
                                nacimiento/casamiento</mat-option
                            >
                            <mat-option value="Certificado de estudios"
                                >Certificado de estudios</mat-option
                            >
                            <mat-option value="Titulo de bachiller"
                                >Titulo de bachiller</mat-option
                            >
                            <mat-option value="Foto 3x4">Foto 3x4</mat-option>
                            <mat-option value="Migracion">Migracion</mat-option>
                            <mat-option value="Mesa de entrada"
                                >Mesa de entrada</mat-option
                            >
                            <mat-option value="Historico universitario"
                                >Historico universitario</mat-option
                            >
                            <mat-option value="Programa analitico"
                                >Programa analitico</mat-option
                            >
                            <mat-option value="Traducion">Traducion</mat-option>
                            <mat-option value="Legalizado"
                                >Legalizado</mat-option
                            >
                            <mat-option value="RUC">RUC</mat-option>
                        </mat-select>
                        <mat-error
                            >Por favor elija un tipo de documento.</mat-error
                        >
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="30"
                        class="pr-4"
                    >
                        <mat-label>Documento Oficial</mat-label>
                        <input matInput formControlName="docOficial" required />
                        <mat-error>Por favor ingrese el documento.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="30">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" required />
                        <mat-error>Por favor ingrese el email.</mat-error>
                    </mat-form-field>
                </div>

                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="33"
                        class="pr-4"
                    >
                        <mat-label>Nacionalidad</mat-label>
                        <mat-select formControlName="nacionalidad" required>
                            <mat-option value="Brasilera">
                                Brasilera
                            </mat-option>
                            <mat-option value="Paraguaya">
                                Paraguaya
                            </mat-option>
                        </mat-select>
                        <mat-error>Por favor elija una nacionalidad.</mat-error>
                    </mat-form-field>

                    <mat-form-field
                        *ngIf="datosAlumno.value.nacionalidad === 'Brasilera'"
                        appearance="outline"
                        fxFlex="34"
                        class="pr-4"
                    >
                        <mat-label>CPF</mat-label>
                        <input matInput formControlName="cpf" required />
                        <mat-error>Por favor ingrese el cpf.</mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="33"
                        *ngIf="datosAlumno.value.nacionalidad === 'Brasilera'"
                    >
                        <mat-label>CEP</mat-label>
                        <input matInput formControlName="cep" required />
                        <mat-error>Por favor ingrese el cep.</mat-error>
                    </mat-form-field>
                </div>

                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="33"
                        class="pr-4"
                    >
                        <mat-label>Sexo</mat-label>
                        <mat-select formControlName="sexo" required>
                            <mat-option value="Masculino">
                                Masculino
                            </mat-option>
                            <mat-option value="Femenino"> Femenino </mat-option>
                        </mat-select>
                        <mat-error>Por favor elija un sexo.</mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="34"
                        class="pr-4"
                    >
                        <mat-label>Estado Civil</mat-label>
                        <mat-select formControlName="estadoCivil" required>
                            <mat-option value="Soltero"> Soltero </mat-option>
                            <mat-option value="Separado"> Separado </mat-option>
                            <mat-option value="Casado"> Casado </mat-option>
                            <mat-option value="Divorciado">
                                Divorciado
                            </mat-option>
                        </mat-select>
                        <mat-error>Por favor elija un estado civil.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="33">
                        <mat-label>Escolaridade</mat-label>
                        <mat-select formControlName="escolaridad" required>
                            <mat-option value="Primaria"> Primaria </mat-option>
                            <mat-option value="Secundaria">
                                Secundaria
                            </mat-option>
                            <mat-option value="Universitaria">
                                Universitaria
                            </mat-option>
                        </mat-select>
                        <mat-error>Por favor elija una escolaridad.</mat-error>
                    </mat-form-field>
                </div>
                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="33"
                        class="pr-4"
                    >
                        <mat-label>Bairro</mat-label>
                        <input matInput formControlName="bairro" required />
                        <mat-error>Por favor ingrese el bairro.</mat-error>
                    </mat-form-field>

                    <mat-form-field
                        *ngIf="datosAlumno.value.nacionalidad === 'Brasilera'"
                        appearance="outline"
                        fxFlex="34"
                        class="pr-4"
                    >
                        <mat-label>Tel. Fixo</mat-label>
                        <input matInput formControlName="telFixo" required />
                        <mat-error>Por favor ingrese el Tel. Fixo.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="33">
                        <mat-label>Seu numero de telefone e</mat-label>
                        <mat-select formControlName="tipoNumero" required>
                            <mat-option value="Brasilero">
                                Brasilero
                            </mat-option>
                            <mat-option value="Paraguayo">
                                Paraguayo
                            </mat-option>
                        </mat-select>
                        <mat-error
                            >Por favor elija su tipo de numero de
                            telefono.</mat-error
                        >
                    </mat-form-field>
                </div>

                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="33"
                        class="pr-4"
                    >
                        <mat-label>Celular 1</mat-label>
                        <input matInput formControlName="celular1" required />
                        <mat-error>Por favor ingrese el celular 1</mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="34"
                        class="pr-4"
                    >
                        <mat-label>Celular 2 (opcional)</mat-label>
                        <input matInput formControlName="celular2" required />
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="33">
                        <mat-label>Profissao</mat-label>
                        <mat-select formControlName="profissao" required>
                            <mat-option value="Cozinheira"
                                >Cozinheira</mat-option
                            >
                            <mat-option value="Paraguayo"
                                >Licenciado</mat-option
                            >
                            <mat-option value="Professor">Professor</mat-option>
                            <mat-option value="Estudiante"
                                >Estudiante</mat-option
                            >
                            <mat-option value="Ingeniero">Ingeniero</mat-option>
                        </mat-select>
                        <mat-error
                            >Por favor elija su tipo de numero de
                            telefono.</mat-error
                        >
                    </mat-form-field>
                </div>

                <h3>Naturalidade</h3>

                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="33"
                        class="pr-4"
                    >
                        <mat-label>Pais</mat-label>
                        <mat-select formControlName="pais" required>
                            <mat-option
                                (click)="estado(pais.id)"
                                *ngFor="let pais of paises"
                                [value]="pais.id"
                            >
                                {{ pais.nome }}
                            </mat-option>
                        </mat-select>
                        <mat-error>Por favor elija un pais.</mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="34"
                        class="pr-4"
                    >
                        <mat-label>Estado</mat-label>
                        <mat-select formControlName="estado" required>
                            <mat-option
                                (click)="ciudad(estado.id)"
                                [value]="estado.id"
                                *ngFor="let estado of estados"
                            >
                                {{ estado.nome }}
                            </mat-option>
                        </mat-select>
                        <mat-error>Por favor elija un estado.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="33">
                        <mat-label>Cidade</mat-label>
                        <mat-select formControlName="cidade" required>
                            <mat-option
                                [value]="ciudad.id"
                                *ngFor="let ciudad of ciudades"
                            >
                                {{ ciudad.nome }}
                            </mat-option>
                        </mat-select>
                        <mat-error>Por favor elija una ciudad.</mat-error>
                    </mat-form-field>
                </div>

                <h3>Endereço</h3>

                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="20"
                        class="pr-4"
                    >
                        <mat-label>Tipo</mat-label>
                        <mat-select formControlName="tipoEndereco" required>
                            <mat-option value="AV"> AV </mat-option>
                            <mat-option value="RUA"> RUA </mat-option>
                        </mat-select>
                        <mat-error>Por favor elija un tipo.</mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="30"
                        class="pr-4"
                    >
                        <mat-label>Endereço</mat-label>
                        <input matInput formControlName="endereco" required />
                        <mat-error>Por favor ingrese la endereço.</mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="20"
                        class="pr-4"
                    >
                        <mat-label>Numero</mat-label>
                        <input
                            matInput
                            formControlName="numeroEndereco"
                            required
                        />
                        <mat-error>Por favor ingrese el numero.</mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="30"
                        class="pr-4"
                    >
                        <mat-label>Complemento</mat-label>
                        <input
                            matInput
                            formControlName="complemento"
                            required
                        />
                        <mat-error>Por favor ingrese el complemento.</mat-error>
                    </mat-form-field>
                </div>

                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="33"
                        class="pr-4"
                    >
                        <mat-label>Pais</mat-label>
                        <mat-select formControlName="paisEndereco" required>
                            <mat-option
                                (click)="estado(pais.id)"
                                *ngFor="let pais of paises"
                                [value]="pais.id"
                            >
                                {{ pais.nome }}
                            </mat-option>
                        </mat-select>
                        <mat-error>Por favor elija un pais.</mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="34"
                        class="pr-4"
                    >
                        <mat-label>Estado</mat-label>
                        <mat-select formControlName="estadoEndereco" required>
                            <mat-option
                                (click)="ciudad(estado.id)"
                                [value]="estado.id"
                                *ngFor="let estado of estados"
                            >
                                {{ estado.nome }}
                            </mat-option>
                        </mat-select>
                        <mat-error>Por favor elija un estado.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="33">
                        <mat-label>Cidade</mat-label>
                        <mat-select formControlName="cidadeEndereco" required>
                            <mat-option> Ciudad 1 </mat-option>
                            <mat-option> Ciudad 2 </mat-option>
                            <mat-option> Ciudad 3 </mat-option>
                        </mat-select>
                        <mat-error>Por favor elija una ciudad.</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="end" fxFlex="1 0 auto">
                    <button mat-button color="accent" (click)="guardar()">
                        Guardar
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div id="academy-courses" class="page-layout simple" fusePerfectScrollbar>
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Editar Pregunta
            </h1>
        </div>
    </div>
    <!--TERMINA HEADER-->

    <div
        class="mb-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-lg="row"
        fxLayout.gt-md="column"
        fusePerfectScrollbar
    >
        <form
            fxLayout="column"
            fxLayoutAlign="center"
            fxFlex="1 0 auto"
            name="form"
        >
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
                    <mat-label>Pregunta</mat-label>
                    <input
                        matInput
                        [(ngModel)]="pregunta.ENUNCIADO"
                        required
                        [ngModelOptions]="{ standalone: true }"
                    />
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
                    <mat-label>Descripcion de la Respuesta Correcta</mat-label>
                    <input
                        matInput
                        [(ngModel)]="pregunta.EXPLICACION"
                        required
                        [ngModelOptions]="{ standalone: true }"
                    />
                </mat-form-field>
            </div>
            <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
                *ngFor="let respuesta of respuestas; let i = index"
            >
                <!--RESPUESTAS CON RADIO BUTTON-->
                <mat-radio-group
                    [(ngModel)]="id_respuesta_correcta"
                    [ngModelOptions]="{ standalone: true }"
                    fxFlex="5"
                >
                    <mat-radio-button
                        [value]="respuesta.ID"
                        [checked]="respuesta.CORRECTA === 1"
                    >
                    </mat-radio-button>
                </mat-radio-group>
                <!--INPUT DE LA RESPUESTA-->
                <mat-form-field appearance="outline" fxFlex="95">
                    <mat-label>Respuesta {{ i + 1 }}</mat-label>
                    <input
                        matInput
                        [(ngModel)]="respuesta.TEXTO"
                        required
                        [ngModelOptions]="{ standalone: true }"
                    />
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="end" fxFlex="1 0 auto">
                <button
                    mat-button
                    color="primary"
                    class="save-button"
                    (click)="save()"
                    aria-label="SAVE"
                >
                    Guardar
                </button>
            </div>
        </form>
    </div>
</div>

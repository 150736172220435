import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { ListadoTrabajos } from "../administradores.modal";

@Component({
    selector: "app-crear-trabajo-practico",
    templateUrl: "./crear-trabajo-practico.component.html",
    styleUrls: ["./crear-trabajo-practico.component.scss"],
    animations: fuseAnimations,
})
export class CrearTrabajoPracticoComponent implements OnInit {
    displayedColumns: string[] = ["Materia", "Unidad", "Nombre", "Acciones"];
    form: FormGroup;
    dataSource: ListadoTrabajos[] = [
        {
            ID: 1,
            NOMBRE: "Trabajo practico individual 1",
            MATERIA: "Biologia",
            UNIDAD: 3,
        },
        {
            ID: 2,
            NOMBRE: "Trabajo practico individual 3",
            MATERIA: "Anatomia",
            UNIDAD: 2,
        },
    ];

    constructor(private _formBuilder: FormBuilder) {}

    ngOnInit(): void {
        // Reactive Form
        this.form = this._formBuilder.group({
            materia: ["", Validators.required],
            unidad: ["", Validators.required],
            nombre: ["", Validators.required],
        });
    }

    crearTrabajo() {}

    editarTrabajo() {}

    eliminarTrabajo() {}
}

<div id="academy-courses" class="page-layout simple">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="hero-text">
            <mat-icon class="hero-icon">school</mat-icon>
            <h1
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Informe de Evaluaciones
            </h1>
        </div>
    </div>

    <div class="content p-12" fxLayout="column">
        <div
            class="mb-24"
            fxLayout="row"
            fxLayoutAlign="start"
            id="products"
            fxFlex="1 0 auto"
        >
            <form
                class="mat-card mat-elevation-z4 p-24"
                fxLayout="column"
                fxLayoutAlign="start"
                fxFlex="1 0 auto"
                name="form"
            >
                <!-- <div class="h2 mb-24">Preguntas</div>-->
                <!--ACA EMPIEZA LA TABLA-->
                <!-- ACA EMPIEZASELECCIONAR mostrar-->
                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="25"
                        class="pr-4"
                    >
                        <mat-label>Filtro</mat-label>
                        <mat-select multiple>
                            <div fxLayout="column" class="p-16">
                                <div
                                    fxLayout="column"
                                    fxLayoutAlign="start"
                                    fxFlex="1 0 auto"
                                >
                                    <label class="mb-4"
                                        ><strong>Sede</strong></label
                                    >

                                    <mat-option
                                        (click)="
                                            filtrado2(
                                                sede.texto,
                                                'NOMBRE_FILIAL'
                                            )
                                        "
                                        *ngFor="let sede of filtroSede"
                                        [value]="sede.texto"
                                    >
                                        {{ sede.texto }}
                                    </mat-option>
                                    <label class="mb-4"
                                        ><strong
                                            >Tipo de Evaluacion</strong
                                        ></label
                                    >
                                    <div fxLayout="column" class="pr-8">
                                        <mat-option
                                            [value]="tipo.id"
                                            (click)="
                                                filtrado2(
                                                    tipo.texto,
                                                    'NOMBRE_TIPO_EXAMEN'
                                                )
                                            "
                                            *ngFor="let tipo of filtroTipoEval"
                                        >
                                            {{ tipo.texto }}
                                        </mat-option>
                                    </div>
                                </div>

                                <div
                                    fxLayout="column"
                                    fxLayoutAlign="start"
                                    fxFlex="1 0 auto"
                                >
                                    <label class="mb-4"
                                        ><strong>Semestre</strong></label
                                    >
                                    <div fxLayout="column" class="pr-8">
                                        <mat-option
                                            (click)="
                                                filtrado2(
                                                    semestre.texto,
                                                    'nome_semestre'
                                                )
                                            "
                                            *ngFor="
                                                let semestre of filtroSemestre
                                            "
                                            [value]="semestre.texto"
                                        >
                                            {{ semestre.texto }}
                                        </mat-option>
                                    </div>
                                </div>
                            </div>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="70">
                        <mat-label>Buscar</mat-label>
                        <input
                            [(ngModel)]="term"
                            matInput
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </mat-form-field>
                    <mat-form-field
                        appearance="outline"
                        fxFlex="30"
                        class="pl-4"
                    >
                        <mat-label>Mostrar</mat-label>
                        <mat-select
                            [(value)]="itemsPerPage"
                            (selectionChange)="p = 1"
                        >
                            <mat-option value="5"> 5 </mat-option>
                            <mat-option value="10"> 10 </mat-option>
                            <mat-option value="15"> 15 </mat-option>
                            <mat-option value="20"> 20 </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!--ACA TERMINA SELECCIONAR MOSTRAR-->

                <mat-table
                    fxFlex="1 0 auto"
                    class="products-table"
                    #table
                    [dataSource]="
                        informeDeEvaluaciones
                            | filter: term
                            | paginate
                                : {
                                      itemsPerPage: itemsPerPage,
                                      currentPage: p
                                  }
                    "
                    [@animateStagger]="{ value: '50' }"
                    fusePerfectScrollbar
                    matSort
                    (matSortChange)="sortData($event)"
                >
                    <!-- Columna FECHA -->
                    <ng-container matColumnDef="fecha">
                        <mat-header-cell
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="FECHA_FIN"
                            >Fecha
                        </mat-header-cell>
                        <mat-cell
                            fxLayoutAlign="start"
                            *matCellDef="let informe"
                        >
                            <div fxLayout="column">
                                <!--<p style="margin-bottom: 0%">Inicio</p>
                                <p style="white-space: normal; margin-top: 2px">
                                    {{ informe.FECHA_INICIO }}
                                </p>-->
                                <p style="margin-bottom: 0%">Fin</p>
                                <p style="white-space: normal; margin-top: 2px">
                                    {{ informe.FECHA_FIN }}
                                </p>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna TIPO -->
                    <ng-container matColumnDef="tipo">
                        <mat-header-cell
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="NOMBRE_TIPO_EXAMEN"
                            >Tipo
                        </mat-header-cell>
                        <mat-cell
                            fxLayoutAlign="start"
                            *matCellDef="let informe"
                        >
                            <p style="white-space: normal">
                                {{ informe.NOMBRE_TIPO_EXAMEN }}
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna SEDE -->
                    <ng-container matColumnDef="sede">
                        <mat-header-cell
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="NOMBRE_FILIAL"
                            >Sede
                        </mat-header-cell>
                        <mat-cell
                            fxLayoutAlign="start"
                            *matCellDef="let informe"
                        >
                            <p style="white-space: normal">
                                {{ informe.NOMBRE_FILIAL }}
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna SEMESTRE -->
                    <ng-container matColumnDef="semestre">
                        <mat-header-cell
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="nome_semestre"
                        >
                            Semestre
                        </mat-header-cell>
                        <mat-cell
                            fxLayoutAlign="start"
                            *matCellDef="let informe"
                        >
                            <p style="white-space: normal">
                                {{ informe.nome_semestre }}
                                {{ informe.nome_turma }}
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna MATERIA -->
                    <ng-container matColumnDef="materia">
                        <mat-header-cell
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="nome"
                            >Materia
                        </mat-header-cell>
                        <mat-cell
                            fxLayoutAlign="start"
                            *matCellDef="let informe"
                        >
                            <p style="white-space: normal">
                                {{ informe.nome }}
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna CONTENIDOS -->
                    <ng-container matColumnDef="contenidos">
                        <mat-header-cell
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header
                            >Contenidos
                        </mat-header-cell>
                        <mat-cell
                            fxLayoutAlign="start"
                            *matCellDef="let informe"
                        >
                            <p style="white-space: normal">contenidos</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna profesor -->
                    <ng-container matColumnDef="profesor">
                        <mat-header-cell
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="NOMBRE"
                            >Profesor
                        </mat-header-cell>
                        <mat-cell
                            fxLayoutAlign="start"
                            *matCellDef="let informe"
                        >
                            <p style="white-space: normal">
                                {{ informe.NOMBRE }} {{ informe.APELIDO }}
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna STATUS -->
                    <ng-container matColumnDef="status">
                        <mat-header-cell
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header="status"
                            >Status
                        </mat-header-cell>
                        <mat-cell
                            fxLayoutAlign="start"
                            *matCellDef="let informe"
                        >
                            <p style="white-space: normal">
                                {{ informe.status }}
                            </p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna AVANCE -->
                    <ng-container matColumnDef="avance">
                        <mat-header-cell
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header
                            >Avance
                        </mat-header-cell>
                        <mat-cell
                            fxLayoutAlign="start"
                            *matCellDef="let informe"
                        >
                            <p style="white-space: normal">avance</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Columna ACCIONES -->
                    <ng-container matColumnDef="acciones">
                        <mat-header-cell
                            fxLayoutAlign="start"
                            *matHeaderCellDef
                            mat-sort-header
                            >Acciones
                        </mat-header-cell>
                        <mat-cell
                            fxLayout="column"
                            fxLayoutAlign="start"
                            *matCellDef="let informe"
                        >
                            <button
                                mat-button
                                color="accent"
                                (click)="verEvaluacion(informe.ID_EXAMEN)"
                            >
                                Ver
                            </button>

                            <button
                                mat-button
                                color="accent"
                                (click)="descargarEvaluacion()"
                            >
                                Descargar
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row
                        *matHeaderRowDef="displayedColumns; sticky: true"
                    ></mat-header-row>

                    <mat-row
                        *matRowDef="let product; columns: displayedColumns"
                        class="product"
                        matRipple
                    >
                    </mat-row>
                </mat-table>
                <pagination-controls
                    (pageChange)="p = $event"
                    autoHide="true"
                    responsive="true"
                    previousLabel="Anterior"
                    nextLabel="Siguiente"
                ></pagination-controls>

                <!--ACA TERMINA LA TABLA-->

                <!--<div
                    fxLayout="row"
                    fxLayoutAlign="end"
                    fxFlex="1 0 auto"
                    style="margin-top: 20px"
                >
                    <h1 *ngIf="print" style="margin-right: 15%">
                        --------------
                    </h1>

                    <div style="margin-top: 2.5%">
                        <button (click)="Imprimir()" mat-button color="accent">
                            Imprimir
                        </button>
                    </div>
                </div>-->
            </form>
        </div>
    </div>
</div>

import { Component, OnInit } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { AdministradoresService } from "../administradores.service";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import Swal from "sweetalert2";
import { ModalEditarExamenAdminComponent } from "./modal-editar-examen-admin/modal-editar-examen-admin.component";
import {
    ExamenesList,
    ListaSemestres,
    ListaMaterias,
    Materias,
    SemestresMateria,
} from "../administradores.modal";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { isUndefined } from "lodash";
import { ModalListaPreguntasComponent } from "./modal-lista-preguntas/modal-lista-preguntas.component";

export interface filtroExamen {
    id?: number;
    texto?: string;
}

@Component({
    selector: "app-lista-examenes-admin",
    templateUrl: "./lista-examenes-admin.component.html",
    styleUrls: ["./lista-examenes-admin.component.scss"],
    animations: fuseAnimations,
})
export class ListaExamenesAdminComponent implements OnInit {
    print = false;
    displayedColumns: string[] = [
        "index",
        "semestre",
        "materia",
        "profesor",
        "puntaje",
        "duracion",
        "fecha",
        "fecha_fin",
        "status",
        "acciones",
    ];
    //Declaracion de filtros
    filtroTipoEval: filtroExamen[] = [];
    filtroSemestre: filtroExamen[] = [];
    filtroSede: filtroExamen[] = [];

    listadoExamenes: ExamenesList[] = [];
    filtradoExamenes: ExamenesList[] = [];
    filtradoSeleccionados = [];
    p: number = 1;
    form: FormGroup;
    listaSede: any[] = [];
    listaMateriasReal: Materias[] = [];
    // listaSemestreReal: SemestresMateria[] = [];
    dialogRef: any;
    id_materia: number;
    itemsPerPage: number = 10;
    term: string;
    id_semestre: number;
    id_sede: number;

    constructor(
        private administradoresService: AdministradoresService,
        private _formBuilder: FormBuilder,
        private _matDialog: MatDialog,
        private route: Router
    ) {
        this.listadoExamenes = this.listadoExamenes.slice();
    }

    ngOnInit(): void {
        // Reactive Form
        this.form = this._formBuilder.group({
            sede: ["", Validators.required],
            semestre: ["", Validators.required],
            materia: ["", Validators.required],
        });
        this.listaSede = [
            {
                ID: 1,
                NOMBRE_SEDE: "CDE LAGO",
            },
            {
                ID: 2,
                NOMBRE_SEDE: "PJC",
            },
            {
                ID: 3,
                NOMBRE_SEDE: "CDE JESUITICA",
            },
        ];
        this.getListaExamenes();
    }
    getListaExamenes() {
        this.loading("Cargando Examenes..");
        this.administradoresService
            .getListaExamenesFinal()
            .subscribe((data) => {
                //console.log("Este es el data", data);
                this.listadoExamenes = data;
                this.filtradoExamenes = JSON.parse(
                    JSON.stringify(this.listadoExamenes)
                );

                //Carga de los filtros
                for (let exam of this.listadoExamenes) {
                    if (
                        this.filtroSemestre.filter(
                            (x) => x.texto == exam.nome_semestre
                        ).length == 0
                    ) {
                        this.filtroSemestre.push({
                            texto: exam.nome_semestre,
                        });
                    }
                    if (
                        this.filtroSede.filter(
                            (x) => x.texto == exam.NOMBRE_FILIAL
                        ).length == 0
                    ) {
                        this.filtroSede.push({
                            texto: exam.NOMBRE_FILIAL,
                        });
                    }

                    if (
                        this.filtroTipoEval.filter(
                            (x) => x.texto == exam.NOMBRE_TIPO_EXAMEN
                        ).length == 0
                    ) {
                        this.filtroTipoEval.push({
                            texto: exam.NOMBRE_TIPO_EXAMEN,
                        });
                    }
                }

                Swal.close();
            });
    }

    eliminarExamen(id_examen) {
        console.log("Este es el id_examen", id_examen);
        /*this.profesoresService.deleteExamen(id_examen).subscribe(
            response => {
                console.log(response)
            }
        )*/
        Swal.fire({
            title: "Estas seguro de eliminar el examen?",
            //text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminalo!",
        }).then((result) => {
            if (result.value) {
                this.administradoresService.deleteExamen(id_examen).subscribe(
                    (response) => {
                        console.log(response);
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "El examen se elimino correctamente",
                            showConfirmButton: false,
                            timer: 1500,
                        }).then(() => {
                            //this.loading("Actualizando Lista de Examenes");
                            this.getMaterias(this.id_semestre);
                        });
                    },
                    (err) => {
                        Swal.fire(
                            "Upps",
                            "Hubo un problema con el servidor",
                            "error"
                        );
                    }
                );
            }
        });
    }

    loading(titulo) {
        Swal.fire({
            title: titulo,
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
    }

    Imprimir() {
        this.print = true;
        setTimeout(() => {
            window.print();
            this.print = false;
        }, 1);
    }

    /*getSemestres(id_sede) {
        this.id_sede = id_sede;
        this.loading("Cargando Examenes");
        this.administradoresService
            .getListadoSemestres(id_sede)
            .subscribe((data) => {
                this.listaSemestreReal = [...data];
                for (let semestre of this.listaSemestreReal) {
                    this.getMaterias(semestre.id);
                }
                console.log("Lista de Semestres", data);
            });
    }*/

    getMaterias(id_semestre) {
        this.id_semestre = id_semestre;
        this.listadoExamenes = [];
        //  this.loading("Cargando Materias");
        this.administradoresService
            .getListaMaterias(id_semestre)
            .subscribe((data) => {
                // Swal.close()
                this.listaMateriasReal = [...data];
                console.log("Lista de materias", data);
                for (let materia of this.listaMateriasReal) {
                    this.getExamenesMateria(
                        materia.id,
                        materia.nome,
                        materia.nome_semestre,
                        materia.nome_turma
                    );
                }
            });
    }
    getExamenesMateria(
        id_materia,
        nombre_materia,
        nombre_semestre,
        semestre_seccion
    ) {
        this.administradoresService
            .getListExamenesMateria(id_materia)
            .subscribe((data) => {
                if (data.length > 0) {
                    for (let examen of data) {
                        examen.NOMBRE_MATERIA = nombre_materia;
                        examen.SEMESTRE = nombre_semestre;
                        examen.SECCION = semestre_seccion;
                        examen.id_disciplina = id_materia;
                        this.listadoExamenes.push(examen);
                    }
                    console.log("Lista de Examenes", this.listadoExamenes);
                    console.log(data);
                }
                console.log("Examenes de la materia " + nombre_materia);
                console.log(data);
                Swal.close();
            });
        this.id_materia = id_materia;
    }
    editarExamen(examen) {
        this.dialogRef = this._matDialog.open(ModalEditarExamenAdminComponent, {
            data: {
                examen: examen,
                id_materia: this.id_materia,
            },
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            this.loading("Actualizando lista");
            this.getListaExamenes();
        });
    }
    verPreguntasExamen(id, id_profesor) {
        sessionStorage.setItem("id_disciplina", id);
        sessionStorage.setItem("id_profesor", id_profesor);
        sessionStorage.setItem("ruta", "/lista-examenes-admin");
        this.route.navigateByUrl("/cargar-unidad");
    }

    sortData(sort: MatSort) {
        console.log("Se llama al sort", sort);
        const data = this.filtradoExamenes.slice();
        if (!sort.active || sort.direction === "") {
            this.filtradoExamenes = data;
            return;
        }
        this.filtradoExamenes = data.sort((a, b) => {
            const isAsc = sort.direction === "asc";
            switch (sort.active) {
                case "NOMBRE_FILIAL":
                    return this.compare(
                        a.NOMBRE_FILIAL,
                        b.NOMBRE_FILIAL,
                        isAsc
                    );
                case "nome_semestre":
                    return this.compare(a.nome_turma, b.nome_turma, isAsc);
                case "nome":
                    return this.compare(a.nome, b.nome, isAsc);
                case "NOMBRE":
                    return this.compare(a.NOMBRE, b.NOMBRE, isAsc);
                case "TOTAL":
                    return this.compare(a.TOTAL, b.TOTAL, isAsc);
                case "DURACION":
                    return this.compare(a.DURACION, b.DURACION, isAsc);
                case "FECHA_INICIO":
                    return this.compare(a.FECHA_INICIO, b.FECHA_INICIO, isAsc);
                case "FECHA_FIN":
                    return this.compare(a.FECHA_FIN, b.FECHA_FIN, isAsc);
                case "status":
                    return this.compare(a.status, b.status, isAsc);

                default:
                    return 0;
            }
        });
    }
    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    filtrosSeleccionados = [];
    filtrado2(valor, propiedad) {
        //Logica del vector de filtros

        if (this.filtrosSeleccionados.length == 0) {
            this.filtrosSeleccionados.push({
                value: valor,
                propiedad: propiedad,
            });
        } else {
            if (
                this.filtrosSeleccionados.filter(
                    (x) => x.value == valor && x.propiedad == propiedad
                ).length > 0
            ) {
                let posicion = -1;
                let i = 0;
                for (let eliminar of this.filtrosSeleccionados) {
                    if (
                        eliminar.value == valor &&
                        eliminar.propiedad == propiedad
                    ) {
                        posicion = i;
                    }
                    i++;
                }
                this.filtrosSeleccionados.splice(posicion, 1);
            } else {
                this.filtrosSeleccionados.push({
                    value: valor,
                    propiedad: propiedad,
                });
            }
        }

        //Vector de filtros con datos correctos
        if (this.filtrosSeleccionados.length > 0) {
            //Si tenemos filtros

            //Preparar para los filtros internos
            let cantidadTipo = this.filtrosSeleccionados.filter(
                (x) => x.propiedad == "NOMBRE_TIPO_EXAMEN"
            ).length;
            let cantidadSede = this.filtrosSeleccionados.filter(
                (x) => x.propiedad == "NOMBRE_FILIAL"
            ).length;
            let cantidadSemestre = this.filtrosSeleccionados.filter(
                (x) => x.propiedad == "nome_semestre"
            ).length;

            console.log("Esots son los filtros", this.filtrosSeleccionados);
            console.log(
                "Cantidades ",
                cantidadTipo,
                cantidadSede,
                cantidadSemestre
            );

            //Agregar todos los filtros de una
            this.filtradoExamenes = this.listadoExamenes.filter((x) => {
                let pasa = false;

                let cumpleTipo = false;
                let cumpleSede = false;
                let cumpleSemestre = false;

                if (cantidadTipo == 0) {
                    cumpleTipo = true;
                }
                if (cantidadSede == 0) {
                    cumpleSede = true;
                }
                if (cantidadSemestre == 0) {
                    cumpleSemestre = true;
                }

                for (let filtro of this.filtrosSeleccionados) {
                    if (x[filtro.propiedad] == filtro.value) {
                        if (filtro.propiedad == "nome_semestre") {
                            cumpleSemestre = true;
                        }
                        if (filtro.propiedad == "NOMBRE_FILIAL") {
                            cumpleSede = true;
                        }
                        if (filtro.propiedad == "NOMBRE_TIPO_EXAMEN") {
                            cumpleTipo = true;
                        }
                    }
                }
                return cumpleTipo && cumpleSede && cumpleSemestre;
            });
        } else {
            this.filtradoExamenes = this.listadoExamenes;
        }
    }

    /*
    
    (click)="
                                                filtrado(
                                                    semestre.texto,
                                                    $event,
                                                    'nome_semestre'
                                                )
                                            "
    */
    filtrado(value, event, propiedadObjeto) {
        //Lo que selecciono
        console.log(value);
        //Si checkeo o saco el check
        console.log(event);
        //wat
        console.log("Objeto", propiedadObjeto);
        if (event) {
            //Si checkeo hacemos el push
            this.filtradoSeleccionados.push(propiedadObjeto);

            //Preguntamos si hay mas de un item seleccionado para el filtro
            if (this.filtradoSeleccionados.length > 1) {
                //Preguntamos si el checkbox que selecciono es diferente a lo que ya esta dentro del
                //array de filtradoSeleccionado
                if (
                    !isUndefined(
                        this.filtradoSeleccionados.filter(
                            (seleccionado) => seleccionado === propiedadObjeto
                        )
                    )
                ) {
                    //Si encontro quiere decir que hay que hacer push del listadoExamenes
                    let result = this.listadoExamenes.filter(
                        (valor) => valor[propiedadObjeto] === value
                    );
                    //Como el resultado del filter es un Array de objetos, procedemos
                    //a hacer push uno a uno dentro del for
                    for (let res of result) {
                        this.filtradoExamenes.push(res);
                    }
                } else {
                    //Si no encontro quiere decir que tenemos que hacer el filtrado pero con el array
                    //de filtradoExamenes
                    this.filtradoExamenes = this.filtradoExamenes.filter(
                        (valor) => valor === propiedadObjeto
                    );
                }
            } else {
                //Si hay un solo item seleccionado para el filtro
                //directamente igualamos el filter a nuestro filtradoExamenes
                this.filtradoExamenes = this.listadoExamenes.filter(
                    (valor) => valor[propiedadObjeto] === value
                );
            }

            //Si saco el check sacamos
        } else {
            let index = this.filtradoSeleccionados.indexOf(value);
            this.filtradoSeleccionados.splice(index, 1);
            //Si hay algun checkbox seleccionado
            if (this.filtradoSeleccionados.length > 0) {
                //En este caso filtramos todo lo que no sea igual a lo que
                //acaba de saca el check dierctamente del array
                //de filtrado examenes
                this.filtradoExamenes = this.filtradoExamenes.filter(
                    (valor) => valor[propiedadObjeto] !== value
                );
                //Si el array queda en 0 de lenght
                //procedemos a hacer un filtro de los checkbox que quedan aun seleccionados
                if (this.filtradoExamenes.length < 1) {
                    for (let element of this.filtradoSeleccionados) {
                        let result = this.listadoExamenes.filter(
                            (valor) => valor === element
                        );
                        for (let res of result) {
                            this.filtradoExamenes.push(res);
                        }
                    }
                }
            }
            //Si saco todos los checkbox directamente volvemos a la lista original y completa
            else {
                this.filtradoExamenes = [...this.listadoExamenes];
            }
        }
        console.log(
            "Este es el vector de filtrados",
            this.filtradoSeleccionados
        );
        console.log("Este es el array filtrado", this.filtradoExamenes);
    }

    verListaPreguntas(ID) {
        console.log("El ID", ID);
        this.dialogRef = this._matDialog.open(ModalListaPreguntasComponent, {
            data: {
                id: ID,
            },
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            this.getListaExamenes();
        });
    }

    verInforme(id_examen) {
        sessionStorage.setItem("id_examen", id_examen);
        this.route.navigateByUrl("/evaluaciones");
    }
}
